import { FC, useMemo } from 'react';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { Checkbox, StackedTextCell } from '@retail/components';
import { Stack, Typography } from '@mui/material';
import { MGIcon } from '@shared/custom-icons';
import { AgreementsTableActions } from './AgreementsTableActions';

interface Props {
  agreements: PurchaseAgreement[];
  compareMode?: boolean;
  checkedAgreements: PurchaseAgreement[];
  handleCheckAgreement: (agreement: PurchaseAgreement) => void;
  setStandardAgreement?: (purchaseAgreement: PurchaseAgreement | null) => void;
}

type GridColAgreement = GridColDef<PurchaseAgreement>;

const baseColumnProps: Partial<GridColAgreement> = {
  minWidth: 60,
  align: 'center',
  headerAlign: 'center',
  sortable: false,
  flex: 1,
};

export const PurchaseAgreementsTable: FC<Props> = ({
  agreements,
  compareMode = false,
  checkedAgreements,
  handleCheckAgreement,
  setStandardAgreement,
}) => {
  const t = usePurchaseAgreementsTFunction();
  const columns: GridColAgreement[] = useMemo(() => {
    const checkboxColDef: GridColAgreement = {
      ...baseColumnProps,
      field: 'action',
      width: 60,
      flex: 0,
      headerName: t('purchaseAgreements.choose'),
      renderCell: ({ row }) => (
        <Checkbox
          labelplacement="top"
          checked={checkedAgreements.map((agreement) => agreement.id).includes(row.id)}
          onClick={() => handleCheckAgreement(row)}
        />
      ),
    };
    const nameColDef: GridColAgreement = {
      ...baseColumnProps,
      field: 'name',
      align: 'left',
      headerAlign: 'left',
      width: 140,
      headerName: t('purchaseAgreements.agreement.columns.agreementText'),
      renderCell: ({ row }) => (
        <StackedTextCell
          nullableBottomLine
          topLine={row.name}
          bottomLine={
            row.standardAgreement ? (
              <Typography variant="body3" justifyContent="center">
                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                  <MGIcon fontSize="small" />
                  {t('purchaseAgreements.agreement.columns.standardAgreement')}
                </Stack>
              </Typography>
            ) : undefined
          }
        />
      ),
    };
    const sourceColDef: GridColAgreement = {
      ...baseColumnProps,
      field: 'source',
      headerName: t('purchaseAgreements.agreement.columns.source'),
    };
    const sourceAgreementIdColDef: GridColAgreement = {
      ...baseColumnProps,
      field: 'sourceAgreementId',
      headerName: t('purchaseAgreements.agreement.columns.sourceAgreementId'),
    };
    const idColDef: GridColAgreement = {
      ...baseColumnProps,
      field: 'id',
      headerName: t('purchaseAgreements.agreement.columns.ourId'),
    };
    const setStandardAgreementColDef: GridColAgreement = {
      ...baseColumnProps,
      field: 'actions',
      headerName: '',
      width: 50,
      renderCell: ({ row }) =>
        row.standardAgreement
          ? null
          : setStandardAgreement && (
              <AgreementsTableActions onSetAsStandardAgreement={() => setStandardAgreement(row)} />
            ),
    };

    const columnsToShow = compareMode
      ? [checkboxColDef, nameColDef]
      : [nameColDef, sourceColDef, sourceAgreementIdColDef, idColDef];

    if (setStandardAgreement) {
      columnsToShow.push(setStandardAgreementColDef);
    }
    return columnsToShow;
  }, [checkedAgreements, compareMode, handleCheckAgreement, setStandardAgreement, t]);

  return (
    <DataGridPremium
      columns={columns}
      rows={agreements}
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooter
    />
  );
};
