import { GridColDef } from '@mui/x-data-grid-premium';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { useMemo } from 'react';
import { AssortmentItem } from '@retail/my-assortment/types';
import dayjs from 'dayjs';
import { StackedTextCell } from '@retail/components';
import { PricePerUnit } from '@retail/products/components';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ItemStatus } from '../ItemStatus/ItemStatus';

interface Props {
  enableSortable?: boolean;
  includeVat?: boolean;
}

const baseColumnProps: Partial<GridColDef> = {
  width: 110,
  headerName: '',
  headerAlign: 'center',
  align: 'center',
  sortable: false,
};

export const usePriceListLevelColumns = ({ enableSortable, includeVat }: Props) => {
  const { t } = useMyAssortmentTranslation();
  const { i18n } = useTranslation();

  return useMemo(() => {
    const customerMarkupFactorColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      sortable: enableSortable,
      field: 'chainCustomerMarkupFactor',
      headerName: t('myAssortment.columns.customerMarkup'),

      renderCell: ({ row }) => {
        const fPakUnitPrice = row.currentSalesPrices?.getBracketPackagePrice('F-PAK');
        const customerMarkup = fPakUnitPrice?.bracketMarkupPrice.customerMarkup;

        return customerMarkup?.percent
          ? `${formatPrice(Number(customerMarkup.percent.toFixed(2)), i18n.language)} %`
          : customerMarkup?.salesFactor ?? '-';
      },
    };
    const chainSalesCoverageColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      sortable: enableSortable,
      field: 'chainSalesCoverage',
      headerName: t('myAssortment.columns.salesCoverageChain'),

      renderCell: ({ row }) => {
        const salesPriceCoverage =
          row.currentSalesPrices?.getBracketPackagePrice('F-PAK')?.bracketMarkupPrice.markup
            ?.markupPriceCoverage;

        return salesPriceCoverage
          ? `${formatPrice(Number(salesPriceCoverage.toFixed(2)), i18n.language)} %`
          : '-';
      },
    };
    const customerSalesCoverageColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      sortable: enableSortable,
      field: 'customerSalesCoverage',
      headerName: t('myAssortment.columns.salesCoverageCustomer'),
      renderCell: ({ row }) => {
        const bracketMarkupPrice =
          row.currentSalesPrices?.getBracketPackagePrice('F-PAK')?.bracketMarkupPrice;
        const salesPriceCoverage =
          bracketMarkupPrice?.customerMarkup?.customerPriceCoverage ??
          bracketMarkupPrice?.markup?.markupPriceCoverage;

        return salesPriceCoverage
          ? `${formatPrice(Number(salesPriceCoverage.toFixed(2)), i18n.language)} %`
          : '-';
      },
    };
    const chainSalesPriceColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      field: 'chainSalesPrice',
      headerName: t('myAssortment.columns.chainPrice'),

      width: 230,
      renderCell: ({ row }) => {
        const primaryPackageUnit = row.primaryPackageUnit;
        const fPakUnitPrice = row.currentSalesPrices?.getBracketPackagePrice('F-PAK');
        const packageSalesPrice =
          fPakUnitPrice?.bracketMarkupPrice.getPriceForUnit(primaryPackageUnit);

        const price = includeVat
          ? packageSalesPrice?.markupPrice?.priceWithVat
          : packageSalesPrice?.markupPrice?.priceWithoutVat;

        const unitDescription = packageSalesPrice?.supplierItemPackage.unit.description;

        const validFrom = fPakUnitPrice?.validFrom
          ? dayjs(fPakUnitPrice?.validFrom).format('ll')
          : undefined;
        return (
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            {fPakUnitPrice && (
              <ItemStatus
                status={fPakUnitPrice.bracketMarkupPrice.status}
                errors={fPakUnitPrice.bracketMarkupPrice.errors}
              />
            )}
            <StackedTextCell
              topLine={
                <PricePerUnit
                  price={price ? formatPrice(price, i18n.language) : '-'}
                  unit={primaryPackageUnit}
                  description={unitDescription}
                />
              }
              nullableBottomLine
              bottomLine={validFrom}
            />
          </Box>
        );
      },
    };
    const customerSalesPriceColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      field: 'customerSalesPrice',
      headerName: t('myAssortment.columns.customerPrice'),

      width: 230,
      renderCell: ({ row }) => {
        const primaryPackageUnit = row.primaryPackageUnit;
        const fPakUnitPrice = row.currentSalesPrices?.getBracketPackagePrice('F-PAK');
        const packageSalesPrice =
          fPakUnitPrice?.bracketMarkupPrice.getPriceForUnit(primaryPackageUnit);

        const price = includeVat
          ? packageSalesPrice?.price?.priceWithVat
          : packageSalesPrice?.price?.priceWithoutVat;

        const unitDescription = packageSalesPrice?.supplierItemPackage.unit.description;

        const validFrom = fPakUnitPrice?.validFrom
          ? dayjs(fPakUnitPrice?.validFrom).format('ll')
          : undefined;
        return (
          <StackedTextCell
            topLine={
              <PricePerUnit
                price={price ? formatPrice(price, i18n.language) : '-'}
                unit={primaryPackageUnit}
                description={unitDescription}
              />
            }
            nullableBottomLine
            bottomLine={validFrom}
          />
        );
      },
    };

    return {
      customerMarkupFactorColumn,
      chainSalesCoverageColumn,
      customerSalesCoverageColumn,
      chainSalesPriceColumn,
      customerSalesPriceColumn,
    };
  }, [enableSortable, i18n.language, includeVat, t]);
};
