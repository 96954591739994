import { useCalculusTFunction } from '@retail/calculus/i18n';
import {
  CreateCustomerMarkupSchema,
  CustomerMarkupConditionValueForm,
} from '@retail/customer-markup/components';
import { Supplier } from '@retail/suppliers/types';
import { ContainedButton, Dialog } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import { useCustomerMarkupActions } from '@retail/customer-markup/context';
import { useFetchSelectedLevelOptions } from '@retail/calculus/context';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { DialogActions, DialogContent, DialogTitle, SelectChangeEvent } from '@mui/material';
import { LevelSelectForm } from '@retail/calculus/components';
import { useCallback, useState } from 'react';
import { PriceListAssortmentSelect } from './PriceListAssortmentSelect';

interface Props {
  assortmentId: number[];
  supplier: Supplier;
  includeAssortmentSelect: boolean;
  open: boolean;
  onClose: () => void;
}

function CreateCustomerMarkupConditionDialog({
  assortmentId,
  supplier,
  includeAssortmentSelect,
  open,
  onClose,
}: Props) {
  const t = useCalculusTFunction();
  const {
    handleSubmit,
    control,
    reset,
    resetField,
    formState: { errors },
    watch,
    setError,
  } = useFormContext<CreateCustomerMarkupSchema>();
  const itemSearch = watch('itemSearch');
  const level = watch('level');
  const productGroup = watch('overGroup');
  const discountGroup = watch('discountGroup');
  const item = watch('item');

  const levelController = useController({ name: 'level', control });

  const [selectedAssortmentIds, selectAssortmentId] = useState<number[]>(
    includeAssortmentSelect ? [] : assortmentId
  );

  const { productGroups, mgSupplierItems, discountGroups, isLoadingFetchItems } =
    useFetchSelectedLevelOptions({
      supplierId: supplier.mgSupplierId,
      selectedProductGroup: level === 'productGroup',
      selectedDiscountGroup: level === 'discountGroup',
      itemSearch,
      selectedMgItemNumber: undefined,
      onItemSearchSuccess: (results) => {
        if (!results.result.length) {
          setError('item', { message: t('calculus.error.itemSearch.noResults') });
        }
      },
      onItemSearchError: (err) => {
        console.error(err);
        setError('item', { message: t('calculus.error.itemSearch.generic') });
      },
    });

  const { createCustomerMarkupCondition } = useCustomerMarkupActions({
    assortmentIds: selectedAssortmentIds,
  });

  const onActivate = useCallback(
    ({
      level,
      enteredField,
      fromDate,
      factor,
      percent,
      overGroup,
      mainGroup,
      productGroup,
      discountGroup,
      item,
    }: CreateCustomerMarkupSchema) => {
      createCustomerMarkupCondition({
        mgSupplierId: supplier.mgSupplierId,
        level,
        fromDate,
        enteredField,
        factor: Number(factor),
        percent: Number(percent),
        groupNumber: productGroup?.groupNumber ?? mainGroup?.groupNumber ?? overGroup?.groupNumber,
        groupCode: discountGroup?.code,
        mgItemNumber: item?.mgItemNumber,
      });

      reset();
      onClose();
    },
    [createCustomerMarkupCondition, supplier.mgSupplierId, reset, onClose]
  );

  const handleLevelSelectChange = (value: SelectChangeEvent<unknown>) => {
    resetField('item');
    resetField('overGroup');
    resetField('mainGroup');
    resetField('productGroup');
    resetField('discountGroup');
    resetField('fromDate');
    resetField('toDate');

    levelController.field.onChange(value);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ pb: 1 }}>{t('calculus.createCustomerMarkup')}</DialogTitle>

      <DialogContent>
        <LevelSelectForm
          productGroups={productGroups}
          discountGroups={discountGroups}
          mgSupplierItems={mgSupplierItems}
          isLoadingFetchItems={isLoadingFetchItems}
          handleLevelSelectChange={handleLevelSelectChange}
        />

        {(level === 'supplier' || !!productGroup || !!discountGroup || !!item) && (
          <>
            {includeAssortmentSelect && (
              <PriceListAssortmentSelect
                selectedAssortmentIds={selectedAssortmentIds}
                selectAssortmentId={selectAssortmentId}
              />
            )}

            <CustomerMarkupConditionValueForm />
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ display: 'flex', mt: 1 }}>
        <ContainedButton
          disabled={Object.keys(errors).length > 0 || selectedAssortmentIds.length === 0}
          onClick={handleSubmit(onActivate)}
        >
          {t('calculus.activate')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(CreateCustomerMarkupConditionDialog);
