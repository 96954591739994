import { Stack, Tooltip } from '@mui/material';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { ConditionState } from '@retail/calculus/types';
import { AddButton, CalculusActionPortal } from '@retail/components';
import { Supplier } from '@retail/suppliers/types';
import { FollowPriceSuggestionDialog } from './FollowPriceSuggestion';
import { PriceSuggestionConditionsTableContainer } from './PriceSuggestionConditionsTableContainer';
import { useFetchPriceSuggestionTypesWithCount } from '@retail/price-suggestion/data-access';
import { FollowPriceSuggestionSchema } from '@retail/price-suggestion/components';
import { useFormContext } from 'react-hook-form';

interface Props {
  selectedSupplier: Supplier;
  assortmentId: number;
  typesToShow: ConditionState;
  isCreateOpen: boolean;
  toggleCreateDialog: () => void;
}

export function PriceSuggestionConditions({
  selectedSupplier,
  assortmentId,
  typesToShow,
  isCreateOpen,
  toggleCreateDialog,
}: Props) {
  const t = useCalculusTFunction();

  const { watch } = useFormContext<FollowPriceSuggestionSchema>();

  const { data: priceSuggestionTypesWithCount = [] } = useFetchPriceSuggestionTypesWithCount({
    assortmentId,
    mgSupplierId: selectedSupplier.mgSupplierId,
    productGroup:
      watch('productGroup')?.groupNumber ??
      watch('mainGroup')?.groupNumber ??
      watch('overGroup')?.groupNumber,
    mgItemNumber: watch('item')?.mgItemNumber,
  });

  return (
    <Stack
      flexGrow={1}
      gap={2}
      overflow="auto"
      borderTop={({ palette }) => `1px solid ${palette.divider}`}
    >
      <CalculusActionPortal>
        <Tooltip title={t('calculus.noPriceSuggestion')}>
          <div>
            <AddButton
              disabled={priceSuggestionTypesWithCount.length === 0}
              onClick={toggleCreateDialog}
            >
              {t('calculus.followPriceSuggestionCondition')}
            </AddButton>
          </div>
        </Tooltip>
      </CalculusActionPortal>

      <PriceSuggestionConditionsTableContainer
        supplier={selectedSupplier}
        typesToShow={typesToShow}
        priceSuggestionTypes={priceSuggestionTypesWithCount}
        openCreateDialog={toggleCreateDialog}
      />

      {isCreateOpen && (
        <FollowPriceSuggestionDialog
          supplierId={selectedSupplier.mgSupplierId}
          open={true}
          priceSuggestionTypes={priceSuggestionTypesWithCount}
          onClose={toggleCreateDialog}
        />
      )}
    </Stack>
  );
}
