import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
  FollowPriceSuggestionForm,
  FollowPriceSuggestionSchema,
} from '@retail/price-suggestion/components';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useFetchSelectedLevelOptions } from '@retail/calculus/context';
import { useFormContext } from 'react-hook-form';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useCallback, useEffect } from 'react';
import { PriceSuggestionType, PriceSuggestionTypeWithCount } from '@retail/price-suggestion/types';
import { usePriceSuggestionActions } from '@retail/price-suggestion/context';
import { withDialogSuspenseErrorBoundary } from '@retail/components';

interface Props {
  supplierId: number;
  open: boolean;
  priceSuggestionTypes: PriceSuggestionTypeWithCount[];
  onClose: () => void;
}

function FollowPriceSuggestionDialog({ supplierId, open, priceSuggestionTypes, onClose }: Props) {
  const t = useCalculusTFunction();

  const { activateOrChangeCondition, isLoadingPostChanges } = usePriceSuggestionActions({
    mgSupplierId: supplierId,
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    clearErrors,
  } = useFormContext<FollowPriceSuggestionSchema>();

  const level = watch('level');
  const itemSearch = watch('itemSearch');

  const { productGroups, mgSupplierItems, isLoadingFetchItems } = useFetchSelectedLevelOptions({
    supplierId,
    selectedProductGroup: level === 'productGroup',
    selectedDiscountGroup: level === 'discountGroup',
    itemSearch,
    onItemSearchSuccess: (results) => {
      if (!results.result.length) {
        setError('item', { message: t('calculus.error.itemSearch.noResults') });
      }
    },
    onItemSearchError: (err) => {
      console.error(err);
      setError('item', { message: t('calculus.error.itemSearch.generic') });
    },
  });

  useEffect(() => {
    clearErrors('item');
  }, [clearErrors, itemSearch]);

  const submitPriceSuggestion = useCallback(
    (data: FollowPriceSuggestionSchema) => {
      activateOrChangeCondition({
        groupNumber:
          data.productGroup?.groupNumber ??
          data.mainGroup?.groupNumber ??
          data.overGroup?.groupNumber,
        mgItemNumber: data.item?.mgItemNumber,
        gtinCode: 0,
        supplierLevel: data.level === 'supplier',
        fromDate: data.fromDate,
        priceSuggestionType: data.priceSuggestionType as PriceSuggestionType,
      });

      reset();
      onClose();
    },
    [activateOrChangeCondition, onClose, reset]
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{t('calculus.followPriceSuggestionCondition')}</DialogTitle>
      <DialogContent>
        <FollowPriceSuggestionForm
          productGroups={productGroups}
          mgSupplierItems={mgSupplierItems}
          priceSuggestionTypes={priceSuggestionTypes}
          isLoadingFetchItems={isLoadingFetchItems}
        />
      </DialogContent>

      <DialogActions>
        <TextButton onClick={onClose}>{t('calculus.cancel')}</TextButton>
        <ContainedButton
          size="small"
          color="secondary"
          disabled={Object.keys(errors).length > 0 || priceSuggestionTypes.length === 0}
          onClick={handleSubmit(submitPriceSuggestion)}
          loading={isLoadingPostChanges}
        >
          {t('calculus.toApproval')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(FollowPriceSuggestionDialog);
