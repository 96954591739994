import { conditionDatesSchema, levelSelectSchema } from '@retail/calculus/components';
import { CalculusTFunction } from '@retail/calculus/i18n';
import { EnteredField } from '@retail/customer-markup/types';
import * as yup from 'yup';

export const customerMarkupSchema = (t: CalculusTFunction) =>
  yup.object({
    factor: yup
      .number()
      .typeError(t('calculus.error.mustBeNumber'))
      .transform((_, value: string | null) => {
        if (value) {
          return Number((value + '').replace(',', '.'));
        }
        return null;
      })
      .default(null)
      .required(t('calculus.error.salesFactor')),
    percent: yup
      .number()
      .typeError(t('calculus.error.mustBeNumber'))
      .transform((_, value: string | null) => {
        if (value) {
          return Number((value + '').replace(',', '.'));
        }
        return null;
      })
      .default(null)
      .required(t('calculus.error.salesCoverage')),
    enteredField: yup.mixed().oneOf(Object.values(EnteredField)).default(EnteredField.FACTOR),
  });

export const createCustomerMarkupSchema = (t: CalculusTFunction) =>
  levelSelectSchema(t).concat(customerMarkupSchema(t).concat(conditionDatesSchema(t)));

export const editCustomerMarkupSchema = (t: CalculusTFunction) =>
  customerMarkupSchema(t).concat(conditionDatesSchema(t));

export type CreateCustomerMarkupSchema = yup.InferType<
  ReturnType<typeof createCustomerMarkupSchema>
>;
export type EditCustomerMarkupSchema = yup.InferType<ReturnType<typeof editCustomerMarkupSchema>>;
