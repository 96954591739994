import { Divider, MenuItem, useTheme } from '@mui/material';

import { TrashIcon } from '@sanity/icons';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { AddIcon, PenIcon } from '@shared/custom-icons';
import { Menu, MenuButton } from '@retail/components';

interface Props {
  onEditNameClick: () => void;
  onAddFromFileClick: () => void;
  onDeleteClick: () => void;
}

export const EditAssortmentMenu: React.FC<Props> = ({
  onEditNameClick,
  onAddFromFileClick,
  onDeleteClick,
}) => {
  const { palette } = useTheme();
  const t = useAssortmentTFunction();
  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton color="secondary" size="small" sx={{ whiteSpace: 'nowrap' }}>
            {t('assortment.editAssortment')}
          </MenuButton>
          <MenuItem
            onClick={() => {
              onAddFromFileClick();
              onClose();
            }}
          >
            {t('assortment.addToMyAssortment')}
            <AddIcon stroke={palette.primary.main} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              onEditNameClick();
              onClose();
            }}
          >
            {t('assortment.admin.edit.options.editTitle')}
            <PenIcon fontSize="small" />
          </MenuItem>
          <Divider />
          <MenuItem
            color="warning"
            onClick={() => {
              onDeleteClick();
              onClose();
            }}
          >
            {t('assortment.admin.edit.options.delete')}
            <TrashIcon fontSize="25px" color={palette.common.black} />
          </MenuItem>
        </>
      )}
    </Menu>
  );
};
