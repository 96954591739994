import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { CalculusTabOptions, SupplierWithProductGroupsDTO } from '@retail/calculus/types';
import { useMPEFetch } from '@retail/app/hooks';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';


interface SearchProps {
  assortmentId: number;
  includeOwner?: boolean;
  baseAssortment?: boolean;
  filterItems?: boolean;
  filterConditions?: CalculusTabOptions[];
}

export function useFetchProductGroupsForSupplier(
  {
    assortmentId,
    includeOwner = true,
    baseAssortment = false,
    filterItems = false,
    filterConditions = [],
  }: SearchProps,
  supplierId?: number,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();
  return useMPEFetch<SupplierWithProductGroupsDTO>({
    method: 'GET',
    queryKey: [
      'product-groups-hierarchy',
      supplierId,
      assortmentId,
      filterItems,
      includeOwner,
      filterConditions,
      baseAssortment,
    ],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.assortments.assortment.conditionHierarchy.getFullLinkPath({ assortmentId }),
      searchParams: {
        mgParticipantAccountId: supplierId,
        includeOwner,
        baseAssortment,
        filterItems,
        filterConditions,
      },
    }),
    ...queryOptions,
  });
}
