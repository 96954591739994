import {
  CalculusAreas,
  PriceSuggestionCondition,
  FreightCondition,
  MarkupCondition,
  PurchaseCondition,
  CustomerMarkupCondition,
} from '@retail/calculus/types';

export const isPurchaseCondition = (
  condition:
    | PurchaseCondition
    | FreightCondition
    | MarkupCondition
    | PriceSuggestionCondition
    | CustomerMarkupCondition
): condition is PurchaseCondition => {
  return condition.getType() === CalculusAreas.PURCHASE;
};

export const isFreightCondition = (
  condition:
    | PurchaseCondition
    | FreightCondition
    | MarkupCondition
    | PriceSuggestionCondition
    | CustomerMarkupCondition
): condition is FreightCondition => {
  return condition.getType() === CalculusAreas.FREIGHT;
};

export const isMarkupCondition = (
  condition:
    | PurchaseCondition
    | FreightCondition
    | MarkupCondition
    | PriceSuggestionCondition
    | CustomerMarkupCondition
): condition is MarkupCondition => {
  return condition.getType() === CalculusAreas.MARKUP;
};

export const isPriceSuggestionCondition = (
  condition:
    | PurchaseCondition
    | FreightCondition
    | MarkupCondition
    | PriceSuggestionCondition
    | CustomerMarkupCondition
): condition is PriceSuggestionCondition => {
  return condition.getType() === CalculusAreas.PRICE_SUGGESTION;
};

export const isCustomerMarkupCondition = (
  condition:
    | PurchaseCondition
    | FreightCondition
    | MarkupCondition
    | PriceSuggestionCondition
    | CustomerMarkupCondition
): condition is CustomerMarkupCondition => {
  return condition.getType() === CalculusAreas.CUSTOMER_MARKUP;
};
