import { PageLayout } from '@retail/components';
import {
  MemberAssortmentFunctionsWrapper,
  MgSupplierItemSearchContainer,
} from '@retail/products/containers';
import { Paper } from '@mui/material';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { BrandAssortmentFunctionsWrapper } from '@retail/mgpdm/containers';

export function MgSupplierItemSearchPage() {
  const { selectedBrandContext, selectedPriceList } = useSelectedOrgUnit();

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 0,
          pt: 2,
          overflow: 'hidden',
        }}
      >
        {selectedBrandContext ? (
          <BrandAssortmentFunctionsWrapper>
            {({
              addItemsToAssortment,
              isLoading,
              assortmentId,
              mgItemLinkPath,
              assortmentLinkPath,
              monitoringAssortments,
            }) => (
              <MgSupplierItemSearchContainer
                addItemsToAssortment={addItemsToAssortment}
                isLoading={isLoading}
                assortmentId={assortmentId}
                mgItemLinkPath={mgItemLinkPath}
                assortmentLinkPath={assortmentLinkPath}
                monitoringAssortments={monitoringAssortments}
              />
            )}
          </BrandAssortmentFunctionsWrapper>
        ) : selectedPriceList ? (
          <MgSupplierItemSearchContainer
            addItemsToAssortment={undefined}
            isLoading={undefined}
            assortmentId={selectedPriceList.draftAssortmentId}
            mgItemLinkPath={undefined}
            assortmentLinkPath={undefined}
            monitoringAssortments={undefined}
          />
        ) : (
          <MemberAssortmentFunctionsWrapper>
            {({
              addItemsToAssortment,
              isLoading,
              assortmentId,
              mgItemLinkPath,
              assortmentLinkPath,
              monitoringAssortments,
            }) => (
              <MgSupplierItemSearchContainer
                addItemsToAssortment={addItemsToAssortment}
                isLoading={isLoading}
                assortmentId={assortmentId}
                mgItemLinkPath={mgItemLinkPath}
                assortmentLinkPath={assortmentLinkPath}
                monitoringAssortments={monitoringAssortments}
              />
            )}
          </MemberAssortmentFunctionsWrapper>
        )}
      </Paper>
    </PageLayout>
  );
}
