import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { Brand, Department, Member, PriceList } from '@retail/auth/types';
import { useUserBrands } from './BrandContext';
import { useNavigate } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useMountEffect } from '@shared/hooks';
import { useMyOrgUnits } from './MyOrgUnitsProvider';
import * as Sentry from '@sentry/react';

export const selectedOrgUnitKey = 'selectedOrgUnit';

interface SelectedOrgUnit {
  type: 'BRAND' | 'MEMBER' | 'DEPARTMENT' | 'PRICE_LIST';
  id: number;
  name: string;
  assortmentId: number;
  draftAssortmentId?: number;
}

type SelectedOrgUnitState = {
  selectedDepartment: Department | undefined;
  selectedMember: Member | undefined;
  selectedPriceList: PriceList | undefined;
  selectedBrandContext: Brand | undefined;
  selectedOrgUnit: SelectedOrgUnit;
  selectDepartment: (department: Department) => void;
  selectMember: (member: Member) => void;
  selectBrand: (brand: Brand) => void;
  selectPriceList: (priceList: PriceList) => void;
  selectedOrgUnitId: number;
};

const SelectedOrgUnitContext = createContext<SelectedOrgUnitState>({} as SelectedOrgUnitState);

const SelectedOrgUnitProvider = (props: { children: ReactNode }) => {
  const navigate = useNavigate();

  const { selectedBrand } = useUserBrands();
  const { departments, members, brand, priceLists } = useMyOrgUnits();

  /**
   * Ignore member context for now
   */
  const selectedOrgUnit: SelectedOrgUnit = useMemo(() => {
    const storedOrgUnitString: string = localStorage.getItem(selectedOrgUnitKey) || '';
    if (storedOrgUnitString) {
      const parsedOrgUnit: SelectedOrgUnit | null = JSON.parse(storedOrgUnitString);
      if (
        parsedOrgUnit?.type === 'DEPARTMENT' &&
        departments.some(({ orgUnitId }) => orgUnitId === parsedOrgUnit.id)
      ) {
        return parsedOrgUnit;
      } else if (
        parsedOrgUnit?.type === 'PRICE_LIST' &&
        priceLists.some(
          ({ orgUnitId, assortmentId }) =>
            orgUnitId === parsedOrgUnit.id && assortmentId === parsedOrgUnit.assortmentId
        )
      ) {
        return parsedOrgUnit;
      } else if (parsedOrgUnit?.type === 'BRAND' && brand?.orgUnitId === parsedOrgUnit.id) {
        return parsedOrgUnit;
      }
    }

    const firstDepartment = departments[0];
    if (firstDepartment)
      return {
        type: 'DEPARTMENT',
        id: firstDepartment.orgUnitId,
        name: firstDepartment.name,
        assortmentId: firstDepartment.assortmentId,
        draftAssortmentId: firstDepartment.draftAssortmentId,
      };

    if (brand)
      return {
        type: 'BRAND',
        id: brand.orgUnitId,
        name: brand.name,
        assortmentId: brand.assortmentId,
      };

    throw new Error(
      `User has no org unit connections for brand ${selectedBrand.name} (id: ${selectedBrand.orgUnitId})`
    );
  }, [brand, departments, priceLists, selectedBrand.name, selectedBrand.orgUnitId]);

  useEffect(() => {
    Sentry.setTag('orgUnitId', selectedOrgUnit.id);
  }, [selectedOrgUnit]);

  useMountEffect(() => {
    const storedOrgUnitString = localStorage.getItem(selectedOrgUnitKey);
    if (!storedOrgUnitString) {
      localStorage.setItem(selectedOrgUnitKey, JSON.stringify(selectedOrgUnit));
    }
  });

  const selectedDepartment = useMemo(() => {
    if (selectedOrgUnit.type === 'DEPARTMENT') {
      return departments.find(({ orgUnitId }) => orgUnitId === selectedOrgUnit.id);
    }
    return undefined;
  }, [departments, selectedOrgUnit.id, selectedOrgUnit.type]);

  const selectedMember = useMemo(() => {
    if (selectedOrgUnit.type === 'MEMBER') {
      return members.find(({ orgUnitId }) => orgUnitId === selectedOrgUnit.id);
    }
    return undefined;
  }, [members, selectedOrgUnit.id, selectedOrgUnit.type]);

  const selectedPriceList = useMemo(() => {
    if (selectedOrgUnit.type === 'PRICE_LIST') {
      return priceLists.find(({ assortmentId }) => assortmentId === selectedOrgUnit.assortmentId);
    }
    return undefined;
  }, [priceLists, selectedOrgUnit.assortmentId, selectedOrgUnit.type]);

  const selectedBrandContext = useMemo(() => {
    if (selectedOrgUnit.type === 'BRAND') {
      return selectedBrand;
    }
    return undefined;
  }, [selectedBrand, selectedOrgUnit.type]);

  const selectDepartment = useCallback(
    (department: Department) => {
      const departmentOrgUnit: SelectedOrgUnit = {
        type: 'DEPARTMENT',
        id: department.orgUnitId,
        name: department.name,
        assortmentId: department.assortmentId,
        draftAssortmentId: department.draftAssortmentId,
      };
      localStorage.setItem(selectedOrgUnitKey, JSON.stringify(departmentOrgUnit));
      navigate(mgPrisRoutes.root.fullRoutePath);
      window.location.reload();
    },
    [navigate]
  );

  const selectMember = useCallback(
    (member: Member) => {
      const memberOrgUnit: SelectedOrgUnit = {
        type: 'MEMBER',
        id: member.orgUnitId,
        name: member.name,
        assortmentId: member.assortmentId,
        draftAssortmentId: member.draftAssortmentId,
      };
      localStorage.setItem(selectedOrgUnitKey, JSON.stringify(memberOrgUnit));
      navigate(mgPrisRoutes.root.fullRoutePath);
      window.location.reload();
    },
    [navigate]
  );

  const selectPriceList = useCallback(
    (priceList: PriceList) => {
      const priceListOrgUnit: SelectedOrgUnit = {
        type: 'PRICE_LIST',
        id: priceList.orgUnitId,
        name: priceList.name,
        assortmentId: priceList.assortmentId,
        draftAssortmentId: priceList.draftAssortmentId,
      };
      localStorage.setItem(selectedOrgUnitKey, JSON.stringify(priceListOrgUnit));
      navigate(mgPrisRoutes.root.fullRoutePath);
      window.location.reload();
    },
    [navigate]
  );

  const selectBrand = useCallback(
    (brand: Brand) => {
      const brandOrgUnit: SelectedOrgUnit = {
        type: 'BRAND',
        id: brand.orgUnitId,
        name: brand.name,
        assortmentId: brand.assortmentId,
      };
      localStorage.setItem(selectedOrgUnitKey, JSON.stringify(brandOrgUnit));
      navigate(mgPrisRoutes.root.fullRoutePath);
      window.location.reload();
    },
    [navigate]
  );

  const value: SelectedOrgUnitState = useMemo(
    () => ({
      selectedDepartment,
      selectedMember,
      selectedPriceList,
      selectedBrandContext,
      selectedOrgUnit,
      selectBrand,
      selectMember,
      selectDepartment,
      selectPriceList,
      selectedOrgUnitId: selectedOrgUnit.id,
    }),
    [
      selectBrand,
      selectDepartment,
      selectMember,
      selectPriceList,
      selectedBrandContext,
      selectedDepartment,
      selectedOrgUnit,
      selectedMember,
      selectedPriceList,
    ]
  );

  return (
    <SelectedOrgUnitContext.Provider value={value}>
      {props.children}
    </SelectedOrgUnitContext.Provider>
  );
};

const useSelectedOrgUnit = () => {
  return useContext(SelectedOrgUnitContext);
};

export { SelectedOrgUnitProvider, useSelectedOrgUnit };
