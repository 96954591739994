import { SvgIcon, SvgIconProps } from '@mui/material';

export const MGIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="43"
        height="28"
        viewBox="0 0 43 28"
        fill="none"
      >
        <path
          d="M42.9614 5.51581V0.453125H21.4691L12.5244 9.39295L3.57979 0.453125H0V25.502H5.06365V9.09464L12.5244 16.5516L19.9843 9.09464V24.0085L19.9881 25.502H42.9614V10.2937H37.8977V20.4384H25.0518V5.51581H42.9614Z"
          fill="url(#paint0_linear_6375_14004)"
        />

        <defs>
          <linearGradient
            id="paint0_linear_6375_14004"
            x1="45.1095"
            y1="17.6742"
            x2="6.81813"
            y2="-8.36466"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.104167" stopColor="#0530C9" />
            <stop offset="0.286458" stopColor="#0331D0" />
            <stop offset="0.484375" stopColor="#0A39E0" />
            <stop offset="1" stopColor="#315CF5" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
