import { ReadMore } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
interface Props {
  viewCalculusCard: () => void;
}
export const AssortmentItemActions = ({ viewCalculusCard }: Props) => {
  const t = useMyAssortmentTFunction();
  return (
    <Stack flexDirection="row" alignItems="center">
      <Tooltip title={t('myAssortment.actions.seeCalculusCard')}>
        <IconButton onClick={viewCalculusCard}>
          <ReadMore />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
