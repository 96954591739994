import { Stack } from '@mui/material';
import {
  AssortmentItemsTable,
  deletionFromDateSchema,
  MarkupChange,
  MarkupMetaSchema,
} from '@retail/my-assortment/components';
import { Paper, UniversalFormProvider } from '@shared/components';
import { AssortmentItemsTableToolbar } from './AssortmentItemsTableToolbar';
import {
  AssortmentItem,
  AssortmentTab,
  FilterOptions,
  FilterSelection,
  SetFilterOptions,
} from '@retail/my-assortment/types';
import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { Pagination, useDisclosure, useSelectedSearchParamState } from '@shared/hooks';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { useIncludeVatMyAssortment } from '@retail/my-assortment/context';
import { useLanguageSelector } from '@retail/app/hooks';
import { ProductCardSuspenseContainer } from '../ProductCard/ProductCardSuspenseContainer';
import {
  useIsFeatureEnabled,
  useSelectedOrgUnit,
  useUserBrands,
} from '@retail/app/stores/selected-context';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { DeleteItemsDialogContainer } from './DeleteItemsDialogContainer';
import { useCreateMarkup } from '@retail/markup/context';
import { Competitor } from '@retail/retailer/types';
import { useFetchCompetitorPrices } from '@retail/my-assortment/data-access';
import { CompetitorPrice, CompetitorPricesResponse } from '@retail/products/types';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  assortmentItems: AssortmentItem[];
  searchFilters: FilterOptions;
  setSearchFilters: SetFilterOptions;
  filterOptions: FilterSelection;
  totalItems: number;
  pagination: Pagination;
  selectedTab: AssortmentTab;
  showFilters: boolean;
  competitors: Competitor[];
  toggleFilters: () => void;
  toggleAddItemsDialog: () => void;
}

export function AssortmentItems({
  assortmentItems,
  searchFilters,
  setSearchFilters,
  totalItems,
  pagination,
  selectedTab,
  competitors,
  showFilters,
  toggleFilters,
  toggleAddItemsDialog,
}: Props) {
  const t = useAssortmentTFunction();
  const { language } = useLanguageSelector();

  const createMarkupDialog = useDisclosure();
  const queryClient = useQueryClient();

  const { includeVat } = useIncludeVatMyAssortment();
  const { selectedBrand } = useUserBrands();
  const { selectedPriceList, selectedDepartment } = useSelectedOrgUnit();
  const baseAssortmentId = (selectedDepartment?.assortmentId ?? selectedPriceList?.assortmentId)!;

  const {
    createMgItemLevelSalesPriceCondition,
    isLoadingPostChanges: isLoadingSalesPriceCondition,
  } = useCreateMarkup();

  const isPriceSuggestionEnabled = useIsFeatureEnabled('price-suggestion');
  const disableItemRemoval = selectedBrand.wholesaler;

  const getSupplierCalculusLink = (supplierId: number) =>
    mgPrisRoutes.calculus.supplier.getFullLinkPath({ supplierId });

  const [selectedItem, setSelectedItem, setSelectedItemId] = useSelectedSearchParamState({
    options: assortmentItems,
    searchParamKey: 'item',
    getOptionId: ({ id }) => id,
  });

  const mgItemNumbers = useMemo(
    () => assortmentItems.map((item) => item.mgItemNumber),
    [assortmentItems]
  );

  const competitorStoreIds = useMemo(
    () => competitors.map((competitor) => competitor.id),
    [competitors]
  );

  const { mutateAsync: fetchCompetitorPrices } = useFetchCompetitorPrices({
    storeIds: competitorStoreIds,
    nPrices: 2,
    enabled: mgItemNumbers.length > 0 && selectedTab === AssortmentTab.COMPETITOR_PRICES,
  });

  const handleFetchCompetitorPrices = useCallback(async () => {
    return fetchCompetitorPrices({
      body: {
        mgItemNumbers,
      },
    }).then((response) => {
      const transformedData = Object.fromEntries(
        Object.entries(response).map(([outerKey, innerRecord]) => [
          Number(outerKey),
          Object.fromEntries(
            Object.entries(innerRecord).map(([innerKey, values]) => [
              Number(innerKey),
              values.map((dto) => new CompetitorPrice(dto)),
            ])
          ),
        ])
      ) as CompetitorPricesResponse;

      return transformedData;
    });
  }, [fetchCompetitorPrices, mgItemNumbers]);

  const viewItem = useCallback(
    (itemId: number) => {
      if (selectedItem?.id === itemId) {
        setSelectedItemId(null);
        !showFilters && toggleFilters();
      } else {
        setSelectedItemId(itemId);
        showFilters && toggleFilters();
      }
    },
    [selectedItem?.id, setSelectedItemId, showFilters, toggleFilters]
  );
  const [itemsChecked, setItemsChecked] = useState<number[]>([]);
  const [itemsForDeletion, setItemsForDeletion] = useState<number[]>([]);

  const setCheckedItemsForDeletion = useCallback(
    () => setItemsForDeletion(itemsChecked),
    [itemsChecked]
  );
  const setItemForDeletion = useCallback((itemId: number) => {
    setItemsForDeletion([itemId]);
  }, []);

  const resetSelectedItems = () => {
    setItemsForDeletion([]);
    setItemsChecked([]);
  };
  const onCloseDeleteItemsDialog = useCallback(() => setItemsForDeletion([]), []);

  const createCondition = useCallback(
    (
      apiRef: MutableRefObject<GridApiPremium>,
      { fromDate, markupName }: MarkupMetaSchema,
      {
        salesPrice: newSalesPrice,
        salesPriceIncVat,
        mgItemNumber,
        mgSupplierId,
        id,
      }: Partial<MarkupChange> & Pick<AssortmentItem, 'id' | 'mgItemNumber' | 'mgSupplierId'>,
      unit: string
    ) => {
      createMgItemLevelSalesPriceCondition({
        mgSupplierId,
        mgItemNumber,
        validFrom: fromDate,
        templateName: markupName,
        salesPrice: (includeVat ? salesPriceIncVat : newSalesPrice) ?? 0,
        incVat: includeVat,
        unit,
      }).then(() => {
        apiRef?.current.stopCellEditMode({ id, field: 'salesPrice' });
        queryClient.invalidateQueries({ queryKey: ['assortmentItems'] });
        createMarkupDialog.onClose();
      });
    },

    [createMarkupDialog, createMgItemLevelSalesPriceCondition, includeVat, queryClient]
  );

  return (
    <Paper sx={{ display: 'flex', flexGrow: 1, p: 0, overflow: 'auto' }}>
      <Stack flexGrow={1}>
        <AssortmentItemsTableToolbar
          {...searchFilters}
          totalItems={totalItems}
          noItemsChecked={!itemsChecked.length}
          baseAssortmentId={baseAssortmentId}
          showFilters={showFilters}
          disableItemRemoval={disableItemRemoval}
          openFilters={toggleFilters}
          setSearchFilters={setSearchFilters}
          deleteCheckedItems={setCheckedItemsForDeletion}
          deletionIsLoading={false}
        />

        <Stack flexDirection="row" flexGrow={1} overflow="hidden">
          <AssortmentItemsTable
            {...pagination}
            {...setSearchFilters}
            items={assortmentItems}
            totalItems={totalItems}
            selectedItem={selectedItem}
            itemsChecked={itemsChecked}
            selectedTab={selectedTab}
            language={language}
            selectedSupplier={searchFilters.selectedSupplier?.mgSupplierId}
            includeVat={includeVat}
            competitors={competitors}
            getSupplierCalculusLink={getSupplierCalculusLink}
            viewItemProductCard={viewItem}
            toggleAddItemsDialog={toggleAddItemsDialog}
            setItemsChecked={setItemsChecked}
            deleteItem={setItemForDeletion}
            createCondition={createCondition}
            createMarkupDialog={createMarkupDialog}
            disableItemRemoval={disableItemRemoval}
            showBracketPriceColumns={selectedBrand.wholesaler}
            isPriceSuggestionEnabled={isPriceSuggestionEnabled}
            handleFetchCompetitorPrices={handleFetchCompetitorPrices}
            isPriceListLevel={!!selectedPriceList}
            isLoadingSalesPriceCondition={isLoadingSalesPriceCondition}
          />
          {selectedItem && (
            <ProductCardSuspenseContainer
              item={{
                primaryText: selectedItem.primaryText,
                secondaryText: selectedItem.secondaryText,
                mgItemNumber: selectedItem.mgItemNumber,
                mgSupplierId: selectedItem.mgSupplierId,
              }}
              closeProductCard={() => {
                setSelectedItem(null);
                !showFilters && toggleFilters();
              }}
            />
          )}
        </Stack>
      </Stack>

      {itemsForDeletion.length > 0 && (
        <UniversalFormProvider
          schema={deletionFromDateSchema(t)}
          defaultValues={deletionFromDateSchema(t).getDefault()}
        >
          <DeleteItemsDialogContainer
            assortmentItems={assortmentItems}
            itemsForDeletion={itemsForDeletion}
            onClose={onCloseDeleteItemsDialog}
            resetSelectedItems={resetSelectedItems}
          />
        </UniversalFormProvider>
      )}
    </Paper>
  );
}
