import { conditionDatesSchema, levelSelectSchema } from '@retail/calculus/components';
import { CalculusTFunction } from '@retail/calculus/i18n';
import * as yup from 'yup';

export const followPriceSuggestionSchema = (t: CalculusTFunction) =>
  levelSelectSchema(t)
    .concat(conditionDatesSchema(t))
    .concat(
      yup.object({
        priceSuggestionType: yup.string().default('').required(),
      })
    );

export type FollowPriceSuggestionSchema = yup.InferType<
  ReturnType<typeof followPriceSuggestionSchema>
>;
