import { conditionDatesSchema, levelSelectSchema } from '@retail/calculus/components';
import { CalculusTFunction } from '@retail/calculus/i18n';
import * as yup from 'yup';


const freightTypes = ['percent', 'fixedAddon'];

export const freightConditionsSchema = (t: CalculusTFunction) =>
  levelSelectSchema(t).concat(
    yup.object({
      freightType: yup
        .mixed()
        .default('')
        .when('level', {
          is: 'item',
          then: yup
            .mixed()
            .oneOf(Object.values(freightTypes), t('calculus.error.requiredField'))
            .default(''),
        }),

      freightFactor: yup
        .number()
        .typeError(t('calculus.error.mustBeNumberBetween1And2'))
        .transform((_, value: string | null) => {
          if (value) {
            return Number((value + '').replace(',', '.'));
          }
          return null;
        })
        .max(1, t('calculus.error.mustBeNumberBetween1And2'))
        .max(2, t('calculus.error.mustBeNumberBetween1And2'))
        .positive(t('calculus.error.mustBeNumberBetween1And2'))
        .nullable()
        .default(null),
      percentAddon: yup
        .number()
        .typeError(t('calculus.error.mustBeNumberBetween'))
        .nullable()
        .default(null)
        .when('freightType', {
          is: (freightType: string) => freightType === '' || freightType === 'percent',
          then: yup
            .number()
            .typeError(t('calculus.error.mustBeNumberBetween'))
            .transform((_, value: string | null) => {
              if (value) {
                return Number((value + '').replace(',', '.'));
              }
              return null;
            })
            .max(0, t('calculus.error.mustBeNumberBetween'))
            .max(100, t('calculus.error.mustBeNumberBetween'))
            .positive(t('calculus.error.mustBeNumberBetween'))
            .required(t('calculus.error.requiredField')),
        }),
      fixedAddon: yup
        .number()
        .default(null)
        .nullable()
        .when('freightType', {
          is: 'fixedAddon',
          then: yup
            .number()
            .typeError(t('calculus.error.mustBeNumber'))
            .transform((_, value: string | null) => {
              if (value) {
                return Number((value + '').replace(',', '.'));
              }
              return null;
            })
            .positive(t('calculus.error.mustBeNumber'))
            .required(t('calculus.error.requiredField')),
        }),
      fixedAddonUnit: yup
        .string()
        .default(null)
        .nullable()
        .when('freightType', {
          is: 'fixedAddon',
          then: yup
            .string()
            .typeError(t('calculus.error.unitIsRequired'))
            .required(t('calculus.error.requiredField')),
        }),
    })
  );

export const createFreightConditionSchema = (t: CalculusTFunction) =>
  conditionDatesSchema(t).concat(freightConditionsSchema(t));

export const editFreightConditionSchema = (t: CalculusTFunction) =>
  conditionDatesSchema(t).concat(freightConditionsSchema(t));

export type CreateFreightConditionSchema = yup.InferType<
  ReturnType<typeof createFreightConditionSchema>
>;
export type EditFreightConditionSchema = yup.InferType<
  ReturnType<typeof editFreightConditionSchema>
>;
