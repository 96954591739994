import { MutableRefObject, useMemo } from 'react';
import { AssortmentItem, AssortmentTab } from '@retail/my-assortment/types';
import { FormattedPrice, StackedTextCell } from '@retail/components';
import { dataGridBoldClass } from '@shared/styles';
import { ItemStatus } from './ItemStatus/ItemStatus';
import dayjs from 'dayjs';
import { Stack } from '@mui/material';
import { DecreaseIcon, IncreaseIcon } from '@shared/custom-icons';
import { formatPrice } from '@retail/products/utils';
import { AssortmentTableActions } from './AssortmentTableActions';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-premium';
import { BracketPriceCell } from './BracketPriceCell';
import { PriceResultType } from '@retail/products/types';
import { PriceResultTypeTooltip } from './PriceResultTypeTooltip';
import { calculatePercentChange } from '@retail/utils';
import { useItemInfoColumns } from './AssortmentColumns/useItemInfoColumns';
import {
  RowPriceProps,
  useCurrentItemPriceColumns,
  usePriceListLevelColumns,
} from './AssortmentColumns';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { MarkupChange, MarkupMetaSchema } from '../ProductCard';

type GridColAssortmentItem = GridColDef<AssortmentItem>;

export interface ShowColumnProps {
  selectedTab: AssortmentTab;
  showBracketPriceColumns: boolean;
  isPriceListLevel?: boolean;
  isPriceSuggestionEnabled: boolean;
}

interface Props {
  disableItemRemoval?: boolean;
  deleteItem: (itemId: number) => void;
  getSupplierCalculusLink?: (supplierId: number) => string;
  selectSupplier: (supplierId: number) => void;
  selectProductGroup: (productGroupNumber: string) => void;
  selectDiscountGroup: (
    discountGroupId: number,
    addContiguousSearchParamKey?: { searchParam: string; value: string }
  ) => void;
  selectRowId?: (rowId: number) => void;
}

type ColumnConfig = {
  show: (props: ShowColumnProps) => boolean;
  getColumn: () => GridColAssortmentItem;
};

const baseColumnProps: Partial<GridColDef> = {
  width: 130,
  headerName: '',
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

export const useAssortmentColumns = ({
  apiRef,
  includeVat,
  enableSortable,
  disableItemRemoval,
  createMarkupDialog,
  isLoadingSalesPriceCondition,
  createCondition,
  deleteItem,
  getSupplierCalculusLink,
  selectSupplier,
  selectProductGroup,
  selectDiscountGroup,
  selectRowId,
}: Props & RowPriceProps): ColumnConfig[] => {
  const { t } = useMyAssortmentTranslation();
  const { i18n } = useTranslation();

  const { itemIdsColumn, itemNameColumn, supplierColumn, itemGroupColumn, discountGroupColumn } =
    useItemInfoColumns({
      enableSortable,
      selectSupplier,
      selectProductGroup,
      selectDiscountGroup,
    });

  const { purchasePriceColumn, costPriceInSalesUnitColumn, costPriceColumn, salesPriceColumn } =
    useCurrentItemPriceColumns({
      apiRef,
      includeVat,
      enableSortable,
      createMarkupDialog,
      isLoadingSalesPriceCondition,
      createCondition,
    });

  const {
    customerMarkupFactorColumn,
    chainSalesCoverageColumn,
    customerSalesCoverageColumn,
    chainSalesPriceColumn,
    customerSalesPriceColumn,
  } = usePriceListLevelColumns({
    includeVat,
    enableSortable,
  });

  return useMemo(
    () => [
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => ({
          ...baseColumnProps,
          sortable: enableSortable,
          field: 'status',
          headerAlign: 'center',
          align: 'center',
          width: 40,
          headerName: '',
          renderCell: ({ value }) => <ItemStatus status={value} />,
        }),
      },
      {
        show: () => true,
        getColumn: () => itemIdsColumn,
      },
      {
        show: () => true,
        getColumn: () => itemNameColumn,
      },

      {
        show: () => true,
        getColumn: () => supplierColumn,
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => itemGroupColumn,
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => discountGroupColumn,
      },
      {
        show: ({ selectedTab }) => selectedTab !== AssortmentTab.COMPETITOR_PRICES,
        getColumn: () => purchasePriceColumn,
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.PRICE_CHANGES,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'futurePurchasePrice',
          headerName: t('myAssortment.columns.futurePurchasePrice'),
          width: 180,
          renderCell: ({ row }) => {
            const firstFuturePrice = row.futureSalesPrices[0];
            const fPakFuturePriceCalculation = firstFuturePrice?.getBracketPackagePrice('F-PAK');
            const purchasePriceInPrimaryUnit =
              fPakFuturePriceCalculation?.purchasePrice?.getPriceForUnit(row.primaryPackageUnit);

            if (!(purchasePriceInPrimaryUnit && fPakFuturePriceCalculation)) return '-';
            return (
              <StackedTextCell
                nullableBottomLine
                topLine={t('myAssortment.pricePerUnit', {
                  price: formatPrice(purchasePriceInPrimaryUnit.price.price, i18n.language),
                  unit: row.primaryPackageUnit,
                })}
                bottomLine={dayjs(fPakFuturePriceCalculation.validFrom).format('ll')}
              />
            );
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.PRICE_CHANGES,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'purchasePriceChange',
          headerName: t('myAssortment.columns.change'),
          sortable: enableSortable,
          renderCell: ({ row }) => {
            const primaryPackageUnit = row.primaryPackageUnit;
            const purchasePrice =
              row.getCurrentPurchasePriceForUnit(primaryPackageUnit)?.price.price;

            const firstFuturePrice = row.futureSalesPrices[0];
            const fPakFuturePriceCalculation = firstFuturePrice?.getBracketPackagePrice('F-PAK');
            const futurePurchasePrice =
              fPakFuturePriceCalculation?.purchasePrice?.getPriceForUnit(primaryPackageUnit)?.price
                .price;

            if (!purchasePrice || !futurePurchasePrice) return '-';
            else if (!!purchasePrice && !!futurePurchasePrice) {
              const changeInValue = futurePurchasePrice - purchasePrice;
              const percentChangeInValue =
                formatPrice(
                  calculatePercentChange(purchasePrice, futurePurchasePrice),
                  i18n.language
                ) + '%';
              return (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  {changeInValue > 0 ? (
                    <IncreaseIcon color="warning" fontSize="small" />
                  ) : changeInValue < 0 ? (
                    <DecreaseIcon color="success" fontSize="small" />
                  ) : null}
                  <StackedTextCell
                    topLine={t('myAssortment.recommendedPrices.value', {
                      value: formatPrice(changeInValue, i18n.language),
                    })}
                    bottomLine={percentChangeInValue}
                  />
                </Stack>
              );
            } else {
              return t('myAssortment.recommendedPrices.value', { value: 0 });
            }
          },
          valueGetter: (_, row) => {
            const primaryPackageUnit = row.primaryPackageUnit;
            const purchasePrice =
              row.getCurrentPurchasePriceForUnit(primaryPackageUnit)?.price.price;

            const firstFuturePrice = row.futureSalesPrices[0];
            const fPakFuturePriceCalculation = firstFuturePrice?.getBracketPackagePrice('F-PAK');
            const futurePurchasePrice =
              fPakFuturePriceCalculation?.purchasePrice?.getPriceForUnit(primaryPackageUnit)?.price
                .price;
            const changeInValue =
              !!futurePurchasePrice && !!purchasePrice
                ? futurePurchasePrice - purchasePrice
                : undefined;
            return changeInValue;
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => costPriceInSalesUnitColumn,
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => costPriceColumn,
      },
      {
        show: ({ isPriceListLevel }) => !isPriceListLevel,
        getColumn: () => salesPriceColumn,
      },
      {
        show: ({ isPriceListLevel }) => !!isPriceListLevel,
        getColumn: () => chainSalesPriceColumn,
      },
      {
        show: ({ isPriceListLevel }) => !!isPriceListLevel,
        getColumn: () => chainSalesCoverageColumn,
      },
      {
        show: ({ selectedTab, isPriceListLevel }) =>
          selectedTab === AssortmentTab.MY_ASSORTMENT && !isPriceListLevel,
        getColumn: () => ({
          ...baseColumnProps,
          cellClassName: () => dataGridBoldClass,
          width: 120,
          field: 'salesPriceCoverage',
          headerName: t('myAssortment.columns.salesCoverage'),
          sortable: enableSortable,
          renderCell: ({ row }) => {
            const salesPriceCoverage = row.getSalesPriceCoverageForPrimaryPackage();

            return salesPriceCoverage
              ? `${formatPrice(Number(salesPriceCoverage.toFixed(2)), i18n.language)} %`
              : '-';
          },
          valueGetter: (_, row) => row.getSalesPriceCoverageForPrimaryPackage(),
        }),
      },
      {
        show: ({ isPriceListLevel }) => !!isPriceListLevel,
        getColumn: () => customerMarkupFactorColumn,
      },
      {
        show: ({ isPriceListLevel }) => !!isPriceListLevel,
        getColumn: () => customerSalesPriceColumn,
      },
      {
        show: ({ isPriceListLevel }) => !!isPriceListLevel,
        getColumn: () => customerSalesCoverageColumn,
      },
      {
        show: ({ selectedTab, showBracketPriceColumns }) =>
          selectedTab === AssortmentTab.MY_ASSORTMENT && showBracketPriceColumns,
        getColumn: () => ({
          ...baseColumnProps,
          width: 180,
          field: 'salesPriceDpak',
          headerName: t('myAssortment.columns.salesPriceDpak'),
          renderCell: ({ row }) => (
            <BracketPriceCell
              priceCalculation={row.currentSalesPrices}
              includeVat={includeVat}
              bracketClassCode="D-PAK"
            />
          ),
        }),
      },
      {
        show: ({ selectedTab, showBracketPriceColumns }) =>
          selectedTab === AssortmentTab.MY_ASSORTMENT && showBracketPriceColumns,
        getColumn: () => ({
          ...baseColumnProps,
          cellClassName: () => dataGridBoldClass,
          width: 120,
          field: 'salesPriceDpakCoverage',
          headerName: t('myAssortment.columns.salesCoverageDpak'),
          renderCell: ({ row }) => {
            const salesPriceCoverage = row.getSalesPriceCoverageForSecondaryPackage();

            return salesPriceCoverage
              ? `${formatPrice(Number(salesPriceCoverage.toFixed(2)), i18n.language)} %`
              : '-';
          },
        }),
      },
      {
        show: ({ selectedTab, showBracketPriceColumns }) =>
          selectedTab === AssortmentTab.MY_ASSORTMENT && showBracketPriceColumns,
        getColumn: () => ({
          ...baseColumnProps,
          width: 180,
          field: 'salesPriceTpak',
          headerName: t('myAssortment.columns.salesPriceTpak'),
          renderCell: ({ row }) => (
            <BracketPriceCell
              priceCalculation={row.currentSalesPrices}
              includeVat={includeVat}
              bracketClassCode="T-PAK"
            />
          ),
        }),
      },
      {
        show: ({ selectedTab, showBracketPriceColumns }) =>
          selectedTab === AssortmentTab.MY_ASSORTMENT && showBracketPriceColumns,
        getColumn: () => ({
          ...baseColumnProps,
          cellClassName: () => dataGridBoldClass,
          width: 120,
          field: 'salesPriceTpakCoverage',
          headerName: t('myAssortment.columns.salesCoverageTpak'),
          renderCell: ({ row }) => {
            const salesPriceCoverage = row.getSalesPriceCoverageForTertiaryPackage();

            return salesPriceCoverage
              ? `${formatPrice(Number(salesPriceCoverage.toFixed(2)), i18n.language)} %`
              : '-';
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab !== AssortmentTab.COMPETITOR_PRICES,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'futureSalesPrice',
          headerName: t('myAssortment.columns.futurePrice'),
          width: 150,
          sortable: enableSortable,
          renderCell: ({ row }) => {
            const firstFuturePrice = row.futureSalesPrices[0];
            const fPakFuturePrices = firstFuturePrice?.getBracketPackagePrice('F-PAK');
            const fPakPrimaryUnitPrice = fPakFuturePrices?.getSalesPriceForUnit(
              row.primaryPackageUnit
            );

            if (!fPakPrimaryUnitPrice) return '-';

            return (
              <StackedTextCell
                topLine={
                  <FormattedPrice
                    price={
                      includeVat
                        ? fPakPrimaryUnitPrice.price.priceWithVat
                        : fPakPrimaryUnitPrice.price.priceWithoutVat
                    }
                    includeVat={false}
                  />
                }
                bottomLine={
                  fPakFuturePrices?.validFrom ? dayjs(fPakFuturePrices.validFrom).format('ll') : ''
                }
              />
            );
          },
          valueGetter: (_, row) =>
            row.futureSalesPrices[0]
              ?.getBracketPackagePrice('F-PAK')
              ?.getSalesPriceForUnit(row.primaryPackageUnit)?.price.priceWithVat,
        }),
      },
      {
        show: ({ selectedTab, isPriceSuggestionEnabled }) =>
          selectedTab === AssortmentTab.MY_ASSORTMENT && isPriceSuggestionEnabled,
        getColumn: () => ({
          ...baseColumnProps,
          width: 82,
          headerAlign: 'center',
          align: 'center',
          field: 'priceResultType',
          headerName: t('myAssortment.columns.originatesFrom'),
          sortable: enableSortable,
          renderCell: ({ row }) => {
            const originatesFrom = row.currentSalesPrices.priceResultType;
            const fPakUnitPrice = row.currentSalesPrices?.getBracketPackagePrice('F-PAK');
            const firstFuturePrice = row.futureSalesPrices[0];
            const fPakFuturePrices = firstFuturePrice?.getBracketPackagePrice('F-PAK');
            const futureOriginatesFrom = fPakFuturePrices?.priceResultType;

            const markupCondition = fPakUnitPrice?.bracketMarkupPrice?.markup?.condition;
            const priceSuggestionCondition =
              fPakUnitPrice?.bracketMarkupPrice?.priceSuggestion?.condition;

            return (
              <PriceResultTypeTooltip
                priceResultType={originatesFrom}
                condition={
                  originatesFrom === PriceResultType.CALCULATION
                    ? markupCondition
                    : priceSuggestionCondition
                }
                futureCondition={
                  futureOriginatesFrom === PriceResultType.CALCULATION
                    ? fPakFuturePrices?.markup?.condition
                    : fPakFuturePrices?.priceSuggestion?.condition
                }
                priceSuggestionType={row.currentSalesPrices.priceSuggestionType}
                futurePriceSuggestionType={fPakFuturePrices?.priceSuggestionType}
              />
            );
          },
          valueGetter: (_, row) => {
            const priceResultType = row.currentSalesPrices.priceResultType;
            const fPakUnitPrice = row.currentSalesPrices?.getBracketPackagePrice('F-PAK');
            const markupCondition = fPakUnitPrice?.bracketMarkupPrice?.markup?.condition;

            if (priceResultType === PriceResultType.CALCULATION && !markupCondition?.bindingType) {
              return undefined;
            }
            return priceResultType;
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.PRICE_CHANGES,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'changeInSalesPrice',
          headerName: t('myAssortment.columns.salesPriceChange'),
          headerAlign: 'center',
          renderCell: ({ row }) => {
            const primaryPackageUnit = row.primaryPackageUnit;
            const fPakUnitPrice = row.currentSalesPrices?.getBracketPackagePrice('F-PAK');
            const packageSalesPrice =
              fPakUnitPrice?.bracketMarkupPrice.getPriceForUnit(primaryPackageUnit)?.price
                .priceWithVat;

            const firstFuturePrice = row.futureSalesPrices[0];
            const fPakFuturePrices = firstFuturePrice?.getBracketPackagePrice('F-PAK');
            const futurePrice = fPakFuturePrices?.getSalesPriceForUnit(row.primaryPackageUnit)
              ?.price.priceWithVat;

            if (!packageSalesPrice || !futurePrice) return '-';
            else if (!!packageSalesPrice && !!futurePrice) {
              const changeInSalesPrice = futurePrice - packageSalesPrice;
              const percentChange =
                formatPrice(calculatePercentChange(packageSalesPrice, futurePrice), i18n.language) +
                '%';
              return (
                <Stack flexDirection="row" gap={1} alignItems="center">
                  {changeInSalesPrice > 0 ? (
                    <IncreaseIcon color="warning" fontSize="small" />
                  ) : changeInSalesPrice < 0 ? (
                    <DecreaseIcon color="success" fontSize="small" />
                  ) : null}
                  <StackedTextCell
                    topLine={t('myAssortment.recommendedPrices.value', {
                      value: formatPrice(changeInSalesPrice, i18n.language),
                    })}
                    bottomLine={percentChange}
                  />
                </Stack>
              );
            } else {
              return '-';
            }
          },
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => ({
          ...baseColumnProps,
          width: 150,
          sortable: enableSortable,
          field: 'priorityValidFrom',
          headerName: t('myAssortment.columns.addedToAssortmentDate'),

          renderCell: ({ row }) =>
            row.priorityValidFrom ? dayjs(row.priorityValidFrom).format('ll') : '-',
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => ({
          ...baseColumnProps,
          width: 150,
          field: 'source',
          headerName: t('myAssortment.columns.source'),
        }),
      },
      {
        show: () => true,
        getColumn: () => ({
          ...baseColumnProps,
          field: 'actions',
          width: 125,
          sortable: false,
          hideable: false,
          headerAlign: 'center',
          align: 'center',
          disableColumnMenu: true,
          renderCell: ({ row }) => (
            <AssortmentTableActions
              deleteItem={!disableItemRemoval ? () => deleteItem(row.id) : undefined}
              calculationLink={getSupplierCalculusLink && getSupplierCalculusLink(row.mgSupplierId)}
              viewItemProductCard={selectRowId ? () => selectRowId(row.id) : undefined}
            />
          ),
        }),
      },
      {
        show: ({ selectedTab }) => selectedTab === AssortmentTab.MY_ASSORTMENT,
        getColumn: () => ({
          ...baseColumnProps,
          width: 150,
          field: 'discontinuation',
          headerName: t('myAssortment.columns.discontinuation'),
          renderCell: ({ row }) => {
            return (
              <StackedTextCell
                topLine={
                  row.discontinuationCode &&
                  t('myAssortment.columns.discontinuationCode', {
                    code: row.discontinuationCode.toString().padStart(2, '0'),
                  })
                }
                bottomLine={
                  row.discontinuationDate ? dayjs(row.discontinuationDate).format('ll') : null
                }
              />
            );
          },
        }),
      },
    ],
    [
      enableSortable,
      itemIdsColumn,
      itemNameColumn,
      supplierColumn,
      itemGroupColumn,
      discountGroupColumn,
      purchasePriceColumn,
      t,
      i18n.language,
      costPriceInSalesUnitColumn,
      costPriceColumn,
      salesPriceColumn,
      chainSalesPriceColumn,
      chainSalesCoverageColumn,
      customerMarkupFactorColumn,
      customerSalesPriceColumn,
      customerSalesCoverageColumn,
      includeVat,
      disableItemRemoval,
      getSupplierCalculusLink,
      selectRowId,
      deleteItem,
    ]
  );
};
