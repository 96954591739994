import { QueryOptions } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { buildUrl } from '@shared/utils';
import { PriceListItemDTO } from '@retail/purchase-agreements/types';
import { useMPEFetch } from '@retail/app/hooks';
import { PaginationResultDTO } from '@retail/products/types';
import { mpeApi } from '@retail/mgpris/config';

interface SearchParamsPriceLists {
  assortmentId: number;
  agreementIds: number[];
  groupNumbers?: string[];
  mgItemNumbers?: number[];
  query?: string;
  current: boolean;
  page?: number;
  size?: number;
}

export function useFetchAgreementPricesComparison(
  {
    assortmentId,
    agreementIds,
    groupNumbers,
    mgItemNumbers,
    current,
    query,
    page,
    size,
  }: SearchParamsPriceLists,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<PaginationResultDTO<PriceListItemDTO[]>>({
    method: 'GET',
    queryKey: [
      'agreement-items-comparison',
      agreementIds,
      groupNumbers,
      mgItemNumbers,
      current,
      page,
      size,
      query,
    ],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.assortments.assortment.agreementPricesComparison.getFullLinkPath({
        assortmentId,
      }),
      searchParams: {
        page,
        size,
        query,
        'agreement-ids': agreementIds,
        groupNumbers,
        mgItemNumbers,
        current,
      },
    }),
    ...queryOptions,
  });
}
