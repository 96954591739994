import { Stack, Typography } from '@mui/material';
import { UnitTooltip } from './UnitTooltip';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props {
  price: string | number;
  unit: string;
  description?: string;
}

export const PricePerUnit = ({ unit, price, description }: Props) => {
  const t = useProductsTFunction();
  return (
    <Stack flexDirection="row" gap={0.4} alignItems="center">
      <Typography variant="subtitle2">{t('products.prices.pricePer', { price })}</Typography>
      <UnitTooltip
        unit={unit}
        description={description}
        typographyProps={{ variant: 'subtitle2' }}
      />
    </Stack>
  );
};
