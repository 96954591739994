import { FC, useCallback, useMemo, useState } from 'react';
import { useAssortmentDraftDrawer } from '@retail/my-assortment/context';
import { DraftAssortmentItemsTable } from '@retail/my-assortment/components';
import { ContainedButton } from '@shared/components';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { DraftActionsRowPortal } from '@retail/components';
import { ItemsDTO, SelectedItemProps } from '@retail/my-assortment/types';
import { useLanguageSelector } from '@retail/app/hooks';
import { Stack } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';

export const DraftAssortmentItemsContainer: FC = () => {
  const t = useMyAssortmentTFunction();
  const { language } = useLanguageSelector();

  const {
    deleteDraftItemIsLoading,
    draftAssortmentItems,
    timeoutLoading: assortmentTimeoutLoading,
    activateIsLoading: activateAssortmentIsLoading,
    onDeleteAssortmentDraftItems,
    onSubmitAssortmentDraft,
  } = useAssortmentDraftDrawer();

  const [selectedItemIds, setSelectedItemIds] = useState<SelectedItemProps[]>([]);

  const getSelectedItemIds = useCallback(
    () => selectedItemIds.flatMap((item) => item.selectedItems),
    [selectedItemIds]
  );

  const submitActivateSelectedItems = useCallback(() => {
    // @ts-ignore
    const itemDtos: ItemsDTO[] = getSelectedItemIds()
      .map((id) => {
        const item = draftAssortmentItems?.find((item) => item.id === id);
        if (!item) return null;

        return {
          mgSupplierId: item.mgSupplierId,
          mgItemNumber: item.mgItemNumber,
          priority: item.priority,
          validFrom: item.priorityValidFrom.toISOString(),
        };
      })
      .filter((item) => !!item);

    onSubmitAssortmentDraft(itemDtos);
  }, [draftAssortmentItems, getSelectedItemIds, onSubmitAssortmentDraft]);

  const submitDeleteSelectedItems = useCallback(() => {
    onDeleteAssortmentDraftItems(getSelectedItemIds());
  }, [getSelectedItemIds, onDeleteAssortmentDraftItems]);

  const isLoading = useMemo(
    () => assortmentTimeoutLoading || activateAssortmentIsLoading || deleteDraftItemIsLoading,
    [activateAssortmentIsLoading, assortmentTimeoutLoading, deleteDraftItemIsLoading]
  );

  const handleSetSelectedItemIds = (mgSupplierId: GridRowId, itemIds: number[]) => {
    const updatedSelectedItems = selectedItemIds.filter(
      (itemPair) => itemPair.mgSupplierId !== mgSupplierId
    );

    updatedSelectedItems.push({ mgSupplierId: mgSupplierId as number, selectedItems: itemIds });
    setSelectedItemIds(updatedSelectedItems);
  };

  return (
    <Stack flexGrow={1}>
      <DraftAssortmentItemsTable
        items={draftAssortmentItems ?? []}
        language={language}
        isLoading={isLoading}
        onDeleteDraftItem={onDeleteAssortmentDraftItems}
        onSelectItems={handleSetSelectedItemIds}
      />

      <DraftActionsRowPortal>
        <ContainedButton
          color="warning"
          size="small"
          disabled={!getSelectedItemIds().length}
          onClick={submitDeleteSelectedItems}
          loading={isLoading}
        >
          {getSelectedItemIds().length === 1
            ? t('myAssortment.actions.disapproveChange', { count: getSelectedItemIds().length })
            : t('myAssortment.actions.disapproveChanges', { count: getSelectedItemIds().length })}
        </ContainedButton>

        <ContainedButton
          onClick={submitActivateSelectedItems}
          size="small"
          disabled={!getSelectedItemIds().length}
          loading={isLoading}
        >
          {getSelectedItemIds().length === 1
            ? t('myAssortment.actions.addChange', { count: getSelectedItemIds().length })
            : t('myAssortment.actions.addChanges', { count: getSelectedItemIds().length })}
        </ContainedButton>
      </DraftActionsRowPortal>
    </Stack>
  );
};
