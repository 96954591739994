import { useMemo, useState } from 'react';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { PurchaseCondition } from '@retail/calculus/types';
import { Supplier } from '@retail/suppliers/types';
import { useCalculusConditionActions } from '@retail/calculus/context';
import { useLanguageSelector } from '@retail/app/hooks';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { Stack } from '@mui/material';
import { ConditionsTable } from '@retail/purchase-agreements/components';
import { UniversalFormProvider } from '@shared/components';
import { conditionDatesSchema } from '@retail/calculus/components';
import {
  ActivateSelectedAgreementDialog,
  ChangeSelectedAgreementDialog,
} from '../ActivateAgreement';
import { DeactivateAgreementDialog } from '../DeactivateAgreementDialog';
import { PriceComparisonDialogContainer } from '../PriceList';
import { DeleteDialog } from '@retail/components';

interface Props {
  conditions: PurchaseCondition[];
  supplier: Supplier;
  assortmentId: number;
  agreements: PurchaseAgreement[];
  openCreateDialog: () => void;
}

export function ConditionsTableContainer({
  conditions,
  supplier,
  assortmentId,
  agreements,
  openCreateDialog,
}: Props) {
  const t = useCalculusTFunction();
  const { language } = useLanguageSelector();

  const [agreementToActivate, setAgreementToActivate] = useState<PurchaseCondition | null>();
  const [conditionToDeactivate, setConditionToDeactivate] = useState<PurchaseCondition | null>();
  const [agreementToEdit, setAgreementToEdit] = useState<PurchaseCondition | null>();
  const [conditionIdToDelete, setConditionIdToDelete] = useState<PurchaseCondition['id'] | null>();

  const [conditionForPriceList, setConditionForPriceList] = useState<PurchaseCondition | null>();
  const defaultPriceListAgreement = useMemo(
    () => agreements.find(({ id }) => id === conditionForPriceList?.agreementId),
    [agreements, conditionForPriceList?.agreementId]
  );

  const { handleDeleteAssortmentConditions } = useCalculusConditionActions();

  return (
    <Stack flexGrow={1} borderTop={({ palette }) => `1px solid ${palette.divider}`}>
      <ConditionsTable
        conditions={conditions}
        language={language}
        setConditionForPriceList={setConditionForPriceList}
        handleActivate={setAgreementToActivate}
        handleDeactivate={setConditionToDeactivate}
        handleEdit={setAgreementToEdit}
        handleDelete={setConditionIdToDelete}
        openCreateDialog={openCreateDialog}
      />

      {agreementToActivate && (
        <UniversalFormProvider
          schema={conditionDatesSchema(t)}
          defaultValues={conditionDatesSchema(t).getDefault()}
        >
          <ActivateSelectedAgreementDialog
            isOpen
            supplierId={supplier.mgSupplierId}
            agreementId={agreementToActivate.agreementId}
            agreementText={agreementToActivate.agreementText}
            onClose={() => setAgreementToActivate(null)}
          />
        </UniversalFormProvider>
      )}
      {conditionToDeactivate && (
        <DeactivateAgreementDialog
          isOpen
          onClose={() => setConditionToDeactivate(null)}
          supplierId={supplier.mgSupplierId}
          groupNumber={conditionToDeactivate.condition.productGroup?.groupNumber}
          mgItemNumber={conditionToDeactivate.mgItemNumber}
        />
      )}
      {agreementToEdit && (
        <ChangeSelectedAgreementDialog
          open
          onClose={() => setAgreementToEdit(null)}
          assortmentId={assortmentId}
          agreements={agreements}
          agreementToEdit={agreementToEdit}
          supplierId={supplier.mgSupplierId}
        />
      )}
      {conditionIdToDelete && (
        <DeleteDialog
          isOpen
          dialogTitle={t('calculus.actions.deleteFromDraft')}
          message={t('calculus.actions.confirmRemoval')}
          onDelete={() => handleDeleteAssortmentConditions([conditionIdToDelete])}
          onClose={() => setConditionIdToDelete(null)}
        />
      )}
      {conditionForPriceList && (
        <PriceComparisonDialogContainer
          open
          onClose={() => setConditionForPriceList(null)}
          assortmentId={assortmentId}
          agreements={agreements}
          defaultSelectedAgreements={defaultPriceListAgreement && [defaultPriceListAgreement]}
          levelProductGroupNr={conditionForPriceList.condition?.productGroup?.groupNumber}
          levelMgItemNr={conditionForPriceList.mgItemNumber}
        />
      )}
    </Stack>
  );
}
