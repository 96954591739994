import { Competitor } from '@retail/retailer/types';
import { usePagination } from '@shared/hooks';
import { useFetchMonitoringAssortmentItems } from '@retail/monitoring-assortment/data-access';
import { MonitoringAssortmentItem } from '@retail/monitoring-assortment/types';

interface Props {
  assortmentId: number;
  competitors: Competitor[];
  groupNumbers?: number[];
  supplierIds?: number[];
  query?: string;
  initPageSize?: number;
}

export const useMonitoringAssortmentItems = ({
  assortmentId,
  competitors,
  groupNumbers,
  supplierIds,
  query,
  initPageSize = 2000,
}: Props) => {
  const { pageSize, page, setPageSize, setPage } = usePagination({
    initPageSize,
    initPage: 0,
  });

  const { data: assortmentPagePaginationDto, isFetching } = useFetchMonitoringAssortmentItems({
    assortmentId,
    competitorStoreIds: competitors.map((competitor) => competitor.id),
    page,
    supplierIds,
    size: pageSize,
    nCmpPrices: 2,
    groupNumbers,
    query,
  });

  const assortmentItems = assortmentPagePaginationDto?.result
    ? assortmentPagePaginationDto.result.map((itemDto) => new MonitoringAssortmentItem(itemDto))
    : [];

  return {
    result: {
      totalItems: assortmentPagePaginationDto?.totalCount,
      assortmentItems,
      page,
      pageSize,
    },
    isFetching,
    setPageSize,
    setPage,
  };
};
