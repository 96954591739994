import { Grid, Link, Stack, Typography } from '@mui/material';

import { ItemPriority, MgItem, MgSupplierItem } from '@retail/products/types';
import { Paper } from '@shared/components';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useProductsTFunction } from '@retail/products/i18n';
import { useRecoilValue } from 'recoil';
import { isSuperUserAtom } from '@retail/auth/data-access';
import {
  ActionsMenu,
  AddItemToAssortmentDialog,
  AddToMyAssortmentFunction,
  MgItemGallery,
  MgItemView,
  MonitoringItemSelectionDialog,
  SuccessAddingItemsDialog,
} from '@retail/products/components';
import { MgItemTabContainer } from './MgItemTabContainer';
import { useToast } from '@retail/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useFeatureContext, useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useRefetchDraftDrawer } from '@retail/context';
import { useFetchAssortmentsForMgItem } from '@retail/products/data-access';
import { useCallback, useMemo, useState } from 'react';
import { AssortmentTypeDTO, ItemsDTO } from '@retail/my-assortment/types';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';
import { useDisclosure } from '@shared/hooks';
import { useMonitoringAssortmentActions } from '@retail/monitoring-assortment/context';
import {
  getMgAssortmentsQueryKey,
  useCreateMonitoringAssortment,
} from '@retail/monitoring-assortment/data-access';
import {
  CreateAssortmentManuallyDialog,
  NewAssortment,
} from '@retail/monitoring-assortment/components';
import { FetchError } from '@shared/fetch-utils';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { AddButton, Feature } from '@retail/components';
import { useMgPrisConfig } from '@retail/app/stores/app-config';

interface Props {
  mgItem: MgItem;
  hiddenBreadcrumbs?: boolean;
  assortmentId: number;
  monitoringAssortments: MonitoringAssortment[];
  showMonitorItem?: boolean;
  showAddItem?: boolean;
  addItemsToAssortment: (items: ItemsDTO[]) => Promise<void>;
  navigateToAssortment: (
    assortmentId: number,
    selectedAssortment: MonitoringAssortment | null
  ) => void;
}

export function MgItemDetails({
  mgItem,
  hiddenBreadcrumbs = false,
  assortmentId,
  monitoringAssortments,
  showMonitorItem = true,
  showAddItem = true,
  addItemsToAssortment,
  navigateToAssortment,
}: Props) {
  const t = useProductsTFunction();
  const isSuperUser = useRecoilValue(isSuperUserAtom);

  const primarySupplierItem = mgItem.getPrimarySupplierItem();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { featureFlags } = useMgPrisConfig();
  const featureContext = useFeatureContext();

  const { selectedOrgUnitId } = useSelectedOrgUnit();

  const { invalidateAssortmentDraft } = useRefetchDraftDrawer();

  const { data: mgItemAssortmentDtos = [] } = useFetchAssortmentsForMgItem(
    { mgItemNumber: mgItem.id, orgUnitId: selectedOrgUnitId },
    { suspense: false }
  );

  const mgItemAssortments = useMemo(
    () =>
      mgItemAssortmentDtos
        .filter(({ type }) => type === AssortmentTypeDTO.MONITORING)
        .map((dto) => new MonitoringAssortment(dto)),
    [mgItemAssortmentDtos]
  );

  const availableMonitoringAssortments = useMemo(() => {
    const idsToExclude = mgItemAssortments.map(({ id }) => id);

    return monitoringAssortments.filter((assortment) => !idsToExclude.includes(assortment.id));
  }, [mgItemAssortments, monitoringAssortments]);

  const firstSupplierItem = mgItem.getPrimarySupplierItem();

  const [selectedAssortment, setSelectedAssortment] = useState<MonitoringAssortment | null>(null);
  const [selectedItem, setSelectedItem] = useState<MgSupplierItem>();
  const [successModal, setSuccessModal] = useState<boolean>(false);

  const {
    isOpen: isSelectItemModalOpen,
    onOpen: openSelectItemModal,
    onClose: closeSelectItemModal,
  } = useDisclosure(false);

  const {
    isOpen: isCreateManuallyModalOpen,
    onClose: closeCreateManuallyModal,
    onOpen: openCreateManuallyModal,
  } = useDisclosure(false);

  const {
    isOpen: isAddToExistingModalOpen,
    onClose: closeAddToExistingModal,
    onOpen: openAddToExistingModal,
  } = useDisclosure(false);

  const { handleAddItemsToMgAssortment, handleDeleteItemsFromMgAssortment } =
    useMonitoringAssortmentActions();

  const { mutateAsync: createMgAssortmentAsync, isLoading: isLoadingCreate } =
    useCreateMonitoringAssortment();

  const submitNewAssortmentForm = ({ title }: NewAssortment) => {
    createMgAssortmentAsync({
      body: {
        orgUnitId: selectedOrgUnitId,
        title,
        itemCodes: {
          mgItemNumbers: [mgItem.id],
        },
      },
    })
      .then((assortment) => {
        closeCreateManuallyModal();
        queryClient.invalidateQueries(getMgAssortmentsQueryKey());
        setSelectedAssortment(assortment);
        setSuccessModal(true);
      })
      .catch((err: FetchError) => {
        console.error(err);
        toast.error(t('products.mgItem.search.error.addingItemToAssortment'));
      });
  };

  const handleItemSuccess = useCallback(
    (assortmentId: number) => {
      const currentAssortment = availableMonitoringAssortments.find(
        (assortment) => assortment.id === assortmentId
      )!;

      setSelectedAssortment({
        title: currentAssortment.title,
        id: currentAssortment.id,
        size: 0,
        readOnly: currentAssortment.readOnly,
      });
    },
    [availableMonitoringAssortments]
  );

  const addItemToAssortment = useCallback(
    (assortmentId: number) => {
      handleAddItemsToMgAssortment({
        assortmentId,
        mgItemNumbers: [mgItem.id],
      })
        .then(() => {
          queryClient.invalidateQueries(getMgAssortmentsQueryKey([mgItem.id]));
          setSuccessModal(true);
          handleItemSuccess(assortmentId);
        })
        .catch(() => toast.error(t('products.errors.addItemToAssortment')));
    },
    [handleAddItemsToMgAssortment, handleItemSuccess, mgItem.id, queryClient, t, toast]
  );

  const deleteItemFromAssortment = useCallback(
    (assortmentId: number) => {
      handleDeleteItemsFromMgAssortment({
        assortmentId,
        mgItemNumbers: [mgItem.id],
      })
        .then(() => queryClient.invalidateQueries(getMgAssortmentsQueryKey([mgItem.id])))
        .catch(() => toast.error(t('products.errors.deleteItemFromAssortment')));
    },
    [handleDeleteItemsFromMgAssortment, mgItem.id, queryClient, t, toast]
  );

  const addItemToMyAssortment: AddToMyAssortmentFunction = useCallback(
    ({ supplierId, fromDate }) => {
      setSelectedItem(mgItem.supplierItems.find((item) => item.mgSupplierId === supplierId));
      addItemsToAssortment([
        {
          mgSupplierId: supplierId,
          mgItemNumber: mgItem.id,
          priority: ItemPriority.ZERO,
          validFrom: fromDate?.toISOString(),
        },
      ])
        .then(() => {
          invalidateAssortmentDraft();
          closeSelectItemModal();
          setSuccessModal(true);
        })
        .catch((error) => {
          console.error(error);
          toast.error(t('products.errors.addItemToAssortment'));
        });
    },
    [
      mgItem.supplierItems,
      mgItem.id,
      addItemsToAssortment,
      invalidateAssortmentDraft,
      closeSelectItemModal,
      toast,
      t,
    ]
  );

  const navigateOnSuccess = (assortmentId: number) => {
    navigateToAssortment(assortmentId, selectedAssortment);
  };

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 0 }}>
      {!hiddenBreadcrumbs && (
        <Link
          color="text.primary"
          component={RouterLink}
          to={mgPrisRoutes.items.getFullLinkPath()}
          alignSelf="flex-start"
        >
          <Stack flexDirection="row" alignItems="center" p={1.5} pb={0}>
            <ArrowBack fontSize="small" /> {t('products.mgItem.notFound.goBack')}
          </Stack>
        </Link>
      )}
      <Stack flexGrow={1} gap={1} p={3} pt={1}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" pb={3}>
          <Typography variant="h4" fontWeight={600}>
            {primarySupplierItem.primaryText}
          </Typography>
          <Stack flexDirection="row" gap={1}>
            {showMonitorItem && (
              <ActionsMenu
                onAddToNewMonitoringAssortments={openCreateManuallyModal}
                onAddToExistingMonitoringAssortments={openAddToExistingModal}
              />
            )}
            {showAddItem && (
              <Feature
                features={featureFlags}
                name="my-assortment:items:write"
                context={featureContext}
                renderEnabled={() => (
                  <AddButton onClick={openSelectItemModal}>
                    {t('products.mgItem.supplierItem.addItem')}
                  </AddButton>
                )}
              />
            )}
          </Stack>
        </Stack>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <Stack gap={5}>
              <MgItemView mgItem={mgItem} isSuperUser={isSuperUser} />

              <MgItemGallery mgItem={mgItem} />
            </Stack>
          </Grid>

          <Grid display="flex" item xs={8}>
            <MgItemTabContainer mgItem={mgItem} />
          </Grid>
        </Grid>

        {isSelectItemModalOpen && (
          <MonitoringItemSelectionDialog
            supplierItems={mgItem.supplierItems}
            open
            onClose={closeSelectItemModal}
            addToMyAssortment={addItemToMyAssortment}
          />
        )}

        <CreateAssortmentManuallyDialog
          open={isCreateManuallyModalOpen}
          onClose={closeCreateManuallyModal}
          onSubmit={submitNewAssortmentForm}
          isLoading={isLoadingCreate}
          hideAddItemsLater
        />

        <AddItemToAssortmentDialog
          open={isAddToExistingModalOpen}
          onClose={closeAddToExistingModal}
          assortmentsItemExistsIn={mgItemAssortments}
          availableAssortments={availableMonitoringAssortments}
          addItemToAssortment={addItemToAssortment}
          deleteItemFromAssortment={deleteItemFromAssortment}
        />

        {successModal && (
          <SuccessAddingItemsDialog
            isOpen={successModal}
            closeDialog={() => setSuccessModal(false)}
            successText={t('products.mgItem.addItemsToAssortment.added', {
              itemName: selectedItem?.primaryText || firstSupplierItem?.primaryText || mgItem.id,
            })}
            assortmentId={selectedAssortment?.id ?? assortmentId}
            assortmentTitle={
              selectedAssortment?.title ?? t('products.mgItem.addItemsToAssortment.myAssortment')
            }
            navigate={navigateOnSuccess}
          />
        )}
      </Stack>
    </Paper>
  );
}
