import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { DataGridPremium, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  CopyToClipboard,
  CustomColumnMenu,
  DataGridPagination,
  StackedTextCell,
  TopLineTypography,
} from '@retail/components';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { AssortmentItem, ItemWithPriority } from '@retail/my-assortment/types';
import { ItemPriority } from '@retail/products/types';
import { getDataGridLocalization, MgPrisLocale } from '@retail/utils';
import { dataGridBoldClass } from '@shared/styles';
import { useMemo } from 'react';
import { FilterCellAction } from '../AssortmentTable/FilterCellAction';
import dayjs from 'dayjs';
import { StarBorder } from '@mui/icons-material';

const baseColumnProps: Partial<GridColDef<AssortmentItem>> = {
  width: 120,
  sortable: false,
  headerAlign: 'left',
  align: 'left',
};

interface Props {
  assortmentItems: AssortmentItem[];
  totalItems: number;
  itemsChecked: ItemWithPriority[];
  language: string;
  page: number;
  pageSize: number;
  setItemsChecked: (items: ItemWithPriority[]) => void;
  setPage: (page: number) => void;
  setPageSize: (size: number) => void;
  setSupplier: (supplierId: number | null) => void;
  setProductGroup: (productGroupNr: string | null) => void;
}

export function CentralAssortmentTable({
  assortmentItems,
  totalItems,
  itemsChecked,
  language,
  page,
  pageSize,
  setItemsChecked,
  setPage,
  setPageSize,
  setSupplier,
  setProductGroup,
}: Props) {
  const t = useMyAssortmentTFunction();

  const onRowsSelectionHandler = (selections: GridRowSelectionModel) => {
    const rowSelections: ItemWithPriority[] = selections
      .map((selection) => {
        const item = assortmentItems.find(
          (item) => `${item.mgItemNumber}_${item.mgSupplierId}` === selection
        );
        return item
          ? ({
              mgSupplierId: item.mgSupplierId,
              mgItemNumber: item.mgItemNumber,
              priority: 'ZERO',
            } as ItemWithPriority)
          : undefined;
      })
      .filter((selection): selection is ItemWithPriority => selection !== undefined);

    setItemsChecked(rowSelections);
  };

  const columns: GridColDef<AssortmentItem>[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'mgItemFinfoNumber',
        headerName: t('myAssortment.columns.mgItemFinfoNumber'),
        renderCell: ({ row }) => (
          <StackedTextCell
            topLine={
              <CopyToClipboard value={row.mgItemNumber} hasIconButtonPadding={false}>
                {row.mgItemNumber}
              </CopyToClipboard>
            }
            bottomLine={row.finfoNumber}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'itemText',
        cellClassName: () => dataGridBoldClass,
        headerName: t('myAssortment.columns.itemText'),
        width: 250,
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.primaryText} bottomLine={row.secondaryText} />
        ),
      },
      {
        ...baseColumnProps,
        width: 200,
        field: 'supplier',
        headerName: t('myAssortment.columns.supplier'),
        renderCell: ({ row }) => (
          <Stack flexDirection="row" alignItems="center" gap={1}>
            {row.priority === ItemPriority.TEN && (
              <Tooltip title={t('myAssortment.priority.mainPrioSupplier')} placement="top" arrow>
                <StarBorder color="success" fontSize="small" />
              </Tooltip>
            )}
            <StackedTextCell
              topLine={
                <TopLineTypography variant="subtitle2">
                  {row.mgSupplierName}

                  <FilterCellAction
                    onFilterClick={() => setSupplier(row.mgSupplierId)}
                    filterTitle={t('myAssortment.actions.selectSupplierAsQuickFilter')}
                  />
                </TopLineTypography>
              }
              bottomLine={row.mgSupplierId}
            />
          </Stack>
        ),
      },
      {
        ...baseColumnProps,
        width: 180,
        field: 'itemGroup',
        headerName: t('myAssortment.columns.itemGroup'),
        renderCell: ({ row }) => (
          <StackedTextCell
            topLine={
              <TopLineTypography variant="subtitle2">
                {row.groupNumber}

                <FilterCellAction
                  onFilterClick={() => setProductGroup(row.groupNumber)}
                  filterTitle={t('myAssortment.actions.selectProductGroupAsQuickFilter')}
                />
              </TopLineTypography>
            }
            bottomLine={row.groupDescription}
          />
        ),
      },
      {
        ...baseColumnProps,
        width: 150,
        field: 'priorityValidFrom',
        headerName: t('myAssortment.columns.addedToAssortmentDate'),
        renderCell: ({ row }) =>
          row.priorityValidFrom ? dayjs(row.priorityValidFrom).format('ll') : '-',
      },
    ],
    [setProductGroup, setSupplier, t]
  );
  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium<AssortmentItem>
          columns={columns}
          rows={assortmentItems}
          getRowId={(row) => `${row.mgItemNumber}_${row.mgSupplierId}`}
          // @ts-ignore
          rowHeight={60}
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnReorder
          rowCount={totalItems}
          pagination={true}
          paginationMode="server"
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={(model) => {
            if (model.page !== page) setPage(model.page);
            if (model.pageSize !== pageSize) setPageSize(model.pageSize);
          }}
          rowSelectionModel={itemsChecked.map(
            (item) => `${item.mgItemNumber}_${item.mgSupplierId}`
          )}
          onRowSelectionModelChange={onRowsSelectionHandler}
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          slots={{
            columnMenu: CustomColumnMenu,
            pagination: () => <DataGridPagination />,
            noRowsOverlay: () => (
              <Stack
                alignItems="center"
                justifyContent="center"
                gap={2}
                height="100%"
                sx={{ position: 'relative', zIndex: 1, pointerEvents: 'all' }} // https://github.com/mui/mui-x/issues/5725
              >
                <Typography variant="h3" color="text.secondary">
                  {t('myAssortment.noItems')}
                </Typography>
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
}
