export enum CalculusAreas {
  PURCHASE = 'PURCHASE',
  FREIGHT = 'FREIGHT',
  MARKUP = 'MARKUP',
  PRICE_SUGGESTION = 'PRICE_SUGGESTION',
  CUSTOMER_MARKUP = 'CUSTOMER_MARKUP',
}

export enum CalculusTabOptions {
  ITEMS = 'ITEMS',
  PURCHASE = 'PURCHASE',
  FREIGHT = 'FREIGHT',
  MARKUP = 'MARKUP',
  PRICE_SUGGESTION = 'PRICE_SUGGESTION',
  CUSTOMER_MARKUP = 'CUSTOMER_MARKUP',
}

export enum CalculusDraftTabOptions {
  CONDITIONS = 'CONDITIONS',
  ITEMS = 'ITEMS',
}
