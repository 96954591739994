import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useLanguageSelector } from '@retail/app/hooks';
import { MgItemSearchAutocomplete } from '@retail/products/containers';
import {
  BrandSelect,
  BrandSelectOption,
  GlobalDraggable,
  LanguageSwitch,
} from '@retail/components';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useIsFeatureEnabled, useUserBrands } from '@retail/app/stores/selected-context';
import { mgprisHeaderHeight } from '@shared/styles';
import { AvailableOrgUnitsContainer } from '@retail/app/containers';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useSharedTranslation } from '@shared/i18n';
import dayjs from 'dayjs';
import { HelpOutline } from '@mui/icons-material';
import { useDisclosure } from '@shared/hooks';
import { useWindowSize } from 'react-use';
import { HelpCenter } from '@retail/help-center/containers';
import { useAppTFunction } from '@retail/app/i18n';

const handleClass = 'help-center-drag-handle';

interface Props {
  appLogo: ReactElement;
}

export function Header({ appLogo }: Props) {
  const { palette } = useTheme();
  const t = useAppTFunction();
  const { t: sharedT } = useSharedTranslation();
  const { version, buildTime } = useMgPrisConfig();
  const { width: screenWidth } = useWindowSize();
  const {
    i18n: { language },
  } = useTranslation();
  const { switchLanguage } = useLanguageSelector();
  const { availableBrands, selectedBrand, switchBrand } = useUserBrands();
  const isHelpCenterEnabled = useIsFeatureEnabled('help-center');
  const {
    isOpen: showHelpCenter,
    onToggle: onToggleHelpCenter,
    onClose: onCloseHelpCenter,
  } = useDisclosure();

  const brandOptions: BrandSelectOption[] = useMemo(
    () =>
      availableBrands.map(({ name, brandId }) => ({
        name,
        key: brandId,
      })),
    [availableBrands]
  );

  const changeBrand = useCallback(
    (brandKey: string) => {
      const newBrand = availableBrands.find(({ brandId }) => brandId === brandKey);
      if (newBrand) {
        switchBrand(newBrand);
      }
    },
    [availableBrands, switchBrand]
  );

  const navigate = useNavigate();
  const onMgItemSearchResultClick = useCallback(
    (mgItemId: number) =>
      navigate(mgPrisRoutes.items.item.getFullLinkPath({ itemNumber: mgItemId })),
    [navigate]
  );

  const { buildDate, buildTimeFormatted } = useMemo(
    () => ({
      buildDate: buildTime && dayjs.unix(buildTime).format('l'),
      buildTimeFormatted: buildTime && dayjs.unix(buildTime).format('hh:mm:ss'),
    }),
    [buildTime]
  );

  return (
    <AppBar
      position="fixed"
      sx={{ height: mgprisHeaderHeight, background: ({ palette }) => palette.common.white }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row" alignItems="center" alignSelf="stretch" paddingY={2}>
          <BrandSelect
            brands={brandOptions}
            selectedBrandKey={selectedBrand.brandId}
            onBrandSelect={changeBrand}
          />

          <Divider
            orientation="vertical"
            sx={{ borderColor: palette.background.default, marginX: 3 }}
          />

          <MgItemSearchAutocomplete
            onItemClick={onMgItemSearchResultClick}
            sx={{ width: '300px' }}
          />
        </Stack>

        <Stack direction="row" marginLeft={2} height="100%" gap={2}>
          <Stack alignItems="center" direction="row">
            <AvailableOrgUnitsContainer />
            <Tooltip
              arrow
              title={
                <Stack px={2}>
                  <Typography variant="body2">{sharedT('shared.version')}</Typography>
                  <Typography variant="body2">{version.slice(-6)}</Typography>
                  {!!buildDate && <Typography variant="body2">{buildDate}</Typography>}
                  {!!buildTimeFormatted && (
                    <Typography variant="body2">{buildTimeFormatted}</Typography>
                  )}
                </Stack>
              }
            >
              {appLogo}
            </Tooltip>
            {isHelpCenterEnabled && (
              <IconButton onClick={onToggleHelpCenter} aria-label={t('helpCenter.open')}>
                <HelpOutline color="disabled" sx={{ height: '34px', width: '34px' }} />
              </IconButton>
            )}

            <LanguageSwitch
              selectedLanguage={language}
              switchLanguage={switchLanguage}
              languages={['no', 'se']}
            />
          </Stack>
        </Stack>
      </Toolbar>
      {showHelpCenter && (
        <GlobalDraggable
          handle={`.${handleClass}`}
          defaultPosition={{ x: screenWidth - 600, y: 100 }}
        >
          <HelpCenter onClose={onCloseHelpCenter} titleClassName={handleClass} />
        </GlobalDraggable>
      )}
    </AppBar>
  );
}
