import { Supplier } from '@retail/suppliers/types';
import { useMemo, useState } from 'react';
import {
  createAssortmentMarkupSchema,
  createMarkupSchema,
  MarkupsTable,
} from '@retail/markup/components';
import { CalculusAreas, ConditionState, MarkupCondition } from '@retail/calculus/types';
import { UniversalFormProvider } from '@shared/components';
import { CreateMarkupConditionDialog } from './CreateMarkupCondition';
import { DeleteOrDeactivateMarkupDialog } from './DeleteOrDeactivateMarkupDialog';
import { useFetchAssortmentConditions } from '@retail/calculus/data-access';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useLevelSearchParams } from '@retail/calculus/hooks';
import { AddButton, CalculusActionPortal } from '@retail/components';
import { useSelectedOrgUnit, useUserBrands } from '@retail/app/stores/selected-context';
import { useLanguageSelector } from '@retail/app/hooks';
import { Stack } from '@mui/material';
import { EditMarkupDialog } from './EditMarkupCondition';
import { UploadMarkupConditionsDialog } from './UploadMarkupConditions';
import { DeleteDialogContainer } from './DeleteOrDeactivateMarkupDialog/DeleteDialogContainer';
import CreateAssortmentMarkupConditionDialog from './CreateMarkupCondition/CreateAssortmentMarkupConditionDialog';

interface Props {
  selectedSupplier?: Supplier;
  typesToShow: ConditionState;
  isCreateOpen: boolean;
  isUploadOpen: boolean;
  toggleCreateDialog: () => void;
  toggleUploadDialog: () => void;
}

export function MarkupContainer({
  selectedSupplier,
  typesToShow,
  isCreateOpen,
  isUploadOpen,
  toggleCreateDialog,
  toggleUploadDialog,
}: Props) {
  const t = useCalculusTFunction();
  const { language } = useLanguageSelector();

  const { selectedDepartment, selectedPriceList } = useSelectedOrgUnit();
  const baseAssortmentId = (selectedDepartment?.assortmentId ?? selectedPriceList?.assortmentId)!;
  const draftAssortmentId = (selectedDepartment?.draftAssortmentId ??
    selectedPriceList?.draftAssortmentId)!;

  const { selectedProductGroupNumber, selectedMgItemId } = useLevelSearchParams();

  const [markupForDeactivation, setMarkupForDeactivation] = useState<MarkupCondition | undefined>(
    undefined
  );
  const [markupForEdit, setMarkupForEdit] = useState<MarkupCondition | undefined>(undefined);
  const [markupIdToDelete, setMarkupIdToDelete] = useState<number | undefined>(undefined);

  const handleDeactivate = (markup?: MarkupCondition) => setMarkupForDeactivation(markup);
  const handleEdit = (markup?: MarkupCondition) => setMarkupForEdit(markup);
  const handleDelete = (markupId?: number) => setMarkupIdToDelete(markupId);

  const { data: conditionDtos } = useFetchAssortmentConditions({
    assortmentId: typesToShow === ConditionState.ACTIVE ? baseAssortmentId : draftAssortmentId,
    conditionTypes: [CalculusAreas.MARKUP],
    conditionState: typesToShow === ConditionState.DRAFT ? ConditionState.ALL : typesToShow,
    mgSupplierIds: selectedSupplier ? [selectedSupplier.mgSupplierId] : [],
    groupNumbers: selectedProductGroupNumber ? [selectedProductGroupNumber] : [],
    mgItemNumbers: selectedMgItemId ? [selectedMgItemId] : [],
    includeOwnerConditions: typesToShow !== ConditionState.DRAFT,
  });
  const { selectedBrand } = useUserBrands();
  const includeUnit = useMemo(() => !!selectedBrand.wholesaler, [selectedBrand.wholesaler]);

  const markupConditionDtos = useMemo(
    () =>
      (selectedSupplier
        ? conditionDtos?.markupConditions?.supplierConditions
            .map((supplierCondition) => supplierCondition.conditions)
            .flat()
        : conditionDtos?.markupConditions?.assortmentConditions) ?? [],
    [
      conditionDtos?.markupConditions?.assortmentConditions,
      conditionDtos?.markupConditions?.supplierConditions,
      selectedSupplier,
    ]
  );
  const markupConditions = markupConditionDtos.map(MarkupCondition.fromDto);

  const existingMarkupConditions = markupConditions.filter(
    (markup) => markup.operation !== 'DELETE'
  );

  const markupSchema = useMemo(() => createMarkupSchema(t, includeUnit), [includeUnit, t]);
  const assortmentMarkupSchema = useMemo(() => createAssortmentMarkupSchema(t), [t]);

  return (
    <Stack flexGrow={1} borderTop={({ palette }) => `1px solid ${palette.divider}`}>
      <CalculusActionPortal>
        {selectedSupplier && (
          <AddButton onClick={toggleUploadDialog} disabled={!!selectedPriceList}>
            {t('calculus.actions.uploadMarkup')}
          </AddButton>
        )}
        <AddButton onClick={toggleCreateDialog} disabled={!!selectedPriceList}>
          {t('calculus.createMarkup')}
        </AddButton>
      </CalculusActionPortal>

      <MarkupsTable
        language={language}
        markups={existingMarkupConditions}
        handleDeactivate={handleDeactivate}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        openCreateDialog={toggleCreateDialog}
        disableActions={!!selectedPriceList}
      />

      {markupForDeactivation && (
        <DeleteOrDeactivateMarkupDialog
          isOpen
          mgSupplierId={selectedSupplier?.mgSupplierId}
          activeMarkupCondition={markupForDeactivation}
          onClose={handleDeactivate}
        />
      )}
      {!selectedPriceList && selectedSupplier && (
        <UniversalFormProvider schema={markupSchema} defaultValues={markupSchema.getDefault()}>
          <CreateMarkupConditionDialog
            open={isCreateOpen}
            onClose={toggleCreateDialog}
            supplier={selectedSupplier}
            includeUnit={includeUnit}
          />
        </UniversalFormProvider>
      )}
      {!selectedPriceList && !selectedSupplier && (
        <UniversalFormProvider
          schema={assortmentMarkupSchema}
          defaultValues={assortmentMarkupSchema.getDefault()}
        >
          <CreateAssortmentMarkupConditionDialog open={isCreateOpen} onClose={toggleCreateDialog} />
        </UniversalFormProvider>
      )}

      {selectedSupplier && !selectedPriceList && (
        <UploadMarkupConditionsDialog
          open={isUploadOpen}
          onClose={toggleUploadDialog}
          supplier={selectedSupplier}
        />
      )}

      {markupForEdit && selectedSupplier && (
        <EditMarkupDialog
          isOpen
          markupToEdit={markupForEdit}
          mgSupplierId={selectedSupplier.mgSupplierId}
          onClose={() => handleEdit()}
        />
      )}
      {markupIdToDelete && !selectedPriceList && (
        <DeleteDialogContainer markupIdToDelete={markupIdToDelete} handleDelete={handleDelete} />
      )}
    </Stack>
  );
}
