import { PageLayout } from '@retail/components';
import { Paper } from '@shared/components';
import { BrandAssortmentContainer } from '@retail/mgpdm/containers';
import { useBrandAssortments } from '@retail/mgpdm/context';

export function BrandAssortmentPage() {
  const { baseAssortment, draftAssortmentId } = useBrandAssortments();
  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper
        paddingY="dense"
        sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 0 }}
      >
        {baseAssortment && (
          <BrandAssortmentContainer
            baseAssortment={baseAssortment}
            draftAssortmentId={draftAssortmentId}
          />
        )}
      </Paper>
    </PageLayout>
  );
}
