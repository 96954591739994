import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  noRowsText: string;
  addAction?: ReactNode;
}

export const NoTableRowsOverlay = ({ noRowsText, addAction }: Props) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      gap={2}
      height="100%"
      sx={{ position: 'relative', zIndex: 1, pointerEvents: 'all' }}
      // https://github.com/mui/mui-x/issues/5725
    >
      <Typography variant="h4" color="text.secondary">
        {noRowsText}
      </Typography>

      {addAction}
    </Stack>
  );
};
