import { Competitor, StoreCompetitor } from '@retail/retailer/types';
import { useMemo } from 'react';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useFetchCompetitors } from '../competitorsApi';

export const useSelectedCompetitors = (orgUnitId: number): Competitor[] => {
  const { data: competitorsDto = [] } = useFetchCompetitors(orgUnitId);
  const { retailerLogoBaseUrl } = useMgPrisConfig();

  return useMemo(() => {
    return competitorsDto.map((dto) => StoreCompetitor.fromStoreDto(dto, retailerLogoBaseUrl));
  }, [competitorsDto, retailerLogoBaseUrl]);
};
