import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { PriceSuggestionResources } from '../types/PriceSuggestionResources';

export const usePriceSuggestionTranslation = () =>
  useMessagesTranslation<PriceSuggestionResources>();

export type PriceSuggestionMessageKey = MessagePaths<PriceSuggestionResources>;

export const usePriceSuggestionTFunction = () => usePriceSuggestionTranslation().t;

export type PriceSuggestionTFunction = ReturnType<typeof usePriceSuggestionTFunction>;
