import { SupplierFreightConditionsDTO } from '../../dtos';
import { FreightCondition } from './FreightCondition';

interface SupplierFreightConditionsProps {
  mgSupplierId: number;
  freightConditions: FreightCondition[];
}

export class SupplierFreightConditions {
  mgSupplierId: number;
  freightConditions: FreightCondition[];

  constructor({ mgSupplierId, freightConditions }: SupplierFreightConditionsProps) {
    this.mgSupplierId = mgSupplierId;
    this.freightConditions = freightConditions;
  }

  static fromDto = (dto: SupplierFreightConditionsDTO): SupplierFreightConditions =>
    new SupplierFreightConditions({
      mgSupplierId: dto.mgSupplierId,
      freightConditions: dto.conditions.map(FreightCondition.fromDto),
    });
}
