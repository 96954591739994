import { SelectInputField } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import { MgSupplierItemPackage } from '@retail/products/types';
import { useFreightTFunction } from '@retail/freight/i18n';
import { Stack } from '@mui/material';
import { FreightConditionFormBase } from './FreightConditionFormBase';
import { FixedAddonFreightForm } from './FixedAddonFreightForm';
import { CreateFreightConditionSchema } from './freightConditionSchema';

interface Props {
  supplierItemPackages?: MgSupplierItemPackage[];
}

export function ItemLevelFreightForm({ supplierItemPackages }: Props) {
  const t = useFreightTFunction();

  const { control } = useFormContext<CreateFreightConditionSchema>();
  const freightTypeController = useController({ name: 'freightType', control });

  const freightOptions = [
    { value: 'percent', name: t('freight.form.percentOrFactor') },
    { value: 'fixedAddon', name: t('freight.form.fixedAddon') },
  ];

  return (
    <Stack flexDirection="column" gap={2}>
      <SelectInputField
        {...freightTypeController.field}
        error={freightTypeController.fieldState.error?.message}
        size="small"
        width={400}
        label={t('freight.form.freightType')}
        placeholder={t('freight.actions.chooseFreightType')}
        menuItems={freightOptions}
        required
      />
      {freightTypeController.field.value === 'percent' && <FreightConditionFormBase />}
      {freightTypeController.field.value === 'fixedAddon' && supplierItemPackages && (
        <FixedAddonFreightForm supplierItemPackages={supplierItemPackages} />
      )}
    </Stack>
  );
}
