import { Divider, Stack } from '@mui/material';
import { PurchaseAgreementsTable } from '@retail/purchase-agreements/components';
import { PriceListTable } from './PriceListTable';
import { MgSupplierItemSearchField } from '@retail/products/components';
import { useCallback, useMemo, useState } from 'react';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { useFetchPurchaseAgreements } from '@retail/purchase-agreements/data-access';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';

interface Props {
  assortmentId: number;
  supplierId: number;
  comparePriceLists: boolean;
  setStandardAgreement?: (purchaseAgreement: PurchaseAgreement | null) => void;
}

export function AgreementsWithPriceComparison({
  assortmentId,
  supplierId,
  comparePriceLists,
  setStandardAgreement,
}: Props) {
  const { data: purchaseAgreementsDto = [] } = useFetchPurchaseAgreements({ supplierId });
  const purchaseAgreements = purchaseAgreementsDto.map(PurchaseAgreement.fromDto);

  const standardAgreement = useMemo(
    () => purchaseAgreements.find((agreement) => agreement.standardAgreement),
    [purchaseAgreements]
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [checkedAgreements, setCheckedAgreements] = useState<PurchaseAgreement[]>(
    standardAgreement ? [standardAgreement] : []
  );

  const handleCheckAgreement = useCallback(
    (agreement: PurchaseAgreement) => {
      setCheckedAgreements((prev) => {
        const checkedAgreementIds = checkedAgreements.map((agreement) => agreement.id);
        if (checkedAgreementIds.includes(agreement.id)) {
          return prev.filter((checkedAgreement) => checkedAgreement.id !== agreement.id);
        }
        return prev.concat(agreement);
      });
    },
    [checkedAgreements]
  );

  return (
    <Stack flexDirection="row" justifyContent="space-between" flexGrow={1} overflow="hidden">
      <PurchaseAgreementsTable
        agreements={purchaseAgreements}
        compareMode={comparePriceLists}
        checkedAgreements={checkedAgreements}
        handleCheckAgreement={handleCheckAgreement}
        setStandardAgreement={setStandardAgreement}
      />

      {comparePriceLists && <Divider orientation="vertical" flexItem />}
      {comparePriceLists && (
        <Stack width="70%">
          <Stack alignSelf="flex-end">
            <MgSupplierItemSearchField
              isDisabled={false}
              onSubmitSearch={setSearchQuery}
              includeLabel={false}
              size="small"
            />
          </Stack>
          <SuspenseWithSentryErrorBoundary>
            <PriceListTable
              assortmentId={assortmentId}
              selectedAgreements={checkedAgreements}
              query={searchQuery}
              includeFuturePrices={true}
            />
          </SuspenseWithSentryErrorBoundary>
        </Stack>
      )}
    </Stack>
  );
}
