import { BodyLoader } from '@retail/components';
import {
  SupplierTabs,
  useBracketPriceStore,
  WholesalerProductCard,
} from '@retail/my-assortment/components';
import { SupplierWithPriority } from '@retail/my-assortment/types';
import { MgItem } from '@retail/products/types';
import { useDisclosure } from '@shared/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useFetchMgItem } from '@retail/products/data-access';
import { useIncludeVatMyAssortment } from '@retail/my-assortment/context';
import { useMgPrisConfig } from '@retail/app/stores/app-config';

import { useFeatureContext, useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { PrioritizeSupplierDialogContainer } from './PrioritizeSupplierDialogContainer';
import { EditBracketPricesDialogContainer } from './EditBracketPricesDialogContainer';

export interface ProductCardContainerProps {
  mgItemNumber: number;
  mgSupplierId: number;
}

export function WholesalerProductCardContainer({
  mgSupplierId,
  mgItemNumber,
}: ProductCardContainerProps) {
  const { featureFlags } = useMgPrisConfig();
  const featureContext = useFeatureContext();
  const { selectedDepartment, selectedPriceList } = useSelectedOrgUnit();
  const baseAssortmentId = selectedDepartment?.assortmentId ?? selectedPriceList?.assortmentId;

  const { includeVat, toggleIncludeVat } = useIncludeVatMyAssortment();
  const { selectedPackageType } = useBracketPriceStore();

  const { data: mgItemDto } = useFetchMgItem(
    {
      mgItemNumber,
      assortmentId: baseAssortmentId,
      calculationPackages: ['PRIMARY', 'SECONDARY', 'TERTIARY'],
    },
    { suspense: true }
  );
  const mgItem = MgItem.fromDto(mgItemDto!);

  const { isOpen: isPrioritizeDialogOpen, onToggle: togglePrioritizeDialog } = useDisclosure();

  const suppliers: SupplierWithPriority[] = useMemo(
    () =>
      mgItem.supplierItems.map((supplierItem) => {
        return {
          id: supplierItem.mgSupplierId,
          name: supplierItem.mgSupplierName,
          priority: supplierItem.priority,
        };
      }),
    [mgItem.supplierItems]
  );

  const [selectedSupplierId, selectSupplier] = useState(() => mgSupplierId);

  useEffect(() => {
    selectSupplier(mgSupplierId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mgSupplierId]);

  const selectedSupplierItem = mgItem.supplierItems.find(
    (supplierItem) => supplierItem.mgSupplierId === selectedSupplierId
  );

  const selectedSupplierItemPackage = useMemo(
    () => selectedSupplierItem?.packages.find(({ type }) => type === selectedPackageType),
    [selectedPackageType, selectedSupplierItem?.packages]
  );

  const {
    isOpen: isBracketPriceModalOpen,
    onOpen: onOpenBracketPricelModal,
    onClose: onCloseBracketPriceModal,
  } = useDisclosure();

  if (!selectedSupplierItem || !selectedSupplierItemPackage) {
    return <BodyLoader />;
  }

  return (
    <>
      <SupplierTabs
        suppliers={suppliers}
        clickedSupplier={mgSupplierId}
        selectedSupplier={selectedSupplierId}
        selectSupplier={selectSupplier}
        openPrioritizeDialog={togglePrioritizeDialog}
        disableEditPriority={!!selectedPriceList}
      />
      <WholesalerProductCard
        key={selectedSupplierItem.id}
        selectedSupplierItem={selectedSupplierItem}
        selectedSupplierItemPackage={selectedSupplierItemPackage}
        includeVat={includeVat}
        features={featureFlags}
        featureContext={featureContext}
        onOpenBracketPricelModal={onOpenBracketPricelModal}
      />

      {!selectedPriceList && (
        <PrioritizeSupplierDialogContainer
          mgItemNumber={mgItem.id}
          suppliers={suppliers}
          isPrioritizeDialogOpen={isPrioritizeDialogOpen}
          togglePrioritizeDialog={togglePrioritizeDialog}
        />
      )}
      {!selectedPriceList && (
        <EditBracketPricesDialogContainer
          currentSalesPrices={selectedSupplierItem.currentSalesPrices}
          selectedSupplierId={selectedSupplierId}
          selectedSupplierItem={selectedSupplierItem}
          mgItemNumber={mgItem.id}
          includeVat={includeVat}
          toggleIncludeVat={toggleIncludeVat}
          isBracketPriceModalOpen={isBracketPriceModalOpen}
          onCloseBracketPriceModal={onCloseBracketPriceModal}
        />
      )}
    </>
  );
}
