import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { Retailer } from '@retail/retailer/types';
import { useCallback, useMemo, useState } from 'react';
import { StoreCompetitorsSelector } from '@retail/retailer/components';
import { ContainedButton } from '@shared/components';
import { useAppTFunction } from '@retail/app/i18n';
import { EmptyCircularFallbackImage, Image } from '@retail/components';
import { useScrollWithShadow } from '@retail/hooks';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';

interface Props {
  retailers: Retailer[];
  addCompetitors: (storeIds: number[]) => void;
  loading: boolean;
}

export function EditCompetitors({ retailers, addCompetitors, loading }: Props) {
  const t = useAppTFunction();
  const { selectedDepartment } = useSelectedOrgUnit();
  const [selectedRetailer, setSelectedRetailer] = useState<Retailer | null>(null);

  const stores = useMemo(
    () =>
      retailers
        .find((retailer) => retailer.id === selectedRetailer?.id)
        ?.stores.filter((store) => !store.isSelected),
    [retailers, selectedRetailer]
  );

  const { onScrollHandler, boxShadow, overflowRef } = useScrollWithShadow([selectedRetailer]);

  const [selectedStores, setSelectedStores] = useState<number[]>([]);

  const updateSelectedStores = useCallback((storeId: number) => {
    setSelectedStores((prev) => {
      return prev.includes(storeId)
        ? prev.filter((prevStoreIds) => prevStoreIds !== storeId)
        : prev.concat(storeId);
    });
  }, []);

  return (
    <Stack p={1} flexGrow={1} overflow="hidden" gap={2}>
      <Typography variant="h4" component="h2">
        {t('assortment.competitors.chooseCompetitors')}
      </Typography>
      <Autocomplete
        options={retailers}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        // @ts-ignore
        renderOption={({ key, ...props }, option) => (
          <Stack key={key} component={'li'} my={0.5} flexDirection="row" gap={2} {...props}>
            <Image
              src={option.circularLogoUrl()}
              fallback={() => <EmptyCircularFallbackImage size="30px" />}
            />
            {option.name}
          </Stack>
        )}
        onChange={(_, newValue) => {
          setSelectedRetailer(newValue);
          setSelectedStores([]);
        }}
        value={selectedRetailer}
        renderInput={(params) => (
          <TextField {...params} placeholder={t('assortment.competitors.chooseRetailer')} />
        )}
      />
      <Stack
        flexGrow={1}
        overflow="auto"
        ref={overflowRef}
        onScroll={onScrollHandler}
        sx={{ boxShadow }}
      >
        {stores &&
          (stores.length ? (
            <StoreCompetitorsSelector
              stores={stores}
              onChange={(event) => updateSelectedStores(Number(event.target.value))}
              selectedStores={selectedStores}
              myCoordinates={
                selectedDepartment && selectedDepartment.latitude && selectedDepartment.longitude
                  ? { lat: selectedDepartment.latitude, long: selectedDepartment.longitude }
                  : undefined
              }
              logoUrl={selectedRetailer!.circularLogoUrl()}
            />
          ) : (
            <Typography mt={3}>{t('assortment.competitors.allCompetitors')}</Typography>
          ))}
      </Stack>
      <Stack alignItems="end">
        <ContainedButton
          loading={loading}
          onClick={() => {
            addCompetitors(selectedStores);
            setSelectedStores([]);
          }}
          disabled={!selectedStores.length}
        >
          {t('common.save')}
        </ContainedButton>
      </Stack>
    </Stack>
  );
}
