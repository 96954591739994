import { FC, PropsWithChildren } from 'react';
import { Paper } from '@mui/material';

export const ProductCardPaper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 0,
        overflow: 'auto',
        width: 500,
        minWidth: 500,
        maxWidth: 500,
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
      }}
    >
      {children}
    </Paper>
  );
};
