import { useCallback, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  DataGridPremium,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRowId,
} from '@mui/x-data-grid-premium';
import { AssortmentItem } from '@retail/my-assortment/types';
import { useAppTFunction } from '@retail/app/i18n';
import { getDataGridLocalization, MgPrisLocale } from '@retail/utils';
import { DraftAssortmentItemsTableContent } from './DraftAssortmentItemsTableContent';
import { CustomDetailPanelToggle } from '@retail/calculus/components';
import { dataGridNoHorizontalPaddingClass } from '@shared/styles';

interface Props {
  items: AssortmentItem[];
  isLoading: boolean;
  language: string;
  onDeleteDraftItem: (ids: number[]) => void;
  onSelectItems: (mgSupplierId: GridRowId, itemIds: number[]) => void;
}

const assortmentItemRowHeight = 70;
const headerHeight = 60;
const maxViewableRows = 10;

export interface GroupedAssortmentItems {
  mgSupplierId: number;
  items: AssortmentItem[];
}

const baseColumnProps: Partial<GridColDef> = {
  width: 150,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
};

export const DraftAssortmentItemsTable = ({
  items,
  isLoading,
  language,
  onDeleteDraftItem,
  onSelectItems,
}: Props) => {
  const t = useAppTFunction();

  const groupAssortmentItemsBySupplierId = (items: AssortmentItem[]): GroupedAssortmentItems[] => {
    const grouped = items.reduce((acc: { [key: number]: AssortmentItem[] }, item) => {
      if (!acc[item.mgSupplierId]) {
        acc[item.mgSupplierId] = [];
      }
      acc[item.mgSupplierId].push(item);
      return acc;
    }, {});

    return Object.keys(grouped).map((mgSupplierId) => ({
      mgSupplierId: Number(mgSupplierId),
      items: grouped[Number(mgSupplierId)],
    }));
  };

  const groupedAssortmentItems = useMemo(() => groupAssortmentItemsBySupplierId(items), [items]);

  const findSupplierName = useCallback(
    (mgSupplierId: number) => {
      const group = groupedAssortmentItems.find(
        (assortmentItemGroup) => assortmentItemGroup.mgSupplierId === mgSupplierId
      );

      return group?.items[0]?.mgSupplierName ?? mgSupplierId;
    },
    [groupedAssortmentItems]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'mgSupplierId',
        headerName: t('markup.supplier'),
        flex: 1,
        renderCell: ({ value }) => (
          <Box ml={6}>
            <Typography variant="body2" fontWeight={600}>
              {findSupplierName(value)}
            </Typography>
          </Box>
        ),
      },
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        align: 'right',
        renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
      },
    ],
    [findSupplierName, t]
  );

  const getDetailPanelContent = useCallback(
    (mgSupplierId: GridRowId) => {
      const assortmentItemsGroup = groupedAssortmentItems.find(
        (group) => group.mgSupplierId === mgSupplierId
      );

      return (
        <DraftAssortmentItemsTableContent
          mgSupplierId={mgSupplierId}
          items={assortmentItemsGroup?.items ?? []}
          isLoading={isLoading}
          language={language}
          onDeleteDraftItem={onDeleteDraftItem}
          onSelectItems={onSelectItems}
        />
      );
    },
    [groupedAssortmentItems, isLoading, language, onDeleteDraftItem, onSelectItems]
  );

  const getDetailPanelHeight = useCallback(
    (mgSupplierId: GridRowId) => {
      const assortmentItemsGroup = groupedAssortmentItems.find(
        (group) => group.mgSupplierId === mgSupplierId
      );
      const numberOfItems = assortmentItemsGroup?.items.length;
      return numberOfItems && numberOfItems < maxViewableRows
        ? numberOfItems * assortmentItemRowHeight + headerHeight
        : 700;
    },
    [groupedAssortmentItems]
  );

  const [detailPanelsOpen, setDetailPanelOpen] = useState<GridRowId[]>(
    groupedAssortmentItems.length === 1 ? [groupedAssortmentItems[0].mgSupplierId] : []
  );

  const onRowClick = useCallback(
    (id: GridRowId) =>
      detailPanelsOpen.includes(id)
        ? setDetailPanelOpen(detailPanelsOpen.filter((panelId) => panelId !== id))
        : setDetailPanelOpen([...detailPanelsOpen, id]),
    [detailPanelsOpen]
  );

  return (
    <DataGridPremium
      className={dataGridNoHorizontalPaddingClass}
      detailPanelExpandedRowIds={detailPanelsOpen}
      onDetailPanelExpandedRowIdsChange={(id) => setDetailPanelOpen(id)}
      onRowClick={({ id }) => onRowClick(id)}
      getRowId={(row) => row.mgSupplierId}
      columns={columns}
      rows={groupedAssortmentItems}
      rowHeight={50}
      columnHeaderHeight={0}
      getDetailPanelContent={({ id }) => getDetailPanelContent(id)}
      getDetailPanelHeight={({ id }) => getDetailPanelHeight(id)}
      hideFooter
      localeText={getDataGridLocalization(language as MgPrisLocale)}
      slots={{
        noRowsOverlay: () => (
          <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
            <Typography variant="h3" color="text.secondary">
              {t('myAssortment.noItems')}
            </Typography>
          </Stack>
        ),
      }}
      sx={{
        '.MuiDataGrid-row:hover': {
          cursor: 'auto',
        },
      }}
    />
  );
};
