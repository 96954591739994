import { Stack, Typography } from '@mui/material';
import { SalesCoverageInput } from './SalesCoverageInput';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { SalesCoverageValueView } from './SalesCoverageValueView';

interface Props {
  currentSalesCoverage?: number;
  newSalesCoverage?: {
    value: number;
    isApproximate: boolean;
  };
  isEditing: boolean;
  openEditMode: () => void;
  cancelEditMode: () => void;
  onChangeSalesCoverage: (value: number) => void;
}

export function SalesCoverageView({
  currentSalesCoverage,
  newSalesCoverage,
  isEditing,
  openEditMode,
  cancelEditMode,
  onChangeSalesCoverage,
}: Props) {
  const t = useMyAssortmentTFunction();

  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography fontWeight={600}>{t('myAssortment.salesCoverage')}</Typography>
      {isEditing ? (
        <SalesCoverageInput
          defaultValue={newSalesCoverage?.value || currentSalesCoverage || 0}
          onSubmit={onChangeSalesCoverage}
          onCancel={cancelEditMode}
        />
      ) : (
        <SalesCoverageValueView
          openEditMode={openEditMode}
          currentSalesCoverage={currentSalesCoverage}
          newSalesCoverage={newSalesCoverage}
        />
      )}
    </Stack>
  );
}
