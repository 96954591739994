import { useMemo } from 'react';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { CustomColumnMenu, DataGridPagination, StackedTextCell } from '@retail/components';
import { ItemAgreement } from '@retail/purchase-agreements/types';
import { Pagination } from '@shared/hooks';
import { AssortmentItem } from '@retail/my-assortment/types';
import { AssortmentItemActions } from './AssortmentItemActions';
import { useAppTFunction } from '@retail/app/i18n';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { getTogglableDataGridColumns } from '@shared/utils';
import { PriceSuggestionType } from '@retail/price-suggestion/types';
import { PriceResultType } from '@retail/products/types';
import {
  PriceResultTypeTooltip,
  useCurrentItemPriceColumns,
  useItemInfoColumns,
  usePriceListLevelColumns,
  useSalesPriceColumn,
} from '../AssortmentTable';
import { ItemStatus } from '../AssortmentTable/ItemStatus/ItemStatus';
import { EditButtonIcon } from '@shared/custom-icons';
import { ConditionTooltipInfo } from '@retail/calculus/components';

interface Props {
  items: AssortmentItem[];
  totalItems: number;
  mgItemClicked?: ItemAgreement;
  includeVat: boolean;
  language: string;
  viewItemCalculusCard: (itemId: number) => void;
  setEditItemAgreement: (item: AssortmentItem) => void;
  disableEdit: boolean;
  priceListLevel: boolean;
}

export const AssortmentItemsCalculusViewTable = ({
  items,
  totalItems,
  includeVat,
  language,
  page,
  pageSize,
  setPage,
  setPageSize,
  viewItemCalculusCard,
  setEditItemAgreement,
  disableEdit,
  priceListLevel,
}: Props & Pagination) => {
  const t = useAppTFunction();

  const { itemIdsColumn, itemNameColumn, itemGroupColumn, discountGroupColumn } =
    useItemInfoColumns({
      enableSortable: false,
    });
  const { purchasePriceColumn, costPriceInSalesUnitColumn } = useCurrentItemPriceColumns({
    includeVat,
    enableSortable: false,
  });
  const {
    customerMarkupFactorColumn,
    chainSalesPriceColumn,
    chainSalesCoverageColumn,
    customerSalesPriceColumn,
    customerSalesCoverageColumn,
  } = usePriceListLevelColumns({ includeVat, enableSortable: false });
  const salesPriceColumn = useSalesPriceColumn({ includeVat });

  const baseColumnProps: Partial<GridColDef> = useMemo(
    () => ({
      sortable: false,
      headerName: '',
      headerAlign: 'left',
      align: 'left',
      width: 140,
    }),
    []
  );
  const columns: GridColDef<AssortmentItem>[] = useMemo(
    () => [
      {
        ...baseColumnProps,

        field: 'status',
        headerAlign: 'center',
        align: 'center',
        width: 40,
        headerName: '',
        renderCell: ({ value }) => <ItemStatus status={value} />,
      },
      itemIdsColumn,
      itemNameColumn,
      itemGroupColumn,
      discountGroupColumn,
      purchasePriceColumn,
      {
        ...baseColumnProps,
        field: 'purchaseCondition',
        headerName: t('purchaseAgreements.condition'),
        width: 200,
        renderCell: ({ row }) => {
          const purchaseCondition = row.currentSalesPrices.purchaseCondition;

          return (
            <Stack flexDirection="row">
              <Tooltip
                title={<ConditionTooltipInfo title="" condition={purchaseCondition?.condition} />}
              >
                <Stack
                  border={({ palette }) => `1px solid ${palette.divider}`}
                  p={1}
                  borderRadius={2}
                >
                  <StackedTextCell
                    topLine={`${t('purchaseAgreements.agreement.columns.name')}: ${
                      purchaseCondition?.agreement.agreementName ?? '-'
                    }`}
                    bottomLine={`${t('purchaseAgreements.agreement.columns.id')}: ${
                      purchaseCondition?.agreement.agreementId ?? '-'
                    }`}
                  />
                </Stack>
              </Tooltip>
              {!disableEdit && (
                <IconButton onClick={() => setEditItemAgreement(row)}>
                  <EditButtonIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
          );
        },
      },
      costPriceInSalesUnitColumn,
      {
        ...baseColumnProps,
        field: 'freightCondition',
        headerName: t('freight.freightCondition'),
        align: 'center',
        headerAlign: 'center',
        width: 180,
        renderCell: ({ row }) => {
          const freightCondition = row.currentSalesPrices.freightCondition;

          const freightConditionValue = freightCondition?.fixedAddOn
            ? t('freight.columns.pricePerUnit', {
                price: freightCondition.fixedAddOn,
                unit: freightCondition.fixedAddOnUnit,
              })
            : freightCondition?.percentAddon
            ? freightCondition.percentAddon + '%'
            : freightCondition?.freightFactor;
          return freightCondition?.condition ? (
            <Tooltip
              title={<ConditionTooltipInfo title="" condition={freightCondition?.condition} />}
            >
              <Stack
                border={({ palette }) => `1px solid ${palette.divider}`}
                p={1}
                borderRadius={2}
              >
                {freightConditionValue}
              </Stack>
            </Tooltip>
          ) : (
            '-'
          );
        },
      },
      {
        ...baseColumnProps,
        width: 100,
        headerAlign: 'center',
        align: 'center',
        field: 'priceResultType',
        headerName: t('myAssortment.columns.originatesFrom'),
        renderCell: ({ row }) => {
          const originatesFrom = row.currentSalesPrices.priceResultType;
          const bracketMarkupPrice =
            row.currentSalesPrices?.getBracketPackagePrice('F-PAK')?.bracketMarkupPrice;
          const markupCondition = bracketMarkupPrice?.markup?.condition;
          const priceSuggestionCondition = bracketMarkupPrice?.priceSuggestion?.condition;
          const customerMarkupCondition = bracketMarkupPrice?.customerMarkup?.condition;

          return (
            <PriceResultTypeTooltip
              priceResultType={originatesFrom}
              condition={
                originatesFrom !== PriceResultType.PRICE_SUGGESTION
                  ? markupCondition
                  : priceSuggestionCondition
              }
              priceSuggestionType={
                row.currentSalesPrices.priceSuggestionType as PriceSuggestionType
              }
              customerMarkup={customerMarkupCondition}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'actions',
        align: 'center',
        width: 60,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <AssortmentItemActions
            viewCalculusCard={() => viewItemCalculusCard(row.mgSupplierItemId)}
          />
        ),
      },
    ],
    [
      baseColumnProps,
      costPriceInSalesUnitColumn,
      disableEdit,
      discountGroupColumn,
      itemGroupColumn,
      itemIdsColumn,
      itemNameColumn,
      purchasePriceColumn,
      setEditItemAgreement,
      t,
      viewItemCalculusCard,
    ]
  );

  if (priceListLevel) {
    columns.splice(
      9,
      0,
      chainSalesPriceColumn,
      chainSalesCoverageColumn,
      customerMarkupFactorColumn,
      customerSalesPriceColumn,
      customerSalesCoverageColumn
    );
  }
  if (!priceListLevel) {
    columns.splice(9, 0, salesPriceColumn);
  }
  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium
          getRowId={(row) => row.mgItemNumber}
          columns={columns}
          rows={items}
          rowHeight={70}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnReorder
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  DATA_GRID_ID.CALCULUS_ITEMS_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: { right: ['actions'] },
          }}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);
            saveHiddenDataGridColumnsToLocalStorage(
              DATA_GRID_ID.CALCULUS_ITEMS_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          pagination={true}
          paginationMode="server"
          slots={{
            columnMenu: CustomColumnMenu,
            pagination: () => <DataGridPagination />,
          }}
          rowCount={totalItems}
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={(model) => {
            if (model.page !== page) setPage(model.page);
            if (model.pageSize !== pageSize) setPageSize(model.pageSize);
          }}
          slotProps={{
            ...columnsManagementSlotProps,
          }}
        />
      </Box>
    </Box>
  );
};
