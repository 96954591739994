import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Typography,
  styled,
} from '@mui/material';
import { ReactNode } from 'react';
import { PANELS } from './ProductInformationAccordions';
import { useProductsTFunction } from '@retail/products/i18n';

export interface PanelProps {
  expanded: boolean;
  handleChange: (expanded: PANELS) => void;
}

interface Props {
  panelType: PANELS;
  children: ReactNode;
  backgroundColor?: string;
  expanded: boolean;
  handleChange: (expanded: PANELS) => void;
}

export const ProductInformationAccordion = ({
  panelType,
  expanded,
  backgroundColor,
  children,
  handleChange,
}: Props) => {
  const t = useProductsTFunction();

  // @ts-ignore
  const getTitle = () => t(`products.mgItem.details.${panelType.toLowerCase()}`);

  return (
    <StyledProductInformationAccordion expanded={expanded} onChange={() => handleChange(panelType)}>
      <ProductInformationAccordionSummary sx={{ backgroundColor }}>
        {getTitle()}
      </ProductInformationAccordionSummary>
      <ProductInformationAccordionDetails>
        <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.grey[400] }}>
          {children}
        </Typography>
      </ProductInformationAccordionDetails>
    </StyledProductInformationAccordion>
  );
};

const ProductInformationAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary {...props} />
))(() => ({ fontWeight: 500 }));

const ProductInformationAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
}));

const StyledProductInformationAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[50]}`,

  '&:not(:last-child)': {
    borderBottom: 0,
  },

  '&::before': {
    display: 'none',
  },
}));
