import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { usePriceSuggestionTFunction } from '@retail/price-suggestion/i18n';
import { PriceSuggestionType, PriceSuggestionTypeWithCount } from '@retail/price-suggestion/types';
import { ChangeEvent, useCallback } from 'react';
import { RadioLabel } from './RadioLabel';

interface Props {
  priceSuggestionTypes: PriceSuggestionTypeWithCount[];
  checkedPriceType?: PriceSuggestionType | null;
  checkPriceType: (priceType: PriceSuggestionType) => void;
  itemLevel?: boolean;
}

export function PriceTypesRadionGroup({
  priceSuggestionTypes,
  checkedPriceType,
  checkPriceType,
  itemLevel,
}: Props) {
  const t = usePriceSuggestionTFunction();

  const handleFollowPriceSuggestion = useCallback(
    (value: ChangeEvent<HTMLInputElement>) => {
      checkPriceType(value.target.value as PriceSuggestionType);
    },
    [checkPriceType]
  );

  const getItemCount = useCallback(
    (priceType: PriceSuggestionType) =>
      itemLevel
        ? undefined
        : priceSuggestionTypes.find(
            (priceSuggestion) => priceSuggestion.suggestionType === priceType
          )?.count,
    [itemLevel, priceSuggestionTypes]
  );

  const includedPriceSuggestionTypes = priceSuggestionTypes.map(
    (priceSuggestion) => priceSuggestion.suggestionType
  );

  return (
    <RadioGroup value={checkedPriceType} onChange={(value) => handleFollowPriceSuggestion(value)}>
      {(includedPriceSuggestionTypes.includes(PriceSuggestionType.FINFO_BASE) ||
        includedPriceSuggestionTypes.includes(PriceSuggestionType.FINFO_RECOMMENDED)) && (
        <Stack alignContent="flex-start">
          <Stack
            height={50}
            width="100%"
            justifyContent="center"
            bgcolor={({ palette }) => palette.grey[50]}
          >
            <Typography pl={2}>{t('priceSuggestion.finfoPriceSuggestion')}</Typography>
          </Stack>
          {includedPriceSuggestionTypes.includes(PriceSuggestionType.FINFO_BASE) && (
            <FormControlLabel
              control={<Radio />}
              value={PriceSuggestionType.FINFO_BASE}
              label={
                <RadioLabel
                  priceType={PriceSuggestionType.FINFO_BASE}
                  itemCount={getItemCount(PriceSuggestionType.FINFO_BASE)}
                />
              }
            />
          )}
          {includedPriceSuggestionTypes.includes(PriceSuggestionType.FINFO_RECOMMENDED) && (
            <FormControlLabel
              control={<Radio />}
              value={PriceSuggestionType.FINFO_RECOMMENDED}
              label={
                <RadioLabel
                  priceType={PriceSuggestionType.FINFO_RECOMMENDED}
                  itemCount={getItemCount(PriceSuggestionType.FINFO_RECOMMENDED)}
                />
              }
            />
          )}
        </Stack>
      )}
      {includedPriceSuggestionTypes.includes(
        PriceSuggestionType.MG_AGGRESSIVE ||
          PriceSuggestionType.MG_CONSERVATIVE ||
          PriceSuggestionType.MG_MODERATE
      ) && (
        <Stack alignContent="flex-start">
          <Stack
            height={50}
            width="100%"
            justifyContent="center"
            bgcolor={({ palette }) => palette.grey[50]}
          >
            <Typography pl={2}>{t('priceSuggestion.mgSuggestedPrice')}</Typography>
          </Stack>
          <FormControlLabel
            control={<Radio />}
            value={PriceSuggestionType.MG_CONSERVATIVE}
            label={
              <RadioLabel
                priceType={PriceSuggestionType.MG_CONSERVATIVE}
                itemCount={getItemCount(PriceSuggestionType.MG_CONSERVATIVE)}
              />
            }
          />
          <FormControlLabel
            control={<Radio />}
            value={PriceSuggestionType.MG_MODERATE}
            label={
              <RadioLabel
                priceType={PriceSuggestionType.MG_MODERATE}
                itemCount={getItemCount(PriceSuggestionType.MG_MODERATE)}
              />
            }
          />
          <FormControlLabel
            control={<Radio />}
            value={PriceSuggestionType.MG_AGGRESSIVE}
            label={
              <RadioLabel
                priceType={PriceSuggestionType.MG_AGGRESSIVE}
                itemCount={getItemCount(PriceSuggestionType.MG_AGGRESSIVE)}
              />
            }
          />
        </Stack>
      )}
    </RadioGroup>
  );
}
