import { Stack } from '@mui/material';
import { calculusActionPortalId, SideFilters, ToggleFiltersButton } from '@retail/components';
import { useDisclosure } from '@shared/hooks';
import { PropsWithChildren } from 'react';

export function AssortmentCalculusTabContainer({ children }: PropsWithChildren) {
  const { isOpen: showFilters, onToggle: toggleFilters } = useDisclosure(false);

  return (
    <Stack direction="row" flexGrow={1} overflow="auto" mb={2}>
      <Stack flexGrow={1}>
        <Stack flexDirection="row" gap={2} justifyContent="flex-end" my={1.5} mx={2}>
          <Stack id={calculusActionPortalId} direction="row" gap={1} />

          {!showFilters && (
            <ToggleFiltersButton showFilters={showFilters} toggleFilters={toggleFilters} />
          )}
        </Stack>
        {children}
      </Stack>
      {showFilters && (
        <SideFilters heading="Temp" showFilters={showFilters} closeFilters={toggleFilters} />
      )}
    </Stack>
  );
}
