import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { ItemCodes, ItemsCodeLists } from '@retail/monitoring-assortment/types';
import { ExpandCircleDown } from '@mui/icons-material';
import { Chip } from '@shared/components';
import { useMemo } from 'react';

interface Props {
  importedItems: ItemCodes[];
  invalidItemCodes?: ItemsCodeLists;
}

export function ValidateImportedItems({ importedItems, invalidItemCodes }: Props) {

  const t = useAssortmentTFunction();

  const uniqueItems = useMemo(
    () =>
      importedItems.filter(
        (v, i, a) => a.findIndex((v2) => v2.mgItemNumber === v.mgItemNumber) === i
      ),
    [importedItems]
  );

  const nrOfInvalidRows = useMemo(() => {
    const invalidItems = uniqueItems.filter(
      (item) =>
        invalidItemCodes?.mgItemNumbers?.includes(Number(item.mgItemNumber)) ||
        invalidItemCodes?.finfoNumbers?.includes(Number(item.finfoNumber)) ||
        invalidItemCodes?.gtinCodes?.includes(Number(item.gtinCode))
    );
    return invalidItems.length;
  }, [invalidItemCodes, uniqueItems]);

  return (
    <Stack gap={1}>
      {uniqueItems.length > nrOfInvalidRows && (
        <Alert severity="success">
          <Typography variant="subtitle1">
            {t('assortment.importFromFile.validated', {
              count: uniqueItems.length - nrOfInvalidRows,
            })}
          </Typography>
        </Alert>
      )}
      {invalidItemCodes && !!nrOfInvalidRows && (
        <Alert severity="error">
          <Stack gap={1}>
            <Typography variant="subtitle1">
              {nrOfInvalidRows === 1
                ? t('assortment.importFromFile.notValidated.singular', {
                    count: nrOfInvalidRows,
                  })
                : t('assortment.importFromFile.notValidated.plural', {
                    count: nrOfInvalidRows,
                  })}
            </Typography>

            <Typography>{t('assortment.admin.create.fromFile.import.notValid')}</Typography>
            {!!invalidItemCodes.finfoNumbers?.length && (
              <StyledAccordion>
                <AccordionSummary expandIcon={<ExpandCircleDown />} id="finfo">
                  {t('assortment.admin.create.fromFile.import.notValidItem', {
                    count: invalidItemCodes.finfoNumbers.length,
                    numbers: t('assortment.admin.create.fromFile.import.finfo'),
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  {invalidItemCodes.finfoNumbers.map((nr) => (
                    <Chip key={nr} backgroundColor="white" sx={{ mr: 1 }} label={nr} />
                  ))}
                </AccordionDetails>
              </StyledAccordion>
            )}
            {!!invalidItemCodes.gtinCodes?.length && (
              <StyledAccordion>
                <AccordionSummary expandIcon={<ExpandCircleDown />} id="gtin">
                  {t('assortment.admin.create.fromFile.import.notValidItem', {
                    count: invalidItemCodes.gtinCodes.length,
                    numbers: t('assortment.admin.create.fromFile.import.gtin'),
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  {invalidItemCodes.gtinCodes.map((nr) => (
                    <Chip key={nr} backgroundColor="white" sx={{ mr: 1 }} label={nr} />
                  ))}
                </AccordionDetails>
              </StyledAccordion>
            )}
            {!!invalidItemCodes.mgItemNumbers?.length && (
              <StyledAccordion>
                <AccordionSummary expandIcon={<ExpandCircleDown />} id="mgItem">
                  {t('assortment.admin.create.fromFile.import.notValidItem', {
                    count: invalidItemCodes.mgItemNumbers.length,
                    numbers: t('assortment.admin.create.fromFile.import.mgItem'),
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  {invalidItemCodes.mgItemNumbers.map((nr) => (
                    <Chip key={nr} backgroundColor="white" sx={{ mr: 1 }} label={nr} />
                  ))}
                </AccordionDetails>
              </StyledAccordion>
            )}
          </Stack>
        </Alert>
      )}
    </Stack>
  );
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'inherit',
  border: `1px solid ${theme.palette.error.main}`,
}));
