import { MgSupplierItem } from './MgSupplierItem';
import { MgItemDTO } from '../../dto';
import { MgItemBase } from './MgItemBase';
import { MgPackage } from './MgPackage';
import { CompetitorPrice } from '../CompetitorPrice';

export class MgItem extends MgItemBase<MgSupplierItem> {
  constructor(
    id: number,
    supplierItems: MgSupplierItem[],
    mgPackages: MgPackage[],
    updatedAt: string,
    createdAt: string,
    replacedByMgItemNumber?: number,
    competitorPrices?: Record<number, CompetitorPrice[]>
  ) {
    super({
      id,
      supplierItems,
      mgPackages,
      updatedAt,
      createdAt,
      replacedByMgItemNumber,
      competitorPrices,
    });
  }

  getSuppliers = (): Partial<MgSupplierItem>[] =>
    this.supplierItems.map((supplierItems) => ({
      mgSupplierId: supplierItems.mgSupplierId,
      mgSupplierName: supplierItems.mgSupplierName,
      mgSupplierItemId: supplierItems.mgSupplierItemId,
    })) ?? [];

  getSupplierItem = (supplierId: number): MgSupplierItem | undefined =>
    this.supplierItems.find((supplierItem) => supplierItem.mgSupplierId === supplierId);

  getPrioritizedSupplierItem = (): MgSupplierItem | undefined =>
    this.supplierItems.find((supplierItem) => supplierItem.priority === 'TEN') ??
    this.supplierItems.find((supplierItem) => supplierItem.priority === 'ZERO') ??
    this.supplierItems.find((supplierItem) => supplierItem.mainSupplier);

  static fromDto = (mgItemDto: MgItemDTO): MgItem => {
    const supplierItems: MgSupplierItem[] = mgItemDto.mgSupplierItems?.map((supplierItem) =>
      MgSupplierItem.fromDto(supplierItem)
    );

    const mgPackages = mgItemDto.mgPackages.map(MgPackage.fromDto);

    const competitorPrices = mgItemDto.competitorPrices
      ? Object.entries(mgItemDto.competitorPrices).reduce(
          (tmp, [storeId, storePrices]) => ({
            ...tmp,
            [storeId]: storePrices.map((storePrice) => new CompetitorPrice(storePrice)),
          }),
          {}
        )
      : {};

    return new MgItem(
      mgItemDto.mgItemNumber,
      supplierItems,
      mgPackages,
      mgItemDto.updatedAt,
      mgItemDto.createdAt,
      mgItemDto.replacedByMgItemNumber,
      competitorPrices
    );
  };
}
