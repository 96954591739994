import {
  BrandAssortmentPage,
  BrandMarketInsightPage,
  CalculusPage,
  CompetitorsPage,
  DepartmentMarketInsightPage,
  ExportPage,
  FeatureTogglesPage,
  MgItemPage,
  MgSupplierItemSearchPage,
  MonitoringAssortmentPage,
  MyAssortmentPage,
  SuppliersPage,
} from '@retail/app/pages';
import { mgPrisRoutes, pdmRoutes } from '@retail/mgpris/config';
import { Navigate, Route } from 'react-router-dom';
import { PdmUserRouteGuardOutlet } from './PdmUserRouteGuardOutlet';
import { NoMonitoringAssortmentsContainer } from '@retail/monitoring-assortment/containers';
import { MGPUserRouteGuardOutlet } from './MGPUserRouteGuardOutlet';

export const mgpRoutes = () => (
  <Route path={mgPrisRoutes.root.fullRoutePath} element={<MGPUserRouteGuardOutlet />}>
    <Route
      path={mgPrisRoutes.marketInsight.fullRoutePath}
      element={<DepartmentMarketInsightPage />}
    >
      <Route
        path={mgPrisRoutes.marketInsight.assortments.fullRoutePath}
        element={
          <NoMonitoringAssortmentsContainer marketInsightRoute={mgPrisRoutes.marketInsight} />
        }
      />
      <Route
        path={mgPrisRoutes.marketInsight.assortments.fullRoutePath}
        element={<Navigate to={mgPrisRoutes.marketInsight.fullRoutePath} replace />}
      />
      <Route
        path={mgPrisRoutes.marketInsight.assortments.assortment.fullRoutePath}
        element={
          <MonitoringAssortmentPage
            marketInsightRoute={mgPrisRoutes.marketInsight}
            orgType="department"
          />
        }
      />
      <Route
        path={mgPrisRoutes.marketInsight.competitors.fullRoutePath}
        element={<CompetitorsPage />}
      />
    </Route>

    <Route path={mgPrisRoutes.items.fullRoutePath} element={<MgSupplierItemSearchPage />} />

    <Route path={mgPrisRoutes.items.item.fullRoutePath} element={<MgItemPage />} />

    <Route path={mgPrisRoutes.myAssortment.fullRoutePath} element={<MyAssortmentPage />} />
    <Route path={mgPrisRoutes.calculus.fullRoutePath} element={<CalculusPage />} />
    <Route path={mgPrisRoutes.calculus.supplier.fullRoutePath} element={<CalculusPage />} />

    <Route path={mgPrisRoutes.suppliers.fullRoutePath} element={<SuppliersPage />} />

    <Route path={mgPrisRoutes.export.fullRoutePath} element={<ExportPage />} />

    <Route path={mgPrisRoutes.superuser.features.fullRoutePath} element={<FeatureTogglesPage />} />
  </Route>
);

export const mgPdmRoutes = () => (
  <Route path={pdmRoutes.root.fullRoutePath} element={<PdmUserRouteGuardOutlet />}>
    <Route path={pdmRoutes.brandAssortment.fullRoutePath} element={<BrandAssortmentPage />} />

    <Route path={pdmRoutes.marketInsight.fullRoutePath} element={<BrandMarketInsightPage />}>
      <Route
        path={pdmRoutes.marketInsight.assortments.fullRoutePath}
        element={<NoMonitoringAssortmentsContainer marketInsightRoute={pdmRoutes.marketInsight} />}
      />
      <Route
        path={pdmRoutes.marketInsight.assortments.fullRoutePath}
        element={<Navigate to={pdmRoutes.marketInsight.fullRoutePath} replace />}
      />
      <Route
        path={pdmRoutes.marketInsight.assortments.assortment.fullRoutePath}
        element={
          <MonitoringAssortmentPage marketInsightRoute={pdmRoutes.marketInsight} orgType="brand" />
        }
      />
      <Route
        path={pdmRoutes.marketInsight.competitors.fullRoutePath}
        element={<CompetitorsPage />}
      />
    </Route>

    <Route path={pdmRoutes.items.fullRoutePath} element={<MgSupplierItemSearchPage />} />

    <Route path={pdmRoutes.items.item.fullRoutePath} element={<MgItemPage />} />
  </Route>
);
