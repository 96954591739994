import { FormControl, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { LevelSelectForm } from '@retail/calculus/components';
import { MgSupplierItemSearchResult, OverGroup } from '@retail/products/types';
import { useController, useFormContext } from 'react-hook-form';
import { FollowPriceSuggestionSchema } from './followPriceSuggestionSchema';
import { PriceSuggestionType, PriceSuggestionTypeWithCount } from '@retail/price-suggestion/types';
import { useCallback } from 'react';
import { usePriceSuggestionTFunction } from '@retail/price-suggestion/i18n';
import { HelperText } from '@shared/components';
import { PriceTypesRadionGroup } from '../PriceTypeRadioGroup';

interface Props {
  productGroups: OverGroup[];
  mgSupplierItems?: MgSupplierItemSearchResult[];
  priceSuggestionTypes: PriceSuggestionTypeWithCount[];
  isLoadingFetchItems: boolean;
}

export function FollowPriceSuggestionForm({
  productGroups,
  mgSupplierItems,
  priceSuggestionTypes,
  isLoadingFetchItems,
}: Props) {
  const t = usePriceSuggestionTFunction();

  const { control, resetField } = useFormContext<FollowPriceSuggestionSchema>();
  const levelController = useController({ name: 'level', control });
  const overGroupController = useController({ name: 'overGroup', control });
  const itemController = useController({ name: 'item', control });
  const priceSuggestionController = useController({ name: 'priceSuggestionType', control });

  const handleLevelSelectChange = (value: SelectChangeEvent<unknown>) => {
    resetField('item');
    resetField('overGroup');
    resetField('mainGroup');
    resetField('productGroup');
    resetField('discountGroup');
    levelController.field.onChange(value);
  };

  const handleFollowPriceSuggestion = useCallback(
    (value: PriceSuggestionType) => {
      priceSuggestionController.field.onChange(value);
    },
    [priceSuggestionController.field]
  );

  return (
    <form>
      <Stack gap={3} pb={1}>
        <LevelSelectForm
          productGroups={productGroups}
          mgSupplierItems={mgSupplierItems}
          isLoadingFetchItems={isLoadingFetchItems}
          handleLevelSelectChange={handleLevelSelectChange}
        />
        {(levelController.field.value === 'supplier' ||
          overGroupController.field.value ||
          !!itemController.field.value) &&
          (priceSuggestionTypes.length === 0 ? (
            <Typography>{t('priceSuggestion.noPriceSuggestion')}</Typography>
          ) : (
            <FormControl error={!!priceSuggestionController.fieldState.error} required>
              <PriceTypesRadionGroup
                priceSuggestionTypes={priceSuggestionTypes}
                checkedPriceType={priceSuggestionController.field.value as PriceSuggestionType}
                checkPriceType={handleFollowPriceSuggestion}
                itemLevel={!!itemController.field.value}
              />
              {priceSuggestionController.fieldState.error && (
                <HelperText id={'pricesuggestion'} status={'error'}>
                  {t('priceSuggestion.error.selectPriceSuggestion')}
                </HelperText>
              )}
            </FormControl>
          ))}
      </Stack>
    </form>
  );
}
