import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { ContainedButton, Dialog } from '@shared/components';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { CreateAssortmentMarkupSchema, CreateMarkup } from '@retail/markup/components';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { useCreateMarkup } from '@retail/markup/context';

interface Props {
  open: boolean;
  onClose: () => void;
}

function CreateAssortmentMarkupConditionDialog({ open, onClose }: Props) {
  const t = useAppTFunction();

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useFormContext<CreateAssortmentMarkupSchema>();

  const { createAssortmentMarkupCondition } = useCreateMarkup();

  const onActivate = useCallback(
    ({ fromDate, markupName, salesFactor }: CreateAssortmentMarkupSchema) => {
      createAssortmentMarkupCondition({
        validFrom: fromDate,
        templateName: markupName,
        salesFactor: Number(salesFactor),
      });

      reset();
      onClose();
    },
    [createAssortmentMarkupCondition, reset, onClose]
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ pb: 1 }}>{t('markup.create.newMarkup')}</DialogTitle>

      <DialogContent>
        <CreateMarkup markupCondition={undefined} />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', mt: 1 }}>
        <ContainedButton
          disabled={Object.keys(errors).length > 0}
          onClick={handleSubmit(onActivate)}
        >
          {t('markup.actions.activate')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(CreateAssortmentMarkupConditionDialog);
