import { SpreadsheetStructure } from '@retail/products/types';
import { atom, useRecoilState } from 'recoil';

export const itemsSpreadsheetAtom = atom<SpreadsheetStructure | undefined>({
  key: 'importedItemsSpreadsheet',
  default: undefined,
});
export const useSpreadsheetState = () => useRecoilState(itemsSpreadsheetAtom);

export const itemsFileAtom = atom<File | undefined>({
  key: 'importedItemsFileAtom',
  default: undefined,
});
