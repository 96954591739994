import { useMyPriceLists, useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useFetchAssortmentConditions, useFetchConditions } from '@retail/calculus/data-access';
import {
  CalculusAreas,
  Conditions,
  CustomerMarkupsForPriceLists,
  SupplierCustomerMarkupCondition,
} from '@retail/calculus/types';
import { MgSupplierMinimal } from '@retail/products/types';
import { useMemo } from 'react';

export const useFetchCalculusConditions = (isOpen?: boolean) => {
  const { selectedDepartment, selectedPriceList } = useSelectedOrgUnit();
  const draftAssortmentId = (selectedDepartment?.draftAssortmentId ??
    selectedPriceList?.draftAssortmentId)!;

  const availablePriceListsForDepartment = useMyPriceLists();
  const priceListIds = useMemo(
    () =>
      availablePriceListsForDepartment?.map(
        (availablePriceListsForDepartment) => availablePriceListsForDepartment.draftAssortmentId
      ),
    [availablePriceListsForDepartment]
  );

  // Agreements
  const { data: draftConditionsDTO, isLoading: conditionsIsLoading } = useFetchAssortmentConditions(
    {
      assortmentId: draftAssortmentId,
      refetchInterval: isOpen ? 3000 : undefined,
    }
  );
  const draftConditions = draftConditionsDTO && Conditions.fromDto(draftConditionsDTO);

  const { data: draftConditionsForPriceListsDTO } = useFetchConditions({
    assortmentIds: priceListIds ?? [],
    conditionTypes: [CalculusAreas.CUSTOMER_MARKUP],
    refetchInterval: isOpen ? 3000 : undefined,
    enabled: !!availablePriceListsForDepartment,
  });
  const draftConditionsForPriceLists =
    draftConditionsForPriceListsDTO && draftConditionsForPriceListsDTO.map(Conditions.fromDto);

  const priceListsWithCustomerMarkups = draftConditionsForPriceLists?.filter(
    (priceList) => !!priceList.customerMarkupConditions?.supplierMarkupConditions.length
  );

  const customerMarkupsForPriceLists: CustomerMarkupsForPriceLists[] | undefined = useMemo(() => {
    const draftConditionsWithCustomerMarkup = draftConditionsForPriceLists?.filter(
      (priceList) => !!priceList.customerMarkupConditions?.supplierMarkupConditions.length
    );

    return draftConditionsWithCustomerMarkup?.flatMap((conditions) => {
      return {
        priceListAssortmentId: conditions.assortmentId,
        priceListName: availablePriceListsForDepartment?.find(
          (priceList) => priceList.draftAssortmentId === conditions.assortmentId
        )?.name,
        conditionIds: conditions.customerMarkupConditions?.supplierMarkupConditions
          .flatMap((condition) => condition.markupConditions)
          .map(({ id }) => id),
      };
    });
  }, [availablePriceListsForDepartment, draftConditionsForPriceLists]);

  const purchaseConditions = useMemo(
    () => draftConditions?.purchaseConditions ?? [],
    [draftConditions]
  );

  const freightConditions = useMemo(
    () => draftConditions?.freightConditions?.supplierFreightConditions ?? [],
    [draftConditions]
  );
  const markupConditions = useMemo(
    () => draftConditions?.markupConditions?.supplierMarkupConditions ?? [],
    [draftConditions]
  );
  const followPriceConditions = useMemo(
    () => draftConditions?.priceSuggestionConditions?.supplierPriceSuggestionConditions ?? [],
    [draftConditions]
  );

  const customerMarkupConditions = useMemo(() => {
    if (priceListsWithCustomerMarkups) {
      const customerMarkups = priceListsWithCustomerMarkups
        .flatMap((condition) => condition.customerMarkupConditions)
        .flatMap((customerMarkupConditions) => customerMarkupConditions?.supplierMarkupConditions)
        .filter((condition) => !!condition) as SupplierCustomerMarkupCondition[];

      return customerMarkups;
    }

    return draftConditions?.customerMarkupConditions?.supplierMarkupConditions ?? [];
  }, [
    draftConditions?.customerMarkupConditions?.supplierMarkupConditions,
    priceListsWithCustomerMarkups,
  ]);

  const conditions = useMemo(() => {
    return {
      ...draftConditions,
      customerMarkupConditions: { supplierMarkupConditions: customerMarkupConditions },
    } as Conditions;
  }, [customerMarkupConditions, draftConditions]);

  const conditionsLength = useMemo(
    () =>
      purchaseConditions.length +
      freightConditions.length +
      markupConditions.length +
      followPriceConditions.length +
      customerMarkupConditions.length,
    [
      customerMarkupConditions.length,
      followPriceConditions.length,
      freightConditions.length,
      markupConditions.length,
      purchaseConditions.length,
    ]
  );

  const suppliersList = useMemo(() => {
    const purchase =
      purchaseConditions.map((condition) => {
        return {
          mgSupplierId: condition.mgSupplierId,
          name: condition.purchaseConditions[0].mgSupplierName,
        } as MgSupplierMinimal;
      }) ?? [];
    const freight =
      freightConditions.map((condition) => {
        return {
          mgSupplierId: condition.mgSupplierId,
          name: condition.freightConditions[0].mgSupplierName,
        } as MgSupplierMinimal;
      }) ?? [];
    const markup =
      markupConditions.map((condition) => {
        return {
          mgSupplierId: condition.mgSupplierId,
          name: condition.markupConditions[0].mgSupplierName,
        } as MgSupplierMinimal;
      }) ?? [];
    const priceSuggestion =
      followPriceConditions.map((condition) => {
        return {
          mgSupplierId: condition.mgSupplierId,
          name: condition.priceSuggestionConditions[0].mgSupplierName,
        } as MgSupplierMinimal;
      }) ?? [];
    const customerMarkup =
      customerMarkupConditions.map((condition) => {
        return {
          mgSupplierId: condition.mgSupplierId,
          name: condition.markupConditions[0].mgSupplierName,
        } as MgSupplierMinimal;
      }) ?? [];

    const filteredSuppliers: MgSupplierMinimal[] = [];
    const suppliers = [
      ...new Set([...purchase, ...freight, ...markup, ...priceSuggestion, ...customerMarkup]),
    ];

    suppliers.map((supplier) =>
      filteredSuppliers.map((supplier) => supplier.mgSupplierId).includes(supplier.mgSupplierId)
        ? null
        : filteredSuppliers.push(supplier)
    );

    return filteredSuppliers;
  }, [
    customerMarkupConditions,
    followPriceConditions,
    freightConditions,
    markupConditions,
    purchaseConditions,
  ]);

  return {
    conditionsLength,
    draftConditions: conditions,
    customerMarkupsForPriceLists,
    draftConditionsIsLoading: conditionsIsLoading,
    suppliersList,
  };
};
