import { useCallback, useEffect, useMemo } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import {
  APP_BAR_HEIGHT,
  Navigation,
  NavRoute,
  NavWithSubRoutes,
  Sidebar,
  SidebarHeader,
  SidebarUserMenu,
} from '@shared/components';
import { useSidebarDisclosure } from '@shared/stores';
import {
  isSuperUserAtom,
  useAuthContext,
  useFetchMe,
  useIsSuperUser,
} from '@retail/auth/data-access';
import { useSharedTranslation } from '@shared/i18n';
import { useSetRecoilState } from 'recoil';

export type Route = NavRoute | NavWithSubRoutes;
export const APP_BAR_MGP_MARGIN_TOP = 16;

interface Props {
  navRoutes: Route[];
}

export function AppSidebar({ navRoutes }: Props) {
  const { t: sharedT } = useSharedTranslation();

  const {
    isOpen: isSidebarOpen,
    onToggle: toggleSidebar,
    onOpen: openSidebar,
  } = useSidebarDisclosure();

  const { data: user } = useFetchMe({ refetchOnWindowFocus: false });
  const userName = useMemo(() => `${user?.givenName} ${user?.familyName}`, [user]);

  const isSuperUser = useIsSuperUser();
  const setIsSuperUser = useSetRecoilState(isSuperUserAtom);

  const { logout } = useAuthContext();
  const logoutDefault = useCallback(() => logout(), [logout]);

  useEffect(() => {
    setIsSuperUser(isSuperUser);
  }, [isSuperUser, setIsSuperUser]);

  return (
    <Sidebar
      isOpen={isSidebarOpen}
      toggleOpen={toggleSidebar}
      appBarHeight={APP_BAR_HEIGHT + APP_BAR_MGP_MARGIN_TOP}
      hasBorderTopRightRadius
    >
      <SidebarHeader title={sharedT('shared.navigation')} expandedView={isSidebarOpen} />
      <Navigation
        navRoutes={navRoutes}
        expandedView={isSidebarOpen}
        expandView={openSidebar}
        overridingStyles={listButtonStyles}
      />

      <Box marginTop="auto" display="flex" p={3} justifyContent="center">
        <SidebarUserMenu userName={userName} logOut={logoutDefault} expandedView={isSidebarOpen} />
      </Box>
    </Sidebar>
  );
}

const listButtonStyles: SxProps<Theme> = ({ palette }) => ({
  color: palette.grey.A400,
  background: 'none',

  '&.Mui-selected': {
    color: palette.common.white,
    background: palette.primary.main,
  },
});
