import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useCreateMonitoringAssortment } from '@retail/monitoring-assortment/data-access';
import { Stack, Typography } from '@mui/material';
import {
  CreateAssortmentManuallyDialog,
  CreateAssortmentMenu,
  ImportAssortmentDialog,
  NewAssortment,
} from '@retail/monitoring-assortment/components';
import { mgPrisRoutes, pdmRoutes } from '@retail/mgpris/config';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@retail/hooks';
import { useDisclosure } from '@shared/hooks';
import { FetchError } from '@shared/fetch-utils';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { Navigate } from 'react-router-dom';
import { useValidationOnImportedItems } from '@retail/monitoring-assortment/context';
import { MonitoringAssortmentsProps, withMonitoringAssortments } from './withMonitoringAssortments';

interface Props extends MonitoringAssortmentsProps {
  marketInsightRoute: typeof mgPrisRoutes.marketInsight | typeof pdmRoutes.marketInsight;
}

export function NoMonitoringAssortmentsContainerBase({
  monitoringAssortments,
  marketInsightRoute,
}: Props) {
  const t = useAssortmentTFunction();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { selectedOrgUnitId } = useSelectedOrgUnit();

  const { importedItems, validationState, resetImportedAssortmentStates, loadingValidateItems } =
    useValidationOnImportedItems();

  const { isOpen: isImportModalOpen, onToggle: toggleImportModal } = useDisclosure(false);
  const { isOpen: isCreateManuallyModalOpen, onToggle: toggleCreateManuallyModal } =
    useDisclosure(false);

  const { mutateAsync: createAssortmentAsync, isLoading } = useCreateMonitoringAssortment();
  const submitCreateAssortment = (newAssortment: NewAssortment) => {
    createAssortmentAsync({
      body: {
        title: newAssortment.title,
        itemCodes: { mgItemNumbers: newAssortment.itemCodes.mgItemNumbers || [] },
        orgUnitId: selectedOrgUnitId,
      },
    })
      .then(() => {
        queryClient.invalidateQueries(['department-assortments']);
        queryClient.invalidateQueries(['brand-assortments']);
        if (isImportModalOpen) {
          toast.success(t('assortment.admin.create.success', { title: newAssortment.title }));
          toggleImportModal();
        } else {
          toggleCreateManuallyModal();
        }
      })
      .catch((err: FetchError) => {
        console.error(err);
        toast.error(t('assortment.admin.create.error.generic'));
      });
  };

  if (monitoringAssortments.length) {
    return <Navigate to={marketInsightRoute.getFullLinkPath()} />;
  }

  return (
    <Stack gap={2} width="470px">
      <Typography component="h1" variant="h3">
        {t('assortment.infoCards.welcome')}
      </Typography>
      <Typography variant="body1" fontSize="18px">
        {t('assortment.infoCards.welcomeDesc')}
      </Typography>

      <div>
        <CreateAssortmentMenu
          openImportModal={toggleImportModal}
          openCreateManuallyModal={toggleCreateManuallyModal}
        />
      </div>

      <ImportAssortmentDialog
        open={isImportModalOpen}
        onClose={toggleImportModal}
        onSubmit={submitCreateAssortment}
        importedItems={importedItems}
        resetImportedAssortmentStates={resetImportedAssortmentStates}
        loadingValidateItems={loadingValidateItems}
        validationState={validationState}
        isLoading={isLoading}
      />
      <CreateAssortmentManuallyDialog
        open={isCreateManuallyModalOpen}
        onClose={toggleCreateManuallyModal}
        onSubmit={submitCreateAssortment}
        isLoading={isLoading}
      />
    </Stack>
  );
}

export const NoMonitoringAssortmentsContainer = withMonitoringAssortments(
  NoMonitoringAssortmentsContainerBase
);
