import { Palette } from '@mui/material';
import { HierarchyLevels, PackageType } from '@retail/calculus/types';

interface Props {
  palette: Palette;
  bindingType?: HierarchyLevels;
  packageType?: PackageType;
  active?: boolean;
}

export const chipColor = ({ palette, bindingType, packageType, active }: Props) => {
  if (active) {
    switch (bindingType) {
      case HierarchyLevels.supplier:
        return '#C0B6F4';
      case HierarchyLevels.overGroup:
        return '#E9E1A0';
      case HierarchyLevels.mainGroup:
        return palette.info.main;
      case HierarchyLevels.productGroup:
        return palette.warning.light;
      case HierarchyLevels.discountGroup:
        return palette.secondary.light;
      case HierarchyLevels.item:
        switch (packageType) {
          case 'SECONDARY':
            return '#FFD1DC';
          case 'TERTIARY':
            return '#FF8678';
          default:
            return '#A5E1B4';
        }
      default:
        return palette.divider;
    }
  } else {
    return palette.divider;
  }
};
