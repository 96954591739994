import { SpreadsheetStructure } from '@retail/products/types';
import { atom, useRecoilState } from 'recoil';

export const importedMarkupConditionsSpreadsheetAtom = atom<SpreadsheetStructure | undefined>({
  key: 'importedMarkupConditionsSpreadsheet',
  default: undefined,
});
export const useSpreadsheetState = () => useRecoilState(importedMarkupConditionsSpreadsheetAtom);

export const importedMarkupConditionsFileAtom = atom<File | undefined>({
  key: 'importedMarkupConditionsFileAtom',
  default: undefined,
});
