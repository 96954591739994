import { SideFilters, ToggleSwitch } from '@retail/components';
import { useProductsTFunction } from '@retail/products/i18n';
import { FilterOptions, FilterSelection, SetFilterOptions } from '@retail/products/types';
import {
  FilterSearchToggleGroup,
  SelectDiscountGroupsAutocomplete,
  SelectProductGroupsAutocomplete,
  SelectSuppliersAutocomplete,
} from '../MgSupplierItem';
import { ArticleIdSearchField, MgSupplierItemSearchField } from '../MgItem';

interface Props {
  searchFilters: FilterOptions;
  setSearchFilters: SetFilterOptions;
  filterOptions: FilterSelection;
  isFetching: boolean;
  setPage: (page: number) => void;
  showFilters: boolean;
  toggleFilters: () => void;
}

export function SearchFilters({
  searchFilters,
  setSearchFilters,
  filterOptions,
  isFetching,
  setPage,
  showFilters,
  toggleFilters,
}: Props) {
  const t = useProductsTFunction();

  const {
    fromMainSupplier,
    inAssortment,
    withAgreementPrices,
    selectedSuppliers,
    selectedDiscountGroups,
    selectedProductGroups,
    articleIds,
    query,
  } = { ...searchFilters };

  const {
    toggleFromMainSupplier,
    toggleInAssortment,
    toggleWithAgreementPrices,
    selectSupplier,
    selectProductGroups,
    selectDiscountGroups,
    setArticleIds,
    removeArticleId,
    setQuery,
  } = { ...setSearchFilters };

  const { suppliers, productGroups, discountGroups } = { ...filterOptions };

  return (
    <SideFilters
      heading={t('products.search.openSearch.label')}
      showFilters={showFilters}
      closeFilters={toggleFilters}
      sx={{ px: 4, py: 1 }}
    >
      <FilterSearchToggleGroup
        label={t('products.search.mainSupplierItems')}
        selectedOption={fromMainSupplier}
        onChangeOption={toggleFromMainSupplier}
        justifyContent="space-between"
      />
      <FilterSearchToggleGroup
        label={t('products.search.itemsInMyAssortment')}
        selectedOption={inAssortment}
        onChangeOption={toggleInAssortment}
        justifyContent="space-between"
      />
      <ToggleSwitch
        text={t('products.search.itemsWithPurchasePrice')}
        toggle={withAgreementPrices}
        setToggle={toggleWithAgreementPrices}
        justifyContent="space-between"
      />

      <SelectSuppliersAutocomplete
        suppliers={suppliers}
        selectedSuppliers={selectedSuppliers || []}
        isFetching={isFetching}
        setPage={setPage}
        selectSuppliers={selectSupplier}
      />
      {selectedSuppliers && selectedSuppliers?.length > 0 && (
        <SelectDiscountGroupsAutocomplete
          discountGroups={discountGroups}
          selectedDiscountGroups={selectedDiscountGroups || []}
          isFetching={isFetching}
          setPage={setPage}
          selectDiscountGroups={selectDiscountGroups}
        />
      )}
      <SelectProductGroupsAutocomplete
        productGroups={productGroups}
        selectedProductGroups={selectedProductGroups || []}
        isFetching={isFetching}
        setPage={setPage}
        selectProductGroups={selectProductGroups}
      />
      <ArticleIdSearchField
        articleIdSearch={articleIds}
        isDisabled={isFetching}
        setPage={setPage}
        onSubmitSearch={setArticleIds}
        onRemoveSearch={removeArticleId}
      />
      <MgSupplierItemSearchField
        searchTerm={query}
        isDisabled={isFetching}
        size="small"
        setPage={setPage}
        onSubmitSearch={setQuery}
      />
    </SideFilters>
  );
}
