import { Divider, Stack, Tooltip, Typography } from '@mui/material';
import { ConditionTooltipInfo, LevelChipWithChange } from '@retail/calculus/components';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { PriceSuggestionConditionTooltipInfo } from '@retail/price-suggestion/components';
import { PriceSuggestionType } from '@retail/price-suggestion/types';
import { Condition, PriceResultType } from '@retail/products/types';

interface Props {
  priceResultType?: PriceResultType;
  condition?: Condition;
  futureCondition?: Condition;
  priceSuggestionType?: PriceSuggestionType;
  futurePriceSuggestionType?: PriceSuggestionType;
  customerMarkup?: Condition;
}

export function PriceResultTypeTooltip({
  priceResultType,
  condition,
  futureCondition,
  priceSuggestionType,
  futurePriceSuggestionType,
  customerMarkup,
}: Props) {
  const t = useMyAssortmentTFunction();

  if (priceResultType !== PriceResultType.PRICE_SUGGESTION) {
    return condition?.bindingType ? (
      <Tooltip
        componentsProps={{ tooltip: { sx: { minWidth: 'fit-content' } } }}
        title={
          <Stack gap={1}>
            {customerMarkup && (
              <Typography>{t('myAssortment.originatesFrom.markupAndCustomerMarkup')}</Typography>
            )}
            <Stack flexDirection="row" gap={4}>
              <ConditionTooltipInfo
                condition={condition}
                title={
                  customerMarkup
                    ? t('myAssortment.markup') + ':'
                    : t('myAssortment.originatesFrom.markup')
                }
              />
              {futureCondition &&
                (futurePriceSuggestionType !== priceSuggestionType ||
                  condition.bindingType !== futureCondition?.bindingType) && (
                  <>
                    <Divider orientation="vertical" flexItem />
                    {futurePriceSuggestionType ? (
                      <PriceSuggestionConditionTooltipInfo
                        condition={futureCondition}
                        title={t('myAssortment.originatesFrom.futurePriceSuggestion')}
                        selectedPriceSuggestionType={futurePriceSuggestionType}
                      />
                    ) : (
                      <ConditionTooltipInfo
                        condition={futureCondition}
                        title={t('myAssortment.originatesFrom.futureMarkup')}
                      />
                    )}
                  </>
                )}
              {customerMarkup && (
                <>
                  <Divider orientation="vertical" flexItem />
                  <ConditionTooltipInfo
                    condition={customerMarkup}
                    title={t('myAssortment.columns.customerMarkup') + ':'}
                  />
                </>
              )}
            </Stack>
          </Stack>
        }
      >
        <div>
          <LevelChipWithChange
            active
            bindingType={condition?.bindingType}
            futureBindingType={
              futureCondition?.validFrom !== condition?.validFrom
                ? futureCondition?.bindingType
                : undefined
            }
            isPriceSuggestion={!!priceSuggestionType}
            isFuturePriceSuggestion={!!futurePriceSuggestionType}
            hasCustomerMarkup={!!customerMarkup}
          />
        </div>
      </Tooltip>
    ) : (
      '-'
    );
  } else if (priceResultType === PriceResultType.PRICE_SUGGESTION) {
    return (
      <Tooltip
        title={
          <Stack>
            <PriceSuggestionConditionTooltipInfo
              condition={condition}
              title={t('myAssortment.originatesFrom.priceSuggestion')}
              selectedPriceSuggestionType={priceSuggestionType}
            />
            {futureCondition &&
              (futurePriceSuggestionType !== priceSuggestionType ||
                (condition?.bindingType !== futureCondition?.bindingType &&
                  (futurePriceSuggestionType ? (
                    <PriceSuggestionConditionTooltipInfo
                      condition={futureCondition}
                      title={t('myAssortment.originatesFrom.futurePriceSuggestion')}
                      selectedPriceSuggestionType={futurePriceSuggestionType}
                    />
                  ) : (
                    <ConditionTooltipInfo
                      condition={futureCondition}
                      title={t('myAssortment.originatesFrom.futureMarkup')}
                    />
                  ))))}
          </Stack>
        }
      >
        <div>
          <LevelChipWithChange
            active
            bindingType={condition?.bindingType}
            futureBindingType={
              futureCondition?.validFrom && futureCondition?.validFrom > new Date()
                ? futureCondition?.bindingType
                : undefined
            }
            isPriceSuggestion={!!priceSuggestionType}
            isFuturePriceSuggestion={!!futurePriceSuggestionType}
          />
        </div>
      </Tooltip>
    );
  } else {
    return '-';
  }
}
