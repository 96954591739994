import { Box, Stack, Typography } from '@mui/material';
import { useDisclosure } from '@shared/hooks';
import {
  ExportButtons,
  ExportDialog,
  exportSchema,
  ExportSchema,
  ExportTable,
} from '@retail/export/components';
import { useLanguageSelector } from '@retail/app/hooks';
import { useToast } from '@retail/hooks';
import {
  useFetchTotalFileDownloadStatus,
  useFetchExportTotalFileZip,
  useFetchExportTotalFileCsv,
} from '@retail/export/data-access';
import { TotalFileDownload } from '@retail/export/types';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';
import { downloadResponseFile } from '@shared/fetch-utils';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useCallback, useEffect, useMemo } from 'react';

export const ExportContainer = () => {
  const t = useAppTFunction();

  const toast = useToast();
  const { language } = useLanguageSelector();

  const { baseAssortmentId: departmentBaseAssortmentId } = useDepartmentAssortments();

  const {
    isOpen: isExportDialogOpen,
    onToggle: toggleExportDialog,
    onClose: closeExportDialog,
  } = useDisclosure(false);

  const formMethods = useForm<ExportSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(exportSchema(t)),
  });

  const fromDate = formMethods.watch('fromDate');

  const { mutateAsync: mutateExportTotalFile, isLoading: exportTotalFileIsLoading } =
    useFetchExportTotalFileZip();

  const { mutateAsync: mutateExportTotalFileCsv, isLoading: exportTotalFileCsvIsLoading } =
    useFetchExportTotalFileCsv();

  const formatDateTime = (date?: Date) =>
    date && dayjs(date).isValid()
      ? dayjs(date.toISOString()).format('YYYY-MM-DDTHH:mm')
      : undefined;

  const { mutateAsync: getTotalFileSummary, data: exportDto } = useFetchTotalFileDownloadStatus(
    {
      assortmentId: departmentBaseAssortmentId,
      from: formatDateTime(fromDate),
    },
    { suspense: false }
  );

  const exportObject = useMemo(
    () => (exportDto ? TotalFileDownload.fromDto(exportDto) : undefined),
    [exportDto]
  );

  // Update form with data from API response
  const fetchAndUpdateWithNewestData = useCallback(async () => {
    const res = await getTotalFileSummary({
      urlVariables: formatDateTime(fromDate),
    });

    formMethods.setValue('fromDate', res.lastDownload ? new Date(res.lastDownload) : undefined);
    formMethods.setValue('allArticles', res.lastDownload ? false : true);
  }, [formMethods, fromDate, getTotalFileSummary]);

  // Update form and table with current DateTimePicker value
  const handleUpdateDateTimePicker = useCallback(
    async (fromDate: Date | null) => {
      try {
        await getTotalFileSummary({
          urlVariables: fromDate ? formatDateTime(fromDate) : undefined,
        });
        formMethods.setValue('fromDate', fromDate ?? undefined);
      } catch (e) {
        console.error(e);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [formMethods, getTotalFileSummary]
  );

  const handleFormSubmit = (data: ExportSchema, csv: boolean) => {
    try {
      const from = data?.fromDate?.toISOString() ? formatDateTime(data?.fromDate) : undefined;

      if (csv) {
        mutateExportTotalFileCsv({
          assortmentId: departmentBaseAssortmentId,
          from: data?.onlyChanges ? from : undefined,
        }).then(async (response: Response) => {
          await downloadResponseFile({ filenameFallback: 'export.zip' })(response);
        });
      } else {
        mutateExportTotalFile({
          assortmentId: departmentBaseAssortmentId,
          from: data?.onlyChanges ? from : undefined,
        }).then(async (response: Response) => {
          await downloadResponseFile({ filenameFallback: 'export.zip' })(response);
        });

        toast.success(t('export.messages.fileDownloaded'));
        fetchAndUpdateWithNewestData();
      }
    } catch (e) {
      toast.error(t('export.errors.downloadFailed'));
    }
  };

  useEffect(() => {
    fetchAndUpdateWithNewestData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods]);

  return (
    <Stack flexGrow={1}>
      <FormProvider {...formMethods}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box display="flex" justifyContent="space-between" p={4} pb={3}>
            <Box>
              <Typography variant="body2" sx={{ mb: 1 }}>
                {t('export.lastDownload')}:{' '}
                {exportObject?.lastDownload
                  ? dayjs(exportObject?.lastDownload)?.format('L, LT')
                  : '-'}
              </Typography>

              <Typography variant="body2">
                {t('export.changesSinceLastDownload')}: {exportObject?.totalItems}
              </Typography>
            </Box>

            <ExportButtons
              onUpdateDateTimePicker={handleUpdateDateTimePicker}
              toggleExportDialog={toggleExportDialog}
            />
          </Box>

          <ExportTable language={language} suppliers={exportObject?.suppliers ?? []} />

          {isExportDialogOpen && (
            <ExportDialog
              isLoading={exportTotalFileIsLoading || exportTotalFileCsvIsLoading}
              onUpdateDateTimePicker={handleUpdateDateTimePicker}
              onSubmit={handleFormSubmit}
              onClose={closeExportDialog}
            />
          )}
        </LocalizationProvider>
      </FormProvider>
    </Stack>
  );
};
