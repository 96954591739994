import { IconButton, Tooltip } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { CalculatorVariantIcon, DeleteIcon } from '@shared/custom-icons';
import { Link } from 'react-router-dom';
import { ReadMore } from '@mui/icons-material';
interface Props {
  deleteItem?: () => void;
  viewItemProductCard?: () => void;
  calculationLink?: string;
}

export const AssortmentTableActions = ({
  calculationLink,
  deleteItem,
  viewItemProductCard,
}: Props) => {
  const t = useMyAssortmentTFunction();

  return (
    <>
      {calculationLink && (
        <Tooltip title={t('myAssortment.actions.goToCalculation')}>
          <IconButton component={Link} to={calculationLink}>
            <CalculatorVariantIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {deleteItem && (
        <Tooltip title={t('myAssortment.actions.deleteItem')}>
          <IconButton onClick={deleteItem}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {viewItemProductCard && (
        <Tooltip title={t('myAssortment.actions.seeCalculusCard')}>
          <IconButton onClick={viewItemProductCard}>
            <ReadMore />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
