import { Box, Link } from '@mui/material';
import { DataGridPremium, GridColDef, useGridApiRef } from '@mui/x-data-grid-premium';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useProductsTFunction } from '@retail/products/i18n';
import {
  AddButton,
  ColumnHeaderWithHelpText,
  CopyToClipboard,
  DataGridPagination,
  NoTableRowsOverlay,
  StackedTextCell,
} from '@retail/components';
import { Competitor } from '@retail/retailer/types';
import { dataGridBoldClass } from '@shared/styles';
import { MonitoringAssortmentItem } from '@retail/monitoring-assortment/types';
import { CompetitorPriceCell, NoPrice, OurPriceCell } from '../../ProductCell';
import RetailerColumnHeader from '../../ProductCell/RetailerColumnHeader';

interface Props {
  items: MonitoringAssortmentItem[];
  totalItems: number | undefined;
  pageSize: number;
  page: number;
  excludeVat: boolean;
  competitors: Competitor[];
  openAddItemsDialog: () => void;
  updatePageSize: (pageSize: number) => void;
  updatePage: (page: number) => void;
  setItemToView: (mgItemNumber: number) => void;
  loading: boolean;
}

type GridColMonitoringAssortmentItem = GridColDef<MonitoringAssortmentItem>;

const baseColumnProps: Partial<GridColMonitoringAssortmentItem> = {
  width: 170,
  sortable: false,
  align: 'left',
  headerAlign: 'left',
  headerName: '',
};

export function MonitoringAssortmentTable({
  items,
  totalItems,
  pageSize,
  page,
  excludeVat,
  competitors,
  openAddItemsDialog,
  updatePageSize,
  updatePage,
  setItemToView,
  loading,
}: Props) {
  const t = useProductsTFunction();
  const [columnOrder, setColumnOrder] = useState<string[]>();
  const apiRef = useGridApiRef();

  const columns: GridColDef[] = useMemo(() => {
    const productNameColDef: GridColDef<MonitoringAssortmentItem> = {
      ...baseColumnProps,
      field: 'name',
      headerName: t('products.mgItem.supplierItem.primaryText'),
      minWidth: 180,
      cellClassName: () => dataGridBoldClass,
      renderCell: ({ row }) => {
        const primarySupplierItem = row.getPrimarySupplierItem();
        return (
          <Link
            p={0}
            color="text.primary"
            sx={{ cursor: 'pointer' }}
            onClick={() => setItemToView(Number(row.mgItemNumber))}
          >
            {primarySupplierItem?.primaryText || '-'}
          </Link>
        );
      },
    };
    const finfoNumberColDef: GridColDef<MonitoringAssortmentItem> = {
      ...baseColumnProps,
      field: 'finfoNumber',
      minWidth: 130,
      renderHeader: () => (
        <ColumnHeaderWithHelpText
          header={t('products.mgItem.number.mgItemAndFinfo')}
          tooltipContent={t('products.mgItem.supplierItem.finfoNumber.primaryDescription')}
        />
      ),
      renderCell: ({ row }) => {
        const primarySupplierItem = row.getPrimarySupplierItem();

        return (
          <StackedTextCell
            nullableBottomLine
            topLine={
              <CopyToClipboard value={row.mgItemNumber} hasIconButtonPadding={false}>
                {row.mgItemNumber}
              </CopyToClipboard>
            }
            bottomLine={
              primarySupplierItem?.finfoNumber && (
                <CopyToClipboard
                  value={primarySupplierItem.finfoNumber}
                  hasIconButtonPadding={false}
                >
                  {primarySupplierItem.finfoNumber}
                </CopyToClipboard>
              )
            }
          />
        );
      },
    };

    const salesPriceColDef: GridColDef<MonitoringAssortmentItem> = {
      ...baseColumnProps,
      field: 'salesPrice',
      minWidth: 140,
      headerName: t('products.mgItem.supplierItem.salesPrice'),
      renderCell: ({ row }) => {
        const salesPrice = row.getPrimarySupplierItem().salesPrice;
        if (!salesPrice) return <NoPrice />;
        return (
          <OurPriceCell
            price={salesPrice.price}
            unit={salesPrice.unit}
            priceDate={salesPrice.priceDate}
            includeVat={!excludeVat}
          />
        );
      },
    };
    const competitorColDefs: GridColDef<MonitoringAssortmentItem>[] = competitors.map(
      (competitor) => ({
        ...baseColumnProps,
        field: competitor.key(),
        minWidth: 190,
        renderHeader: () => (
          <RetailerColumnHeader
            retailerName={competitor.retailer().name}
            storeName={competitor.name}
            retailerUrlName={competitor.urlName()}
            logoUrl={competitor.circularLogoUrl()}
          />
        ),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        renderCell: ({ row }) => {
          const currentCompetitorPrice = row.getCurrentCompetitorPrice(competitor.id);
          if (!currentCompetitorPrice) return <NoPrice />;

          const defaultPrice = currentCompetitorPrice.getDefaultPrice();
          const previousCompPrice = row.getPreviousCompetitorPrice(competitor.id);
          const previousDefaultPrice = previousCompPrice?.getDefaultPrice();

          return (
            <CompetitorPriceCell
              currentPrice={{
                price: defaultPrice.price || 0,
                unit: defaultPrice.unit || '',
                priceDate: currentCompetitorPrice.lastObservedAt,
              }}
              prevPrice={
                previousDefaultPrice && {
                  price: previousDefaultPrice.price || 0,
                  unit: previousDefaultPrice.unit || '',
                  priceDate: previousCompPrice!.lastObservedAt,
                }
              }
              eqMgItemNumberSrc={currentCompetitorPrice.eqMgItemNumber}
              url={currentCompetitorPrice.productUrl}
              setItemToView={setItemToView}
              excludeVat={excludeVat}
            />
          );
        },
      })
    );

    return [productNameColDef, finfoNumberColDef, salesPriceColDef, ...competitorColDefs];
  }, [t, competitors, setItemToView, excludeVat]);

  const saveReorderingOfColumns = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem('columnOrder', JSON.stringify(currentState.columns!.orderedFields));
    }
  }, [apiRef]);

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage.getItem('columnOrder');
    setColumnOrder(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : []);

    window.addEventListener('drop', saveReorderingOfColumns);
    window.removeEventListener('drop', saveReorderingOfColumns);
  }, [saveReorderingOfColumns]);

  const sortedColumns = useMemo(() => {
    return columnOrder
      ? columns.sort((a, b) => columnOrder.indexOf(a.field) - columnOrder.indexOf(b.field))
      : columns;
  }, [columns, columnOrder]);

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium
          rowHeight={60}
          apiRef={apiRef.current ? apiRef : undefined}
          columns={sortedColumns}
          rows={items}
          disableRowSelectionOnClick
          localeText={{ noRowsLabel: t('products.productList.noProducts') }}
          disableColumnMenu
          pagination={true}
          loading={loading}
          paginationMode="server"
          slots={{
            noRowsOverlay: () => (
              <NoTableRowsOverlay
                noRowsText={t('products.mgItem.noItems')}
                addAction={
                  <AddButton onClick={openAddItemsDialog}>{t('products.mgItem.add')}</AddButton>
                }
              />
            ),
            pagination: () => <DataGridPagination />,
          }}
          rowCount={totalItems}
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={(model) => {
            if (model.page !== page) updatePage(model.page);
            if (model.pageSize !== pageSize) updatePageSize(model.pageSize);
          }}
          initialState={{
            pinnedColumns: { left: ['name', 'id', 'finfoNumber', 'gtin', 'salesPrice'] },
          }}
          sx={{
            '& .MuiDataGrid-columnHeaders': { marginLeft: 0 },
            '& .MuiDataGrid-row': { paddingLeft: 0 },
            '& .MuiDataGrid-topContainer': { paddingLeft: 0, paddingRight: 0 },
          }}
        />
      </Box>
    </Box>
  );
}
