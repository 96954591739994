import { FuturePriceCalculationDTO } from '../../dto';
import { Package } from './Package';
import { Calculation } from './Calculation';
import { MgPackageClassCode } from '../MgItem';

export class FuturePriceCalculation {
  calculationPackage: Package;
  result: Calculation[];

  constructor(dto: FuturePriceCalculationDTO) {
    this.calculationPackage = Package.fromDto(dto.calculationPackage);
    this.result = dto.result?.map(Calculation.fromDto) || [];
  }

  getBracketPackagePrice = (bracketClassCode: MgPackageClassCode): Calculation | undefined => {
    return this.result.find(
      (calculation) => calculation.calculationPackage.classCode === bracketClassCode
    );
  };

  static fromDto(dto: FuturePriceCalculationDTO): FuturePriceCalculation {
    return new FuturePriceCalculation(dto);
  }
}
