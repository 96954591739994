import { Stack, Tooltip, Typography } from '@mui/material';
import { CreateMarkupDialogRow } from './CreateMarkupDialogRow';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { useMemo } from 'react';
import { HierarchyLevels, MarkupCondition } from '@retail/calculus/types';
import dayjs from 'dayjs';
import { CheckIcon, InheritedIcon } from '@shared/custom-icons';
import { LevelChip } from '@retail/calculus/components';

interface Props {
  markupCondition: MarkupCondition;
  selectedLevel: string;
}

export function CurrentActiveMarkup({ markupCondition, selectedLevel }: Props) {
  const t = useMarkupTFunction();

  const inheritedFrom = useMemo(() => markupCondition.getLevelName(), [markupCondition]);
  const { bindingType } = markupCondition;

  const isNotInherited = useMemo(
    () =>
      (selectedLevel === 'supplier' && bindingType === HierarchyLevels.supplier) ||
      (selectedLevel === 'productGroup' &&
        (bindingType === HierarchyLevels.overGroup ||
          bindingType === HierarchyLevels.mainGroup ||
          bindingType === HierarchyLevels.productGroup)) ||
      (selectedLevel === 'discountGroup' && bindingType === HierarchyLevels.discountGroup) ||
      (selectedLevel === 'item' && bindingType === HierarchyLevels.item),
    [bindingType, selectedLevel]
  );

  if (!markupCondition.id) return null;

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={1} alignItems="flex-start">
        <Typography fontWeight={600}>{t('markup.activeMarkup')}</Typography>
        <Tooltip
          title={
            <Stack gap={2}>
              <Typography variant="subtitle2">{t('markup.activeMarkupOrigin')}</Typography>
              <Stack flexDirection="row" alignItems="center" gap={2}>
                <Typography variant="body2">{t('markup.level')}:</Typography>
                <LevelChip height={28} active bindingType={markupCondition.bindingType} />
              </Stack>
              <Stack flexDirection="row" gap={1}>
                <Typography variant="body2">{t('markup.levelOrItemName')}:</Typography>
                <Typography variant="subtitle2">{inheritedFrom}</Typography>
              </Stack>
            </Stack>
          }
        >
          <div>{isNotInherited ? <CheckIcon /> : <InheritedIcon fontSize="small" />}</div>
        </Tooltip>
      </Stack>
      <CreateMarkupDialogRow
        name={t('markup.markupName')}
        value={markupCondition.markupTemplateName}
      />
      <CreateMarkupDialogRow
        name={t('markup.salesCoverage')}
        value={markupCondition.coverageDegree ? `${markupCondition?.coverageDegree}%` : '-'}
      />
      <CreateMarkupDialogRow name={t('markup.salesFactor')} value={markupCondition.salesFactor} />
      <CreateMarkupDialogRow
        name={t('markup.fromDate')}
        value={dayjs(markupCondition.validFrom).format('ll')}
      />
      <CreateMarkupDialogRow
        name={t('markup.toDate')}
        value={markupCondition.validTo ? dayjs(markupCondition.validTo).format('ll') : '-'}
      />
    </Stack>
  );
}
