import { HierarchyLevels } from '@retail/calculus/types';
import { ConditionDTO } from '../../dto';
import { GroupType } from '../GroupType';

export interface MgSupplierMinimal {
  mgSupplierId: number;
  name: string;
}

export interface ProductGroupMinimal {
  type: GroupType;
  groupNumber: string;
  name: string;
}

export interface DiscountGroupMinimal {
  id: number;
  code: string;
  name?: string;
  mgSupplierId: number;
}

export interface MgSupplierItemMinimal {
  mgItemNumber: number;
  primaryText: string;
  secondaryText: string;
}

interface ConditionProps {
  validFrom: Date;
  validTo?: Date;
  bindingType?: HierarchyLevels;
  mgSupplier?: MgSupplierMinimal;
  mgSupplierItemId?: number;
  productGroup?: ProductGroupMinimal;
  discountGroup?: DiscountGroupMinimal;
  mgSupplierItem?: MgSupplierItemMinimal;
  inherited: boolean;
}

export class Condition {
  validFrom?: Date;
  validTo?: Date;
  bindingType?: HierarchyLevels;
  mgSupplier?: MgSupplierMinimal;
  mgSupplierItemId?: number;
  productGroup?: ProductGroupMinimal;
  discountGroup?: DiscountGroupMinimal;
  mgSupplierItem?: MgSupplierItemMinimal;
  inherited: boolean;

  constructor({
    validFrom,
    validTo,
    bindingType,
    mgSupplier,
    mgSupplierItemId,
    productGroup,
    discountGroup,
    mgSupplierItem,
    inherited,
  }: ConditionProps) {
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.bindingType = bindingType;
    this.mgSupplier = mgSupplier;
    this.mgSupplierItemId = mgSupplierItemId;
    this.productGroup = productGroup;
    this.discountGroup = discountGroup;
    this.mgSupplierItem = mgSupplierItem;
    this.inherited = inherited;
  }

  getLevelName = () => {
    return (
      this.mgSupplierItem?.primaryText ??
      this.productGroup?.name ??
      this.discountGroup?.name ??
      this.mgSupplier?.name
    );
  };

  static fromDto = (dto: ConditionDTO): Condition =>
    new Condition({
      validFrom: new Date(dto.validFrom),
      validTo: dto.validTo ? new Date(dto.validTo) : undefined,
      bindingType: dto.bindingType,
      mgSupplier: dto.mgSupplier,
      mgSupplierItemId: dto.mgSupplierItemId,
      productGroup: dto.productGroup,
      discountGroup: dto.discountGroup,
      mgSupplierItem: dto.mgSupplierItem,
      inherited: dto.inherited,
    });
}
