import { useMutateUpdateItemsToAssortment } from '@retail/mgpdm/data-access';
import { ItemsDTO } from '@retail/my-assortment/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useBrandAssortments } from './useBrandAssortments';

export const useAssortmentActions = () => {
  const queryClient = useQueryClient();

  const { baseAssortmentId } = useBrandAssortments();

  const { mutateAsync: postItems, isLoading: loadingPostItems } = useMutateUpdateItemsToAssortment({
    assortmentId: baseAssortmentId,
  });

  const updateAssortmentItems = useCallback(
    (selectedItems: ItemsDTO[]) =>
      postItems({ body: selectedItems })
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ['assortmentItems'] });
          queryClient.invalidateQueries({ queryKey: ['mg-item'] });
        })
        .catch((err) => {
          console.error(err);
          throw err;
        }),
    [postItems, queryClient]
  );

  return {
    updateAssortmentItems,
    updateItemsIsLoading: loadingPostItems,
  };
};
