import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { NotFoundError, PageLayout, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { Paper, UniversalFormProvider } from '@shared/components';
import { useSelectSupplier } from '@retail/calculus/context';
import { AssortmentOrSupplierSelect, CalculusTabs } from '@retail/calculus/components';
import { CalculusTabOptions, ConditionState } from '@retail/calculus/types';
import { AssortmentItemsCalculusView } from '@retail/my-assortment/containers';
import { PurchaseContainer } from '@retail/purchase-agreements/containers';
import { FreightConditions } from '@retail/freight/containers';
import { MarkupContainer } from '@retail/markup/containers';
import { useDisclosure, useMountEffect } from '@shared/hooks';
import { useCallback, useMemo, useState } from 'react';
import { calculusTabsParamKey, productGroupSearchParamKey } from '@retail/products/utils';
import { PriceSuggestionConditions } from '@retail/price-suggestion/containers';
import { useIsFeatureEnabled, useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { followPriceSuggestionSchema } from '@retail/price-suggestion/components';
import { CustomerMarkupContainer } from '@retail/customer-markup/containers';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { AssortmentCalculusTabContainer, CalculusTabContainer } from '@retail/calculus/containers';
import { useSelectCalculusTab } from '@retail/calculus/hooks';

export function CalculusPage() {
  const t = useAppTFunction();
  const navigate = useNavigate();
  const { search } = useLocation();
  const isPriceSuggestionEnabled = useIsFeatureEnabled('price-suggestion');
  const isCustomerMarkupEnabled = useIsFeatureEnabled('customer-markup');

  const { supplierId } = useParams<{ supplierId: string }>();
  const { selectedOrgUnit, selectedPriceList } = useSelectedOrgUnit();
  const baseAssortmentId = selectedOrgUnit.assortmentId;
  const draftAssortmentId = selectedOrgUnit.draftAssortmentId!;

  const tabs = useMemo(() => {
    const _tabs = [
      { label: t('markup.itemPrices'), value: CalculusTabOptions.ITEMS },
      { label: t('app.routes.agreements'), value: CalculusTabOptions.PURCHASE },
      { label: t('app.routes.freight'), value: CalculusTabOptions.FREIGHT },
      { label: t('app.routes.markup'), value: CalculusTabOptions.MARKUP },
    ];

    const priceListTabs = [
      { label: t('markup.itemPrices'), value: CalculusTabOptions.ITEMS },
      { label: t('app.routes.markup'), value: CalculusTabOptions.MARKUP },
    ];

    if (isPriceSuggestionEnabled)
      _tabs.push({
        label: t('app.routes.priceSuggestion'),
        value: CalculusTabOptions.PRICE_SUGGESTION,
      });

    if (isCustomerMarkupEnabled) {
      _tabs.push({
        label: t('app.routes.customerMarkup'),
        value: CalculusTabOptions.CUSTOMER_MARKUP,
      });
      priceListTabs.push({
        label: t('app.routes.customerMarkup'),
        value: CalculusTabOptions.CUSTOMER_MARKUP,
      });
    }

    return selectedPriceList ? priceListTabs : _tabs;
  }, [isCustomerMarkupEnabled, isPriceSuggestionEnabled, selectedPriceList, t]);

  const {
    suppliers,
    selectedSupplier,
    storedOrDefaultSupplier,
    navigateToSupplier,
    navigateToStoredOrDefaultSupplier,
  } = useSelectSupplier({
    supplierId: Number(supplierId),
    draftAssortmentId,
  });

  const [calculusArea, setCalculusArea] = useSelectCalculusTab({
    searchParamKey: calculusTabsParamKey,
    resetContiguousSearchParamKey: productGroupSearchParamKey,
    initialValue: CalculusTabOptions.ITEMS,
  });

  useMountEffect(() => {
    if (
      calculusArea !== CalculusTabOptions.MARKUP &&
      calculusArea !== CalculusTabOptions.CUSTOMER_MARKUP &&
      !selectedSupplier
    ) {
      navigateToStoredOrDefaultSupplier(CalculusTabOptions.ITEMS);
    }
  });

  const handleSetCalculusArea = useCallback(
    (calculusArea: CalculusTabOptions) => {
      if (
        calculusArea !== CalculusTabOptions.MARKUP &&
        calculusArea !== CalculusTabOptions.CUSTOMER_MARKUP &&
        !selectedSupplier
      ) {
        navigateToStoredOrDefaultSupplier(calculusArea);
      } else {
        setCalculusArea(calculusArea);
      }
    },
    [navigateToStoredOrDefaultSupplier, setCalculusArea, selectedSupplier]
  );

  const handleSetAssortmentLevel = useCallback(() => {
    navigate({ pathname: mgPrisRoutes.calculus.getFullLinkPath(), search });
  }, [navigate, search]);

  const { isOpen: isCreateOpen, onToggle: toggleCreate } = useDisclosure();
  const { isOpen: isUploadOpen, onToggle: toggleUpload } = useDisclosure();

  const [conditionState, setConditionState] = useState<ConditionState>(ConditionState.ACTIVE);

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Stack height="100%" overflow="hidden">
        <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-end">
          <CalculusTabs
            tabs={tabs}
            currentCalculusArea={calculusArea ?? CalculusTabOptions.ITEMS}
            setCalculusArea={handleSetCalculusArea}
          />

          <AssortmentOrSupplierSelect
            suppliers={suppliers}
            selectedOrgUnitName={
              calculusArea === CalculusTabOptions.MARKUP ||
              calculusArea === CalculusTabOptions.CUSTOMER_MARKUP
                ? selectedOrgUnit.name
                : undefined
            }
            selectedSupplier={selectedSupplier}
            onSelectAssortmentLevel={handleSetAssortmentLevel}
            onSelectSupplier={navigateToSupplier}
          />
        </Stack>
        <Paper
          paddingY="dense"
          sx={{
            p: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Stack flexGrow={1} p={0} overflow="hidden">
            {calculusArea === CalculusTabOptions.ITEMS && selectedSupplier && (
              <SuspenseWithSentryErrorBoundary>
                <AssortmentItemsCalculusView
                  draftAssortmentId={draftAssortmentId}
                  baseAssortmentId={baseAssortmentId}
                  mgSupplierId={selectedSupplier.mgSupplierId}
                  priceListLevel={!!selectedPriceList}
                />
              </SuspenseWithSentryErrorBoundary>
            )}

            {calculusArea !== CalculusTabOptions.ITEMS && selectedSupplier && (
              <CalculusTabContainer
                draftAssortmentId={draftAssortmentId}
                baseAssortmentId={baseAssortmentId}
                currentCalculusArea={calculusArea ?? CalculusTabOptions.PURCHASE}
                selectedSupplier={selectedSupplier}
                conditionState={conditionState}
                setConditionState={setConditionState}
              >
                <SuspenseWithSentryErrorBoundary>
                  {calculusArea === CalculusTabOptions.PURCHASE && (
                    <PurchaseContainer
                      typesToShow={conditionState}
                      selectedSupplier={selectedSupplier}
                      isCreateOpen={isCreateOpen}
                      toggleCreateDialog={toggleCreate}
                    />
                  )}

                  {calculusArea === CalculusTabOptions.FREIGHT && (
                    <FreightConditions
                      supplier={selectedSupplier}
                      typesToShow={conditionState}
                      isCreateOpen={isCreateOpen}
                      toggleCreateDialog={toggleCreate}
                    />
                  )}
                  {calculusArea === CalculusTabOptions.MARKUP && (
                    <MarkupContainer
                      selectedSupplier={selectedSupplier}
                      typesToShow={conditionState}
                      isCreateOpen={isCreateOpen}
                      isUploadOpen={isUploadOpen}
                      toggleCreateDialog={toggleCreate}
                      toggleUploadDialog={toggleUpload}
                    />
                  )}
                  {calculusArea === CalculusTabOptions.PRICE_SUGGESTION && (
                    <UniversalFormProvider
                      schema={followPriceSuggestionSchema(t)}
                      defaultValues={followPriceSuggestionSchema(t).getDefault()}
                    >
                      <PriceSuggestionConditions
                        selectedSupplier={selectedSupplier}
                        assortmentId={baseAssortmentId}
                        typesToShow={conditionState}
                        isCreateOpen={isCreateOpen}
                        toggleCreateDialog={toggleCreate}
                      />
                    </UniversalFormProvider>
                  )}

                  {calculusArea === CalculusTabOptions.CUSTOMER_MARKUP && (
                    <CustomerMarkupContainer
                      selectedSupplier={selectedSupplier}
                      typesToShow={conditionState}
                      isCreateOpen={isCreateOpen}
                      toggleCreateDialog={toggleCreate}
                    />
                  )}
                </SuspenseWithSentryErrorBoundary>
              </CalculusTabContainer>
            )}
            {!selectedSupplier && (
              <AssortmentCalculusTabContainer>
                {calculusArea === CalculusTabOptions.MARKUP && (
                  <MarkupContainer
                    selectedSupplier={selectedSupplier}
                    typesToShow={conditionState}
                    isCreateOpen={isCreateOpen}
                    isUploadOpen={isUploadOpen}
                    toggleCreateDialog={toggleCreate}
                    toggleUploadDialog={toggleUpload}
                  />
                )}
                {calculusArea === CalculusTabOptions.CUSTOMER_MARKUP && (
                  <CustomerMarkupContainer
                    selectedSupplier={selectedSupplier}
                    typesToShow={conditionState}
                    isCreateOpen={isCreateOpen}
                    toggleCreateDialog={toggleCreate}
                  />
                )}
              </AssortmentCalculusTabContainer>
            )}
          </Stack>

          {calculusArea !== CalculusTabOptions.MARKUP &&
            !storedOrDefaultSupplier &&
            !selectedSupplier && (
              <NotFoundError
                title={t('freight.supplier.notFound.title')}
                subtitle={t('freight.supplier.notFound.subtitle', { supplierId })}
                buttonText={t('freight.supplier.notFound.goBack')}
              />
            )}
        </Paper>
      </Stack>
    </PageLayout>
  );
}
