import { FreightConditionsDTO } from '../../dtos';
import { CalculusAreas } from '../CalculusAreas';
import { SupplierFreightConditions } from './SupplierFreightConditions';
import { FreightCondition } from './FreightCondition';

interface FreightConditionsProps {
  assortmentFreightConditions: FreightCondition[];
  supplierFreightConditions: SupplierFreightConditions[];
}

export class FreightConditions {
  assortmentFreightConditions: FreightCondition[];
  supplierFreightConditions: SupplierFreightConditions[];

  constructor({ assortmentFreightConditions, supplierFreightConditions }: FreightConditionsProps) {
    this.assortmentFreightConditions = assortmentFreightConditions;
    this.supplierFreightConditions = supplierFreightConditions;
  }

  static fromDto = (dto: FreightConditionsDTO): FreightConditions =>
    new FreightConditions({
      assortmentFreightConditions: dto.assortmentConditions
        ? dto.assortmentConditions.map(FreightCondition.fromDto)
        : [],
      supplierFreightConditions: dto.supplierConditions
        ? dto.supplierConditions.map(SupplierFreightConditions.fromDto)
        : [],
    });

  getType = () => CalculusAreas.FREIGHT;
}
