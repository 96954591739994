import { AssortmentItem } from '@retail/my-assortment/types';
import { FlexRow, UniversalFormProvider } from '@shared/components';
import { useCallback, useState } from 'react';
import { SalesPriceInput } from '../../ProductCard/SalesPriceSummary/SalesPriceInput';
import { GridColDef } from '@mui/x-data-grid';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import dayjs from 'dayjs';
import { Box, IconButton } from '@mui/material';
import { ItemStatus } from '../ItemStatus/ItemStatus';
import { EditButtonIcon } from '@shared/custom-icons';
import { StackedTextCell } from '@retail/components';
import { PricePerUnit } from '@retail/products/components';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';
import { CreateConditionDialog, markupMetaSchema, MarkupMetaSchema } from '../../ProductCard';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { RowPriceProps } from './useCurrentItemPriceColumns';

const baseColumnProps: Partial<GridColDef> = {
  width: 130,
  headerName: '',
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

export const useSalesPriceColumn = ({
  apiRef,
  includeVat,
  enableSortable,
  createMarkupDialog,
  isLoadingSalesPriceCondition,
  createCondition,
}: RowPriceProps) => {
  const { t } = useMyAssortmentTranslation();
  const calculusT = useCalculusTFunction();

  const { i18n } = useTranslation();

  const [newSalesPrice, setNewSalesPrice] = useState<number | undefined>(undefined);

  const getPrices = useCallback(
    (row: AssortmentItem) => {
      const primaryPackageUnit = row.primaryPackageUnit;
      const fPakUnitPrice = row.currentSalesPrices?.getBracketPackagePrice('F-PAK');
      const packageSalesPrice =
        fPakUnitPrice?.bracketMarkupPrice.getPriceForUnit(primaryPackageUnit);

      const price = includeVat
        ? packageSalesPrice?.price.priceWithVat
        : packageSalesPrice?.price.priceWithoutVat;

      return {
        price: price ?? 0,
        packageSalesPrice,
        fPakUnitPrice,
        primaryPackageUnit,
      };
    },
    [includeVat]
  );

  const salesPriceColumn: GridColDef<AssortmentItem> = {
    ...baseColumnProps,
    field: 'salesPrice',
    headerName: t('myAssortment.columns.salesPrice'),
    width: 200,
    editable: true,
    sortable: enableSortable,
    renderEditCell: ({ row }) => {
      const handleSubmit = (newSalesPrice: number) => {
        setNewSalesPrice(newSalesPrice);
        createMarkupDialog && createMarkupDialog.onToggle();
      };

      return (
        <UniversalFormProvider
          schema={markupMetaSchema(calculusT)}
          defaultValues={
            {
              fromDate: new Date(),
              markupName: row.primaryText,
            } as MarkupMetaSchema
          }
        >
          <FlexRow justifyContent="center" width="100%">
            <SalesPriceInput
              defaultValue={getPrices(row).price}
              onSubmit={handleSubmit}
              onCancel={() => apiRef?.current.stopCellEditMode({ id: row.id, field: 'salesPrice' })}
            />

            <CreateConditionDialog
              itemName={row.primaryText}
              open={createMarkupDialog?.isOpen ?? false}
              isLoadingCreate={isLoadingSalesPriceCondition ?? false}
              onClose={() => createMarkupDialog?.onClose() ?? null}
              handleCreateCondition={(formData) =>
                createCondition &&
                createCondition(
                  formData,
                  {
                    salesPrice: newSalesPrice,
                    mgSupplierId: row.mgSupplierId,
                    mgItemNumber: row.mgItemNumber,
                    id: row.id,
                  },
                  row.primaryPackageUnit
                )
              }
            />
          </FlexRow>
        </UniversalFormProvider>
      );
    },
    renderCell: ({ row }) => {
      const basePriceUnit = row.basePriceUnit;
      const currentCostPrice = row.getCurrentCostPriceForUnit(basePriceUnit);

      const { price, packageSalesPrice, fPakUnitPrice, primaryPackageUnit } = getPrices(row);

      const unitDescription = packageSalesPrice?.supplierItemPackage.unit.description;

      const validFrom = fPakUnitPrice?.validFrom
        ? dayjs(fPakUnitPrice?.validFrom).format('ll')
        : undefined;

      return (
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          {fPakUnitPrice && (
            <ItemStatus
              status={fPakUnitPrice.bracketMarkupPrice.status}
              errors={fPakUnitPrice.bracketMarkupPrice.errors}
            />
          )}

          <FlexRow>
            <StackedTextCell
              topLine={
                <PricePerUnit
                  price={price ? formatPrice(price, i18n.language) : '-'}
                  unit={primaryPackageUnit}
                  description={unitDescription}
                />
              }
              nullableBottomLine
              bottomLine={validFrom}
            />

            {currentCostPrice && (
              <IconButton
                onClick={() =>
                  apiRef && apiRef.current.startCellEditMode({ id: row.id, field: 'salesPrice' })
                }
              >
                <EditButtonIcon color="inherit" fontSize="small" />
              </IconButton>
            )}
          </FlexRow>
        </Box>
      );
    },
    valueGetter: (_, row) =>
      row.currentSalesPrices
        ?.getBracketPackagePrice('F-PAK')
        ?.bracketMarkupPrice.getPriceForUnit(row.primaryPackageUnit)?.price.priceWithVat,
  };

  return salesPriceColumn;
};
