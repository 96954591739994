import { DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import {
  AddOrSelectAssortmentDropdown,
  CreateAssortmentManuallyDialog,
  NewAssortment,
} from '@retail/monitoring-assortment/components';
import { useToast } from '@retail/hooks';
import {
  MgSupplierItemsTableWithDelete,
  SuccessAddingItemsDialog,
} from '@retail/products/components';
import {
  getMgAssortmentsQueryKey,
  useCreateMonitoringAssortment,
} from '@retail/monitoring-assortment/data-access';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { FetchError } from '@shared/fetch-utils';
import { useDisclosure } from '@shared/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useMonitoringAssortmentActions } from '@retail/monitoring-assortment/context';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedItems: MgSupplierItemSearchResult[];
  setCheckedItems: (ids: MgSupplierItemSearchResult[]) => void;
  monitoringAssortments: MonitoringAssortment[];
  navigateToAssortment: (assortmentId: number) => void;
}

function MonitorMgSupplierItemsDialog({
  open,
  selectedItems,
  onClose,
  setCheckedItems,
  monitoringAssortments,
  navigateToAssortment,
}: Props) {
  const t = useAppTFunction();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { selectedOrgUnitId } = useSelectedOrgUnit();

  const [selectedAssortment, setSelectedAssortment] = useState<MonitoringAssortment | null>(null);
  const [successDialog, openSuccessDialog] = useState(false);
  const uniqueMgItemIds = useMemo(
    () => Array.from(new Set(selectedItems.map(({ mgItemNumber }) => mgItemNumber))),
    [selectedItems]
  );
  const removeCheckedItem = useCallback(
    (mgItemId: number) => {
      setCheckedItems(
        selectedItems.filter((checkedItems) => checkedItems.mgItemNumber !== mgItemId)
      );
    },
    [setCheckedItems, selectedItems]
  );
  const { isLoading, handleAddItemsToMgAssortment } = useMonitoringAssortmentActions();

  const { isOpen: isCreateManuallyModalOpen, onToggle: toggleCreateManuallyModal } =
    useDisclosure(false);

  const { mutateAsync: createMgAssortmentAsync, isLoading: isLoadingCreate } =
    useCreateMonitoringAssortment();

  const submitForm = ({ title }: NewAssortment) => {
    createMgAssortmentAsync({
      body: {
        title,
        itemCodes: { mgItemNumbers: selectedItems.map(({ mgItemNumber }) => mgItemNumber) },
        orgUnitId: selectedOrgUnitId,
      },
    })
      .then((assortment) => {
        queryClient.invalidateQueries(getMgAssortmentsQueryKey());
        setSelectedAssortment(new MonitoringAssortment(assortment));
        toggleCreateManuallyModal();
        onClose();
        openSuccessDialog(true);
      })
      .catch((err: FetchError) => {
        console.error(err);
        toast.error(t('assortment.admin.create.error.generic'));
      });
  };

  const handleAddItemsToAssortment = () => {
    if (!selectedAssortment) {
      return;
    }
    return handleAddItemsToMgAssortment({
      assortmentId: selectedAssortment.id,
      mgItemNumbers: uniqueMgItemIds,
    })
      .then((updatedAssortment) => {
        toggleCreateManuallyModal();
        onClose();
        openSuccessDialog(true);
        toast.success(t('assortment.admin.update.success', { name: updatedAssortment.title }));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('assortment.admin.update.error'));
      });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="3xl">
        <DialogTitle>{t('products.mgItem.add')}</DialogTitle>

        <DialogContent>
          <Stack
            position="sticky"
            top={0}
            zIndex={1}
            pb={2}
            bgcolor={({ palette }) => palette.common.white}
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack>
              <Typography variant="body1">
                {t('products.mgItem.numberChosenItems', { number: selectedItems.length })}
              </Typography>
              <Typography variant="body2">
                {uniqueMgItemIds.length === 1
                  ? t('products.search.results.numberOfUniqueItemsSingular', {
                      number: uniqueMgItemIds.length,
                    })
                  : t('products.search.results.numberOfUniqueItemsPlural', {
                      number: uniqueMgItemIds.length,
                    })}
              </Typography>
            </Stack>

            <AddOrSelectAssortmentDropdown
              assortments={monitoringAssortments}
              selectedAssortment={selectedAssortment}
              setSelectedAssortment={setSelectedAssortment}
              openCreateManuallyModal={toggleCreateManuallyModal}
            />
          </Stack>
          <MgSupplierItemsTableWithDelete
            items={selectedItems}
            removeCheckedItem={removeCheckedItem}
          />
        </DialogContent>

        <DialogActions>
          <TextButton onClick={onClose}>{t('common.cancel')}</TextButton>
          <ContainedButton
            size="small"
            loading={isLoading}
            disabled={!selectedAssortment}
            onClick={handleAddItemsToAssortment}
          >
            {t('common.save')}
          </ContainedButton>
        </DialogActions>
      </Dialog>

      <CreateAssortmentManuallyDialog
        open={isCreateManuallyModalOpen}
        onClose={toggleCreateManuallyModal}
        onSubmit={submitForm}
        isLoading={isLoadingCreate}
        hideAddItemsLater
      />

      {successDialog && selectedAssortment && (
        <SuccessAddingItemsDialog
          isOpen={successDialog}
          closeDialog={() => {
            setCheckedItems([]);
            openSuccessDialog(false);
          }}
          successText={t('products.mgItem.addItemsToAssortment.addedTo', {
            nrOfItems: uniqueMgItemIds.length,
          })}
          assortmentTitle={selectedAssortment.title}
          assortmentId={selectedAssortment.id}
          navigate={navigateToAssortment}
        />
      )}
    </>
  );
}

export default withDialogSuspenseErrorBoundary(MonitorMgSupplierItemsDialog);
