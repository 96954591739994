import { Package } from './Package';
import { PriceError, PriceStatus } from '@retail/calculus/types';
import { VatPackagePrice } from './VatPackagePrice';
import { BracketMarkupPriceDTO } from '../../dto';
import { MarkupCondition } from './MarkupCondition';
import { MgPackageClassCode } from '../MgItem';
import { PriceSuggestionConditionMinimal } from './PriceSuggestionMinimal';
import { CustomerMarkupConditionMinimal } from './CustomerMarkupMinimal';

interface BracketMarkupPriceProps {
  calculationPackage: Package;
  status: PriceStatus;
  errors?: PriceError[];
  markup?: MarkupCondition;
  priceSuggestion?: PriceSuggestionConditionMinimal;
  customerMarkup?: CustomerMarkupConditionMinimal;
  packageSalesPrices?: VatPackagePrice[];
}

export class BracketMarkupPrice {
  calculationPackage: Package;
  status: PriceStatus;
  errors?: PriceError[];
  markup?: MarkupCondition;
  priceSuggestion?: PriceSuggestionConditionMinimal;
  customerMarkup?: CustomerMarkupConditionMinimal;
  packageSalesPrices?: VatPackagePrice[];

  constructor(props: BracketMarkupPriceProps) {
    this.calculationPackage = props.calculationPackage;
    this.status = props.status;
    this.errors = props.errors;
    this.markup = props.markup;
    this.priceSuggestion = props.priceSuggestion;
    this.customerMarkup = props.customerMarkup;
    this.packageSalesPrices = props.packageSalesPrices;
  }

  static fromDto = (dto: BracketMarkupPriceDTO): BracketMarkupPrice =>
    new BracketMarkupPrice({
      calculationPackage: Package.fromDto(dto.calculationPackage),
      status: dto.status,
      errors: dto.errors,
      markup: dto.markupCondition ? MarkupCondition.fromDto(dto.markupCondition) : undefined,
      priceSuggestion: dto.priceSuggestionCondition
        ? PriceSuggestionConditionMinimal.fromDto(dto.priceSuggestionCondition)
        : undefined,
      customerMarkup: dto.customerMarkupCondition
        ? CustomerMarkupConditionMinimal.fromDto(dto.customerMarkupCondition)
        : undefined,
      packageSalesPrices: dto.packageSalesPrices?.map(VatPackagePrice.fromDto),
    });

  getPriceForUnit = (unit: string): VatPackagePrice | undefined => {
    return this.packageSalesPrices?.find(
      (packagePrice) => packagePrice.supplierItemPackage.unit.unit === unit
    );
  };

  getPriceForClassCode = (classCode: MgPackageClassCode): VatPackagePrice | undefined => {
    return this.packageSalesPrices?.find(
      (packagePrice) => packagePrice.supplierItemPackage.classCode === classCode
    );
  };
}
