import { useMemo } from 'react';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { dataGridBoldClass } from '@shared/styles';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import {
  AssortmentType,
  CustomerMarkupCondition,
  CustomerMarkupsForPriceLists,
} from '@retail/calculus/types';
import {
  AddButton,
  CustomColumnMenu,
  NoTableRowsOverlay,
  StackedTextCell,
} from '@retail/components';
import { getTogglableDataGridColumns } from '@shared/utils';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { CalculusAreaTableActions, LevelChip } from '@retail/calculus/components';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  multipleChains: boolean;
  conditions: CustomerMarkupCondition[];
  language: string;
  customerMarkupsForPriceLists?: CustomerMarkupsForPriceLists[];
  openCreateDialog: () => void;
  handleDeactivate: (condition: CustomerMarkupCondition) => void;
  handleEdit: (condition: CustomerMarkupCondition) => void;
}

const baseColumnProps: Partial<GridColDef<CustomerMarkupCondition>> = {
  width: 140,
  sortable: false,
  headerAlign: 'left',
  align: 'left',
};

export function CustomerMarkupTable({
  multipleChains,
  conditions,
  language,
  customerMarkupsForPriceLists,
  openCreateDialog,
  handleDeactivate,
  handleEdit,
}: Props) {
  const t = useCalculusTFunction();
  const { i18n } = useTranslation();

  const columns: GridColDef<CustomerMarkupCondition>[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        cellClassName: () => dataGridBoldClass,
        field: 'level',
        headerName: t('calculus.level.level'),
        width: 190,
        renderCell: ({ row }) => <LevelChip active bindingType={row.bindingType} />,
      },
      {
        ...baseColumnProps,
        cellClassName: () => dataGridBoldClass,
        field: 'name',
        headerName: t('calculus.level.levelOrItemName'),
        width: 260,
        renderCell: ({ row }) => {
          const name = row.getLevelName();
          const id = row.getLevelId();

          return <StackedTextCell topLine={name} bottomLine={id} />;
        },
      },
      {
        ...baseColumnProps,
        cellClassName: () => dataGridBoldClass,
        headerAlign: 'center',
        align: 'center',
        field: 'salesFactor',
        headerName: t('calculus.customerMarkup.factor'),
        width: 100,
      },
      {
        ...baseColumnProps,
        cellClassName: () => dataGridBoldClass,
        headerAlign: 'center',
        align: 'center',
        field: 'percent',
        headerName: t('calculus.customerMarkup.percent'),
        width: 100,
        renderCell: ({ value }) => (value ? `${formatPrice(value, i18n.language)} %` : '-'),
      },
      {
        ...baseColumnProps,
        field: 'activeFromTo',
        width: 160,
        headerName: t('calculus.activeFromTo'),
        renderCell: ({ row }) => {
          const activeFrom = dayjs(row.validFrom).format('ll');
          const activeTo = row.validTo && dayjs(row.validTo).format('ll');
          return <StackedTextCell topLine={activeFrom} bottomLine={activeTo} centerAlign />;
        },
      },
      {
        ...baseColumnProps,
        field: 'actions',
        headerName: '',
        width: 90,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <CalculusAreaTableActions
            handleDeactivate={
              row.assortmentType !== AssortmentType.DRAFT ? () => handleDeactivate(row) : undefined
            }
            handleEdit={() => handleEdit(row)}
          />
        ),
      },
    ],
    [handleDeactivate, handleEdit, t]
  );

  if (multipleChains) {
    columns.unshift({
      ...baseColumnProps,
      field: 'customer',
      headerName: t('calculus.level.customer'),
      width: 190,
      renderCell: ({ row }) => {
        const priceList = customerMarkupsForPriceLists?.find((priceListConditions) =>
          priceListConditions.conditionIds?.includes(row.id)
        );
        return priceList?.priceListName;
      },
    });
  }

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium
          getRowId={(row) =>
            `${row.id}${
              customerMarkupsForPriceLists?.find((priceListConditions) =>
                priceListConditions.conditionIds?.includes(row.id)
              )?.priceListAssortmentId
            }`
          }
          columns={columns}
          rows={conditions}
          rowHeight={70}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  DATA_GRID_ID.CALCULUS_MARKUP_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: { right: ['actions'] },
          }}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);
            saveHiddenDataGridColumnsToLocalStorage(
              DATA_GRID_ID.CALCULUS_MARKUP_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnReorder
          hideFooter
          slots={{
            noRowsOverlay: () => (
              <NoTableRowsOverlay
                noRowsText={t('calculus.noCustomerMarkup')}
                addAction={
                  <AddButton onClick={openCreateDialog}>
                    {t('calculus.createCustomerMarkup')}
                  </AddButton>
                }
              />
            ),
            columnMenu: CustomColumnMenu,
          }}
          slotProps={{
            ...columnsManagementSlotProps,
          }}
        />
      </Box>
    </Box>
  );
}
