import { fetchFile, QueryOptions } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { buildUrl } from '@shared/utils';
import { useMPEFetch, useMutateMPEFetch, useSelectedOrgUnitHeader } from '@retail/app/hooks';
import { ItemsCodeLists, MonitoringAssortmentItemDTO } from '@retail/monitoring-assortment/types';
import { PaginationResultDTO } from '@retail/products/types';
import { AssortmentDTO } from '@retail/my-assortment/types';
import { useMutation } from '@tanstack/react-query';
import { mpeApi } from '@retail/mgpris/config';

export const getMgAssortmentsQueryKey = (mgItemNumbers: number[] = []) => [
  'monitoring-assortments',
  mgItemNumbers,
];

interface SearchParams {
  assortmentId: number;
  competitorStoreIds: number[];
  page?: number;
  size?: number;
  nCmpPrices?: number;
  supplierIds?: number[];
  groupNumbers?: number[];
  query?: string;
}

export function useFetchMonitoringAssortmentItems(
  {
    assortmentId,
    competitorStoreIds,
    page,
    size,
    nCmpPrices,
    supplierIds,
    groupNumbers,
    query,
  }: SearchParams,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<PaginationResultDTO<MonitoringAssortmentItemDTO[]>>({
    method: 'GET',
    queryKey: ['monitoring-assortment', assortmentId, page, size, query],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.monitoringAssortments.assortment.items.getFullLinkPath({ assortmentId }),
      searchParams: {
        storeIds: competitorStoreIds,
        page,
        size,
        nCmpPrices,
        groupNumbers,
        query,
        supplierIds,
      },
    }),
    ...queryOptions,
  });
}

export function useValidateImportedItems() {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();
  return useMutateMPEFetch<ItemsCodeLists, ItemsCodeLists>({
    method: 'POST',
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.monitoringAssortments.validateItemCodes.getFullLinkPath(),
    }),
  });
}

interface CreateAssortmentDTO {
  orgUnitId: number;
  title: string;
  itemCodes: ItemsCodeLists;
}

export function useCreateMonitoringAssortment() {
  const { token } = useAuthContext();
  const { mpe } = useMgPrisConfig();

  return useMutateMPEFetch<AssortmentDTO, CreateAssortmentDTO>({
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.monitoringAssortments.getFullLinkPath(),
    }),
    method: 'POST',
    token,
  });
}

interface PostProps {
  mgItemNumbers: number[];
  finfoNumbers?: number[];
  gtinCodes?: number[];
}

interface PatchProps {
  title: string;
}

export const useAddItemsToMonitoringAssortment = () => {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<AssortmentDTO, PostProps>({
    url: (assortmentId: number) =>
      buildUrl({
        base: mpe.url,
        route: mpeApi.monitoringAssortments.assortment.items.getFullLinkPath({ assortmentId }),
      }),
    method: 'POST',
    token,
  });
};

export const useDeleteItemsFromMonitoringAssortment = () => {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void>({
    url: (assortmentId: number) =>
      buildUrl({
        base: mpe.url,
        route: mpeApi.monitoringAssortments.assortment.items.getFullLinkPath({ assortmentId }),
      }),
    method: 'DELETE',
    token,
  });
};

export const usePatchTitleOrItemsOfMonitoringAssortment = () => {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();
  return useMutateMPEFetch<object, PatchProps>({
    url: (assortmentId: number) =>
      buildUrl({
        base: mpe.url,
        route: mpeApi.monitoringAssortments.assortment.getFullLinkPath({ assortmentId }),
      }),
    method: 'PATCH',
    token,
  });
};

export const useDeleteMonitoringAssortment = () => {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void>({
    url: (assortmentId: number) =>
      buildUrl({
        base: mpe.url,
        route: mpeApi.monitoringAssortments.assortment.getFullLinkPath({ assortmentId }),
      }),
    method: 'DELETE',
    token,
  });
};

export const useExportAssortmentToExcel = () => {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  return useMutation((assortmentId: number) =>
    fetchFile({
      url: buildUrl({
        base: mpe.url,
        route: mpeApi.monitoringAssortments.assortment.getFullLinkPath({ assortmentId }),
      }),
      token,
      method: 'GET',
      headers: selectedOrgUnitHeader,
    })
  );
};
