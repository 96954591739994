import { MgSupplierItem, MgSupplierItemPackage } from '@retail/products/types';
import { useCallback, useEffect, useMemo } from 'react';
import { Packages } from './Packages';
import { SalesPriceSummary } from './SalesPriceSummary';
import { useProductCardStore } from './store';
import {
  CostPriceRow,
  FreightAccordion,
  MarkupAccordion,
  MarkupAccordionSummary,
  PriceSuggestion,
  PurchaseAccordion,
} from './Calculus';
import { Divider, Stack } from '@mui/material';
import { PriceSuggestionType } from '@retail/price-suggestion/types';

interface Props {
  selectedSupplierItem: MgSupplierItem;
  selectedSupplierItemPackage: MgSupplierItemPackage;
  availablePriceSuggestions?: PriceSuggestionType[];
  includeVat: boolean;
  submitMarkupValues: () => void;
  toggleChangePriceSuggestionModal: () => void;
}

export function ProductCard({
  selectedSupplierItem,
  selectedSupplierItemPackage,
  availablePriceSuggestions,
  includeVat,
  submitMarkupValues,
  toggleChangePriceSuggestionModal,
}: Props) {
  const {
    selectedPackageType,
    editingField,
    getSalesPriceEditState,
    setEditingField,
    closeEditingField,
    changeSelectedPackage,
    setNewSalesCoverage,
    setNewSalesFactor,
    setNewSalesPrice,
    newMarkup,
    resetMarkup,
    resetState,
  } = useProductCardStore((state) => state);
  const { currentSalesPrices } = selectedSupplierItem;

  const mgPackages = MgSupplierItem.getPackages(selectedSupplierItem.packages || []);
  const selectedUnit = selectedSupplierItemPackage.vilmaUnit.unit;

  const currentPurchasePrice = useMemo(
    () => currentSalesPrices.getCurrentPurchasePriceForUnit(selectedUnit),
    [currentSalesPrices, selectedUnit]
  );

  const currentCostPrice = useMemo(
    () => currentSalesPrices.getCurrentCostPriceForUnit(selectedUnit),
    [currentSalesPrices, selectedUnit]
  );

  const salesPriceCoverage = useMemo(
    () => selectedSupplierItem.getSalesPriceCoverageForPackage(selectedPackageType),
    [selectedPackageType, selectedSupplierItem]
  );

  const currentCalculatedMarkupForPackage = useMemo(
    () => selectedSupplierItem.getMarkupConditionForPackage(selectedPackageType),
    [selectedPackageType, selectedSupplierItem]
  );

  const { currentSalesPrice, currentCalculatedSalesPrice } = useMemo(() => {
    const priceForUnit = currentSalesPrices
      .getBracketPackagePrice('F-PAK')
      ?.bracketMarkupPrice.getPriceForUnit(selectedUnit);

    if (!priceForUnit)
      return { currentSalesPricse: undefined, currentCalculatedSalesPricse: undefined };

    const currentSalesPrice = includeVat
      ? priceForUnit.price.priceWithVat
      : priceForUnit.price.priceWithoutVat;
    const currentCalculatedSalesPrice = includeVat
      ? priceForUnit.markupPrice?.priceWithVat
      : priceForUnit.markupPrice?.priceWithoutVat;

    return { currentSalesPrice, currentCalculatedSalesPrice };
  }, [includeVat, currentSalesPrices, selectedUnit]);

  useEffect(() => {
    resetMarkup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeVat]);

  useEffect(() => {
    return () => resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSupplierItem.mgSupplierItemId]);

  const setSalesPrice = useCallback(
    (salesPrice: number) =>
      setNewSalesPrice(salesPrice, selectedSupplierItemPackage, currentSalesPrices, includeVat),
    [includeVat, currentSalesPrices, selectedSupplierItemPackage, setNewSalesPrice]
  );

  const setSalesFactor = useCallback(
    (salesFactor: number) =>
      setNewSalesFactor(salesFactor, selectedSupplierItemPackage, currentSalesPrices),
    [currentSalesPrices, selectedSupplierItemPackage, setNewSalesFactor]
  );

  const setSalesCoverage = useCallback(
    (salesCoverage: number) =>
      setNewSalesCoverage(salesCoverage, selectedSupplierItemPackage, currentSalesPrices),
    [currentSalesPrices, selectedSupplierItemPackage, setNewSalesCoverage]
  );

  const salesPriceEditState = useMemo(
    () => getSalesPriceEditState(selectedSupplierItemPackage, currentSalesPrices),
    [getSalesPriceEditState, currentSalesPrices, selectedSupplierItemPackage]
  );

  return (
    <Stack divider={<Divider />} flexGrow={1}>
      <Packages
        packages={mgPackages}
        selectedPackage={selectedPackageType}
        basePriceUnit={selectedSupplierItem.basePriceUnit}
        setSelectedPackage={changeSelectedPackage}
      />

      <PurchaseAccordion
        purchaseCondition={currentSalesPrices.purchaseCondition}
        unit={selectedUnit}
        purchasePrice={currentPurchasePrice?.price?.price}
      />

      <FreightAccordion
        freightCondition={selectedSupplierItem.currentSalesPrices.freightCondition}
      />

      <CostPriceRow costPrice={currentCostPrice?.price?.price} unit={selectedUnit} />

      <MarkupAccordion
        markupCondition={currentCalculatedMarkupForPackage}
        accordionSummary={
          <MarkupAccordionSummary
            currentSalesFactor={currentCalculatedMarkupForPackage?.salesFactor}
            newSalesFactor={
              newMarkup && {
                value: newMarkup.salesFactor,
                isApproximate:
                  newMarkup.editedField === 'price' || newMarkup.editedField === 'coverage',
              }
            }
            editProps={{
              openEditMode: () => setEditingField('factor'),
              cancelEditMode: closeEditingField,
              isEditing: editingField === 'factor',
              onChangeSalesFactor: setSalesFactor,
            }}
          />
        }
      />

      {availablePriceSuggestions && (
        <PriceSuggestion
          selectedPriceSuggestion={currentSalesPrices.priceSuggestionType}
          noAvailablePriceSuggestions={availablePriceSuggestions.length === 0}
          openPriceSuggestionChangeModal={toggleChangePriceSuggestionModal}
        />
      )}

      <SalesPriceSummary
        currentSalesPrice={currentSalesPrice}
        currentCalculatedSalesPrice={currentCalculatedSalesPrice}
        newSalesPrice={
          newMarkup?.salesPriceIncVat && newMarkup?.salesPrice
            ? {
                value: includeVat ? newMarkup.salesPriceIncVat : newMarkup.salesPrice,
                isApproximate: newMarkup.editedField !== 'price',
              }
            : undefined
        }
        currentSalesCoverage={salesPriceCoverage}
        currentCalculatedSalesCoverage={currentCalculatedMarkupForPackage?.markupPriceCoverage}
        newSalesCoverage={
          newMarkup
            ? {
                value: newMarkup.salesCoverage,
                isApproximate: newMarkup.editedField !== 'coverage',
              }
            : undefined
        }
        salesPriceEditState={salesPriceEditState}
        onChangeSalesCoverage={setSalesCoverage}
        closeEditingField={closeEditingField}
        editingField={editingField}
        packageUnit={selectedUnit}
        setEditingField={setEditingField}
        onChangeSalesPrice={setSalesPrice}
        includeVat={includeVat}
        resetMarkupValues={resetMarkup}
        submitMarkupValues={submitMarkupValues}
      />
    </Stack>
  );
}
