import { Condition } from '@retail/products/types';
import { ConditionTooltipInfo } from '@retail/calculus/components';
import { PriceSuggestionType } from '@retail/price-suggestion/types';
import { usePriceSuggestionTFunction } from '@retail/price-suggestion/i18n';
import { getPriceSuggestionType } from '@retail/products/utils';

interface Props {
  condition?: Condition;
  title: string;
  selectedPriceSuggestionType?: PriceSuggestionType;
}

export const PriceSuggestionConditionTooltipInfo = ({
  condition,
  title,
  selectedPriceSuggestionType,
}: Props) => {
  const t = usePriceSuggestionTFunction();

  return (
    <ConditionTooltipInfo
      title={title}
      condition={condition}
      selectedPriceSuggestionType={
        selectedPriceSuggestionType && getPriceSuggestionType(t, selectedPriceSuggestionType)
      }
    />
  );
};
