import { Stack, Typography } from '@mui/material';
import { LevelChip } from './LevelChip';
import { Condition } from '@retail/products/types';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import dayjs from 'dayjs';

interface Props {
  title: string;
  condition?: Condition;
  selectedPriceSuggestionType?: string;
}

export function ConditionTooltipInfo({ title, condition, selectedPriceSuggestionType }: Props) {
  const t = useCalculusTFunction();

  const activeFrom = condition?.validFrom && dayjs(condition.validFrom).format('ll');
  const activeTo = condition?.validTo ? dayjs(condition.validTo).format('ll') : '-';

  return (
    <Stack gap={1}>
      <Typography variant="subtitle2">{title}</Typography>
      {selectedPriceSuggestionType && (
        <Stack flexDirection="row" gap={1}>
          <Typography variant="body2">{t('calculus.priceType')}:</Typography>
          <Typography variant="subtitle2">{selectedPriceSuggestionType}</Typography>
        </Stack>
      )}

      {condition?.bindingType && (
        <>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <Typography variant="body2">{t('calculus.level.level')}:</Typography>
            <LevelChip height={28} active bindingType={condition.bindingType} />
          </Stack>
          <Stack flexDirection="row" gap={1} alignItems="center">
            <Typography variant="body2">{t('calculus.level.levelOrItemName')}:</Typography>
            <Typography variant="subtitle2">{condition.getLevelName()}</Typography>
          </Stack>
        </>
      )}
      <Stack flexDirection="row" gap={1} alignItems="center">
        <Typography variant="body2">{t('calculus.activeFrom')}: </Typography>
        <Typography variant="subtitle2">{activeFrom}</Typography>
      </Stack>
      <Stack flexDirection="row" gap={1} alignItems="center">
        <Typography variant="body2">{t('calculus.activeTo')}: </Typography>
        <Typography variant="subtitle2">{activeTo}</Typography>
      </Stack>
    </Stack>
  );
}
