import { SupplierCustomerMarkupConditionsDTO } from '../../dtos';
import { CustomerMarkupCondition } from './CustomerMarkupCondition';

interface SupplierCustomerMarkupConditionProps {
  mgSupplierId: number;
  markupConditions: CustomerMarkupCondition[];
}

export class SupplierCustomerMarkupCondition {
  mgSupplierId: number;
  markupConditions: CustomerMarkupCondition[];

  constructor({ mgSupplierId, markupConditions }: SupplierCustomerMarkupConditionProps) {
    this.mgSupplierId = mgSupplierId;
    this.markupConditions = markupConditions;
  }

  static fromDto = (dto: SupplierCustomerMarkupConditionsDTO): SupplierCustomerMarkupCondition =>
    new SupplierCustomerMarkupCondition({
      mgSupplierId: dto.mgSupplierId,
      markupConditions: dto.conditions.map(CustomerMarkupCondition.fromDto),
    });
}
