import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { useGetErrorMessage } from '@retail/form/validation';

export interface RadioOption {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface Props<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  label: string;
  options: RadioOption[];
  disabled?: boolean;
  defaultValue?: PathValue<TFieldValues, Path<TFieldValues>>;
  'aria-label'?: string;
  control: Control<TFieldValues>;
}

export const RadioInput = <TFieldValues extends FieldValues>({
  name,
  label,
  options,
  disabled,
  defaultValue,
  control,
  ...rest
}: Props<TFieldValues>) => {
  const getErrorMessage = useGetErrorMessage();

  return (
    <FormControl component="fieldset">
      <FormControlLabel
        label={label}
        aria-label={rest['aria-label']}
        disabled={disabled}
        id={name}
        sx={{
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'flex-start',
          margin: '0.5em 0',
          '& *[role=radiogroup]': {
            marginLeft: '1em',
          },
        }}
        control={
          <Controller
            name={name}
            defaultValue={defaultValue}
            control={control}
            render={({ field: { value, ref, onChange, onBlur }, fieldState: { error } }) => {
              return (
                <>
                  {!!error && (
                    <FormHelperText error>{getErrorMessage(error['message'])}</FormHelperText>
                  )}
                  <RadioGroup ref={ref}>
                    {options.map((option) => (
                      <FormControlLabel
                        checked={value === option.value}
                        value={option.value}
                        label={option.label}
                        id={`${name}.${option.value}`}
                        control={
                          <Radio
                            onChange={(e) => onChange(e.target.value)}
                            onBlur={onBlur}
                            color="primary"
                            disabled={disabled || option.disabled}
                          />
                        }
                        key={option.value}
                      />
                    ))}
                  </RadioGroup>
                </>
              );
            }}
          />
        }
      />
    </FormControl>
  );
};
