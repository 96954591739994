import { MgSupplierItem, MgSupplierItemPackage } from '@retail/products/types';
import { useEffect, useMemo } from 'react';
import { Packages } from './Packages';
import { Divider, Stack, Typography } from '@mui/material';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';
import {
  CostPriceRow,
  FreightAccordion,
  MarkupAccordion,
  MarkupAccordionSummary,
  PurchaseAccordion,
} from './Calculus';
import { SalesPriceSummaryWrapper } from './SalesPriceSummary/SalesPriceSummaryWrapper';
import {
  BracketPriceAccordion,
  useBracketPackagePrices,
  useBracketPriceStore,
} from './BracketPrices';
import { Feature } from '@retail/components';
import { FeatureContext, FeatureFlag } from '@retail/mgpris/config-types';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  selectedSupplierItem: MgSupplierItem;
  selectedSupplierItemPackage: MgSupplierItemPackage;
  includeVat: boolean;
  onOpenBracketPricelModal?: () => void;
  features: FeatureFlag[];
  featureContext: FeatureContext;
}

export function WholesalerProductCard({
  selectedSupplierItem,
  selectedSupplierItemPackage,
  includeVat,
  onOpenBracketPricelModal,
  features,
  featureContext,
}: Props) {
  const t = useAppTFunction();
  const {
    i18n: { language },
  } = useTranslation();

  const mgPackages = MgSupplierItem.getPackages(selectedSupplierItem.packages);
  const selectedUnit = selectedSupplierItemPackage.vilmaUnit.unit;
  const { currentSalesPrices } = selectedSupplierItem;

  const { selectedPackageType, changeSelectedPackage, resetState } = useBracketPriceStore(
    (state) => state
  );

  const currentPurchasePrice = useMemo(
    () => currentSalesPrices.getCurrentPurchasePriceForUnit(selectedUnit),
    [currentSalesPrices, selectedUnit]
  );

  const currentCostPrice = useMemo(
    () => currentSalesPrices.getCurrentCostPriceForUnit(selectedUnit),
    [currentSalesPrices, selectedUnit]
  );

  const currentSalesPrice = useMemo(() => {
    const priceForUnit = currentSalesPrices
      .getBracketPackagePrice(selectedSupplierItemPackage.classCode)
      ?.bracketMarkupPrice.getPriceForUnit(selectedUnit);

    if (!priceForUnit) return undefined;

    return includeVat ? priceForUnit.price.priceWithVat : priceForUnit.price.priceWithoutVat;
  }, [includeVat, currentSalesPrices, selectedSupplierItemPackage.classCode, selectedUnit]);

  useEffect(() => {
    return () => resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSupplierItem.mgSupplierItemId]);

  const currentMarkupForPackage =
    selectedSupplierItem.getMarkupConditionForPackage(selectedPackageType);

  const packagePrices = useBracketPackagePrices(
    currentSalesPrices,
    selectedSupplierItem.packages,
    includeVat
  );

  return (
    <Stack divider={<Divider />}>
      <Feature
        features={features}
        name="bracket-price-table"
        context={featureContext}
        renderEnabled={() => (
          <BracketPriceAccordion
            packagePrices={packagePrices}
            baseUnit={selectedSupplierItem.basePriceUnit}
            editable={false}
            onEdit={onOpenBracketPricelModal}
          />
        )}
      />
      <Packages
        packages={mgPackages}
        selectedPackage={selectedPackageType}
        basePriceUnit={selectedSupplierItem.basePriceUnit}
        setSelectedPackage={changeSelectedPackage}
      />

      <PurchaseAccordion
        purchaseCondition={currentSalesPrices.purchaseCondition}
        unit={selectedUnit}
        purchasePrice={currentPurchasePrice?.price?.price}
      />

      <FreightAccordion
        freightCondition={selectedSupplierItem.currentSalesPrices.freightCondition}
      />

      <CostPriceRow costPrice={currentCostPrice?.price?.price} unit={selectedUnit} />

      <MarkupAccordion
        markupCondition={currentMarkupForPackage}
        accordionSummary={
          <MarkupAccordionSummary currentSalesFactor={currentMarkupForPackage?.salesFactor} />
        }
      />

      <SalesPriceSummaryWrapper>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Stack flexDirection="row" gap={1}>
            <Typography fontWeight={600}>
              {t('myAssortment.salesPricePer', { unit: selectedUnit })}
            </Typography>
            <Typography>
              {includeVat
                ? t('myAssortment.actions.includeVat')
                : t('myAssortment.actions.excludeVat')}
            </Typography>
          </Stack>
          <Typography variant="body2">
            {`${currentSalesPrice ? formatPrice(currentSalesPrice, language) : '-'} ${t(
              'myAssortment.productCard.currency.sek'
            )}`}
          </Typography>
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography fontWeight={600}>{t('myAssortment.salesCoverage')}</Typography>
          <Typography variant="body2">
            {currentMarkupForPackage?.markupPriceCoverage
              ? `${currentMarkupForPackage.markupPriceCoverage.toLocaleString(language, {
                  maximumFractionDigits: 6,
                })}%`
              : '-'}
          </Typography>
        </Stack>
      </SalesPriceSummaryWrapper>
    </Stack>
  );
}
