import { FC, ReactNode, useCallback } from 'react';
import { useDepartmentAssortments, useMemberAssortments } from '@retail/my-assortment/context';
import { useRefetchDraftDrawer } from '@retail/context';
import { useMutateAddItemsToDraft } from '@retail/my-assortment/data-access';
import { ItemsDTO } from '@retail/my-assortment/types';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { ItemPriority, MgSupplierItemSearchResult } from '@retail/products/types';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';

export interface AddItemsProps {
  items: MgSupplierItemSearchResult[];
  validFrom?: string;
}

export interface MemberAssortmentChildrenProps {
  addItemsToAssortment?: (props: AddItemsProps) => Promise<void>;
  isLoading?: boolean;
  mgItemLinkPath?: (mgItemNumber: string) => string;
  assortmentLinkPath?: (assortmentId: number) => string;
  assortmentId: number;
  monitoringAssortments?: MonitoringAssortment[];
}

interface Props {
  children: (props: MemberAssortmentChildrenProps) => ReactNode;
}

export const MemberAssortmentFunctionsWrapper: FC<Props> = ({ children }) => {
  const { draftAssortmentId: memberDraftAssortmentId, memberId } = useMemberAssortments();
  const { monitoringAssortments } = useDepartmentAssortments();
  const { invalidateAssortmentDraft } = useRefetchDraftDrawer();

  const { mutateAsync: addItems, isLoading } = useMutateAddItemsToDraft({
    memberId,
    assortmentId: memberDraftAssortmentId,
  });

  const addItemsToAssortment = useCallback(
    async ({ items, validFrom }: AddItemsProps) => {
      const itemDtos: ItemsDTO[] = items.map((item) => ({
        mgSupplierId: item.mgParticipantAccountId,
        mgItemNumber: item.mgItemNumber,
        priority: ItemPriority.ZERO,
        validFrom,
      }));
      await addItems({ body: itemDtos });
      invalidateAssortmentDraft();
    },
    [addItems, invalidateAssortmentDraft]
  );

  const mgItemLinkPath = useCallback(
    (mgItemNumber: string) => mgPrisRoutes.items.item.getFullLinkPath({ itemNumber: mgItemNumber }),
    []
  );

  const assortmentLinkPath = useCallback(
    (assortmentId: number) =>
      mgPrisRoutes.marketInsight.assortments.assortment.getFullLinkPath({ assortmentId }),
    []
  );

  return children({
    addItemsToAssortment,
    isLoading,
    mgItemLinkPath,
    assortmentLinkPath,
    assortmentId: memberDraftAssortmentId,
    monitoringAssortments,
  });
};
