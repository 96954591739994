import { Stack, Typography } from '@mui/material';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { MarkupCondition } from '@retail/calculus/types';
import { TextInputField } from '@shared/components';
import { CreateMarkupSchema } from '../markupSchema';
import { useController, useFormContext } from 'react-hook-form';
import { Percent } from '@mui/icons-material';
import { calculateCoverageFromSalesFactor, calculateSalesFactorFromDG } from '@retail/utils';
import { CurrentActiveMarkup } from './CurrentActiveMarkup';
import { ConditionDatesForm } from '@retail/calculus/components';
import { ChangeEventHandler, useCallback } from 'react';

interface Props {
  markupCondition?: MarkupCondition;
}

export function CreateMarkup({ markupCondition }: Props) {
  const t = useMarkupTFunction();
  const { control } = useFormContext<CreateMarkupSchema>();

  const levelController = useController({ name: 'level', control });
  const nameController = useController({ name: 'markupName', control });
  const salesCoverageController = useController({ name: 'salesCoverage', control });
  const salesFactorController = useController({ name: 'salesFactor', control });

  const onSalesCoverageChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const value = e.target.value.replace(',', '.');
      if (Number(value)) {
        salesFactorController.field.onChange(calculateSalesFactorFromDG(Number(value)));
      }
      salesCoverageController.field.onChange(value);
    },
    [salesCoverageController.field, salesFactorController.field]
  );

  const onSalesFactorChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const value = e.target.value.replace(',', '.');
      if (Number(value)) {
        salesCoverageController.field.onChange(calculateCoverageFromSalesFactor(Number(value)));
      }
      salesFactorController.field.onChange(value);
    },
    [salesCoverageController.field, salesFactorController.field]
  );

  return (
    <Stack flexDirection="row" gap={4}>
      <Stack gap={2}>
        <Typography fontWeight={600}>{t('markup.create.newMarkup')}</Typography>

        <Stack width={342} gap={2}>
          <TextInputField
            {...nameController.field}
            required
            fullWidth
            labelWidth={142}
            labelDirection="row"
            margin="none"
            label={t('markup.markupName')}
            size="small"
            placeholder={t('markup.markupName')}
          />

          <TextInputField
            {...salesCoverageController.field}
            error={salesCoverageController.fieldState.error?.message}
            required
            fullWidth
            labelWidth={142}
            labelDirection="row"
            margin="none"
            label={t('markup.salesCoverage')}
            size="small"
            placeholder={t('markup.salesCoverage')}
            endAdornment={<Percent />}
            onChange={onSalesCoverageChange}
          />

          <TextInputField
            {...salesFactorController.field}
            error={salesFactorController.fieldState.error?.message}
            required
            fullWidth
            labelWidth={142}
            labelDirection="row"
            value={salesFactorController.field.value}
            onChange={onSalesFactorChange}
            margin="none"
            label={t('markup.salesFactor')}
            size="small"
            placeholder={t('markup.salesFactor')}
          />
        </Stack>

        <ConditionDatesForm alignment="column" labelAlignment="row" />
      </Stack>

      {markupCondition && (
        <CurrentActiveMarkup
          markupCondition={markupCondition}
          selectedLevel={levelController.field.value}
        />
      )}
    </Stack>
  );
}
