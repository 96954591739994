import { FC } from 'react';
import { MgItemInfo } from '@retail/my-assortment/components';
import { useDisclosure } from '@shared/hooks';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { AssortmentItem } from '@retail/my-assortment/types';
import { ProductCardPaper } from '@retail/my-assortment/containers';
import { ProductCardContainer } from './ProductCardContainer';
import { MgItemDialogContainer } from '@retail/products/containers';
import { PdmMgItemContainer } from '../../MgItem';

interface Props {
  assortmentItem: AssortmentItem;
  closeProductCard: () => void;
}

export const PdmProductCardSuspenseContainer: FC<Props> = ({
  assortmentItem,
  closeProductCard,
}) => {
  const { isOpen: openFullProductCard, onOpen, onClose } = useDisclosure();

  return (
    <ProductCardPaper>
      <MgItemInfo
        primaryText={assortmentItem.primaryText}
        secondaryText={assortmentItem.secondaryText}
        mgItemNumber={assortmentItem.mgItemNumber}
        closeProductCard={closeProductCard}
        openProductCardModal={onOpen}
      />
      <SuspenseWithSentryErrorBoundary>
        <ProductCardContainer assortmentItem={assortmentItem} />
      </SuspenseWithSentryErrorBoundary>
      <MgItemDialogContainer
        open={openFullProductCard}
        itemNumber={assortmentItem.mgItemNumber}
        onClose={onClose}
      >
        {({ mgItem }) => (
          <PdmMgItemContainer mgItem={mgItem} showAddItem={false} hiddenBreadcrumbs />
        )}
      </MgItemDialogContainer>
    </ProductCardPaper>
  );
};
