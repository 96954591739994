import { useEffect } from 'react';
import {
  getImportedMarkupConditions,
  markupConditionsSpreadsheetValidatedAtom,
  markupConditionsSpreadsheetValidationAtom,
  useImportedMarkupConditionsState,
  useResetImportMarkupConditionsState,
} from '@retail/markup/utils';
import { useRecoilState } from 'recoil';
import { Supplier } from '@retail/suppliers/types';

export function useMarkupConditionUpload({
  mgSupplierId,
}: {
  mgSupplierId: Supplier['mgSupplierId'];
}) {
  const importState = useImportedMarkupConditionsState(mgSupplierId);
  const importedMarkupConditions = getImportedMarkupConditions({
    validMarkups: importState.validDataRows,
  });

  const resetImportMarkupConditions = useResetImportMarkupConditionsState();

  const [validationState, setValidationState] = useRecoilState(
    markupConditionsSpreadsheetValidationAtom
  );
  const [isValidated, setIsValidated] = useRecoilState(markupConditionsSpreadsheetValidatedAtom);

  useEffect(() => {
    if (importState.invalidDataRowIndexes.length > 0 && !isValidated) {
      setIsValidated(true);
      setValidationState({ invalidMarkupIndexes: importState.invalidDataRowIndexes });
    }
  }, [
    importState.invalidDataRowIndexes,
    importState.invalidDataRowIndexes.length,
    isValidated,
    setIsValidated,
    setValidationState,
  ]);

  return {
    importedMarkupConditions,
    validationState,
    importState,
    resetImportMarkupConditions,
  };
}
