import { MgSupplierItemSearchResult } from '@retail/products/types';

import { Box, Stack, Typography } from '@mui/material';
import { CustomColumnMenu, DataGridPagination, StackedTextCell } from '@retail/components';
import { useProductsTFunction } from '@retail/products/i18n';
import { Dispatch, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DataGridPremium, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { Pagination as PaginationType } from '@shared/hooks';
import {
  dataGridCellHoverClass,
  dataGridColorRowClass,
  dataGridWhiteRowClass,
} from '@shared/styles';
import { GridRowClassNameParams } from '@mui/x-data-grid/models/params';
import clsx from 'clsx';
import { CheckIcon } from '@shared/custom-icons';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { getTogglableDataGridColumns } from '@shared/utils';

interface Props {
  items: MgSupplierItemSearchResult[];
  totalItems: number;
  language: string;
  onRowClick?: (mgItemNumber: string) => void;
  selection?: {
    selectedItems: MgSupplierItemSearchResult[];
    selectItem: Dispatch<MgSupplierItemSearchResult[]>;
  };
}

type GridColItem = GridColDef<MgSupplierItemSearchResult>;

const baseColumnProps: Partial<GridColItem> = {
  width: 170,
  headerName: '',
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

export function MgSupplierItemsTableWithCheckbox({
  items,
  totalItems,
  selection,
  language,
  page,
  pageSize,
  setPage,
  setPageSize,
  onRowClick,
}: Props & PaginationType) {
  const t = useProductsTFunction();
  const lastMgItemNumber = useRef<number | undefined>(items[0]?.mgItemNumber);
  const rowColor = useRef<string>(dataGridColorRowClass);

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  const onRowsSelectionHandler = (selections: GridRowSelectionModel) => {
    setSelectionModel(selections);
    const rowSelections: MgSupplierItemSearchResult[] = selections
      .map((selection) => items.find((item) => item.mgSupplierItemId === selection))
      .filter((selection): selection is MgSupplierItemSearchResult => selection !== undefined);
    selection?.selectItem(rowSelections);
  };

  useEffect(() => {
    if (
      selection?.selectedItems.length === 0 &&
      selectionModel.length !== selection?.selectedItems.length
    ) {
      setSelectionModel([]);
    }
  }, [selection?.selectedItems.length, selectionModel.length]);

  const handleSetPage = (page: number) => {
    setPage(page);
    selection?.selectItem([]);
  };

  const columns: GridColItem[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'primaryText',
        width: 330,
        headerName: t('products.mgItem.supplierItem.primaryText'),
        renderCell: ({ row }) => (
          <StackedTextCell
            topLine={<Typography fontWeight="600">{row.primaryText}</Typography>}
            bottomLine={row.secondaryText}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'mgItemAndFinfoNumber',
        width: 140,
        headerName: t('products.mgItem.number.mgItemAndFinfo'),
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.mgItemNumber} bottomLine={row.finfoNumber} />
        ),
      },
      {
        ...baseColumnProps,
        field: 'supplier',
        width: 250,
        headerName: t('products.supplier.name'),
        renderCell: ({ row }) => (
          <StackedTextCell
            topLine={row.participantAccountName}
            bottomLine={row.mgParticipantAccountId}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'isMainSupplier',
        headerName: t('products.mgItem.supplierItem.mainSupplier'),
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) =>
          row.mainSupplier ? (
            <Stack flexDirection="row" gap={1} justifyContent="center">
              <CheckIcon />
            </Stack>
          ) : (
            t('products.no')
          ),
      },
      {
        ...baseColumnProps,
        field: 'productGroup',
        width: 220,
        headerName: t('products.mgItem.supplierItem.bk04ProductCategory'),
        renderCell: ({ row }) => (
          <StackedTextCell
            topLine={row.bk04Varegruppe?.name}
            bottomLine={row.bk04Varegruppe?.groupNumber}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'discountGroup',
        width: 220,
        headerName: t('products.search.discountGroup.label'),
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.discountGroup?.name} bottomLine={row.discountGroup?.code} />
        ),
      },
      {
        ...baseColumnProps,
        field: 'inAssortment',
        headerName: t('products.mgItem.supplierItem.inAssortment'),
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) =>
          row.inAssortment ? (
            <Stack flexDirection="row" gap={1} justifyContent="center">
              <CheckIcon />
            </Stack>
          ) : (
            t('products.no')
          ),
      },
    ],
    [t]
  );

  const getRowClassName = useCallback(
    ({ row }: GridRowClassNameParams<MgSupplierItemSearchResult>) => {
      if (row.mgItemNumber !== lastMgItemNumber.current) {
        rowColor.current =
          rowColor.current === dataGridWhiteRowClass
            ? dataGridColorRowClass
            : dataGridWhiteRowClass;
        lastMgItemNumber.current = row.mgItemNumber;
      }

      return rowColor.current;
    },
    []
  );

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium<MgSupplierItemSearchResult>
          rows={items}
          columns={columns}
          getRowId={(row) => row.mgSupplierItemId}
          // @ts-ignore
          getRowClassName={getRowClassName}
          rowHeight={65}
          checkboxSelection
          disableColumnReorder
          pagination
          paginationMode="server"
          rowCount={totalItems}
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={(model) => {
            if (model.page !== page) handleSetPage(model.page);
            if (model.pageSize !== pageSize) setPageSize(model.pageSize);
          }}
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          onRowSelectionModelChange={(selection) => onRowsSelectionHandler(selection)}
          rowSelectionModel={selectionModel}
          rowSelection
          onRowClick={(params) => onRowClick && onRowClick(params.row.mgItemNumber)}
          getCellClassName={({ field }) =>
            clsx({
              [dataGridCellHoverClass]: !['__check__'].includes(field),
            })
          }
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  DATA_GRID_ID.MG_SUPPLIER_ITEMS_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
          }}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);
            saveHiddenDataGridColumnsToLocalStorage(
              DATA_GRID_ID.MG_SUPPLIER_ITEMS_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          slots={{
            pagination: () => <DataGridPagination />,
            columnMenu: CustomColumnMenu,
            noRowsOverlay: () => (
              <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
                <Typography variant="h3" color="text.secondary">
                  {t('products.mgItem.noItems')}
                </Typography>
              </Stack>
            ),
          }}
          slotProps={{
            ...columnsManagementSlotProps,
          }}
        />
      </Box>
    </Box>
  );
}
