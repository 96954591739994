import { PriceSuggestionConditionsDTO } from '../../dtos';
import { CalculusAreas } from '../CalculusAreas';
import { PriceSuggestionCondition } from './PriceSuggestionCondition';
import { SupplierPriceSuggestionCondition } from './SupplierPriceSuggestionConditions';

interface PriceSuggestionConditionsProps {
  assortmentPriceSuggestionConditions: PriceSuggestionCondition[];
  supplierPriceSuggestionConditions: SupplierPriceSuggestionCondition[];
}

export class PriceSuggestionConditions {
  assortmentPriceSuggestionConditions: PriceSuggestionCondition[];
  supplierPriceSuggestionConditions: SupplierPriceSuggestionCondition[];

  constructor({
    assortmentPriceSuggestionConditions,
    supplierPriceSuggestionConditions,
  }: PriceSuggestionConditionsProps) {
    this.assortmentPriceSuggestionConditions = assortmentPriceSuggestionConditions;
    this.supplierPriceSuggestionConditions = supplierPriceSuggestionConditions;
  }

  static fromDto = (dto: PriceSuggestionConditionsDTO): PriceSuggestionConditions =>
    new PriceSuggestionConditions({
      assortmentPriceSuggestionConditions: dto.assortmentConditions
        ? dto.assortmentConditions.map(PriceSuggestionCondition.fromDto)
        : [],
      supplierPriceSuggestionConditions: dto.supplierConditions
        ? dto.supplierConditions.map(SupplierPriceSuggestionCondition.fromDto)
        : [],
    });

  getType = () => CalculusAreas.PRICE_SUGGESTION;
}
