import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Brand, Department, Member, PriceList } from '@retail/auth/types';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { usePopupElement } from '@shared/hooks';
import { OrgUnitHeading } from './OrgUnitHeading';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  brand?: Brand;
  members: Member[];
  departments: Department[];
  priceLists: PriceList[];
  selectedBrand?: Brand;
  selectedMember?: Member;
  selectedDepartment?: Department;
  selectedPriceList?: PriceList;
  selectBrand: (brand: Brand) => void;
  selectMember: (member: Member) => void;
  selectDepartment: (department: Department) => void;
  selectPriceList: (priceList: PriceList) => void;
}

export const OrgUnitSelect: React.FC<Props> = ({
  brand,
  members,
  departments,
  priceLists,
  selectedBrand,
  selectedMember,
  selectedDepartment,
  selectedPriceList,
  selectBrand,
  selectMember,
  selectDepartment,
  selectPriceList,
}) => {
  const t = useAppTFunction();
  const { palette } = useTheme();
  const { menuElementProps, triggerElementProps } = usePopupElement();
  const selectedOrgUnitName = useMemo(() => {
    if (selectedBrand) {
      return selectedBrand.name;
    }
    if (selectedMember) {
      return selectedMember.name;
    }
    if (selectedPriceList) {
      return selectedPriceList.name;
    }
    return selectedDepartment?.name || '';
  }, [selectedBrand, selectedDepartment?.name, selectedMember, selectedPriceList]);

  const priceListForDepartment = useCallback(
    (departmentId: string) => {
      return priceLists.filter((priceList) => priceList.departmentId === departmentId);
    },
    [priceLists]
  );

  const [openDepartments, setOpenDepartments] = useState<Department['departmentId'][]>([]);

  const handleSetOpenDepartments = useCallback(
    (departmentId: string) => {
      if (openDepartments.includes(departmentId)) {
        setOpenDepartments(openDepartments.filter((department) => department !== departmentId));
      } else {
        setOpenDepartments([...openDepartments, departmentId]);
      }
    },
    [openDepartments]
  );
  return (
    <Fragment>
      <OrgUnitHeading
        name={selectedOrgUnitName}
        sx={{ cursor: 'pointer' }}
        endIcon={
          <ChevronLeft
            sx={{
              transform: menuElementProps.open ? 'rotate(90deg)' : 'rotate(-90deg)',
              transition: 'transform 0.2s ease-in-out',
              color: palette.common.black,
            }}
          />
        }
        {...triggerElementProps}
      />
      {
        <Menu {...menuElementProps} elevation={0}>
          {brand && (
            <Box pt={2}>
              <ListSubheader>{t('app.orgUnit.brand.singular')}</ListSubheader>
              <MenuItem
                key={brand.brandId}
                value={brand.brandId}
                onClick={() => selectBrand(brand)}
                selected={!!selectedBrand}
              >
                {brand.name}
              </MenuItem>
            </Box>
          )}
          {members.length > 0 && (
            <Box pt={2}>
              <ListSubheader>{t('app.orgUnit.member.plural')}</ListSubheader>
              {members.map((member) => (
                <MenuItem
                  key={member.memberId}
                  value={member.memberId}
                  onClick={() => selectMember(member)}
                  selected={member.memberId === selectedMember?.memberId}
                >
                  {member?.name}
                </MenuItem>
              ))}
            </Box>
          )}
          {departments.length > 0 && (
            <Box pt={2}>
              <ListSubheader>{t('app.orgUnit.department.plural')}</ListSubheader>
              {departments.map((department) => {
                const departmentPriceLists = priceListForDepartment(department.departmentId);
                return (
                  <Stack key={department.departmentId}>
                    <MenuItem
                      key={department.departmentId}
                      value={department.departmentId}
                      onClick={() => selectDepartment(department)}
                      selected={department.departmentId === selectedDepartment?.departmentId}
                      sx={{ py: departmentPriceLists.length > 0 ? 0.5 : 1.5 }}
                    >
                      {departmentPriceLists.length > 0 ? (
                        <Stack
                          flexDirection="row"
                          flexGrow={1}
                          gap={1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography>{department.name}</Typography>
                          <Tooltip title={t('app.orgUnit.priceList.singular')}>
                            <div>
                              <IconButton
                                onClick={(e) => {
                                  handleSetOpenDepartments(department.departmentId);
                                  e.stopPropagation();
                                }}
                                sx={{ zIndex: 1 }}
                              >
                                <ChevronRight
                                  sx={{
                                    transform: openDepartments.includes(department.departmentId)
                                      ? 'rotate(90deg)'
                                      : null,
                                    transition: 'transform 0.2s ease-in-out',
                                  }}
                                />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </Stack>
                      ) : (
                        department.name
                      )}
                    </MenuItem>
                    {departmentPriceLists.length > 0 &&
                      openDepartments.includes(department.departmentId) && (
                        <Box pt={2}>
                          <ListSubheader sx={{ pl: 6 }}>
                            {t('app.orgUnit.priceList.singular')}
                          </ListSubheader>
                          {priceListForDepartment(department.departmentId).map((priceList) => (
                            <MenuItem
                              key={priceList.assortmentId}
                              sx={{
                                pl: 6,
                                bgcolor: ({ palette }) => `${palette.common.white} !important`,
                                ':hover': {
                                  bgcolor: ({ palette }) => `${palette.divider} !important`,
                                },
                              }}
                              value={priceList.assortmentId}
                              onClick={() => selectPriceList(priceList)}
                              selected={priceList.assortmentId === selectedPriceList?.assortmentId}
                            >
                              {priceList.name}
                            </MenuItem>
                          ))}
                        </Box>
                      )}
                  </Stack>
                );
              })}
            </Box>
          )}
        </Menu>
      }
    </Fragment>
  );
};
