import { AppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';
import * as yup from 'yup';

const today = new Date();

export const fromDateSchema = (t: AppTFunction) =>
  yup.object({
    fromDate: yup
      .date()
      .required(t('products.mgItem.addItemsToAssortment.fromDate.validation.required'))
      .min(
        dayjs(today).subtract(1, 'day').toDate(),
        t('products.mgItem.addItemsToAssortment.fromDate.validation.min')
      ),
  });

export type FromDateSchema = yup.InferType<ReturnType<typeof fromDateSchema>>;
