import { PageLayout, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { Paper } from '@shared/components';
import { MyAssortmentContainer } from '@retail/my-assortment/containers';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';

export function MyAssortmentPage() {
  // Everything that includes conditions will use departmentId
  const { selectedPriceList, selectedDepartment } = useSelectedOrgUnit();
  const draftAssortmentId = (selectedDepartment?.draftAssortmentId ??
    selectedPriceList?.draftAssortmentId)!;

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper sx={{ display: 'flex', flexDirection: 'column', p: 0, height: '100%' }}>
        <SuspenseWithSentryErrorBoundary>
          <MyAssortmentContainer draftAssortmentId={draftAssortmentId} />
        </SuspenseWithSentryErrorBoundary>
      </Paper>
    </PageLayout>
  );
}
