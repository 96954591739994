import { CustomerMarkupConditionsDTO } from '../../dtos';
import { CalculusAreas } from '../CalculusAreas';
import { CustomerMarkupCondition } from './CustomerMarkupCondition';
import { SupplierCustomerMarkupCondition } from './SupplierCustomerMarkupCondition';

interface CustomerMarkupConditionsProps {
  assortmentMarkupConditions: CustomerMarkupCondition[];
  supplierMarkupConditions: SupplierCustomerMarkupCondition[];
}

export class CustomerMarkupConditions {
  assortmentMarkupConditions: CustomerMarkupCondition[];
  supplierMarkupConditions: SupplierCustomerMarkupCondition[];

  constructor({
    assortmentMarkupConditions,
    supplierMarkupConditions,
  }: CustomerMarkupConditionsProps) {
    this.assortmentMarkupConditions = assortmentMarkupConditions;
    this.supplierMarkupConditions = supplierMarkupConditions;
  }

  static fromDto = (dto: CustomerMarkupConditionsDTO): CustomerMarkupConditions =>
    new CustomerMarkupConditions({
      assortmentMarkupConditions: dto.assortmentConditions
        ? dto.assortmentConditions.map(CustomerMarkupCondition.fromDto)
        : [],
      supplierMarkupConditions: dto.supplierConditions
        ? dto.supplierConditions.map(SupplierCustomerMarkupCondition.fromDto)
        : [],
    });

  getType = () => CalculusAreas.MARKUP;
}
