import { MarketInsightPage } from '../MarketInsight';
import { useBrandAssortments } from '@retail/mgpdm/context';
import { pdmRoutes } from '@retail/mgpris/config';

export const BrandMarketInsightPage = () => {
  const { monitoringAssortments } = useBrandAssortments();

  return (
    <MarketInsightPage
      monitoringAssortments={monitoringAssortments}
      marketInsightRoute={pdmRoutes.marketInsight}
    />
  );
};
