import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { ExpandCircleDown } from '@mui/icons-material';
import { Chip } from '@shared/components';
import { useMemo } from 'react';
import { ImportState } from '@retail/markup/utils';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  importState: ImportState;
}

export function ImportStateValidation({ importState }: Props) {
  const t = useAppTFunction();

  const nrOfInvalidRows = useMemo(() => {
    return importState.invalidDataRowIndexes.length;
  }, [importState.invalidDataRowIndexes.length]);

  return (
    <Stack gap={1}>
      {importState.validDataRows.length > 0 && (
        <Alert
          severity="success"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1">
            {importState.validDataRows.length === 1
              ? t('markup.messages.validatedUploads.singular', {
                  count: importState.validDataRows.length,
                })
              : t('markup.messages.validatedUploads.plural', {
                  count: importState.validDataRows.length,
                })}
          </Typography>
        </Alert>
      )}
      {!!nrOfInvalidRows && (
        <Alert
          severity="error"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Stack gap={1}>
            <Typography variant="subtitle1">
              {t('markup.error.notValidated', {
                count: nrOfInvalidRows,
              })}
            </Typography>

            <Typography>{t('markup.error.notValidatedInfo')}</Typography>
            <StyledAccordion>
              <AccordionSummary expandIcon={<ExpandCircleDown />} id="mgItem">
                {nrOfInvalidRows === 1
                  ? t('markup.messages.rows.singular')
                  : t('markup.messages.rows.plural')}
              </AccordionSummary>

              <AccordionDetails>
                {importState.invalidDataRowIndexes.map((nr) => (
                  <Chip key={nr} backgroundColor="white" sx={{ mr: 1 }} label={nr} />
                ))}
              </AccordionDetails>
            </StyledAccordion>
          </Stack>
        </Alert>
      )}
    </Stack>
  );
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'inherit',
  border: `1px solid ${theme.palette.error.main}`,
}));
