import * as yup from 'yup';
import { CalculusTFunction } from '@retail/calculus/i18n';

export const conditionDatesSchema = (t: CalculusTFunction) =>
  yup.object({
    fromDate: yup.date().default(new Date()).required(t('calculus.error.requiredField')),
    toDate: yup.date().default(null).nullable().optional(),
  });

export type ConditionDatesSchema = yup.InferType<ReturnType<typeof conditionDatesSchema>>;
