import { IconButton, Stack, Tooltip } from '@mui/material';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { DeactivateIcon, EditButtonIcon, TrashIcon } from '@shared/custom-icons';

interface Props {
  editCondition: () => void;
  deactivateCondition?: () => void;
  deleteCondition?: () => void;
}

export function PriceSuggestionActions({
  editCondition,
  deactivateCondition,
  deleteCondition,
}: Props) {
  const t = useCalculusTFunction();
  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      {deactivateCondition && (
        <Tooltip title={t('calculus.deactivate')}>
          <IconButton onClick={deactivateCondition}>
            <DeactivateIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {deleteCondition && (
        <Tooltip title={t('calculus.delete')}>
          <IconButton onClick={deleteCondition}>
            <TrashIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t('calculus.edit')}>
        <IconButton onClick={editCondition}>
          <EditButtonIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
