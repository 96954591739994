import { FeatureContext, FeatureFlag, MgPrisFeature } from '@retail/mgpris/config-types';

interface EnabledProps {
  features: FeatureFlag[];
  name: MgPrisFeature;
  context: FeatureContext;
}

export const isFeatureEnabled = ({ features, name, context }: EnabledProps) => {
  const feature = features.find((f) => f.name === name);
  if (!feature) return false;
  if (typeof feature.enabled === 'boolean') return feature.enabled;
  return feature.enabled(context);
};
