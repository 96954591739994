import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { Header } from './Header';
import { MgpAppSidebar } from './MgpAppSidebar';
import { mgprisHeaderHeight } from '@shared/styles';
import { useSelectedOrgUnit, useUserBrands } from '@retail/app/stores/selected-context';
import { PdmAppSidebar } from './PdmAppSidebar';
import { CalculusDraftDrawer, DraftDrawer } from '../GlobalDrawer';
import { MgPdmLogo } from './MgPdmLogo';
import { MGPrisLogo } from './MGPrisLogo';

interface Props {
  hideDrawer?: boolean;
}

export function AppLayout({ children, hideDrawer = false }: PropsWithChildren<Props>) {
  const { selectedBrandContext } = useSelectedOrgUnit();
  const { selectedBrand } = useUserBrands();

  return (
    <Box display="flex" flexGrow="1">
      <Header appLogo={selectedBrandContext ? <MgPdmLogo /> : <MGPrisLogo />} />
      {selectedBrandContext ? <PdmAppSidebar /> : <MgpAppSidebar />}

      <Box display="flex" flexDirection="column" flexGrow="1" marginTop={mgprisHeaderHeight}>
        {!hideDrawer && (
          <SuspenseWithSentryErrorBoundary suspenseFallback={null} errorFallback={() => <></>}>
            {!selectedBrand.wholesaler && !selectedBrandContext && <DraftDrawer />}
            {selectedBrand.wholesaler && !selectedBrandContext && <CalculusDraftDrawer />}
          </SuspenseWithSentryErrorBoundary>
        )}
        <SuspenseWithSentryErrorBoundary>{children}</SuspenseWithSentryErrorBoundary>
      </Box>
    </Box>
  );
}
