import { Stack } from '@mui/material';
import { AddButton, CalculusActionPortal } from '@retail/components';
import { Supplier } from '@retail/suppliers/types';
import { OutlinedButton, UniversalFormProvider } from '@shared/components';
import { activateAgreementSchema } from '@retail/purchase-agreements/components';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useAgreements } from '@retail/purchase-agreements/context';
import {
  CalculusAreas,
  ConditionState,
  HierarchyLevels,
  PurchaseCondition,
} from '@retail/calculus/types';
import { ActivateAgreementDialog } from './ActivateAgreement';
import { ConditionsTableContainer } from './PurchaseConditions';
import { useDisclosure } from '@shared/hooks';
import { PriceComparisonDialogContainer } from './PriceList';
import { useMemo } from 'react';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { useLevelSearchParams } from '@retail/calculus/hooks';
import { useFetchAssortmentConditions } from '@retail/calculus/data-access';

interface Props {
  typesToShow: ConditionState;
  selectedSupplier: Supplier;
  isCreateOpen: boolean;
  toggleCreateDialog: () => void;
}

export function PurchaseContainer({
  typesToShow,
  selectedSupplier,
  isCreateOpen,
  toggleCreateDialog,
}: Props) {
  const t = useCalculusTFunction();

  const { agreements, standardAgreement } = useAgreements({
    supplierId: selectedSupplier.mgSupplierId,
  });
  const { isOpen: viewPriceComparison, onToggle: toggleViewPriceComparison } = useDisclosure(false);

  const { draftAssortmentId, baseAssortmentId } = useDepartmentAssortments();
  const { selectedProductGroupNumber, selectedMgItemId, selectedLevel } = useLevelSearchParams();

  const { data: draftConditionsDTO } = useFetchAssortmentConditions({
    assortmentId: typesToShow === ConditionState.ACTIVE ? baseAssortmentId : draftAssortmentId,
    conditionTypes: [CalculusAreas.PURCHASE],
    conditionState: typesToShow === ConditionState.DRAFT ? ConditionState.ALL : typesToShow,
    mgSupplierIds: [selectedSupplier.mgSupplierId],
    groupNumbers: selectedProductGroupNumber ? [selectedProductGroupNumber] : undefined,
    mgItemNumbers: selectedMgItemId ? [selectedMgItemId] : undefined,
    includeOwnerConditions: typesToShow !== ConditionState.DRAFT,
    includeAgreements:
      typesToShow === ConditionState.ALL ||
      (typesToShow === ConditionState.ACTIVE && selectedLevel === HierarchyLevels.supplier),
  });

  const purchaseConditionsDTO = useMemo(
    () =>
      draftConditionsDTO?.purchaseConditions
        .map((supplierCondition) => supplierCondition.conditions)
        .flat(),
    [draftConditionsDTO]
  );

  const purchaseConditions = useMemo(
    () => (purchaseConditionsDTO ?? []).map(PurchaseCondition.fromDto),
    [purchaseConditionsDTO]
  );
  const activePurchaseConditions = useMemo(
    () => purchaseConditions.filter((condition) => condition.operation !== 'DELETE'),
    [purchaseConditions]
  );

  return (
    <Stack flexGrow={1}>
      <CalculusActionPortal>
        <OutlinedButton size="small" color="secondary" onClick={toggleViewPriceComparison}>
          {t('calculus.actions.comparePriceLists')}
        </OutlinedButton>
        <AddButton onClick={toggleCreateDialog}>{t('calculus.newPurchaseCondition')}</AddButton>
      </CalculusActionPortal>

      <ConditionsTableContainer
        conditions={activePurchaseConditions}
        supplier={selectedSupplier}
        assortmentId={baseAssortmentId}
        agreements={agreements}
        openCreateDialog={toggleCreateDialog}
      />

      {isCreateOpen && (
        <UniversalFormProvider
          schema={activateAgreementSchema(t)}
          defaultValues={activateAgreementSchema(t).getDefault()}
        >
          <ActivateAgreementDialog
            open
            agreements={agreements}
            assortmentId={baseAssortmentId}
            onClose={toggleCreateDialog}
            supplierId={selectedSupplier.mgSupplierId}
          />
        </UniversalFormProvider>
      )}

      {viewPriceComparison && (
        <PriceComparisonDialogContainer
          open
          onClose={toggleViewPriceComparison}
          assortmentId={baseAssortmentId}
          agreements={agreements}
          defaultSelectedAgreements={standardAgreement && [standardAgreement]}
        />
      )}
    </Stack>
  );
}
