import { ProductsTFunction } from '@retail/products/i18n';
import * as yup from 'yup';

export const fromDates = ['today', 'later'] as const;
export type FromDateType = (typeof fromDates)[number];

export const assortmentItemFromDateSchema = (t: ProductsTFunction) =>
  yup.object({
    fromDateType: yup.mixed<FromDateType>().required(),
    fromDate: yup
      .date()
      .nullable()
      .when('fromDateType', {
        is: 'later',
        then: yup
          .date()
          .required(t('products.mgItem.addItemsToAssortment.fromDate.validation.required'))
          .min(new Date(), t('products.mgItem.addItemsToAssortment.fromDate.validation.min')),
        otherwise: yup.date().nullable(),
      }),
  });

export type AssortmentItemFromDateSchema = yup.InferType<
  ReturnType<typeof assortmentItemFromDateSchema>
>;
