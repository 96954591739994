import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { AddItemsTabs, ImportStateValidation } from '@retail/my-assortment/components';
import { useAssortmentImport } from '@retail/my-assortment/context';
import { DraftTabs, ItemsDTO } from '@retail/my-assortment/types';
import { isValidItem } from '@retail/my-assortment/utils';
import { ItemPriority, MgSupplierItemSearchResult } from '@retail/products/types';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useCallback, useEffect, useState } from 'react';
import { useAssortmentActions } from '@retail/mgpdm/context';
import { useController, useForm } from 'react-hook-form';
import { fromDateSchema, FromDateSchema } from '@retail/my-assortment/containers';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useToast } from '@retail/hooks';
import { InputDatePicker } from '@retail/components';
import { UploadItemsContainer } from '@retail/products/components';
import { MgSupplierItemSearchContainer } from '@retail/products/containers';
import { BrandAssortmentFunctionsWrapper } from './BrandAssortmentFunctionsWrapper';

const today = new Date();
const csvData = 'MgItem-nummer;MgSupplier-id;Prioritized';

interface Props {
  baseAssortmentId: number;
  closeDialog: () => void;
  resetFilters: () => void;
}

export function AddItemsToAssortment({ baseAssortmentId, closeDialog, resetFilters }: Props) {
  const t = useAppTFunction();
  const toast = useToast();

  const [checkedItems, setCheckedItems] = useState<MgSupplierItemSearchResult[]>([]);
  const [selectedTab, setSelectedTab] = useState<DraftTabs>(DraftTabs.ADD_ITEMS);

  const { control, handleSubmit } = useForm<FromDateSchema>({
    resolver: yupResolver(fromDateSchema(t)),
    mode: 'onSubmit',
    defaultValues: {
      fromDate: today,
    },
  });
  const fromDateController = useController({ control, name: 'fromDate' });

  const { updateAssortmentItems, updateItemsIsLoading } = useAssortmentActions();

  const submitCheckedItems = useCallback(
    (selectedItems: MgSupplierItemSearchResult[], validFrom?: string) => {
      const items: ItemsDTO[] = selectedItems.map((item) => ({
        mgSupplierId: item.mgParticipantAccountId,
        mgItemNumber: item.mgItemNumber,
        priority: ItemPriority.ZERO,
        validFrom,
      }));
      updateAssortmentItems(items)
        .then(() => {
          setCheckedItems([]);
          closeDialog();
        })
        .catch(() => toast.error(t('products.errors.generic')));
    },
    [closeDialog, t, toast, updateAssortmentItems]
  );

  const { importedItems, validationState, resetImportedAssortmentStates, loadingValidateItems } =
    useAssortmentImport({ assortmentId: baseAssortmentId });

  const onImportItems = useCallback(
    (validFrom?: string) => {
      const validItems: ItemsDTO[] = importedItems
        .filter((item) => isValidItem(item, validationState))
        .map((item) => ({
          ...item,
          validFrom,
        }));
      updateAssortmentItems(validItems).then(() => closeDialog());
    },
    [closeDialog, importedItems, updateAssortmentItems, validationState]
  );

  useEffect(() => {
    if (selectedTab !== DraftTabs.IMPORT) resetImportedAssortmentStates();
    if (selectedTab !== DraftTabs.ADD_ITEMS) {
      setCheckedItems([]);
      resetFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const onSubmit = handleSubmit(({ fromDate }) => {
    const validFrom = dayjs(fromDate).isSame(today, 'day') ? undefined : fromDate.toISOString();
    if (selectedTab === DraftTabs.ADD_ITEMS) {
      submitCheckedItems(checkedItems, validFrom);
    } else {
      onImportItems(validFrom);
    }
  });

  const importItemsDisabled =
    (selectedTab === DraftTabs.ADD_ITEMS && checkedItems.length === 0) ||
    (selectedTab === DraftTabs.IMPORT && importedItems.length === 0);

  return (
    <Dialog open onClose={closeDialog} fullScreen sx={{ padding: 4 }}>
      <DialogTitle>
        <AddItemsTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          includeCentral={false}
        />
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexGrow: 1 }}>
        <Stack display="flex" flexGrow={1}>
          {selectedTab === DraftTabs.ADD_ITEMS && (
            <BrandAssortmentFunctionsWrapper>
              {({ assortmentLinkPath, isLoading, assortmentId, monitoringAssortments }) => (
                <MgSupplierItemSearchContainer
                  assortmentLinkPath={assortmentLinkPath}
                  isLoading={isLoading}
                  assortmentId={assortmentId}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  monitoringAssortments={monitoringAssortments}
                  disableAddItemsToMonitoringAssortment
                />
              )}
            </BrandAssortmentFunctionsWrapper>
          )}

          {selectedTab === DraftTabs.IMPORT && (
            <Stack display="flex" flexGrow={1} justifyContent="center">
              <UploadItemsContainer
                isLarge
                loadingValidateItems={loadingValidateItems}
                csvData={csvData}
                resetImportedAssortmentStates={resetImportedAssortmentStates}
                renderValidation={<ImportStateValidation importedItems={importedItems} />}
              />
            </Stack>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack alignItems="flex-end" spacing={2}>
          {!importItemsDisabled && (
            <InputDatePicker
              {...fromDateController.field}
              error={fromDateController.fieldState.error?.message}
              label={t('products.mgItem.addItemsToAssortment.fromDateType.label.plural')}
              minDate={today}
              margin="none"
            />
          )}
          <Stack direction="row" spacing={2}>
            <TextButton onClick={closeDialog}>{t('myAssortment.cancel')}</TextButton>
            <ContainedButton
              color="secondary"
              disabled={importItemsDisabled}
              loading={loadingValidateItems || updateItemsIsLoading}
              onClick={onSubmit}
              size="small"
            >
              {selectedTab === DraftTabs.IMPORT
                ? t('myAssortment.actions.addApprovedItems')
                : t('myAssortment.actions.addItems')}
            </ContainedButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
