import { useMemo } from 'react';
import { useSelectedOrgUnit } from './SelectedOrgUnitProvider';

import { useMyOrgUnits } from './MyOrgUnitsProvider';
import { useUserBrands } from './BrandContext';

export const useMyPriceLists = () => {
  const { priceLists } = useMyOrgUnits();
  const { selectedBrand } = useUserBrands();
  const { selectedDepartment } = useSelectedOrgUnit();

  return useMemo(() => {
    if (selectedBrand.wholesaler && selectedDepartment) {
      return priceLists.filter(
        (priceList) => priceList.departmentId === selectedDepartment.departmentId
      );
    }
    return undefined;
  }, [priceLists, selectedBrand.wholesaler, selectedDepartment]);
};
