import {
  BodyLoader,
  draftActionsRowPortalId,
  SuspenseWithSentryErrorBoundary,
} from '@retail/components';
import { useDisclosure } from '@shared/hooks';
import { OutlinedButton } from '@shared/components';
import { DrawerIcon } from '@shared/custom-icons';
import { Paper, Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { GlobalBottomDrawer } from './GlobalBottomDrawer';
import { useAppTFunction } from '@retail/app/i18n';
import { DraftContainer } from '@retail/calculus/containers';
import { useFetchCalculusConditions } from '@retail/calculus/context';

// Summary drawer uses member draft, table uses department draft
export function CalculusDraftDrawer() {
  const t = useAppTFunction();
  const { transitions } = useTheme();

  const [showContent, setShowContent] = useState(false);

  const { isOpen, onToggle } = useDisclosure(false);

  const { conditionsLength, draftConditionsIsLoading } = useFetchCalculusConditions(isOpen);

  const showDraftDrawer = !!conditionsLength;

  useEffect(() => {
    conditionsLength === 0 && onToggle();
  }, [conditionsLength, onToggle]);

  useEffect(() => {
    let timeoutId: number | undefined;
    if (isOpen) {
      timeoutId = window.setTimeout(
        () => setShowContent(true),
        transitions.duration.leavingScreen + 200
      );
    } else {
      setShowContent(false);
    }
    return () => {
      timeoutId && window.clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    showDraftDrawer && (
      <GlobalBottomDrawer isOpen={isOpen || draftConditionsIsLoading} collapsedHeight={34}>
        <OutlinedButton
          sx={{ height: 28, alignSelf: 'center' }}
          size="small"
          onClick={onToggle}
          startIcon={
            <DrawerIcon
              orientation={isOpen ? 'down' : 'up'}
              sx={{ width: '16px', height: '16px' }}
            />
          }
        >
          {t('calculus.hasCalculusUpdates')}
        </OutlinedButton>

        {isOpen && (
          <Stack flexGrow={1} overflow="hidden">
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                mx: 2,
                overflow: 'auto',
              }}
            >
              {draftConditionsIsLoading || !showContent ? (
                <BodyLoader />
              ) : (
                <Stack flexGrow={1} bgcolor={({ palette }) => palette.grey[50]}>
                  <SuspenseWithSentryErrorBoundary>
                    <DraftContainer />
                  </SuspenseWithSentryErrorBoundary>
                </Stack>
              )}
            </Paper>

            <Stack
              m={2}
              spacing={2}
              justifyContent="flex-end"
              id={draftActionsRowPortalId}
              direction="row"
            />
          </Stack>
        )}
      </GlobalBottomDrawer>
    )
  );
}
