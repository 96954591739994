import { MonitoringAssortment } from '@retail/monitoring-assortment/types';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

export interface MonitoringAssortmentsProps {
  monitoringAssortments: MonitoringAssortment[];
}

export const withMonitoringAssortments =
  <ComponentProps extends MonitoringAssortmentsProps>(
    WrappedComponent: React.ComponentType<ComponentProps>
  ): React.FC<Omit<ComponentProps, 'monitoringAssortments'>> =>
  (props) => {
    const { monitoringAssortments } = useOutletContext<{
      monitoringAssortments: MonitoringAssortment[];
    }>();

    // @ts-ignore
    return <WrappedComponent monitoringAssortments={monitoringAssortments} {...props} />;
  };
