import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { HelpCenterResources } from '../types/HelpCenterLanguageResources';

export const useHelpCenterTranslation = () => useMessagesTranslation<HelpCenterResources>();

export type HelpCenterMessageKey = MessagePaths<HelpCenterResources>;

export const useHelpCenterTFunction = () => useHelpCenterTranslation().t;

export type HelpCenterTFunction = ReturnType<typeof useHelpCenterTFunction>;
