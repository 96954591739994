import { Supplier } from '@retail/suppliers/types';

export const lastSelectedSupplierItem = 'lastSelectedSupplier';

export const useSelectLocalStorageSupplier = () => {
  const lastSelectedSupplier = localStorage.getItem(lastSelectedSupplierItem);

  const setSelectedSupplier = (mgSupplierId?: Supplier['mgSupplierId']) =>
    localStorage.setItem(lastSelectedSupplierItem, String(mgSupplierId));

  return { lastSelectedSupplier, setSelectedSupplier };
};
