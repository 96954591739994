import { MarkupConditionsDTO } from '../../dtos';
import { CalculusAreas } from '../CalculusAreas';
import { SupplierMarkupCondition } from './SupplierMarkupCondition';
import { MarkupCondition } from './MarkupCondition';

interface MarkupConditionsProps {
  assortmentMarkupConditions: MarkupCondition[];
  supplierMarkupConditions: SupplierMarkupCondition[];
}

export class MarkupConditions {
  assortmentMarkupConditions: MarkupCondition[];
  supplierMarkupConditions: SupplierMarkupCondition[];

  constructor({ assortmentMarkupConditions, supplierMarkupConditions }: MarkupConditionsProps) {
    this.assortmentMarkupConditions = assortmentMarkupConditions;
    this.supplierMarkupConditions = supplierMarkupConditions;
  }

  static fromDto = (dto: MarkupConditionsDTO): MarkupConditions =>
    new MarkupConditions({
      assortmentMarkupConditions: dto.assortmentConditions
        ? dto.assortmentConditions.map(MarkupCondition.fromDto)
        : [],
      supplierMarkupConditions: dto.supplierConditions
        ? dto.supplierConditions.map(SupplierMarkupCondition.fromDto)
        : [],
    });

  getType = () => CalculusAreas.MARKUP;
}
