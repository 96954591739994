import { IconButton, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { ArrowBack, Clear } from '@mui/icons-material';
import { useHelpCenterTFunction } from '@retail/help-center/i18n';

interface Props {
  onClose: () => void;
  titleClassName?: string;
}

export const HelpCenter: React.FC<Props> = ({ onClose, titleClassName }) => {
  const t = useHelpCenterTFunction();

  return (
    <Paper variant="outlined" sx={{ width: '350px', height: '600px', padding: 1 }}>
      <Stack direction="row">
        <IconButton sx={{ visibilty: 'hidden', flex: 0 }} aria-label={t('helpCenter.goBack')}>
          <ArrowBack />
        </IconButton>
        <Typography
          variant="h3"
          component="h1"
          sx={{ flex: 1, textAlign: 'center', cursor: 'move' }}
          className={titleClassName}
        >
          {t('helpCenter.help')}
        </Typography>
        <IconButton onClick={onClose} sx={{ flex: 0 }} aria-label={t('helpCenter.close')}>
          <Clear />
        </IconButton>
      </Stack>
    </Paper>
  );
};
