import { Stack } from '@mui/material';
import { TextInputField } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';

import { useMarkupTFunction } from '@retail/markup/i18n';
import { EditMarkupSchema } from '@retail/markup/components';
import { ConditionDatesForm } from '@retail/calculus/components';
import { Percent } from '@mui/icons-material';
import { ChangeEventHandler, useCallback } from 'react';
import { calculateCoverageFromSalesFactor, calculateSalesFactorFromDG } from '@retail/utils';

export function EditMarkupForm() {
  const t = useMarkupTFunction();

  const { control } = useFormContext<EditMarkupSchema>();
  const nameController = useController({ name: 'markupName', control });
  const salesCoverageController = useController({ name: 'salesCoverage', control });
  const salesFactorController = useController({ name: 'salesFactor', control });

  const onSalesCoverageChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const value = e.target.value.replace(',', '.');
      if (Number(value)) {
        salesFactorController.field.onChange(calculateSalesFactorFromDG(Number(value)));
      }
      salesCoverageController.field.onChange(value);
    },
    [salesCoverageController.field, salesFactorController.field]
  );

  const onSalesFactorChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const value = e.target.value.replace(',', '.');
      if (Number(value)) {
        salesCoverageController.field.onChange(calculateCoverageFromSalesFactor(Number(value)));
      }
      salesFactorController.field.onChange(value);
    },
    [salesCoverageController.field, salesFactorController.field]
  );

  return (
    <form>
      <Stack width={343} gap={2} py={1}>
        <TextInputField
          {...nameController.field}
          error={nameController.fieldState.error?.message}
          required
          fullWidth
          labelWidth={145}
          labelDirection="row"
          margin="none"
          label={t('markup.markupName')}
          size="small"
          placeholder={t('markup.markupName')}
        />

        <TextInputField
          {...salesCoverageController.field}
          error={salesCoverageController.fieldState.error?.message}
          required
          fullWidth
          labelWidth={142}
          labelDirection="row"
          margin="none"
          label={t('markup.salesCoverage')}
          size="small"
          placeholder={t('markup.salesCoverage')}
          endAdornment={<Percent />}
          onChange={onSalesCoverageChange}
        />

        <TextInputField
          {...salesFactorController.field}
          error={salesFactorController.fieldState.error?.message}
          required
          fullWidth
          labelWidth={142}
          labelDirection="row"
          value={salesFactorController.field.value}
          onChange={onSalesFactorChange}
          margin="none"
          label={t('markup.salesFactor')}
          size="small"
          placeholder={t('markup.salesFactor')}
        />

        <ConditionDatesForm labelAlignment="column" />
      </Stack>
    </form>
  );
}
