import { useMemo } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { Image, StackedTextCell } from '@retail/components';
import {
  PriceSuggestionCondition,
  FreightCondition,
  HierarchyLevels,
  MarkupCondition,
  Operation,
  PurchaseCondition,
  CustomerMarkupCondition,
  CustomerMarkupsForPriceLists,
} from '@retail/calculus/types';
import { useAppTFunction } from '@retail/app/i18n';
import { getDataGridLocalization, MgPrisLocale } from '@retail/utils';
import { ConditionTypeChip } from './ConditionTypeChip';
import { InfinityIcon } from '@shared/custom-icons';
import dayjs from 'dayjs';
import { TrashIcon } from '@sanity/icons';
import { LevelChip } from '../LevelChip';
import { ChangeType } from './ChangeType';
import { isMarkupCondition, isPurchaseCondition } from '@retail/calculus/utils';
import { mestergruppenIconUri } from '@assets/images';

interface Props {
  condition: (
    | PurchaseCondition
    | FreightCondition
    | MarkupCondition
    | PriceSuggestionCondition
    | CustomerMarkupCondition
  )[];
  customerMarkupsForPriceLists?: CustomerMarkupsForPriceLists[];
  isLoading: boolean;
  language: string;
  handleDeleteCondition: (id: number, priceListAssortmentId?: number) => void;
}

export type ConditionRow =
  | PurchaseCondition
  | FreightCondition
  | MarkupCondition
  | PriceSuggestionCondition
  | CustomerMarkupCondition;

type GridRowCondition = GridColDef<ConditionRow>;

const baseColumnProps: Partial<GridRowCondition> = {
  width: 150,
  sortable: false,
  hideable: false,
  disableColumnMenu: true,
  headerAlign: 'center',
  align: 'center',
};

export const DraftDrawerTableContent = ({
  condition,
  customerMarkupsForPriceLists,
  isLoading,
  language,
  handleDeleteCondition,
}: Props) => {
  const t = useAppTFunction();

  const rows = useMemo(() => condition, [condition]);

  const columns: GridRowCondition[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'type',
        headerAlign: 'left',
        align: 'left',
        width: 180,
        headerName: t('purchaseAgreements.agreement.columns.type'),
        renderCell: ({ row, rowNode }) => {
          if (rowNode.type === 'group') return '';

          const customerMarkupsPriceListOrigin = customerMarkupsForPriceLists?.find(
            (customerMarkup) => customerMarkup.conditionIds?.includes(row.id)
          );
          return (
            <ConditionTypeChip
              calculusArea={row.getType()}
              height={customerMarkupsPriceListOrigin && 50}
              labelDetails={customerMarkupsPriceListOrigin?.priceListName}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'typeOfAction',
        headerAlign: 'left',
        align: 'left',
        width: 150,
        headerName: t('calculus.typeOfAction'),
        renderCell: ({ row, rowNode }) => {
          if (rowNode.type === 'group') return '';
          else {
            if (row.operation === Operation.DELETE) return t('calculus.removeCondition');
            else if (row.operation === Operation.UPSERT) return t('calculus.newCondition');

            return '-';
          }
        },
      },
      {
        ...baseColumnProps,
        field: 'level',
        headerName: t('calculus.level.changeLevel'),
        headerAlign: 'left',
        align: 'left',
        width: 180,
        renderCell: ({ row, rowNode }) => {
          if (rowNode.type === 'group') return null;

          const isPurchaseAgreement = isPurchaseCondition(row);

          return (
            <LevelChip
              bindingType={row.bindingType ?? (row.current ? HierarchyLevels.supplier : undefined)}
              isAgreement={!row.id}
              packageType={isMarkupCondition(row) ? row.markupPackageType : undefined}
              labelDetails={
                isPurchaseAgreement
                  ? row.standardAgreement && (
                      <Typography variant="body3" justifyContent="center">
                        <Stack flexDirection="row" gap={0.5} alignItems="center">
                          <Image src={mestergruppenIconUri} height={15} width={15} />
                          {t('purchaseAgreements.agreement.columns.standardAgreement')}
                        </Stack>
                      </Typography>
                    )
                  : undefined
              }
              active
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'itemOrLevelName',
        headerName: t('purchaseAgreements.agreement.columns.levelOrItemName'),
        headerAlign: 'left',
        align: 'left',
        renderCell: ({ row }) => {
          const prevLevelName = row.getPreviousCondition()?.getLevelName();
          const newLevelName = row.getLevelName();
          if (prevLevelName !== newLevelName) {
            return (
              <StackedTextCell
                topLine={newLevelName}
                bottomLine={prevLevelName}
                nullableBottomLine
                lineThroughBottomLine
              />
            );
          }
          return newLevelName;
        },
      },
      {
        ...baseColumnProps,
        field: 'changeType',
        headerName: t('calculus.changeType'),
        headerAlign: 'left',
        align: 'left',
        width: 180,
        renderCell: ({ row, rowNode }) => {
          if (rowNode.type === 'group') return null;
          return <ChangeType condition={row} />;
        },
      },
      {
        ...baseColumnProps,
        field: 'validFrom',
        headerName: t('calculus.activeFrom'),
        renderCell: ({ value, rowNode }) => {
          if (rowNode.type === 'group') return null;
          return value ? dayjs(value).format('ll') : '-';
        },
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        headerName: t('calculus.activeTo'),
        renderCell: ({ value, rowNode }) => {
          if (rowNode.type === 'group') return null;
          return value ? dayjs(value).format('ll') : <InfinityIcon />;
        },
      },
      {
        ...baseColumnProps,
        field: 'actions',
        flex: 1,
        headerName: '',
        renderCell: ({ row, rowNode }) => {
          if (rowNode.type === 'group') return null;

          const customerMarkupsPriceListOriginId = customerMarkupsForPriceLists?.find(
            (customerMarkup) => customerMarkup.conditionIds?.includes(row.id)
          )?.priceListAssortmentId;

          return (
            <IconButton
              disabled={isLoading}
              onClick={() => handleDeleteCondition(row.id, customerMarkupsPriceListOriginId)}
            >
              <TrashIcon fontSize="25px" />
            </IconButton>
          );
        },
      },
    ],
    [customerMarkupsForPriceLists, handleDeleteCondition, isLoading, t]
  );

  return (
    <DataGridPremium<ConditionRow>
      getRowId={(row) => row.id}
      columns={columns}
      rows={rows}
      rowHeight={70}
      disableRowSelectionOnClick
      disableColumnResize
      disableColumnReorder
      hideFooter
      localeText={getDataGridLocalization(language as MgPrisLocale)}
      groupingColDef={{ hideDescendantCount: true }}
      slots={{
        noRowsOverlay: () => (
          <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
            <Typography variant="h3" color="text.secondary">
              {t('calculus.messages.noUpdates')}
            </Typography>
          </Stack>
        ),
      }}
      sx={{ '.MuiDataGrid-row:hover': { cursor: 'auto' } }}
    />
  );
};
