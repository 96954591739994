import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { AddButton } from '@retail/components';

interface Props {
  onClick: () => void;
}

export const AddItemsButton = ({ onClick }: Props) => {
  const t = useMyAssortmentTFunction();

  return <AddButton onClick={onClick}>{t('myAssortment.actions.addItems')}</AddButton>;
};
