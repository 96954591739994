import { fetchFile, QueryOptions } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { useMutateMPEFetch, useSelectedOrgUnitHeader } from '@retail/app/hooks';
import { TotalFileDownloadDTO } from '@retail/export/types';
import { buildUrl } from '@shared/utils';
import { mpeApi } from '@retail/mgpris/config';
import { useMutation } from '@tanstack/react-query';

export interface SuppliersSearchParams {
  assortmentId: number;
  from?: string;
}

export function useFetchTotalFileDownloadStatus(
  searchParams: SuppliersSearchParams,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<TotalFileDownloadDTO>({
    method: 'GET',
    token,
    url: (from?: string) =>
      buildUrl({
        base: mpe.url,
        route: mpeApi.export.summary.getFullLinkPath({
          assortmentId: searchParams.assortmentId,
        }),
        searchParams: {
          from,
        },
      }),
    ...queryOptions,
  });
}

interface ExportTotalFileProps {
  assortmentId: number;
  from?: string;
}

export const useFetchExportTotalFileZip = () => {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  return useMutation(({ assortmentId, from }: ExportTotalFileProps) =>
    fetchFile({
      url: buildUrl({
        base: mpe.url,
        route: mpeApi.export.downloadZip.getFullLinkPath({
          assortmentId,
        }),
        searchParams: {
          from,
        },
      }),
      token,
      method: 'GET',
      headers: selectedOrgUnitHeader,
    })
  );
};

export const useFetchExportTotalFileCsv = () => {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  return useMutation(({ assortmentId, from }: ExportTotalFileProps) =>
    fetchFile({
      url: buildUrl({
        base: mpe.url,
        route: mpeApi.export.downloadCSV.getFullLinkPath({
          assortmentId,
        }),
        searchParams: {
          from,
        },
      }),
      token,
      method: 'GET',
      headers: selectedOrgUnitHeader,
    })
  );
};
