import {
  AssortmentImportState,
  ImportColumnsError,
  ImportStatus,
  ItemCodes,
  ItemsCodeLists,
  SpreadsheetStructure,
} from '@retail/monitoring-assortment/types';
import { itemsFileAtom, itemsSpreadsheetAtom } from '@retail/products/utils';
import { zip } from 'lodash';
import { atom, useRecoilValue, useResetRecoilState } from 'recoil';

export const importStepAtom = atom<number>({
  key: 'importAssortmentStepAtom',
  default: 0,
});

export const assortmentSpreadsheetValidatedAtom = atom<boolean>({
  key: 'monitoringAssortmentSpreadsheetValidatedAtom',
  default: false,
});

export const assortmentSpreadsheetValidationAtom = atom<ItemsCodeLists | undefined>({
  key: 'monitoringAssortmentSpreadsheetValidation',
  default: undefined,
});

const recognizedFinfoNrHeadings = [
  'finfo-nummer',
  'finfo nummer',
  'finfo-nr',
  'finfo nr',
  'finfonr',
];
const recognizedMgItemNrHeadings = [
  'mg-item-nummer',
  'mg-item nummer',
  'mgitem-nummer',
  'mgitem nummer',
  'mgitem-nr',
  'mgitem nr',
  'mgitemnr',
  'mgitemNr',
];
const recognizedGtinHeadings = ['gtin', 'gtin-nummer', 'gtin nummer', 'gtin-nr', 'gtin nr'];

const getIsRecognizedHeaderPredicate = (header: string) => (recHeading: string) =>
  header.toLowerCase().includes(recHeading);

export const getColumnHeader = (header: string) =>
  recognizedFinfoNrHeadings.find(getIsRecognizedHeaderPredicate(header)) ||
  recognizedGtinHeadings.find(getIsRecognizedHeaderPredicate(header)) ||
  recognizedMgItemNrHeadings.find(getIsRecognizedHeaderPredicate(header));

const getNumbersForColumn = (
  spreadsheet: SpreadsheetStructure,
  recognizedColumnHeadings: string[]
) => {
  const { headerRow, dataRows } = spreadsheet;
  const columnIndex = headerRow.findIndex((header) =>
    recognizedColumnHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
  );
  if (columnIndex < 0) {
    return undefined;
  }
  const column = dataRows
    .map((row) => row[columnIndex])
    .map((nr) => (Number(nr) ? Number(nr) : undefined));
  const numbers = column.filter((nr) => !!nr) as number[];
  return { numbers, column };
};

export const useResetImportedAssortmentState = () => {
  const resetSpreadsheet = useResetRecoilState(itemsSpreadsheetAtom);
  const resetSpreadsheetValidation = useResetRecoilState(assortmentSpreadsheetValidationAtom);
  const resetSpreadsheetValidated = useResetRecoilState(assortmentSpreadsheetValidatedAtom);
  const resetAcceptedFile = useResetRecoilState(itemsFileAtom);

  const resetImportedAssortmentStates = () => {
    resetSpreadsheet();
    resetSpreadsheetValidation();
    resetSpreadsheetValidated();
    resetAcceptedFile();
  };

  return resetImportedAssortmentStates;
};

export type IdentifiableColumn = 'finfo' | 'gtin' | 'mgItem';

interface Props {
  columnsToRetrieve: Set<IdentifiableColumn>;
}

export const useImportedAssortmentState = ({ columnsToRetrieve }: Props): AssortmentImportState => {
  const spreadsheet = useRecoilValue(itemsSpreadsheetAtom);

  if (!spreadsheet) {
    return { status: 'none' };
  }

  const finfoNumbers = columnsToRetrieve.has('finfo')
    ? getNumbersForColumn(spreadsheet, recognizedFinfoNrHeadings)
    : undefined;
  const gtinNumbers = columnsToRetrieve.has('gtin')
    ? getNumbersForColumn(spreadsheet, recognizedGtinHeadings)
    : undefined;
  const mgItemNumbers = columnsToRetrieve.has('mgItem')
    ? getNumbersForColumn(spreadsheet, recognizedMgItemNrHeadings)
    : undefined;

  const columns = zip(
    mgItemNumbers ? mgItemNumbers.column : [],
    finfoNumbers ? finfoNumbers.column : [],
    gtinNumbers ? gtinNumbers?.column : []
  );
  const importedItems = columns.map((item) => {
    return {
      mgItemNumber: item[0],
      finfoNumber: item[1],
      gtinCode: item[2],
    } as ItemCodes;
  });

  const columnsState: ImportColumnsError | undefined =
    !finfoNumbers && !gtinNumbers && !mgItemNumbers
      ? 'columnsNotFound'
      : (!finfoNumbers || !!finfoNumbers) &&
        (!gtinNumbers || !!gtinNumbers) &&
        (!mgItemNumbers || !!mgItemNumbers)
      ? 'noNumbersFound'
      : undefined;

  const status: ImportStatus = [
    finfoNumbers?.numbers,
    gtinNumbers?.numbers,
    mgItemNumbers?.numbers,
  ].some((numbers) => numbers?.length)
    ? 'success'
    : 'error';
  return {
    status,
    columnsState,
    finfoNumbers: finfoNumbers?.numbers,
    gtinNumbers: gtinNumbers?.numbers,
    mgItemNumbers: mgItemNumbers?.numbers,
    importedItems,
  };
};
