import { atom, useRecoilValue, useResetRecoilState } from 'recoil';
import { Supplier } from '@retail/suppliers/types';
import dayjs from 'dayjs';
import {
  importedMarkupConditionsFileAtom,
  importedMarkupConditionsSpreadsheetAtom,
} from './importMarkupConditions';

export type UploadedMarkup = {
  mgSupplierId: number;
  groupNumber?: string; // productGroup
  groupCode?: string; // discountGroup
  mgItemNumber?: number;
  validFrom?: Date;
  salesFactor?: number;
  salesPrice?: number;
  incMva?: boolean;
};

interface MarkupConditionValidation {
  invalidMarkupIndexes: Array<number>;
}

export interface ImportState {
  validDataRows: UploadedMarkup[];
  invalidDataRowIndexes: number[];
}

export const markupConditionsSpreadsheetValidatedAtom = atom<boolean>({
  key: 'importedMarkupSpreadsheetValidatedAtom',
  default: false,
});

export const markupConditionsSpreadsheetValidationAtom = atom<
  MarkupConditionValidation | undefined
>({
  key: 'importedMarkupSpreadsheetValidation',
  default: undefined,
});

const recognizedMgItemNrHeadings = [
  'mg-item-number',
  'mg-item number',
  'mgitem number',
  'mgitem-number',
  'mgitem number',
  'mg-item-nummer',
  'mg-item nummer',
  'mgitem-nummer',
  'mgitem nummer',
  'mgitem-nr',
  'mgitem nr',
  'mgitemnr',
];
const recognizedGroupNumberHeadings = [
  'gruppenummer',
  'gruppe-nummer',
  'gruppe nummer',
  'gruppenummer',
  'group-number',
  'group number',
  'groupnumber',
];
const recognizedGroupCodesHeadings = [
  'gruppe-kode',
  'gruppe kode',
  'gruppekode',
  'group-codes',
  'group codes',
  'groupcodes',
];
export const recognizedValidFromHeadings = [
  'fromdate',
  'from-date',
  'from date',
  'gyldigfra',
  'gyldig fra',
  'gyldig-fra',
];
const recognizedSalesFactorHeadings = [
  'salgs-faktor',
  'salgs faktor',
  'salgsfaktor',
  'sales-factor',
  'sales factor',
  'salesfactor',
];
const recognizedSalesPriceHeadings = [
  'salgs-pris',
  'salgs pris',
  'salgspris',
  'sales-price',
  'sales price',
  'salesprice',
];
const recognizedIncMvaHeadings = ['ink-mva', 'ink mva', 'inkmva', 'inc-mva', 'inc mva', 'incmva'];

const getIsRecognizedHeaderPredicate = (header: string) => (recHeading: string) =>
  header.toLowerCase().includes(recHeading);

export const useImportedMarkupConditionsState = (
  mgSupplierId: Supplier['mgSupplierId']
): ImportState => {
  const spreadsheet = useRecoilValue(importedMarkupConditionsSpreadsheetAtom);

  const validDataRows: UploadedMarkup[] = [];
  const invalidDataRowIndexes: number[] = [];

  if (!spreadsheet) {
    return {
      validDataRows,
      invalidDataRowIndexes,
    };
  }

  const mgItemNumberColumnIndex = spreadsheet?.headerRow.findIndex((header) =>
    recognizedMgItemNrHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
  );
  const groupNumberColumnIndex = spreadsheet?.headerRow.findIndex((header) =>
    recognizedGroupNumberHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
  );
  const groupCodeColumnIndex = spreadsheet?.headerRow.findIndex((header) =>
    recognizedGroupCodesHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
  );

  const validFromColumnIndex = spreadsheet?.headerRow.findIndex((header) =>
    recognizedValidFromHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
  );
  const salesFactorColumnIndex = spreadsheet?.headerRow.findIndex((header) =>
    recognizedSalesFactorHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
  );
  const salesPriceColumnIndex = spreadsheet?.headerRow.findIndex((header) =>
    recognizedSalesPriceHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
  );
  const incMvaColumnIndex = spreadsheet?.headerRow.findIndex((header) =>
    recognizedIncMvaHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
  );

  spreadsheet?.dataRows.forEach((row, index) => {
    const currentIndex = index + 2;

    const mgItemNumber = row.at(mgItemNumberColumnIndex)
      ? Number(row.at(mgItemNumberColumnIndex))
      : undefined;
    const validFrom = row[validFromColumnIndex]
      ? dayjs(`${row.at(validFromColumnIndex)}`, 'YYYY-MM-DD')
      : undefined;
    const salesFactor = row.at(salesFactorColumnIndex)
      ? Number(row.at(salesFactorColumnIndex))
      : undefined;
    const salesPrice = row.at(salesPriceColumnIndex)
      ? Number(row.at(salesPriceColumnIndex))
      : undefined;
    const salesFactorIsValid =
      salesFactor === undefined ||
      (!Number.isNaN(salesFactor) && salesFactor >= 1 && salesFactor <= 10);

    if (mgItemNumber !== undefined && (Number.isNaN(mgItemNumber) || mgItemNumber <= 0)) {
      invalidDataRowIndexes.push(currentIndex);
      return;
    }

    if (validFrom && !validFrom.isValid()) {
      invalidDataRowIndexes.push(currentIndex);
      return;
    }

    if (!salesFactorIsValid) {
      invalidDataRowIndexes.push(currentIndex);
      return;
    }

    if (salesFactor === undefined && salesPrice === undefined) {
      invalidDataRowIndexes.push(currentIndex);
      return;
    }

    const groupNumber = row[groupNumberColumnIndex]
      ? Number(row[groupNumberColumnIndex]) < 10
        ? `0${row[groupNumberColumnIndex]}`
        : String(row[groupNumberColumnIndex])
      : undefined;

    validDataRows.push({
      mgSupplierId,
      groupNumber,
      groupCode: row[groupCodeColumnIndex] ? String(row[groupCodeColumnIndex]) : undefined,
      mgItemNumber,
      validFrom: row[validFromColumnIndex] ? new Date(row[validFromColumnIndex]) : new Date(),
      salesFactor,
      salesPrice,
      incMva: !!row[incMvaColumnIndex],
    });
  });

  return {
    validDataRows,
    invalidDataRowIndexes,
  };
};

export const useResetImportMarkupConditionsState = () => {
  const resetSpreadsheet = useResetRecoilState(importedMarkupConditionsSpreadsheetAtom);
  const resetSpreadsheetValidation = useResetRecoilState(markupConditionsSpreadsheetValidationAtom);
  const resetSpreadsheetValidated = useResetRecoilState(markupConditionsSpreadsheetValidatedAtom);
  const resetAcceptedFile = useResetRecoilState(importedMarkupConditionsFileAtom);

  const resetImportedAssortmentStates = () => {
    resetSpreadsheet();
    resetSpreadsheetValidation();
    resetSpreadsheetValidated();
    resetAcceptedFile();
  };

  return resetImportedAssortmentStates;
};

export const isValidItem = (validationState: MarkupConditionValidation | undefined) => {
  return !validationState?.invalidMarkupIndexes.length;
};
