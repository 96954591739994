import { useLanguageSelector } from '@retail/app/hooks';
import { useCalculusConditionActions } from '@retail/calculus/context';
import { useFetchAssortmentConditions } from '@retail/calculus/data-access';
import { useLevelSearchParams } from '@retail/calculus/hooks';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { CalculusAreas, ConditionState, PriceSuggestionCondition } from '@retail/calculus/types';
import { DeleteDialog } from '@retail/components';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import {
  ChangeSelectedPriceTypeForCondition,
  DeactivatePriceSuggestionConditionDialog,
  PriceSuggestionConditionsTable,
} from '@retail/price-suggestion/components';
import { usePriceSuggestionActions } from '@retail/price-suggestion/context';
import { PriceSuggestionTypeWithCount } from '@retail/price-suggestion/types';
import { Supplier } from '@retail/suppliers/types';
import { useMemo, useState } from 'react';

interface Props {
  supplier: Supplier;
  typesToShow: ConditionState;
  priceSuggestionTypes: PriceSuggestionTypeWithCount[];
  openCreateDialog: () => void;
}

export function PriceSuggestionConditionsTableContainer({
  supplier,
  typesToShow,
  priceSuggestionTypes,
  openCreateDialog,
}: Props) {
  const t = useCalculusTFunction();
  const { language } = useLanguageSelector();

  const { draftAssortmentId, baseAssortmentId } = useDepartmentAssortments();
  const { handleDeleteAssortmentConditions } = useCalculusConditionActions();
  const { activateOrChangeCondition, deactivateCondition } = usePriceSuggestionActions({
    mgSupplierId: supplier.mgSupplierId,
  });
  const { selectedProductGroupNumber, selectedMgItemId } = useLevelSearchParams();

  const { data: draftConditionsDTO } = useFetchAssortmentConditions({
    assortmentId: typesToShow === ConditionState.ACTIVE ? baseAssortmentId : draftAssortmentId,
    conditionTypes: [CalculusAreas.PRICE_SUGGESTION],
    conditionState: typesToShow === ConditionState.DRAFT ? ConditionState.ALL : typesToShow,
    mgSupplierIds: [supplier.mgSupplierId],
    groupNumbers: selectedProductGroupNumber ? [selectedProductGroupNumber] : undefined,
    mgItemNumbers: selectedMgItemId ? [selectedMgItemId] : undefined,
    includeOwnerConditions: typesToShow !== ConditionState.DRAFT,
  });

  const priceSuggestionConditionsDTO = useMemo(
    () =>
      draftConditionsDTO?.priceSuggestionConditions?.supplierConditions
        .map((supplierCondition) => supplierCondition.conditions)
        .flat(),
    [draftConditionsDTO]
  );

  const priceSuggestionConditions = useMemo(
    () => (priceSuggestionConditionsDTO ?? []).map(PriceSuggestionCondition.fromDto),
    [priceSuggestionConditionsDTO]
  );

  const activePriceSuggestionConditions = useMemo(
    () => priceSuggestionConditions.filter((condition) => condition.operation !== 'DELETE'),
    [priceSuggestionConditions]
  );

  const [conditionForEdit, setConditionForEdit] = useState<PriceSuggestionCondition | undefined>(
    undefined
  );
  const [conditionIdToDelete, setConditionIdToDelete] = useState<undefined | number>(undefined);
  const [conditionForDeactivation, setConditionForDeactivation] = useState<
    PriceSuggestionCondition | undefined
  >(undefined);

  return (
    <>
      <PriceSuggestionConditionsTable
        priceSuggestionConditions={activePriceSuggestionConditions}
        noPriceSuggestionTypes={priceSuggestionTypes.length === 0}
        language={language}
        editCondition={setConditionForEdit}
        deleteCondition={setConditionIdToDelete}
        deactivateCondition={setConditionForDeactivation}
        openCreateDialog={openCreateDialog}
      />
      {conditionIdToDelete && (
        <DeleteDialog
          isOpen
          dialogTitle={t('calculus.actions.deleteFromDraft')}
          message={t('calculus.actions.confirmRemoval')}
          onDelete={() => handleDeleteAssortmentConditions([conditionIdToDelete])}
          onClose={() => setConditionIdToDelete(undefined)}
        />
      )}
      {conditionForDeactivation && (
        <DeactivatePriceSuggestionConditionDialog
          isOpen
          onClose={() => setConditionForDeactivation(undefined)}
          conditionToDeactivate={conditionForDeactivation}
          deactivateCondition={deactivateCondition}
        />
      )}
      {conditionForEdit && (
        <ChangeSelectedPriceTypeForCondition
          open
          onClose={() => setConditionForEdit(undefined)}
          conditionForEdit={conditionForEdit}
          changeCondition={activateOrChangeCondition}
          priceSuggestionTypes={priceSuggestionTypes}
        />
      )}
    </>
  );
}
