import { Stack, Typography } from '@mui/material';
import {
  FilterOptions,
  MgSupplierItemSearchResult,
  SetFilterOptions,
} from '@retail/products/types';
import { Dispatch, SetStateAction } from 'react';
import { Pagination as PaginationType } from '@shared/hooks';
import { useProductsTFunction } from '@retail/products/i18n';
import { ToggleFiltersButton } from '@retail/components';
import { MgSupplierItemsTableWithCheckbox } from '../MgSupplierItemsTableWithCheckbox';
import { SearchFilterChips } from '../../../SearchFilter';
import { AddItemsMenu } from '../AddItemsMenu';
import { useTranslation } from 'react-i18next';

export interface MgSupplierItemSearchProps extends PaginationType {
  totalItemsCount: number;
  supplierItems: MgSupplierItemSearchResult[];
  checkedItems: MgSupplierItemSearchResult[];
  showFilters: boolean;
  resetFilters: () => void;
  toggleFilters: () => void;
  searchFilters: FilterOptions;
  setCheckedItems: Dispatch<SetStateAction<MgSupplierItemSearchResult[]>>;
  setSearchFilters: SetFilterOptions;
  onItemRowClick?: (mgItemNumber: string) => void;
  addItemsToAssortment?: () => void;
  addItemsToMonitoringAssortment?: () => void;
  isAddingItemsToAssortment: boolean;
}

export function MgSupplierItemSearch({
  totalItemsCount,
  supplierItems,
  checkedItems,
  pageSize,
  page,
  showFilters,
  searchFilters,
  setSearchFilters,
  resetFilters,
  toggleFilters,
  setCheckedItems,
  setPage,
  setPageSize,
  onItemRowClick,
  addItemsToAssortment,
  addItemsToMonitoringAssortment,
  isAddingItemsToAssortment,
}: MgSupplierItemSearchProps) {
  const t = useProductsTFunction();
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Stack flexGrow={1}>
      <Stack flexDirection="row" justifyContent="end" alignItems="center" p={4} pt={0} gap={1}>
        <Typography>{t('products.search.result', { count: totalItemsCount })}</Typography>
        <Typography flex={1} textAlign="center">
          {t('products.mgItem.selectedItems', {
            selected: checkedItems.length,
            total: supplierItems.length,
          })}
        </Typography>
        <AddItemsMenu
          onAddItemsToAssortment={addItemsToAssortment}
          onAddItemsToMonitoringAssortment={addItemsToMonitoringAssortment}
          disabled={checkedItems.length === 0}
          isLoading={isAddingItemsToAssortment}
        />

        {!showFilters && (
          <ToggleFiltersButton showFilters={showFilters} toggleFilters={toggleFilters} />
        )}
      </Stack>
      <SearchFilterChips
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        resetFilters={resetFilters}
      />
      <MgSupplierItemsTableWithCheckbox
        items={supplierItems}
        totalItems={totalItemsCount}
        language={language}
        selection={{ selectedItems: checkedItems, selectItem: setCheckedItems }}
        pageSize={pageSize}
        page={page}
        setPage={setPage}
        setPageSize={setPageSize}
        onRowClick={onItemRowClick}
      />
    </Stack>
  );
}
