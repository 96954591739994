import { useMutatePostConditions } from '@retail/calculus/data-access';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useToast } from '@retail/hooks';
import { DeactivateCondition } from '@retail/markup/types';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

interface Props {
  mgSupplierId?: number;
}

export function useDeactivateMarkup({ mgSupplierId }: Props) {
  const t = useCalculusTFunction();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { draftAssortmentId } = useDepartmentAssortments();

  const invalidateMarkups = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['product-groups-hierarchy'] });
  }, [queryClient]);

  const { mutateAsync: mutatePostMarkupChanges, isLoading: isLoadingPostChanges } =
    useMutatePostConditions({ assortmentIds: [draftAssortmentId] });

  const deactivateMarkupCondition = useCallback(
    (deactivateCondition: DeactivateCondition) => {
      const condition = {
        mgSupplierConditions: deactivateCondition.mgSupplierLevel
          ? [{ validFrom: new Date().toISOString(), markup: {} }]
          : undefined,
        productGroupConditions: deactivateCondition.groupNumber
          ? [
              {
                groupNumber: deactivateCondition.groupNumber,
                validFrom: new Date().toISOString(),
                markup: {},
              },
            ]
          : undefined,
        discountGroupConditions: deactivateCondition.discountGroupCode
          ? [
              {
                groupCode: deactivateCondition.discountGroupCode,
                validFrom: new Date().toISOString(),
                markup: {},
              },
            ]
          : undefined,
        mgSupplierItemConditions: deactivateCondition.mgItemNumber
          ? [
              {
                mgItemNumber: deactivateCondition.mgItemNumber,
                validFrom: new Date().toISOString(),
                gtinCode: deactivateCondition.gtin,
                markup: {},
              },
            ]
          : undefined,
      };
      mutatePostMarkupChanges({
        body: {
          markupConditions: {
            assortmentConditions: !mgSupplierId
              ? [{ validFrom: new Date().toISOString(), markup: {} }]
              : [],
            supplierConditions: mgSupplierId ? [{ mgSupplierId, ...condition }] : [],
          },
        },
      })
        .then(() => {
          toast.success(t('calculus.actions.conditionDeleteInDrawer'));
          invalidateMarkups();
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('calculus.error.generic'));
        });
    },
    [invalidateMarkups, mgSupplierId, mutatePostMarkupChanges, t, toast]
  );

  return {
    mutatePostMarkupChanges,
    deactivateMarkupCondition,
    isLoadingPostChanges,
  };
}
