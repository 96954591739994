import {
  CalculusTabOptions,
  ConditionState,
  ProductGroup,
  SupplierWithProductGroups,
} from '@retail/calculus/types';
import { useCallback, useMemo, useState } from 'react';
import { SelectMgItem, TypesToggleButton } from '@retail/calculus/components';
import { useLevelSearchParams } from '@retail/calculus/hooks';
import { ProductGroupFilter, SideFilters } from '@retail/components';
import { useAppTFunction } from '@retail/app/i18n';
import { useDisclosure } from '@shared/hooks';
import { Stack, Typography } from '@mui/material';
import { OverGroup, ProductGroup as BaseProductGroup } from '@retail/products/types';
import { useFetchSelectedLevelOptions } from '@retail/calculus/context';

interface Props {
  supplierWithProductGroups: SupplierWithProductGroups;
  selectedProductGroup: ProductGroup | null;
  calculusArea: CalculusTabOptions;
  conditionState: ConditionState;
  showFilters: boolean;
  selectProductGroupId: (groupNumber: string | null) => void;
  closeFilters: () => void;
  setConditionState: (state: ConditionState) => void;
}

export function CalculusFilters({
  supplierWithProductGroups,
  selectedProductGroup,
  calculusArea,
  conditionState,
  showFilters,
  selectProductGroupId,
  closeFilters,
  setConditionState,
}: Props) {
  const t = useAppTFunction();

  const baseProductGroups = supplierWithProductGroups.productGroups.map(OverGroup.fromDto);
  const flattenedProductGroups = SupplierWithProductGroups.flatten(
    supplierWithProductGroups.productGroups
  );

  const { selectLevel, selectedMgItemId } = useLevelSearchParams();
  const { isOpen: showProductGroups, onToggle: toggleShowProductGroups } = useDisclosure(true);

  const [mgSupplierItemSearch, setMgSupplierItemSearch] = useState('');

  const { mgSupplierItems = [], isLoadingFetchItems } = useFetchSelectedLevelOptions({
    supplierId: supplierWithProductGroups.mgSupplierId,
    itemSearch: mgSupplierItemSearch,
  });

  const getCount = useCallback(
    (productGroup: BaseProductGroup) => {
      const currentProductGroup = flattenedProductGroups.find(
        (pg) => pg.groupNumber === productGroup.groupNumber
      );
      switch (calculusArea) {
        case CalculusTabOptions.PURCHASE:
          return currentProductGroup?.numberOfPurchaseConditions;
        case CalculusTabOptions.FREIGHT:
          return currentProductGroup?.numberOfFreightConditions;
        case CalculusTabOptions.MARKUP:
          return currentProductGroup?.numberOfMarkupConditions;
        default:
          return undefined;
      }
    },
    [calculusArea, flattenedProductGroups]
  );

  const totalCount = useMemo(() => {
    switch (calculusArea) {
      case CalculusTabOptions.PURCHASE:
        return supplierWithProductGroups.numberOfPurchaseConditions;
      case CalculusTabOptions.FREIGHT:
        return supplierWithProductGroups.numberOfFreightConditions;
      case CalculusTabOptions.MARKUP:
        return supplierWithProductGroups.numberOfMarkupConditions;
      default:
        return undefined;
    }
  }, [calculusArea, supplierWithProductGroups]);

  return (
    <SideFilters
      heading={supplierWithProductGroups.mgSupplierName}
      subHeading={
        totalCount === 1
          ? t('calculus.oneCondition', { count: totalCount })
          : t('calculus.nrOfConditions', { count: totalCount })
      }
      showFilters={showFilters}
      closeFilters={closeFilters}
    >
      <TypesToggleButton typesToShow={conditionState} setTypesToShow={setConditionState} />

      <Stack gap={0.5}>
        <Typography variant="body2" color="text.secondary">
          {t('myAssortment.filters.itemSearch')}
        </Typography>
        <SelectMgItem
          supplierId={supplierWithProductGroups.mgSupplierId}
          mgItemId={selectedMgItemId}
          mgItems={mgSupplierItems}
          isLoading={isLoadingFetchItems}
          selectLevel={selectLevel}
          setSearchTerm={setMgSupplierItemSearch}
        />
      </Stack>
      <ProductGroupFilter
        productGroups={baseProductGroups}
        selectedProductGroup={selectedProductGroup as BaseProductGroup | null}
        showProductGroups={showProductGroups}
        toggleShowProductGroups={toggleShowProductGroups}
        getCount={getCount}
        selectProductGroup={selectProductGroupId}
      />
    </SideFilters>
  );
}
