import React, { FC, useCallback } from 'react';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { ContainedButton, Dialog, OutlinedButton, TextButton } from '@shared/components';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useSetStandardPurchaseAgreements } from '@retail/purchase-agreements/data-access';
import { Supplier } from '@retail/suppliers/types';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { useIsSuperUser } from '@retail/auth/data-access';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@retail/hooks';
import { AgreementsWithPriceComparison } from '@retail/purchase-agreements/containers';
import { useDisclosure } from '@shared/hooks';

interface Props {
  assortmentId: number;
  supplier: Supplier;
  open: boolean;
  onClose: () => void;
}

const SupplierAgreementsDialogContainer: FC<Props> = ({
  assortmentId,
  supplier,
  open,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const t = usePurchaseAgreementsTFunction();

  const isSuperUser = useIsSuperUser();
  const { mutateAsync: mutateStandardPurchaseAgreement, isLoading } =
    useSetStandardPurchaseAgreements();

  const [agreementToSet, setAgreementToSet] = React.useState<PurchaseAgreement | null>(null);

  const onSubmitStandardPurchaseAgreement = useCallback(
    (agreementId: number) =>
      mutateStandardPurchaseAgreement({
        urlVariables: [agreementId],
      })
        .then((agreements) => {
          setAgreementToSet(null);
          queryClient.invalidateQueries(['purchase-agreements', supplier.mgSupplierId]);
          queryClient.invalidateQueries(['suppliers']);
          toast.success(
            t('purchaseAgreements.supplier.standardAgreement.success', {
              agreement: agreements[0].agreementText,
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(t('purchaseAgreements.supplier.standardAgreement.error.generic'));
        }),
    [mutateStandardPurchaseAgreement, queryClient, supplier.mgSupplierId, t, toast]
  );

  const { isOpen: comparePriceLists, onToggle: toggleComparePriceList } = useDisclosure();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle alignItems="center">
        {t('purchaseAgreements.agreement.agreementsFor', { supplier: supplier.name })}
        <OutlinedButton size="small" sx={{ whiteSpace: 'nowrap' }} onClick={toggleComparePriceList}>
          {comparePriceLists
            ? t('purchaseAgreements.priceList.closeComparison')
            : t('purchaseAgreements.priceList.comparePrices')}
        </OutlinedButton>
      </DialogTitle>

      <DialogContent sx={{ display: 'flex' }}>
        <AgreementsWithPriceComparison
          assortmentId={assortmentId}
          supplierId={supplier.mgSupplierId}
          comparePriceLists={comparePriceLists}
          setStandardAgreement={isSuperUser ? setAgreementToSet : undefined}
        />
      </DialogContent>
      {agreementToSet && (
        <DialogActions>
          <Typography variant="body1" color="textSecondary">
            {t('purchaseAgreements.supplier.standardAgreement.confirmation.description', {
              agreement: agreementToSet.name,
            })}
          </Typography>
          <TextButton onClick={() => setAgreementToSet(null)} loading={isLoading}>
            {t('purchaseAgreements.supplier.standardAgreement.confirmation.cancel')}
          </TextButton>
          <ContainedButton
            onClick={() => onSubmitStandardPurchaseAgreement(agreementToSet.id)}
            loading={isLoading}
          >
            {t('purchaseAgreements.supplier.standardAgreement.confirmation.confirm')}
          </ContainedButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default withDialogSuspenseErrorBoundary(SupplierAgreementsDialogContainer);
