import { useFetchMgItem } from '@retail/products/data-access';
import { MgItem } from '@retail/products/types';

import {
  ErrorFallback,
  SuspenseWithSentryErrorBoundary,
  withDialogSuspenseErrorBoundary,
} from '@retail/components';
import { DialogContent } from '@mui/material';
import { Dialog } from '@shared/components';
import React from 'react';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useSelectedCompetitors } from '@retail/retailer/data-access';

interface ChildrenProps {
  mgItem: MgItem;
}

interface Props {
  itemNumber: number;
  open: boolean;
  onClose: () => void;
  assortmentId?: number;
  children: (props: ChildrenProps) => React.ReactNode;
}

export const MgItemDialogContainer = withDialogSuspenseErrorBoundary(
  ({ itemNumber, assortmentId, open, onClose, children }: Props) => {
    const { selectedOrgUnitId } = useSelectedOrgUnit();
    const competitors = useSelectedCompetitors(selectedOrgUnitId);

    const { data: mgItemDto } = useFetchMgItem(
      {
        mgItemNumber: itemNumber,
        assortmentId,
        competitorStoreIds: competitors.map((competitor) => competitor.id),
        nPrices: 2,
      },
      { suspense: true }
    );

    const mgItem = MgItem.fromDto(mgItemDto!);

    return (
      <Dialog open={open} onClose={onClose} maxWidth="xl">
        <DialogContent>
          <SuspenseWithSentryErrorBoundary errorFallback={(props) => <ErrorFallback {...props} />}>
            {children({ mgItem })}
          </SuspenseWithSentryErrorBoundary>
        </DialogContent>
      </Dialog>
    );
  }
);
