import { Stack } from '@mui/material';
import { CalculusTabOptions, ConditionState } from '@retail/calculus/types';
import { calculusActionPortalId, ToggleFiltersButton } from '@retail/components';
import { useDisclosure } from '@shared/hooks';
import { CalculusFilters } from './Filters';
import { PropsWithChildren } from 'react';
import { Supplier } from '@retail/suppliers/types';
import { useSelectCalculusProductGroups } from '@retail/calculus/context';

interface Props extends PropsWithChildren {
  draftAssortmentId: number;
  baseAssortmentId: number;
  currentCalculusArea: CalculusTabOptions;
  selectedSupplier?: Supplier;
  conditionState: ConditionState;
  setConditionState: (state: ConditionState) => void;
}

export function CalculusTabContainer({
  draftAssortmentId,
  baseAssortmentId,
  currentCalculusArea,
  selectedSupplier,
  conditionState,
  setConditionState,
  children,
}: Props) {
  const { isOpen: showFilters, onToggle: toggleFilters } = useDisclosure(true);
  const { supplierWithProductGroups, selectedProductGroup, selectProductGroupId } =
    useSelectCalculusProductGroups({
      mgSupplierId: selectedSupplier?.mgSupplierId,
      assortmentId:
        conditionState === ConditionState.DRAFT || conditionState === ConditionState.ALL
          ? draftAssortmentId
          : baseAssortmentId,
      includeOwner: conditionState !== ConditionState.DRAFT,
      filterConditions: currentCalculusArea,
      inAssortment: true,
    });

  return (
    <Stack direction="row" flexGrow={1} overflow="auto" mb={2}>
      <Stack flexGrow={1}>
        <Stack flexDirection="row" gap={2} justifyContent="flex-end" my={1.5} mx={2}>
          <Stack id={calculusActionPortalId} direction="row" gap={1} />

          {!showFilters && (
            <ToggleFiltersButton showFilters={showFilters} toggleFilters={toggleFilters} />
          )}
        </Stack>
        {children}
      </Stack>
      {showFilters && (
        <CalculusFilters
          supplierWithProductGroups={supplierWithProductGroups}
          selectedProductGroup={selectedProductGroup}
          selectProductGroupId={selectProductGroupId}
          calculusArea={currentCalculusArea}
          conditionState={conditionState}
          showFilters={showFilters}
          closeFilters={toggleFilters}
          setConditionState={setConditionState}
        />
      )}
    </Stack>
  );
}
