import { PriceSuggestionType } from '@retail/price-suggestion/types';
import { PriceSuggestionConditionMinimalDTO } from '../../dto';
import { Condition } from './Condition';

interface PriceSuggestionConditionMinimalProps {
  condition: Condition;
  priority: PriceSuggestionType[];
  suggestedPriceCoverage?: number;
}

export class PriceSuggestionConditionMinimal {
  condition: Condition;
  priority: PriceSuggestionType[];
  suggestedPriceCoverage?: number;

  constructor(props: PriceSuggestionConditionMinimalProps) {
    this.condition = props.condition;
    this.priority = props.priority;
    this.suggestedPriceCoverage = props.suggestedPriceCoverage;
  }

  static fromDto(dto: PriceSuggestionConditionMinimalDTO): PriceSuggestionConditionMinimal {
    return new PriceSuggestionConditionMinimal({
      condition: Condition.fromDto(dto.condition),
      priority: dto.priority,
      suggestedPriceCoverage: dto.suggestedPriceCoverage,
    });
  }
}
