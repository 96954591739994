import { useUserBrands } from './BrandContext';
import { FeatureContext, MgPrisFeature } from '@retail/mgpris/config-types';
import { useMemo } from 'react';
import { useSelectedOrgUnit } from './SelectedOrgUnitProvider';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { isFeatureEnabled } from '@retail/utils';

export const useFeatureContext = (): FeatureContext => {
  const { selectedBrand } = useUserBrands();
  const { selectedBrandContext } = useSelectedOrgUnit();

  return useMemo(
    () => ({
      locationType: selectedBrand.wholesaler
        ? 'wholesale'
        : selectedBrandContext
        ? 'pdm'
        : 'retail',
    }),
    [selectedBrand.wholesaler, selectedBrandContext]
  );
};

export const useIsFeatureEnabled = (featureName: MgPrisFeature) => {
  const { featureFlags } = useMgPrisConfig();
  const featureContext = useFeatureContext();

  return useMemo(
    () =>
      isFeatureEnabled({
        features: featureFlags,
        context: featureContext,
        name: featureName,
      }),
    [featureContext, featureFlags, featureName]
  );
};
