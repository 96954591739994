import { useMemo, useState } from 'react';
import { PageLayout, SuspenseWithSentryErrorBoundary } from '@retail/components';
import {
  HasStandardAgreementOption,
  SuppliersTable,
  SuppliersTableToolbar,
} from '@retail/suppliers/components';
import { Supplier } from '@retail/suppliers/types';
import { useFetchAllSuppliers } from '@retail/products/data-access';
import { useDisclosure, useSelectedSearchParamState } from '@shared/hooks';
import { SupplierAgreementsDialogContainer } from '@retail/suppliers/containers';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { AgreementsUploadDialog } from '@retail/purchase-agreements/containers';
import { useUserBrands } from '@retail/app/stores/selected-context';
import { useLanguageSelector } from '@retail/app/hooks';
import { Paper } from '@mui/material';

export function SuppliersPage() {
  const { language } = useLanguageSelector();

  const { isOpen: hasAgreements, onToggle: hasAgreementsToggle } = useDisclosure(true);
  const { selectedBrand } = useUserBrands();

  const [searchInput, setSearchInput] = useState<string>('');

  const [hasStandardAgreement, setHasStandardAgreement] =
    useState<HasStandardAgreementOption>('UNSPECIFIED');

  const { baseAssortmentId } = useDepartmentAssortments();
  const { data: suppliersDtos = [] } = useFetchAllSuppliers({
    withPurchaseAgreements: hasAgreements ? true : undefined,
    assortmentId: baseAssortmentId,
    withStandardAgreement:
      hasStandardAgreement === 'UNSPECIFIED' ? undefined : hasStandardAgreement === 'YES',
  });
  const suppliers = useMemo(() => suppliersDtos.map(Supplier.fromMinimalDTO), [suppliersDtos]);
  const filteredSuppliers = useMemo(
    () =>
      suppliers.filter(({ name }) =>
        name.toLocaleLowerCase().startsWith(searchInput.toLocaleLowerCase())
      ),
    [searchInput, suppliers]
  );

  const [selectedSupplierToView, setSelectedSupplierToView] = useSelectedSearchParamState({
    options: suppliers,
    getOptionId: ({ mgSupplierId }) => mgSupplierId,
    searchParamKey: 'supplier',
  });

  const [selectedSupplierToUploadAgreements, setSelectedSupplierToUploadAgreements] =
    useSelectedSearchParamState({
      options: suppliers,
      getOptionId: ({ mgSupplierId }) => mgSupplierId,
      searchParamKey: 'upload-supplier',
    });

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <SuspenseWithSentryErrorBoundary>
          <SuppliersTableToolbar
            searchInput={searchInput}
            onSearchChange={setSearchInput}
            hasAgreements={hasAgreements}
            hasAgreementsToggle={hasAgreementsToggle}
            hasStandardAgreementOption={hasStandardAgreement}
            onChangeHasStandardAgreementOption={setHasStandardAgreement}
          />
          <SuppliersTable
            language={language}
            suppliers={filteredSuppliers}
            onSupplierSelect={setSelectedSupplierToView}
            onShowUploadDialog={
              selectedBrand.wholesaler ? setSelectedSupplierToUploadAgreements : undefined
            }
          />
        </SuspenseWithSentryErrorBoundary>
      </Paper>
      {selectedSupplierToView && (
        <SupplierAgreementsDialogContainer
          assortmentId={baseAssortmentId}
          supplier={selectedSupplierToView}
          onClose={() => setSelectedSupplierToView(null)}
          open
        />
      )}
      {selectedSupplierToUploadAgreements && (
        <AgreementsUploadDialog
          open
          onClose={() => setSelectedSupplierToUploadAgreements(null)}
          supplier={selectedSupplierToUploadAgreements}
        />
      )}
    </PageLayout>
  );
}
