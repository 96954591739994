import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { DataGridPagination, StackedTextCell } from '@retail/components';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { SupplierItemConditions } from '@retail/calculus/types';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { PricePerUnit } from '@retail/products/components';
import dayjs from 'dayjs';

interface Props {
  items: SupplierItemConditions[];
  page: number;
  pageSize: number;
  totalItems: number;
  updatePage: Dispatch<SetStateAction<number>>;
  updatePageSize: Dispatch<SetStateAction<number>>;
}

const baseColumnProps: Partial<GridColDef> = {
  width: 140,
  headerName: '',
  headerAlign: 'left',
  align: 'left',
};

export function AffectedItemsTable({
  items,
  page,
  pageSize,
  totalItems,
  updatePage,
  updatePageSize,
}: Props) {
  const t = useCalculusTFunction();

  const getbaseUnitPriceAndPriceDate = (supplierItemCondition: SupplierItemConditions) => {
    const prices = supplierItemCondition.simulatedPrices[0]?.prices;
    const baseUnitPrices = prices.find((price) => price.basePriceUnit);
    const priceDate = baseUnitPrices?.priceDate
      ? dayjs(baseUnitPrices.priceDate).format('ll')
      : undefined;
    return { baseUnitPrices, priceDate };
  };

  const columns: GridColDef<SupplierItemConditions>[] = useMemo(
    () => [
      {
        field: 'primaryText',
        width: 280,
        headerName: t('calculus.itemsAffected.columns.itemName'),
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.primaryText} bottomLine={row.secondaryText} />
        ),
      },
      {
        field: 'mgItemAndFinfo',
        width: 160,
        headerName: t('calculus.itemsAffected.columns.mgItemAndFinfo'),
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.mgItemNumber} bottomLine={row.finfoNumber} />
        ),
      },
      {
        field: 'productGroup',
        ...baseColumnProps,
        width: 220,
        headerName: t('calculus.itemsAffected.columns.productGroup'),
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.groupDescription} bottomLine={row.groupNumber} />
        ),
      },
      {
        field: 'purchasePrice',
        ...baseColumnProps,
        headerName: t('calculus.itemsAffected.columns.purchasePrice'),
        renderCell: ({ row }) => {
          const { baseUnitPrices, priceDate } = getbaseUnitPriceAndPriceDate(row);
          const purchasePrice = baseUnitPrices?.purchasePrice;

          return purchasePrice ? (
            <StackedTextCell
              nullableBottomLine
              centerAlign
              topLine={<PricePerUnit price={purchasePrice} unit={baseUnitPrices?.unit} />}
              bottomLine={priceDate}
            />
          ) : (
            '-'
          );
        },
      },
      {
        field: 'costPrice',
        ...baseColumnProps,
        headerName: t('calculus.itemsAffected.columns.costPrice'),
        renderCell: ({ row }) => {
          const { baseUnitPrices, priceDate } = getbaseUnitPriceAndPriceDate(row);
          const costPrice = baseUnitPrices?.costPrice;

          return costPrice ? (
            <StackedTextCell
              nullableBottomLine
              centerAlign
              topLine={<PricePerUnit price={costPrice} unit={baseUnitPrices?.unit} />}
              bottomLine={priceDate}
            />
          ) : (
            '-'
          );
        },
      },
      {
        field: 'salesPrice',
        ...baseColumnProps,
        headerName: t('calculus.itemsAffected.columns.salesPrice'),
        renderCell: ({ row }) => {
          const { baseUnitPrices, priceDate } = getbaseUnitPriceAndPriceDate(row);
          const salesPrice = baseUnitPrices?.salesPrice;

          return salesPrice ? (
            <StackedTextCell
              nullableBottomLine
              centerAlign
              topLine={<PricePerUnit price={salesPrice} unit={baseUnitPrices?.unit} />}
              bottomLine={priceDate}
            />
          ) : (
            '-'
          );
        },
      },
    ],
    [t]
  );

  return (
    <DataGridPremium
      disableRowSelectionOnClick
      rowHeight={65}
      rows={items}
      getRowId={(row) => row.mgItemNumber + row.finfoNumber}
      columns={columns}
      pagination={true}
      paginationMode="server"
      slots={{
        pagination: () => <DataGridPagination isDialog />,
      }}
      rowCount={totalItems}
      paginationModel={{
        page,
        pageSize,
      }}
      onPaginationModelChange={(model) => {
        updatePage(model.page);
        updatePageSize(model.pageSize);
      }}
    />
  );
}
