import { conditionDatesSchema, levelSelectSchema } from '@retail/calculus/components';
import { CalculusTFunction } from '@retail/calculus/i18n';
import { numberWithOptionalDecimalRegex, percentageRegex } from '@retail/form/validation';
import * as yup from 'yup';

export const markupSchema = (t: CalculusTFunction, includeUnit: boolean) =>
  yup.object({
    gtin: includeUnit
      ? yup
          .string()
          .default('')
          .when('level', {
            is: 'item',
            then: yup.string().default('').required(t('calculus.error.unit')),
          })
      : yup.string(),
    markupName: yup.string().default('').required(t('calculus.error.nameRequired')),
    salesFactor: yup
      .string()
      .default('')
      .required(t('calculus.error.salesFactor'))
      .matches(numberWithOptionalDecimalRegex, t('calculus.error.mustBeNumberBetweenZeroAndTen')),
    salesCoverage: yup
      .string()
      .default('')
      .required(t('calculus.error.salesCoverage'))
      .matches(percentageRegex, t('calculus.error.mustBeNumberBetween')),
  });

export const createMarkupSchema = (t: CalculusTFunction, includeUnit: boolean) =>
  levelSelectSchema(t).concat(markupSchema(t, includeUnit).concat(conditionDatesSchema(t)));

export const editMarkupSchema = (t: CalculusTFunction, includeUnit?: boolean) =>
  markupSchema(t, includeUnit ?? false).concat(conditionDatesSchema(t));

export type CreateMarkupSchema = yup.InferType<ReturnType<typeof createMarkupSchema>>;
export type EditMarkupSchema = yup.InferType<ReturnType<typeof editMarkupSchema>>;

export const createAssortmentMarkupSchema = (t: CalculusTFunction) =>
  markupSchema(t, false).concat(conditionDatesSchema(t));

export type CreateAssortmentMarkupSchema = yup.InferType<
  ReturnType<typeof createAssortmentMarkupSchema>
>;

