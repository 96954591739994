import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { MarketInsightPage } from '../MarketInsight';
import { mgPrisRoutes } from '@retail/mgpris/config';

export const DepartmentMarketInsightPage = () => {
  const { monitoringAssortments } = useDepartmentAssortments();

  return (
    <MarketInsightPage
      monitoringAssortments={monitoringAssortments}
      marketInsightRoute={mgPrisRoutes.marketInsight}
    />
  );
};
