import { RecommendedPrice } from './RecommendedPrice';
import { MonitoringAssortmentItemDTO, MonitoringAssortmentMgSupplierItemDTO } from '../dto';
import { SalesPriceDTO, SalesPricesByDateDTO } from '@retail/calculus/types';
import dayjs from 'dayjs';
import { CompetitorPrice, ItemPriority } from '@retail/products/types';

export interface AssortmentItemSalesPriceConstructorProps {
  price: number;
  unit: string;
  basePriceUnit?: boolean;
  primaryUnit?: boolean;
  source: 'MPE' | 'BACKOFFICE';
  priceDate: string;
}

export class AssortmentItemSalesPrice {
  price: number;
  unit: string;
  basePriceUnit?: boolean;
  primaryUnit?: boolean;
  source: 'MPE' | 'BACKOFFICE';
  priceDate: string;

  constructor({
    price,
    unit,
    basePriceUnit,
    primaryUnit,
    source,
    priceDate,
  }: AssortmentItemSalesPriceConstructorProps) {
    this.price = price;
    this.unit = unit;
    this.basePriceUnit = basePriceUnit;
    this.primaryUnit = primaryUnit;
    this.source = source;
    this.priceDate = priceDate;
  }

  static fromDtoSalesPrices = (
    calculatedPrices: SalesPriceDTO[],
    simulatedPrices: SalesPricesByDateDTO[],
    priceUnit: string
  ): AssortmentItemSalesPrice | undefined => {
    const calculatedPrice = calculatedPrices.find(({ unit }) => unit === priceUnit);
    if (calculatedPrice) {
      return new AssortmentItemSalesPrice({
        priceDate: calculatedPrice.priceDate,
        basePriceUnit: calculatedPrice.basePriceUnit,
        unit: calculatedPrice.unit,
        price: calculatedPrice.salesPrice,
        source: 'BACKOFFICE',
        primaryUnit: calculatedPrice.primaryUnit,
      });
    }

    const simulatedPrice = simulatedPrices
      .sort((p1, p2) => p1.priceDate.localeCompare(p2.priceDate))[0]
      ?.prices.find(({ unit }) => unit === priceUnit);

    if (simulatedPrice) {
      return new AssortmentItemSalesPrice({
        priceDate: simulatedPrice.priceDate,
        basePriceUnit: simulatedPrice.basePriceUnit,
        unit: simulatedPrice.unit,
        price: simulatedPrice.salesPrice,
        source: 'MPE',
        primaryUnit: simulatedPrice.primaryUnit,
      });
    }
    return undefined;
  };
}

export class MonitoringAssortmentMgSupplierItem {
  mgItemNumber: number;
  priority?: ItemPriority;
  priorityValidFrom?: string;
  primaryText: string;
  mgSupplierId: number;
  mgSupplierName: string;
  basePriceUnit?: string;
  primaryPackageUnit?: string;
  finfoNumber?: number;
  salesPrice?: AssortmentItemSalesPrice;

  constructor({
    mgItemNumber,
    priority,
    priorityValidFrom,
    primaryText,
    mgSupplierId,
    mgSupplierName,
    basePriceUnit,
    primaryPackageUnit,
    finfoNumber,
    calculatedPrices = [],
    simulatedPrices = [],
  }: MonitoringAssortmentMgSupplierItemDTO) {
    this.mgItemNumber = mgItemNumber;
    this.priority = priority;
    this.priorityValidFrom = priorityValidFrom;
    this.primaryText = primaryText;
    this.mgSupplierId = mgSupplierId;
    this.mgSupplierName = mgSupplierName;
    this.basePriceUnit = basePriceUnit;
    this.primaryPackageUnit = primaryPackageUnit;
    this.finfoNumber = finfoNumber;
    this.salesPrice = basePriceUnit
      ? AssortmentItemSalesPrice.fromDtoSalesPrices(
          calculatedPrices,
          simulatedPrices,
          basePriceUnit
        )
      : undefined;
  }
}

export class MonitoringAssortmentItem {
  id: number;
  assortmentId: number;
  mgItemNumber: number;
  replacedByMgItemNumber?: number;
  selectedSupplierId?: number;
  mgSupplierItems: MonitoringAssortmentMgSupplierItem[];
  gtinCodes?: string[];
  recommendedPrice?: RecommendedPrice;
  competitorPrices: Record<number, CompetitorPrice[]>;

  constructor({
    id,
    assortmentId,
    mgItemNumber,
    replacedByMgItemNumber,
    selectedSupplier,
    mgSupplierItems = [],
    gtinCodes,
    recommendedPrice,
    competitorPrices,
  }: MonitoringAssortmentItemDTO) {
    this.id = id;
    this.assortmentId = assortmentId;
    this.mgItemNumber = mgItemNumber;
    this.replacedByMgItemNumber = replacedByMgItemNumber;
    this.selectedSupplierId = selectedSupplier;
    this.mgSupplierItems = mgSupplierItems?.map(
      (dto) => new MonitoringAssortmentMgSupplierItem(dto)
    );
    this.gtinCodes = gtinCodes;
    this.recommendedPrice = recommendedPrice && new RecommendedPrice(recommendedPrice);
    this.competitorPrices = competitorPrices
      ? Object.entries(competitorPrices).reduce(
          (tmp, [storeId, storePrices]) => ({
            ...tmp,
            [storeId]: storePrices.map((storePrice) => new CompetitorPrice(storePrice)),
          }),
          {}
        )
      : {};
  }

  getPrimarySupplierItem = (): MonitoringAssortmentMgSupplierItem => {
    return (
      this.mgSupplierItems.find(({ mgSupplierId }) => mgSupplierId === this.selectedSupplierId) ||
      this.mgSupplierItems[0]
    );
  };

  getCurrentCompetitorPrice = (storeId: number): CompetitorPrice | undefined =>
    this.competitorPrices[storeId]?.find((price) => price.latest);

  getPreviousCompetitorPrice = (storeId: number): CompetitorPrice | undefined => {
    const storePrices = this.competitorPrices[storeId];

    return storePrices?.reduce((prevPrice: CompetitorPrice | undefined, price) => {
      if (price.latest) {
        return prevPrice;
      }
      return prevPrice
        ? dayjs(price.lastObservedAt).isAfter(dayjs(prevPrice.lastObservedAt))
          ? price
          : prevPrice
        : price;
    }, undefined);
  };
}
