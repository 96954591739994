import React from 'react';
import { PaperProps } from '@mui/material';
import { Paper } from '@shared/components';

export const TabContent: React.FC<PaperProps> = ({ children, sx, ...props }) => (
  <Paper
    paddingY="dense"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      borderTopLeftRadius: 0,
      p: 2,
      ...sx,
    }}
    {...props}
  >
    {children}
  </Paper>
);
