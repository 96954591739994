import { mgPrisRoutes } from '@retail/mgpris/config';
import { useFetchAllSuppliers } from '@retail/products/data-access';
import { Supplier } from '@retail/suppliers/types';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelectLocalStorageSupplier } from '@retail/calculus/hooks';
import { CalculusTabOptions } from '@retail/calculus/types';
import { calculusTabsParamKey } from '@retail/products/utils';

interface Props {
  supplierId: number;
  draftAssortmentId: number;
}

export const useSelectSupplier = ({ supplierId, draftAssortmentId }: Props) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const { lastSelectedSupplier, setSelectedSupplier } = useSelectLocalStorageSupplier();

  const { data: supplierDtos = [] } = useFetchAllSuppliers({ assortmentId: draftAssortmentId });
  const suppliers = useMemo(() => supplierDtos.map(Supplier.fromMinimalDTO), [supplierDtos]);

  const selectedSupplier = useMemo(() => {
    return suppliers.find((supplier) => supplier.mgSupplierId === Number(supplierId));
  }, [supplierId, suppliers]);

  const storedOrDefaultSupplier = useMemo(
    () =>
      suppliers.find((supplier) => supplier.mgSupplierId === Number(lastSelectedSupplier)) ??
      suppliers[0],
    [lastSelectedSupplier, suppliers]
  );

  const navigateToSupplier = useCallback(
    (id: number) => {
      setSelectedSupplier(id);
      navigate({
        pathname: mgPrisRoutes.calculus.supplier.getFullLinkPath({ supplierId: id }),
        search,
      });
    },
    [navigate, search, setSelectedSupplier]
  );

  const navigateToStoredOrDefaultSupplier = useCallback(
    (calculusTab: CalculusTabOptions) => {
      setSelectedSupplier(storedOrDefaultSupplier.mgSupplierId);
      navigate({
        pathname: mgPrisRoutes.calculus.supplier.getFullLinkPath({
          supplierId: storedOrDefaultSupplier.mgSupplierId,
        }),
        search: `?${calculusTabsParamKey}=${calculusTab}`,
      });
    },
    [navigate, setSelectedSupplier, storedOrDefaultSupplier.mgSupplierId]
  );

  return {
    suppliers,
    selectedSupplier,
    storedOrDefaultSupplier,
    navigateToSupplier,
    navigateToStoredOrDefaultSupplier,
  };
};
