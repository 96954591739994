import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { PriceTypesRadionGroup } from '@retail/price-suggestion/components';
import { usePriceSuggestionActions } from '@retail/price-suggestion/context';
import { usePriceSuggestionTFunction } from '@retail/price-suggestion/i18n';
import { PriceSuggestionType, PriceSuggestionTypeWithCount } from '@retail/price-suggestion/types';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useCallback, useState } from 'react';

interface Props {
  open: boolean;
  mgSupplierId: number;
  priceSuggestionTypes: PriceSuggestionTypeWithCount[];
  selectedPriceType?: PriceSuggestionType;
  mgItemNumber: number;
  primaryText: string;
  onClose: () => void;
}

export function ChangeOrFollowPriceSuggestionDialog({
  open,
  mgSupplierId,
  priceSuggestionTypes,
  selectedPriceType,
  mgItemNumber,
  primaryText,
  onClose,
}: Props) {
  const t = usePriceSuggestionTFunction();
  const { activateOrChangeCondition } = usePriceSuggestionActions({ mgSupplierId });

  const [checkedPriceType, checkPriceType] = useState<PriceSuggestionType | null>(
    selectedPriceType ?? null
  );

  const submitPriceTypeChange = useCallback(() => {
    activateOrChangeCondition({
      mgItemNumber: mgItemNumber,
      priceSuggestionType: checkedPriceType as PriceSuggestionType,
    });
    onClose();
  }, [activateOrChangeCondition, checkedPriceType, mgItemNumber, onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('priceSuggestion.changePriceType', { level: primaryText })}</DialogTitle>
      <DialogContent>
        <PriceTypesRadionGroup
          priceSuggestionTypes={priceSuggestionTypes}
          checkedPriceType={checkedPriceType}
          checkPriceType={checkPriceType}
        />
      </DialogContent>
      <DialogActions>
        <TextButton size="small" onClick={onClose}>
          {t('priceSuggestion.actions.cancel')}
        </TextButton>
        <ContainedButton
          onClick={submitPriceTypeChange}
          disabled={selectedPriceType && selectedPriceType === checkedPriceType}
          size="small"
        >
          {t('priceSuggestion.actions.toApproval')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
