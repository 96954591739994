import { useMPEFetch } from '@retail/app/hooks';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { PriceSuggestionType, PriceSuggestionTypeWithCount } from '@retail/price-suggestion/types';
import { mpeApi } from '@retail/mgpris/config';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';

interface SearchProps {
  assortmentId: number;
  mgSupplierId: number;
  productGroup?: string;
  discountGroupId?: number;
  mgItemNumber?: number;
}

export function useFetchPriceSuggestionTypes(
  { assortmentId, mgSupplierId }: SearchProps,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<PriceSuggestionType[]>({
    method: 'GET',
    queryKey: ['price-suggestion-types', mgSupplierId],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.assortments.assortment.priceSuggestion.getFullLinkPath({ assortmentId }),
      searchParams: { mgSupplierId },
    }),
    ...queryOptions,
  });
}

export function useFetchPriceSuggestionTypesWithCount(
  { assortmentId, mgSupplierId, productGroup, discountGroupId, mgItemNumber }: SearchProps,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMPEFetch<PriceSuggestionTypeWithCount[]>({
    method: 'GET',
    queryKey: ['price-suggestion-types', mgSupplierId, productGroup, discountGroupId, mgItemNumber],
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.assortments.assortment.priceSuggestionWithCount.getFullLinkPath({
        assortmentId,
      }),
      searchParams: { mgSupplierId, productGroup, discountGroupId, mgItemNumber },
    }),
    ...queryOptions,
  });
}