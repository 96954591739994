import { ConditionsDTO } from '../dtos';
import { FreightConditions } from './FreightCondition';
import { PurchaseConditions } from './PurchaseCondition/PurchaseConditions';
import { MarkupConditions } from './MarkupCondition/MarkupConditions';
import { PriceSuggestionConditions } from './PriceSuggestionCondition/PriceSuggestionConditions';
import { CustomerMarkupConditions } from './CustomerMarkupCondition';

export enum PriceType {
  GTIN = 'GTIN',
  NET = 'NET',
  REBATE = 'REBATE',
}

export enum Operation {
  UPSERT = 'UPSERT',
  DELETE = 'DELETE',
}

export enum AssortmentType {
  BASE = 'BASE',
  DRAFT = 'DRAFT',
}

interface ConditionsProps {
  assortmentId: number;
  purchaseConditions: PurchaseConditions[];
  freightConditions?: FreightConditions;
  markupConditions?: MarkupConditions;
  priceSuggestionConditions?: PriceSuggestionConditions;
  customerMarkupConditions?: CustomerMarkupConditions;
}

export class Conditions {
  assortmentId: number;
  purchaseConditions: PurchaseConditions[];
  freightConditions?: FreightConditions;
  markupConditions?: MarkupConditions;
  priceSuggestionConditions?: PriceSuggestionConditions;
  customerMarkupConditions?: CustomerMarkupConditions;

  constructor({
    assortmentId,
    purchaseConditions,
    freightConditions,
    markupConditions,
    priceSuggestionConditions,
    customerMarkupConditions,
  }: ConditionsProps) {
    this.assortmentId = assortmentId;
    this.purchaseConditions = purchaseConditions;
    this.freightConditions = freightConditions;
    this.markupConditions = markupConditions;
    this.priceSuggestionConditions = priceSuggestionConditions;
    this.customerMarkupConditions = customerMarkupConditions;
  }

  static fromDto = (dto: ConditionsDTO): Conditions =>
    new Conditions({
      assortmentId: dto.assortmentId,
      purchaseConditions: dto.purchaseConditions?.map(PurchaseConditions.fromDto),
      freightConditions: dto.freightConditions
        ? FreightConditions.fromDto(dto.freightConditions)
        : undefined,
      markupConditions: dto.markupConditions
        ? MarkupConditions.fromDto(dto.markupConditions)
        : undefined,
      priceSuggestionConditions:
        dto.priceSuggestionConditions &&
        PriceSuggestionConditions.fromDto(dto.priceSuggestionConditions),
      customerMarkupConditions:
        dto.customerMarkupConditions &&
        CustomerMarkupConditions.fromDto(dto.customerMarkupConditions),
    });
}
