import { useCallback, useMemo, useState } from 'react';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';
import {
  AddItemsToMonitoringAssortment,
  MonitoringAssortmentContainer,
  MonitoringAssortmentsProps,
  withMonitoringAssortments,
} from '@retail/monitoring-assortment/containers';
import { NotFoundError, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { useSelectedOrgUnit, useUserBrands } from '@retail/app/stores/selected-context';
import { useNavigate, useParams } from 'react-router-dom';
import { mgPrisRoutes, pdmRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import {
  AssortmentPicker,
  CentralAssortmentLogo,
  DeleteAssortmentDialog,
  EditAssortmentMenu,
  EditAssortmentTitleDialog,
} from '@retail/monitoring-assortment/components';
import { Box, Stack, Typography } from '@mui/material';
import { MgItemContainer, MgItemDialogContainer } from '@retail/products/containers';
import { useDisclosure } from '@shared/hooks';
import { useMonitoringAssortmentActions } from '@retail/monitoring-assortment/context';
import { useToast } from '@retail/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { PdmMgItemContainer } from '@retail/mgpdm/containers';

interface Props extends MonitoringAssortmentsProps {
  marketInsightRoute: typeof mgPrisRoutes.marketInsight | typeof pdmRoutes.marketInsight;
  orgType: 'department' | 'brand';
}

export function MonitoringAssortmentPageBase({
  monitoringAssortments,
  marketInsightRoute,
  orgType,
}: Props) {
  const t = useAppTFunction();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [itemToView, setItemToView] = useState<number | undefined>(undefined);

  const { selectedBrandLogos } = useUserBrands();
  const { selectedOrgUnitId } = useSelectedOrgUnit();
  const { assortmentId } = useParams<{ assortmentId: string }>();

  const selectedAssortment = useMemo(
    () => monitoringAssortments.find(({ id }) => id === Number(assortmentId)),
    [assortmentId, monitoringAssortments]
  );

  const otherAssortments = useMemo(
    () => monitoringAssortments.filter((assortment) => assortment.id !== Number(assortmentId)),
    [monitoringAssortments, assortmentId]
  );

  const selectAssortment = useCallback(
    (assortment: MonitoringAssortment | null) => {
      if (assortment) {
        navigate(
          marketInsightRoute.assortments.assortment.getFullLinkPath({
            assortmentId: assortment.id,
          })
        );
      }
    },
    [marketInsightRoute.assortments.assortment, navigate]
  );
  const toast = useToast();

  const { isOpen: isDeleteModalOpen, onToggle: toggleDeleteModal } = useDisclosure();
  const { isOpen: isEditNameModalOpen, onToggle: toggleEditNameModal } = useDisclosure();
  const { isOpen: isAddItemsModalOpen, onToggle: toggleAddItemsModal } = useDisclosure();

  const { isLoading, handleDeleteMgAssortment, handlePatchTitleOrItemsOfMgAssortment } =
    useMonitoringAssortmentActions();

  const onDeleteAssortment = (assortmentId: number) => {
    handleDeleteMgAssortment(assortmentId)
      .then(async () => {
        toast.success(t('assortment.admin.delete.success'));
        await queryClient.invalidateQueries(['department-assortments']);
        await queryClient.invalidateQueries(['brand-assortments']);
        navigate(marketInsightRoute.getFullLinkPath());
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('assortment.admin.update.error'));
        toggleDeleteModal();
      });
  };

  const onUpdateTitle = (assortmentId: number) => (title: string) => {
    handlePatchTitleOrItemsOfMgAssortment({
      assortmentId: assortmentId,
      title,
    })
      .then(() => {
        toggleEditNameModal();
        toast.success(t('assortment.admin.update.assortmentSucess'));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('assortment.admin.update.error'));
      });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" px={1}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h5">{selectedAssortment?.title || assortmentId + ''}</Typography>
          {selectedOrgUnitId !== selectedAssortment?.owner?.id && selectedAssortment && (
            <CentralAssortmentLogo selectedBrandLogo={selectedBrandLogos.circularLogo} />
          )}
        </Stack>

        {selectedAssortment && (
          <Stack gap={1.5} flexDirection="row" alignItems="center">
            {!!otherAssortments.length && (
              <AssortmentPicker
                assortments={otherAssortments}
                selectedAssortment={null}
                selectAssortment={selectAssortment}
                width={260}
                alternativeLabel={t('assortment.changeAssortment')}
              />
            )}
            <EditAssortmentMenu
              onAddFromFileClick={toggleAddItemsModal}
              onEditNameClick={toggleEditNameModal}
              onDeleteClick={toggleDeleteModal}
            />
          </Stack>
        )}
      </Box>

      <SuspenseWithSentryErrorBoundary>
        {selectedAssortment ? (
          <MonitoringAssortmentContainer
            selectedAssortment={selectedAssortment}
            setItemToView={setItemToView}
            toggleAddItemsModal={toggleAddItemsModal}
          />
        ) : (
          <NotFoundError
            title={t('assortment.mgAssortment.notFound.title')}
            subtitle={t('assortment.mgAssortment.notFound.subtitle', { assortmentId })}
            buttonText={t('assortment.mgAssortment.notFound.goBack')}
            navigateTo={marketInsightRoute.getFullLinkPath()}
          />
        )}
      </SuspenseWithSentryErrorBoundary>
      {itemToView && (
        <MgItemDialogContainer
          open={!!itemToView}
          itemNumber={itemToView}
          onClose={() => setItemToView(undefined)}
        >
          {({ mgItem }) =>
            orgType === 'department' ? (
              <MgItemContainer mgItem={mgItem} hiddenBreadcrumbs showMonitorItem={false} />
            ) : (
              <PdmMgItemContainer mgItem={mgItem} hiddenBreadcrumbs showMonitorItem={false} />
            )
          }
        </MgItemDialogContainer>
      )}
      {selectedAssortment && (
        <>
          <DeleteAssortmentDialog
            isOpen={isDeleteModalOpen}
            onClose={toggleDeleteModal}
            handleConfirmDelete={() => onDeleteAssortment(selectedAssortment.id)}
            isLoading={isLoading}
          />
          {isAddItemsModalOpen && (
            <AddItemsToMonitoringAssortment
              open
              onClose={toggleAddItemsModal}
              assortmentId={selectedAssortment.id}
            />
          )}
          <EditAssortmentTitleDialog
            isOpen={isEditNameModalOpen}
            onClose={toggleEditNameModal}
            existingTitle={selectedAssortment.title}
            handleConfirmTitle={() => onUpdateTitle(selectedAssortment.id)}
            isLoading={isLoading}
          />
        </>
      )}
    </>
  );
}

export const MonitoringAssortmentPage = withMonitoringAssortments(MonitoringAssortmentPageBase);
