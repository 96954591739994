import { MgPackageType } from '@retail/products/types';
import { ProductsTFunction } from '@retail/products/i18n';
import { PriceSuggestionType } from '@retail/price-suggestion/types';
import { PriceSuggestionTFunction } from '@retail/price-suggestion/i18n';

export const getPackageTypeLabel = (t: ProductsTFunction, type: MgPackageType) => {
  switch (type) {
    case 'PRIMARY':
      return t('products.mgItem.supplierItem.packageType.primary');
    case 'SECONDARY':
      return t('products.mgItem.supplierItem.packageType.secondary');
    case 'TERTIARY':
      return t('products.mgItem.supplierItem.packageType.tertiary');
    case 'PRICE_COMPARISON':
      return t('products.mgItem.supplierItem.packageType.priceComparison');
    case 'BASE_PRICE':
      return t('products.mgItem.supplierItem.packageType.basePrice');
    case 'ADDITIONAL_GTIN':
      return t('products.mgItem.supplierItem.packageType.additionalGTINs');
  }
};

export const getClassCodeLabel = (type: MgPackageType) => {
  switch (type) {
    case 'PRIMARY':
      return 'F-PAK';
    case 'SECONDARY':
      return 'D-PAK';
    case 'TERTIARY':
      return 'T-PAK';
    case 'PRICE_COMPARISON':
      return 'PSE';
    case 'ADDITIONAL_GTIN':
      return '+';
    case 'BASE_PRICE':
      return 'BPE';
  }
};

export const getPriceSuggestionType = (t: PriceSuggestionTFunction, type: PriceSuggestionType) => {
  switch (type) {
    case PriceSuggestionType.FINFO_BASE:
      return t('priceSuggestion.basePrice');
    case PriceSuggestionType.FINFO_RECOMMENDED:
      return t('priceSuggestion.finfoPriceSuggestion');
    case PriceSuggestionType.MG_AGGRESSIVE:
      return t('priceSuggestion.aggressive');
    case PriceSuggestionType.MG_CONSERVATIVE:
      return t('priceSuggestion.conservative');
    case PriceSuggestionType.MG_MODERATE:
      return t('priceSuggestion.moderate');
  }
};
