import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useCreateMarkup } from '@retail/markup/context';
import {
  CreateMgItemLevelMarkupCondition,
  CreateMgItemLevelSalesPriceCondition,
} from '@retail/markup/types';
import {
  CreatedSalesFactor,
  CreatedSalesPrice,
  DatedPackageMarkups,
  EditBracketPricesDialog,
  useBracketPackagePrices,
  useBracketPriceStore,
} from '@retail/my-assortment/components';
import { MgSupplierItem, PriceCalculation } from '@retail/products/types';
import { useCallback } from 'react';

interface Props {
  currentSalesPrices: PriceCalculation;
  selectedSupplierId: number;
  selectedSupplierItem: MgSupplierItem;
  mgItemNumber: number;
  includeVat: boolean;
  toggleIncludeVat: () => void;
  isBracketPriceModalOpen: boolean;
  onCloseBracketPriceModal: () => void;
}

export function EditBracketPricesDialogContainer({
  currentSalesPrices,
  selectedSupplierId,
  selectedSupplierItem,
  mgItemNumber,
  includeVat,
  toggleIncludeVat,
  isBracketPriceModalOpen,
  onCloseBracketPriceModal,
}: Props) {
  const t = useCalculusTFunction();
  const { resetMarkup } = useBracketPriceStore((state) => state);

  const { createMultipleMgItemLevelConditions, isLoadingPostChanges } = useCreateMarkup();

  const packagePrices = useBracketPackagePrices(
    currentSalesPrices,
    selectedSupplierItem.packages,
    includeVat
  );

  const closeModalAndResetMarkup = useCallback(() => {
    onCloseBracketPriceModal();
    resetMarkup();
  }, [onCloseBracketPriceModal, resetMarkup]);

  const saveBracketPrices = useCallback(
    ({ packageMarkups, validFrom }: DatedPackageMarkups) => {
      const priceConditions: CreateMgItemLevelSalesPriceCondition[] = [];
      const factorConditions: CreateMgItemLevelMarkupCondition[] = [];

      Object.entries(packageMarkups).forEach(([packageClassCode, packageMarkup]) => {
        const templateName = `${packageMarkup?.gtin} (${packageClassCode})`;
        if ((packageMarkup as CreatedSalesPrice)?.salesPriceIncVat) {
          priceConditions.push({
            mgSupplierId: selectedSupplierId,
            mgItemNumber,
            validFrom,
            salesPrice: includeVat
              ? (packageMarkup as CreatedSalesPrice).salesPriceIncVat
              : (packageMarkup as CreatedSalesPrice).salesPriceWithoutVat,
            incVat: includeVat,
            gtin: Number(packageMarkup.gtin),
            templateName,
            unit: selectedSupplierItem?.basePriceUnit,
          });
        } else if ((packageMarkup as CreatedSalesFactor)?.salesFactor) {
          factorConditions.push({
            mgSupplierId: selectedSupplierId,
            mgItemNumber,
            validFrom,
            salesFactor: (packageMarkup as CreatedSalesFactor).salesFactor,
            gtin: Number(packageMarkup.gtin),
            templateName,
          });
        }
      });

      return createMultipleMgItemLevelConditions([...priceConditions, ...factorConditions]);
    },

    [
      createMultipleMgItemLevelConditions,
      includeVat,
      mgItemNumber,
      selectedSupplierId,
      selectedSupplierItem?.basePriceUnit,
    ]
  );

  const onSubmitBracketPrices = useCallback(
    (markups: DatedPackageMarkups) => {
      saveBracketPrices(markups).then(() => {
        closeModalAndResetMarkup();
      });
    },
    [closeModalAndResetMarkup, saveBracketPrices]
  );
  return (
    <EditBracketPricesDialog
      open={isBracketPriceModalOpen}
      onClose={closeModalAndResetMarkup}
      includeVat={includeVat}
      onToggleIncludeVat={toggleIncludeVat}
      saveMarkups={onSubmitBracketPrices}
      isSavingMarkups={isLoadingPostChanges}
      packagePrices={packagePrices}
      baseUnit={selectedSupplierItem.basePriceUnit}
      editable
      t={t}
    />
  );
}
