export enum PriceSuggestionType {
  MG_AGGRESSIVE = 'MG_AGGRESSIVE',
  MG_CONSERVATIVE = 'MG_CONSERVATIVE',
  MG_MODERATE = 'MG_MODERATE',
  FINFO_BASE = 'FINFO_BASE',
  FINFO_RECOMMENDED = 'FINFO_RECOMMENDED',
}

export interface PriceSuggestionTypeWithCount {
  suggestionType: PriceSuggestionType;
  count: number;
}
