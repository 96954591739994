import { Checkbox, Divider, Stack, Typography } from '@mui/material';
import { ProductGroupFilter, ToggleSwitch } from '@retail/components';
import { MgSupplierItemSearchField } from '@retail/products/components';
import { OverGroup, ProductGroup } from '@retail/products/types';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { useDisclosure } from '@shared/hooks';

interface Props {
  agreements: PurchaseAgreement[];
  productGroupHierarchy: OverGroup[];
  selectedPurchaseAgreements: PurchaseAgreement[];
  selectedProductGroup?: ProductGroup;
  includeFuturePrices: boolean;
  selectPurchaseAgreement: (agreement: PurchaseAgreement) => void;
  selectProductGroup: (productGroup: string | null) => void;
  setQuery: (query: string) => void;
  toggleIncludeFuturePrices: () => void;
}

export function PriceListFilters({
  agreements,
  productGroupHierarchy,
  selectedPurchaseAgreements,
  selectedProductGroup,
  includeFuturePrices,
  selectPurchaseAgreement,
  selectProductGroup,
  setQuery,
  toggleIncludeFuturePrices,
}: Props) {
  const t = usePurchaseAgreementsTFunction();

  const { isOpen: showProductGroups, onToggle: toggleShowProductGroups } = useDisclosure(false);

  return (
    <Stack
      gap={3}
      px={2}
      overflow="auto"
      minWidth={310}
      borderLeft={({ palette }) => `1px solid ${palette.divider}`}
    >
      <Stack>
        <Stack gap={1}>
          <Typography variant="body2" color="text.secondary">
            {t('purchaseAgreements.priceList.viewPricesFor')}:
          </Typography>
          {agreements.map((agreement) => {
            return (
              <Stack key={agreement.id} flexDirection="row" alignItems="center">
                <Checkbox
                  id={agreement.id + ''}
                  checked={
                    !!selectedPurchaseAgreements.find(
                      (prevAgreement) => prevAgreement.id === agreement.id
                    )
                  }
                  onClick={() => selectPurchaseAgreement(agreement)}
                />
                <Stack
                  sx={{ cursor: 'pointer' }}
                  component="label"
                  htmlFor={agreement.id + ''}
                  flexGrow={1}
                  flexDirection="row"
                  alignItems="center"
                  gap={2}
                >
                  <Typography variant="subtitle2">{agreement.name}</Typography>
                  <Divider orientation="vertical" flexItem />
                  <Typography variant="body2">{agreement.id}</Typography>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <ToggleSwitch
        justifyContent="space-between"
        text={t('purchaseAgreements.priceList.includeFuturePrices')}
        toggle={includeFuturePrices}
        setToggle={toggleIncludeFuturePrices}
      />
      <MgSupplierItemSearchField isDisabled={false} onSubmitSearch={setQuery} size="small" />
      <ProductGroupFilter
        productGroups={productGroupHierarchy}
        selectedProductGroup={selectedProductGroup as ProductGroup}
        showProductGroups={showProductGroups}
        toggleShowProductGroups={toggleShowProductGroups}
        selectProductGroup={selectProductGroup}
        getCount={(productGroup: ProductGroup) => productGroup.numberOfItems}
      />
    </Stack>
  );
}
