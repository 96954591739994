import { DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { InfoChip, ToggleSwitch } from '@retail/components';
import { MgSupplierItemConditionsPageResult } from '@retail/calculus/types';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { AffectedItemsTable } from './AffectedItemsTable';
import { Dialog } from '@shared/components';

interface Props {
  open: boolean;
  items: MgSupplierItemConditionsPageResult;
  onlyItemsInAssortment: boolean;
  onClose: () => void;
  toggleOnlyItemsInAssortment: () => void;
  updatePage: Dispatch<SetStateAction<number>>;
  updatePageSize: Dispatch<SetStateAction<number>>;
}

export function AffectedItemsDialog({
  open,
  items,
  onlyItemsInAssortment,
  onClose,
  toggleOnlyItemsInAssortment,
  updatePage,
  updatePageSize,
}: Props) {
  const t = useCalculusTFunction();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          height: '100%',
        },
      }}
    >
      <DialogTitle>{t('calculus.itemsAffected.priceList')}</DialogTitle>
      <DialogContent>
        <Stack height="100%">
          <Stack flexDirection="row" py={2} px={4} justifyContent="space-between">
            <Stack flexDirection="row" gap={1} alignItems="center">
              <Typography variant="subtitle1">
                {t('calculus.itemsAffected.affectedItems')}:
              </Typography>
              <InfoChip label={items.totalItems} />
            </Stack>
            <ToggleSwitch
              toggle={onlyItemsInAssortment}
              setToggle={toggleOnlyItemsInAssortment}
              text={t('calculus.itemsAffected.onlyInAssortment')}
            />
          </Stack>

          <AffectedItemsTable {...items} updatePage={updatePage} updatePageSize={updatePageSize} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
