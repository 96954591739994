import { useMemo, useState } from 'react';
import { Supplier } from '@retail/suppliers/types';
import { CalculusAreas, ConditionState, FreightCondition } from '@retail/calculus/types';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { EditFreightConditionDialog } from './EditFreightCondition';
import { useLevelSearchParams } from '@retail/calculus/hooks';
import { CreateFreightConditionContainer } from './CreateFreightCondition';
import { AffectedItemsContainer } from '@retail/calculus/containers';
import { useFetchAssortmentConditions } from '@retail/calculus/data-access';
import { FreightConditionsTable } from './FreightConditionsTable';
import { DeleteFreightDialog } from './DeleteFreightCondition';
import { AddButton, CalculusActionPortal, DeleteDialog } from '@retail/components';
import { useCalculusConditionActions } from '@retail/calculus/context';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useLanguageSelector } from '@retail/app/hooks';
import { Stack } from '@mui/material';

interface Props {
  supplier: Supplier;
  typesToShow: ConditionState;
  isCreateOpen: boolean;
  toggleCreateDialog: () => void;
}

export const FreightConditions = ({
  supplier,
  typesToShow,
  isCreateOpen,
  toggleCreateDialog,
}: Props) => {
  const t = useCalculusTFunction();
  const { language } = useLanguageSelector();

  const { draftAssortmentId, baseAssortmentId } = useDepartmentAssortments();
  const { selectedProductGroupNumber, selectedMgItemId } = useLevelSearchParams();
  const { handleDeleteAssortmentConditions } = useCalculusConditionActions();

  const [conditionForAffectedItemsView, setConditionForAffectedItemsView] = useState<
    FreightCondition | undefined
  >(undefined);
  const [conditionForEdit, setConditionForEdit] = useState<FreightCondition | undefined>(undefined);
  const [conditionIdToDelete, setConditionIdToDelete] = useState<undefined | number>(undefined);
  const [conditionForDeactivation, setConditionForDeactivation] = useState<
    FreightCondition | undefined
  >(undefined);

  const { data: conditionDtos } = useFetchAssortmentConditions({
    assortmentId: typesToShow === ConditionState.ACTIVE ? baseAssortmentId : draftAssortmentId,
    conditionTypes: [CalculusAreas.FREIGHT],
    conditionState: typesToShow === ConditionState.DRAFT ? ConditionState.ALL : typesToShow,
    mgSupplierIds: [supplier.mgSupplierId],
    groupNumbers: selectedProductGroupNumber ? [selectedProductGroupNumber] : [],
    mgItemNumbers: selectedMgItemId ? [selectedMgItemId] : [],
    includeOwnerConditions: typesToShow !== ConditionState.DRAFT,
  });

  const freightConditionsDTO = useMemo(
    () =>
      conditionDtos?.freightConditions?.supplierConditions
        .map((supplierCondition) => supplierCondition.conditions)
        .flat() ?? [],
    [conditionDtos]
  );

  const freightConditions = useMemo(
    () => freightConditionsDTO.map(FreightCondition.fromDto),
    [freightConditionsDTO]
  );

  const activeFreightConditions = freightConditions.filter(
    ({ operation }) => operation !== 'DELETE'
  );

  return (
    <Stack flexGrow={1} overflow="auto" borderTop={({ palette }) => `1px solid ${palette.divider}`}>
      <CalculusActionPortal>
        <AddButton onClick={toggleCreateDialog}>{t('calculus.createFreightCondition')}</AddButton>
      </CalculusActionPortal>
      <FreightConditionsTable
        supplier={supplier}
        language={language}
        freightConditions={activeFreightConditions}
        editCondition={setConditionForEdit}
        deactivateCondition={setConditionForDeactivation}
        deleteCondition={setConditionIdToDelete}
        setConditionForAffectedItemsView={setConditionForAffectedItemsView}
        openCreateDialog={toggleCreateDialog}
      />

      {isCreateOpen && (
        <CreateFreightConditionContainer
          supplierId={supplier.mgSupplierId}
          closeCreateDialog={toggleCreateDialog}
        />
      )}

      {conditionForDeactivation && (
        <DeleteFreightDialog
          isOpen
          conditionToDelete={conditionForDeactivation}
          onClose={() => setConditionForDeactivation(undefined)}
        />
      )}
      {conditionForEdit && (
        <EditFreightConditionDialog
          isOpen
          supplierId={supplier.mgSupplierId}
          freightConditionToEdit={conditionForEdit}
          onClose={() => setConditionForEdit(undefined)}
        />
      )}
      {conditionIdToDelete && (
        <DeleteDialog
          isOpen
          dialogTitle={t('calculus.actions.deleteFromDraft')}
          message={t('calculus.actions.confirmRemoval')}
          onDelete={() => handleDeleteAssortmentConditions([conditionIdToDelete])}
          onClose={() => setConditionIdToDelete(undefined)}
        />
      )}
      {conditionForAffectedItemsView && (
        <AffectedItemsContainer
          open
          onClose={() => setConditionForAffectedItemsView(undefined)}
          conditionType={CalculusAreas.FREIGHT}
          productGroup={conditionForAffectedItemsView?.condition.productGroup?.groupNumber}
          discountGroupId={conditionForAffectedItemsView?.condition.discountGroup?.id}
          mgSupplierId={supplier.mgSupplierId}
        />
      )}
    </Stack>
  );
};
