import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { pdmRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import { MarketIcon, ShoppingCartIcon, StorageUnitIcon } from '@shared/custom-icons';
import { AppSidebar, Route } from './AppSidebar';

export function PdmAppSidebar() {
  const t = useAppTFunction();

  const marketInsightRouteMatch = useMatch(`${pdmRoutes.marketInsight.fullRoutePath}/*`);
  const itemsRouteMatch = useMatch(`${pdmRoutes.items.fullRoutePath}/*`);
  const brandAssortmentRouteMatch = useMatch(`${pdmRoutes.brandAssortment.fullRoutePath}/*`);

  const navRoutes: Route[] = useMemo(() => {
    const routes: Route[] = [
      {
        title: t('mgPdm.brandAssorment'),
        to: pdmRoutes.brandAssortment.getFullLinkPath(),
        Icon: StorageUnitIcon,
        isActive: !!brandAssortmentRouteMatch,
      },
      {
        title: t('app.routes.products'),
        to: pdmRoutes.items.getFullLinkPath(),
        Icon: ShoppingCartIcon,
        isActive: !!itemsRouteMatch,
      },
      {
        title: t('app.routes.marketInsight'),
        to: pdmRoutes.marketInsight.getFullLinkPath(),
        Icon: MarketIcon,
        isActive: !!marketInsightRouteMatch,
      },
    ];

    return routes;
  }, [t, brandAssortmentRouteMatch, marketInsightRouteMatch, itemsRouteMatch]);

  return <AppSidebar navRoutes={navRoutes} />;
}
