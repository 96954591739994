import { FreightConditionDTO } from '../../dtos';
import { CalculusAreas } from '../CalculusAreas';
import { Operation } from '../Conditions';
import { ConditionBase, ConditionBaseProps } from '../ConditionBase';
import { Condition } from '../Condition';

interface FreightConditionProps extends ConditionBaseProps<FreightCondition> {
  freightFactor: number;
  percentAddon?: number;
  fixedAddOn?: number;
  fixedAddOnUnit?: string;
}

export class FreightCondition extends ConditionBase<FreightCondition> {
  freightFactor: number;
  percentAddon?: number;
  fixedAddOn?: number;
  fixedAddOnUnit?: string;

  constructor({
    freightFactor,
    percentAddon,
    fixedAddOn,
    fixedAddOnUnit,
    ...superProps
  }: FreightConditionProps) {
    super(superProps);
    this.freightFactor = freightFactor;
    this.percentAddon = percentAddon;
    this.fixedAddOn = fixedAddOn;
    this.fixedAddOnUnit = fixedAddOnUnit;
  }

  static fromDto = (dto: FreightConditionDTO): FreightCondition =>
    new FreightCondition({
      id: dto.id,
      assortmentId: dto.assortmentId,
      assortmentType: dto.assortmentType,
      bindingType: dto.bindingType,
      mgSupplierId: dto.mgSupplierId,
      levelProductGroup: dto.levelProductGroup,
      levelProductGroupName: dto.levelProductGroupName,
      mgItemNumber: dto.mgItemNumber,
      finfoNumber: dto.finfoNumber,
      mgSupplierName: dto.mgSupplierName,
      mgSupplierItemId: dto.mgSupplierItemId,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      validFrom: dto.validFrom ? new Date(dto.validFrom) : undefined,
      validTo: dto.validTo ? new Date(dto.validTo) : undefined,
      active: dto.active,
      current: dto.current,
      freightFactor: dto.freightFactor,
      percentAddon: dto.percentAddon,
      fixedAddOn: dto.fixedAddOn,
      fixedAddOnUnit: dto.fixedAddOnUnit,
      inherited: dto.inherited,
      source: dto.source,
      futureConditions: dto.futureConditions?.map(FreightCondition.fromDto),
      subConditions: dto.subConditions?.map(FreightCondition.fromDto),
      replacesConditions: dto.replacesConditions?.map(FreightCondition.fromDto),
      newCondition: dto.newCondition && FreightCondition.fromDto(dto.newCondition),
      operation: dto.operation as Operation,
      condition: Condition.fromDto(dto.condition),
    });

  getType = () => CalculusAreas.FREIGHT;
}
