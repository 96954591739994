import { Stack, Typography } from '@mui/material';
import { usePriceSuggestionTFunction } from '@retail/price-suggestion/i18n';
import { PriceSuggestionType } from '@retail/price-suggestion/types';
import { getPriceSuggestionType } from '@retail/products/utils';

interface Props {
  priceType: PriceSuggestionType;
  itemCount?: number;
}

export const RadioLabel = ({ priceType, itemCount }: Props) => {
  const t = usePriceSuggestionTFunction();

  return (
    <Stack direction="row" gap={3} textAlign="center">
      <Typography variant="body1">{getPriceSuggestionType(t, priceType)}</Typography>
      {itemCount && (
        <Typography variant="body2">{t('priceSuggestion.itemCount', { itemCount })}</Typography>
      )}
    </Stack>
  );
};
