import { Stack, Typography } from '@mui/material';
import { formatPrice } from '@retail/products/utils';
import { useMemo } from 'react';
import { useProductsTFunction } from '@retail/products/i18n';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { calculateVatPrice } from '@retail/utils';

interface Props {
  unit: string;
  price?: number;
  priceDate?: string;
  includeVat: boolean;
}

export const OurPriceCell: React.FC<Props> = ({ price, unit, priceDate, includeVat }) => {
  const { i18n } = useTranslation();
  const displayPrice = useMemo(
    () => (price ? formatPrice(calculateVatPrice(price, includeVat), i18n.language) : '-'),
    [i18n.language, includeVat, price]
  );
  const t = useProductsTFunction();

  return (
    <Stack alignItems="center">
      <Typography variant="subtitle2">
        {t('products.prices.pricePerUnit', { price: displayPrice, unit })}
      </Typography>

      <Typography variant="body2">{priceDate ? dayjs(priceDate).format('ll') : '-'}</Typography>
    </Stack>
  );
};
