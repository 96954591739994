import { SupplierPriceSuggestionConditionsDTO } from '../../dtos';
import { PriceSuggestionCondition } from './PriceSuggestionCondition';

interface SupplierPriceSuggestionConditionProps {
  mgSupplierId: number;
  priceSuggestionConditions: PriceSuggestionCondition[];
}

export class SupplierPriceSuggestionCondition {
  mgSupplierId: number;
  priceSuggestionConditions: PriceSuggestionCondition[];

  constructor({ mgSupplierId, priceSuggestionConditions }: SupplierPriceSuggestionConditionProps) {
    this.mgSupplierId = mgSupplierId;
    this.priceSuggestionConditions = priceSuggestionConditions;
  }

  static fromDto = (dto: SupplierPriceSuggestionConditionsDTO): SupplierPriceSuggestionCondition =>
    new SupplierPriceSuggestionCondition({
      mgSupplierId: dto.mgSupplierId,
      priceSuggestionConditions: dto.conditions.map(PriceSuggestionCondition.fromDto),
    });
}
