import { MgSupplierItemPackage } from '@retail/products/types';
import { SelectInputField, TextInputField } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';
import { useFreightTFunction } from '@retail/freight/i18n';
import { CreateFreightConditionSchema } from './freightConditionSchema';
import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

interface Props {
  supplierItemPackages: MgSupplierItemPackage[];
}

export function FixedAddonFreightForm({ supplierItemPackages }: Props) {
  const t = useFreightTFunction();
  const { i18n } = useTranslation();

  const { control } = useFormContext<CreateFreightConditionSchema>();
  const fixedAddonController = useController({ name: 'fixedAddon', control });
  const fixedAddonUnitController = useController({ name: 'fixedAddonUnit', control });

  const fixedAddonValue = fixedAddonController.field.value
    ? formatPrice(fixedAddonController.field.value, i18n.language)
    : '';

  const unitOptions = useMemo(
    () =>
      supplierItemPackages.map((pakg) => {
        return {
          value: pakg.vilmaUnit.unit,
          name: pakg.vilmaUnit.description ?? pakg.vilmaUnit.unit,
        };
      }),
    [supplierItemPackages]
  );

  return (
    <Stack flexDirection="row" alignItems="flex-end" gap={2}>
      <TextInputField
        {...fixedAddonController.field}
        value={fixedAddonValue}
        error={fixedAddonController.fieldState.error?.message}
        margin="none"
        required
        label={t('freight.form.fixedAddon')}
        size="small"
        placeholder={t('freight.actions.writeAddon')}
        onChange={(e) => fixedAddonController.field.onChange(e.target.value)}
      />
      <Typography pb={1}>{t('freight.form.per')}</Typography>
      <SelectInputField
        {...fixedAddonUnitController.field}
        error={fixedAddonUnitController.fieldState.error?.message}
        size="small"
        width={200}
        label={t('freight.form.unit')}
        placeholder={t('freight.form.selectUnit')}
        menuItems={unitOptions}
        required
      />
    </Stack>
  );
}
