import { useUserBrands } from '@retail/app/stores/selected-context';
import { useAssortmentItems } from '@retail/my-assortment/context';
import { FilterOptions, SetFilterOptions } from '@retail/my-assortment/types';
import { usePagination } from '@shared/hooks';
import { AssortmentItems } from './AssortmentItems';
import { useDebounce } from '@retail/hooks';

interface Props {
  baseAssortmentId: number;
  draftAssortmentId: number;
  searchFilters: FilterOptions;
  setSearchFilters: SetFilterOptions;
  showFilters: boolean;
  toggleFilters: () => void;
  toggleAddItemsDialog: () => void;
}

export function AssortmentItemsContainer({
  baseAssortmentId,
  draftAssortmentId,
  searchFilters,
  showFilters,
  setSearchFilters,
  toggleFilters,
  toggleAddItemsDialog,
}: Props) {
  const { selectedBrand } = useUserBrands();
  const pagination = usePagination();

  const debouncedSearch = useDebounce(searchFilters.searchQuery, 350);

  const { assortmentItems } = useAssortmentItems({
    assortmentId: draftAssortmentId,
    selectedSupplier: searchFilters.selectedSupplier?.mgSupplierId,
    selectedProductGroup: searchFilters.selectedProductGroup?.groupNumber,
    selectedDiscountGroup: searchFilters.selectedDiscountGroup?.id,
    searchQuery: debouncedSearch,
    inAssortment: true,
    withAgreementPrices: searchFilters.itemsWithAgreementPrice,
    calculationPackages: selectedBrand.wholesaler
      ? ['PRIMARY', 'SECONDARY', 'TERTIARY']
      : ['PRIMARY'],
    pagination,
  });

  const itemsWithPriority = assortmentItems.filter((item) => !!item.priority);

  return (
    <AssortmentItems
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      assortmentId={baseAssortmentId}
      assortmentItems={itemsWithPriority}
      totalItems={itemsWithPriority.length}
      pagination={pagination}
      showFilters={showFilters}
      toggleFilters={toggleFilters}
      toggleAddItemsDialog={toggleAddItemsDialog}
    />
  );
}
