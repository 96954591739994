import { CustomerMarkupConditionMinimalDTO } from '../../dto';
import { Condition } from './Condition';

interface CustomerMarkupConditionMinimalProps {
  condition: Condition;
  salesFactor?: number;
  percent?: number;
  customerPriceCoverage?: number;
}

export class CustomerMarkupConditionMinimal {
  condition: Condition;
  salesFactor?: number;
  percent?: number;
  customerPriceCoverage?: number;

  constructor(props: CustomerMarkupConditionMinimalProps) {
    this.condition = props.condition;
    this.salesFactor = props.salesFactor;
    this.percent = props.percent;
    this.customerPriceCoverage = props.customerPriceCoverage;
  }

  static fromDto(dto: CustomerMarkupConditionMinimalDTO): CustomerMarkupConditionMinimal {
    return new CustomerMarkupConditionMinimal({
      condition: Condition.fromDto(dto.condition),
      salesFactor: dto.salesFactor,
      percent: dto.percent,
      customerPriceCoverage: dto.customerPriceCoverage,
    });
  }
}
