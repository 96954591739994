import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { ContainedButton, Dialog } from '@shared/components';
import { Supplier } from '@retail/suppliers/types';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { useCreateMarkup, useMarkupConditionUpload } from '@retail/markup/context';
import { useCallback, useEffect } from 'react';
import { ImportStateValidation, UploadMarkupConditionsContent } from '@retail/markup/components';
import { useToast } from '@retail/hooks';

interface Props {
  open: boolean;
  onClose: () => void;
  supplier: Supplier;
}

const csvData = 'MgItem-nummer;Gruppenummer;GruppeKode;GyldigFra;Salgsfaktor;Salgspris;InkMva';

function UploadMarkupConditionsDialog({ supplier, open, onClose }: Props) {
  const t = useAppTFunction();
  const toast = useToast();

  const { invalidateMarkups, mutatePostMarkupChanges } = useCreateMarkup();

  const { importedMarkupConditions, importState, resetImportMarkupConditions } =
    useMarkupConditionUpload({ mgSupplierId: supplier.mgSupplierId });

  const handleUploadMarkupConditions = useCallback(async () => {
    await mutatePostMarkupChanges({
      body: {
        markupConditions: {
          supplierConditions: importedMarkupConditions,
        },
      },
    })
      .then(() => {
        toast.success(
          t(
            `${
              importedMarkupConditions.length === 1
                ? 'markup.messages.uploadedMarkups.singular'
                : 'markup.messages.uploadedMarkups.plural'
            }`
          )
        );
        invalidateMarkups();
        onClose();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('calculus.error.generic'));
      });
  }, [toast, importedMarkupConditions, invalidateMarkups, mutatePostMarkupChanges, onClose, t]);

  useEffect(() => {
    if (!open) resetImportMarkupConditions();
  }, [open, resetImportMarkupConditions]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>{t('calculus.actions.uploadMarkup')}</DialogTitle>

      <DialogContent>
        <UploadMarkupConditionsContent
          csvData={csvData}
          resetImportMarkupConditions={resetImportMarkupConditions}
          isLarge
        />

        <Stack mt={2}>
          <ImportStateValidation importState={importState} />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', mt: 1 }}>
        <ContainedButton onClick={handleUploadMarkupConditions}>
          {t('products.uploadItems.upload')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(UploadMarkupConditionsDialog);
