import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useCallback } from 'react';
import { HierarchyLevels, MarkupCondition } from '@retail/calculus/types';
import { EditMarkupForm } from './EditMarkupForm';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useCreateMarkup } from '@retail/markup/context';
import { editMarkupSchema, EditMarkupSchema } from '@retail/markup/components';
import { calculateCoverageFromSalesFactor } from '@retail/utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  markupToEdit: MarkupCondition;
  mgSupplierId: number;
}

export function EditMarkupDialog({ markupToEdit, mgSupplierId, isOpen, onClose }: Props) {
  const t = useCalculusTFunction();

  const formMethods = useForm<EditMarkupSchema>({
    defaultValues: {
      markupName: markupToEdit.markupTemplateName,
      salesCoverage: markupToEdit.salesPriceCoverage
        ? markupToEdit.salesPriceCoverage + ''
        : calculateCoverageFromSalesFactor(markupToEdit.salesFactor).toFixed(2) + '',
      salesFactor: markupToEdit.salesFactor + '',
      fromDate: new Date(),
      toDate: markupToEdit.validTo ? new Date(markupToEdit?.validTo) : null,
    },
    resolver: yupResolver(editMarkupSchema(t)),
  });

  const {
    createSupplierLevelMarkupCondition,
    createProductGroupLevelMarkupCondition,
    createDiscountGroupLevelMarkupCondition,
    createMgItemLevelMarkupCondition,
  } = useCreateMarkup();

  const onActivate = useCallback(
    ({ fromDate, markupName, salesFactor }: EditMarkupSchema) => {
      if (markupToEdit.bindingType === HierarchyLevels.supplier)
        createSupplierLevelMarkupCondition({
          mgSupplierId,
          validFrom: fromDate,
          templateName: markupName,
          salesFactor: Number(salesFactor),
        });
      else if (markupToEdit.condition.productGroup)
        createProductGroupLevelMarkupCondition({
          mgSupplierId,
          validFrom: fromDate,
          templateName: markupName,
          salesFactor: Number(salesFactor),
          groupNumber: markupToEdit.condition.productGroup.groupNumber,
        });
      else if (markupToEdit.condition.discountGroup)
        createDiscountGroupLevelMarkupCondition({
          mgSupplierId,
          validFrom: fromDate,
          templateName: markupName,
          salesFactor: Number(salesFactor),
          groupCode: markupToEdit.condition.discountGroup.code,
        });
      else if (markupToEdit.mgItemNumber)
        createMgItemLevelMarkupCondition({
          mgSupplierId,
          validFrom: fromDate,
          templateName: markupName,
          salesFactor: Number(salesFactor),
          mgItemNumber: markupToEdit.mgItemNumber,
        });

      onClose();
    },
    [
      markupToEdit.bindingType,
      markupToEdit.condition.productGroup,
      markupToEdit.condition.discountGroup,
      markupToEdit.mgItemNumber,
      createSupplierLevelMarkupCondition,
      mgSupplierId,
      createProductGroupLevelMarkupCondition,
      createDiscountGroupLevelMarkupCondition,
      createMgItemLevelMarkupCondition,
      onClose,
    ]
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('calculus.actions.editEntry', { name: markupToEdit?.markupTemplateName })}
      </DialogTitle>

      <DialogContent>
        <FormProvider {...formMethods}>
          <EditMarkupForm />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <TextButton onClick={onClose} size="small">
          {t('calculus.cancel')}
        </TextButton>
        <ContainedButton
          disabled={Object.keys(formMethods?.formState?.errors).length > 0}
          onClick={formMethods.handleSubmit(onActivate)}
          size="small"
        >
          {t('calculus.actions.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
