import { MgItemPackage, MgItemPackages } from './MgItemPackages';
import { MgSupplierItemBase } from './MgSupplierItem';
import { MgPackage } from './MgPackage';
import { CompetitorPrice } from '../CompetitorPrice';

interface ConstructorProps<TSupplierItem extends MgSupplierItemBase> {
  id: number;
  supplierItems: TSupplierItem[];
  mgPackages: MgPackage[];
  updatedAt: string;
  createdAt: string;
  replacedByMgItemNumber?: number;
  competitorPrices?: Record<number, CompetitorPrice[]>;
}

export abstract class MgItemBase<TSupplierItem extends MgSupplierItemBase> {
  id: number;
  supplierItems: TSupplierItem[];
  mgPackages: MgPackage[];
  updatedAt: string;
  createdAt: string;
  replacedByMgItemNumber?: number;
  competitorPrices?: Record<number, CompetitorPrice[]>;

  protected constructor({
    id,
    supplierItems,
    mgPackages,
    updatedAt,
    createdAt,
    replacedByMgItemNumber,
    competitorPrices,
  }: ConstructorProps<TSupplierItem>) {
    this.id = id;
    this.supplierItems = supplierItems;
    this.mgPackages = mgPackages;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.replacedByMgItemNumber = replacedByMgItemNumber;
    this.competitorPrices = competitorPrices;
  }

  getPrimarySupplierItem(): TSupplierItem {
    return (
      this.supplierItems.find((supplierItem) => supplierItem.mainSupplier) ?? this.supplierItems[0]
    );
  }

  getPrimaryPackage(): MgPackage | undefined {
    return (
      this.mgPackages.find(({ packageType }) => packageType === 'PRIMARY') || this.mgPackages[0]
    );
  }

  capitalizeFirstLetter(text?: string): string | undefined {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  getPackages(): MgItemPackages {
    // @ts-ignore
    const uniquePackages: MgItemPackages = {};

    for (const pkg of this.mgPackages) {
      const vilmaUnit = pkg.vilmaUnit;
      const packageType = pkg.packageType;

      if (packageType) {
        if (!uniquePackages[packageType]) uniquePackages[packageType] = [];

        const newPackage = {
          gtinCode: pkg.gtinCode,
          description: this.capitalizeFirstLetter(vilmaUnit?.description),
          basePriceUnit: pkg.basePriceUnit,
          calculatedCount: pkg.calculatedCount,
          packageType: pkg.packageType,
          classCode: pkg.classCode,
        };

        // Check if the package already exists in the array
        const packageExists = uniquePackages[packageType].some(
          (existingPackage) => existingPackage.gtinCode === newPackage.gtinCode
        );

        if (!packageExists) uniquePackages[packageType].push(newPackage as MgItemPackage);
      }
    }

    return uniquePackages;
  }
}
