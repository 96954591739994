import { forwardRef, ReactNode } from 'react';
import {
  TreeItem2Checkbox,
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2Icon,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Provider,
  TreeItem2Root,
  useTreeItem2,
} from '@mui/x-tree-view';
import { TreeNode } from './NavigationTreeView';
import { navTreeContentStyling, navTreeGroupTransition, navTreeLabelStyling } from './styles';

interface Props extends TreeNode {
  depth: number;
  children?: ReactNode;
  includeCheckbox?: boolean;
  isSelected?: (id: string) => boolean;
}

const CustomTreeItem = forwardRef(function CustomTreeItem(
  { label, onSelect, isSelected, id, depth, children, includeCheckbox = false }: Props,
  ref: React.Ref<HTMLLIElement>
) {
  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId: id, children, label, rootRef: ref });
  const isChildNode = depth > 0;
  return (
    <TreeItem2Provider itemId={id}>
      <TreeItem2Root {...getRootProps()}>
        <TreeItem2Content status={status} sx={navTreeContentStyling(depth, status)}>
          <TreeItem2IconContainer
            {...getIconContainerProps()}
            onClick={(event) => getContentProps().onClick(event)}
          >
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          {includeCheckbox && (
            // @ts-ignore
            <TreeItem2Checkbox
              checked={isSelected && isSelected(id)}
              onClick={() => {
                onSelect && onSelect();
              }}
              visible
            />
          )}

          <TreeItem2Label
            {...getLabelProps()}
            sx={navTreeLabelStyling(isChildNode)}
            onClick={() => {
              onSelect && onSelect();
            }}
          />
        </TreeItem2Content>

        {children && (
          <TreeItem2GroupTransition {...getGroupTransitionProps()} sx={navTreeGroupTransition} />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export function NavigationTreeItem({
  label,
  childNodes = [],
  onClick,
  onSelect,
  isSelected,
  id,
  depth,
  includeCheckbox,
}: Props) {
  return (
    <CustomTreeItem
      id={id}
      label={label}
      depth={depth}
      onClick={onClick}
      onSelect={onSelect}
      isSelected={isSelected}
      includeCheckbox={includeCheckbox}
    >
      {childNodes.map((node) => (
        <NavigationTreeItem
          key={node.id}
          depth={depth + 1}
          {...node}
          isSelected={isSelected}
          includeCheckbox={includeCheckbox}
        />
      ))}
    </CustomTreeItem>
  );
}
