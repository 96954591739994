import { TanstackQueryLoadingWrapper, ToggleFiltersButton } from '@retail/components';
import { useMgSupplierItemsSearch } from '@retail/products/data-access';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { Stack, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { useDisclosure, usePagination } from '@shared/hooks';
import { useSearchFilters } from '@retail/products/context';
import {
  AddItemsToAssortmentDialog,
  MgSupplierItemSearch,
  SearchFilters,
} from '@retail/products/components';
import MonitorMgSupplierItemsDialog from './MonitorMgSupplierItemsDialog';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { MemberAssortmentChildrenProps } from './MemberAssortmentFunctionsWrapper';
import { useToast } from '@retail/hooks';
import { useNavigate } from 'react-router-dom';
import { useIsFeatureEnabled, useSelectedOrgUnit } from '@retail/app/stores/selected-context';

interface Props extends MemberAssortmentChildrenProps {
  checkedItems?: MgSupplierItemSearchResult[];
  setCheckedItems?: Dispatch<SetStateAction<MgSupplierItemSearchResult[]>>;
  disableAddItemsToMonitoringAssortment?: boolean;
}

export function MgSupplierItemSearchContainer({
  assortmentId,
  addItemsToAssortment,
  mgItemLinkPath,
  assortmentLinkPath,
  isLoading,
  checkedItems: checkedItemsProp,
  setCheckedItems: setCheckedItemsProp,
  monitoringAssortments,
  disableAddItemsToMonitoringAssortment,
}: Props) {
  const t = useProductsTFunction();
  const toast = useToast();
  const [internalCheckedItems, setInternalCheckedItems] = useState<MgSupplierItemSearchResult[]>(
    []
  );
  const isAssortmentItemsWriteEnabled = useIsFeatureEnabled('my-assortment:items:write');

  const navigate = useNavigate();
  const navigateToAssortment = useCallback(
    (assortmentId: number) => {
      assortmentLinkPath && navigate(assortmentLinkPath(assortmentId));
    },
    [assortmentLinkPath, navigate]
  );
  const navigateToMgItem = useCallback(
    (mgItemNumber: string) => {
      mgItemLinkPath && navigate(mgItemLinkPath(mgItemNumber));
    },
    [mgItemLinkPath, navigate]
  );

  const checkedItems = checkedItemsProp ?? internalCheckedItems;
  const setCheckedItems = setCheckedItemsProp ?? setInternalCheckedItems;

  const {
    isOpen: isMonitorItemsModalOpen,
    onOpen: onOpenMonitorItemsModal,
    onClose: onCloseMonitorItemsModal,
  } = useDisclosure(false);

  const {
    isOpen: isAddAssortmentItemsModalOpen,
    onOpen: onOpenAddAssortmentItemsModal,
    onClose: onCloseAddAssortmentItemsModal,
  } = useDisclosure(false);

  const { pageSize, page, setPageSize, setPage } = usePagination();

  const {
    searchFilters,
    searchFilters: {
      query,
      articleIds,
      withAgreementPrices,
      fromMainSupplier,
      inAssortment,
      selectedSuppliers,
      selectedProductGroups,
      selectedDiscountGroups,
    },
    setSearchFilters,
    filterOptions,
    filterOptions: { productGroups },
    resetFilters,
  } = useSearchFilters({ assortmentId });

  const { isOpen: showFilters, onToggle: toggleFilters } = useDisclosure(true);

  const loadingState = useMgSupplierItemsSearch(
    {
      size: pageSize,
      page,
      query,
      articleIds,
      assortmentId,
      withAgreementPrices,
      fromMainSupplier: fromMainSupplier === 'UNSPECIFIED' ? undefined : fromMainSupplier === 'YES',
      inAssortment: inAssortment === 'YES' ? true : inAssortment === 'NO' ? false : undefined,
      supplierIds: selectedSuppliers?.map(({ mgSupplierId }) => mgSupplierId),
      groupNumbers: selectedProductGroups?.map(({ groupNumber }) => groupNumber),
      discountGroupIds: selectedDiscountGroups?.map(({ id }) => id),
    },
    {
      enabled:
        !!selectedSuppliers ||
        !!selectedProductGroups ||
        (!!selectedSuppliers && !!selectedDiscountGroups) ||
        !!query ||
        articleIds.length > 0,
      suspense: false,
    }
  );

  const onAddItemsToAssortment = useCallback(
    (validFrom?: string) => {
      if (!addItemsToAssortment || !isAssortmentItemsWriteEnabled) return undefined;
      addItemsToAssortment({ items: checkedItems, validFrom })
        .then(() => {
          toast.success(
            checkedItems.length === 1
              ? t('products.mgItem.itemsAddedToDraftSingular', {
                  count: checkedItems.length,
                })
              : t('products.mgItem.itemsAddedToDraftPlural', {
                  count: checkedItems.length,
                })
          );
          setCheckedItems([]);
          onCloseAddAssortmentItemsModal();
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('products.errors.generic'));
        });
    },
    [
      addItemsToAssortment,
      checkedItems,
      isAssortmentItemsWriteEnabled,
      onCloseAddAssortmentItemsModal,
      setCheckedItems,
      t,
      toast,
    ]
  );

  return (
    <Stack flexDirection="row" flexGrow={1} justifyContent="space-between" overflow="auto">
      {!loadingState.isFetching && !loadingState.data && !loadingState.error && (
        <Stack flexGrow={1}>
          <Stack alignItems="flex-end" mr={2}>
            {!showFilters && (
              <ToggleFiltersButton showFilters={showFilters} toggleFilters={toggleFilters} />
            )}
          </Stack>
          <Stack textAlign="center" justifyContent="center" pb={24} flexGrow={1}>
            <Typography variant="h6" fontWeight="medium" color="text.secondary" alignSelf="center">
              {t('products.mgItem.search.infoText')}
            </Typography>
          </Stack>
        </Stack>
      )}

      <TanstackQueryLoadingWrapper loadingState={loadingState} showReloadButtonOnError>
        {(searchResults) => {
          const supplierItems = MgSupplierItemSearchResult.fromSearchResultDTOs(
            searchResults.result,
            productGroups
          );

          return (
            <MgSupplierItemSearch
              supplierItems={supplierItems}
              totalItemsCount={searchResults.totalCount}
              searchFilters={searchFilters}
              setSearchFilters={setSearchFilters}
              resetFilters={resetFilters}
              showFilters={showFilters}
              toggleFilters={toggleFilters}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              isAddingItemsToAssortment={isLoading ?? false}
              onItemRowClick={navigateToMgItem}
              addItemsToAssortment={
                addItemsToAssortment && isAssortmentItemsWriteEnabled
                  ? onOpenAddAssortmentItemsModal
                  : undefined
              }
              addItemsToMonitoringAssortment={
                disableAddItemsToMonitoringAssortment ? undefined : onOpenMonitorItemsModal
              }
            />
          );
        }}
      </TanstackQueryLoadingWrapper>

      {showFilters && (
        <SearchFilters
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          filterOptions={filterOptions}
          setPage={setPage}
          isFetching={loadingState.isFetching}
          showFilters={showFilters}
          toggleFilters={toggleFilters}
        />
      )}
      {isMonitorItemsModalOpen && monitoringAssortments && (
        <MonitorMgSupplierItemsDialog
          open
          onClose={onCloseMonitorItemsModal}
          selectedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          monitoringAssortments={monitoringAssortments}
          navigateToAssortment={navigateToAssortment}
        />
      )}
      {isAddAssortmentItemsModalOpen && (
        <AddItemsToAssortmentDialog
          open
          onClose={onCloseAddAssortmentItemsModal}
          isLoading={isLoading ?? false}
          addItems={onAddItemsToAssortment}
        />
      )}
    </Stack>
  );
}
