import React from 'react';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  assortmentItemFromDateSchema,
  AssortmentItemFromDateSchema,
} from '../MgDetails/MgItemMonitoring/assortmentItemFromDateSchema';
import { useAppTFunction } from '@retail/app/i18n';
import { InputDatePicker, RadioInput } from '@retail/components';
import dayjs from 'dayjs';

interface Props {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  addItems: (fromDate?: string) => void;
}

export const AddItemsToAssortmentDialog: React.FC<Props> = ({
  open,
  onClose,
  isLoading,
  addItems,
}) => {
  const t = useAppTFunction();

  const { control, handleSubmit, watch, formState } = useForm<AssortmentItemFromDateSchema>({
    resolver: yupResolver(assortmentItemFromDateSchema(t)),
    mode: 'onSubmit',
    defaultValues: {
      fromDateType: 'today',
      fromDate: null,
    },
  });
  const fromDateController = useController({ control, name: 'fromDate' });
  const fromToday = watch('fromDateType') === 'today';

  const onSubmit = handleSubmit(({ fromDate, fromDateType }) => {
    addItems(fromDateType === 'later' && fromDate ? fromDate.toISOString() : undefined);
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth component="form">
      <DialogTitle>{t('products.mgItem.addSelected')}</DialogTitle>
      <DialogContent>
        <RadioInput
          name="fromDateType"
          label={t('products.mgItem.addItemsToAssortment.fromDateType.label.plural')}
          options={[
            {
              label: t('products.mgItem.addItemsToAssortment.fromDateType.option.today'),
              value: 'today',
            },
            {
              label: t('products.mgItem.addItemsToAssortment.fromDateType.option.later'),
              value: 'later',
            },
          ]}
          control={control}
        />
        {!fromToday && (
          <InputDatePicker
            {...fromDateController.field}
            error={fromDateController.fieldState.error?.message}
            label={t('products.mgItem.addItemsToAssortment.fromDate.label')}
            minDate={dayjs().add(1, 'day').toDate()}
            margin="none"
          />
        )}
      </DialogContent>
      <DialogActions>
        <TextButton onClick={onClose}>{t('common.cancel')}</TextButton>
        <ContainedButton
          size="small"
          loading={isLoading}
          disabled={!formState.isValid}
          onClick={onSubmit}
        >
          {t('common.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};
