import { useCalculusTFunction } from '@retail/calculus/i18n';
import { CustomerMarkupCondition } from '@retail/calculus/types';
import { DeleteDialog } from '@retail/components';
import { useCustomerMarkupActions } from '@retail/customer-markup/context';

interface Props {
  customerMarkup: CustomerMarkupCondition;
  assortmentId: number;
  onClose: () => void;
}

export function DeactivateCustomerMarkupDialogContainer({
  customerMarkup,
  assortmentId,
  onClose,
}: Props) {
  const t = useCalculusTFunction();
  const { deactivateCustomerMarkupCondition } = useCustomerMarkupActions({
    assortmentIds: [assortmentId],
  });
  return (
    <DeleteDialog
      isOpen
      dialogTitle={t('calculus.actions.deleteFromDraft')}
      message={t('calculus.actions.confirmRemoval')}
      onDelete={() => deactivateCustomerMarkupCondition(customerMarkup)}
      onClose={onClose}
    />
  );
}
