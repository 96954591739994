import { PriceSuggestionType } from '@retail/price-suggestion/types';
import { PriceSuggestionConditionDTO } from '../../dtos';
import { CalculusAreas } from '../CalculusAreas';
import { Condition } from '../Condition';
import { ConditionBase, ConditionBaseProps } from '../ConditionBase';
import { Operation } from '../Conditions';

interface PriceSuggestionConditionProps extends ConditionBaseProps<PriceSuggestionCondition> {
  condition: Condition;
  priceSuggestionPriority: PriceSuggestionType[];
  newCondition?: PriceSuggestionCondition;
}

export class PriceSuggestionCondition extends ConditionBase<PriceSuggestionCondition> {
  priceSuggestionPriority: PriceSuggestionType[];

  constructor({ priceSuggestionPriority, ...superProps }: PriceSuggestionConditionProps) {
    super(superProps);
    this.priceSuggestionPriority = priceSuggestionPriority;
  }

  static fromDto = (dto: PriceSuggestionConditionDTO): PriceSuggestionCondition =>
    new PriceSuggestionCondition({
      id: dto.id,
      priceSuggestionPriority: dto.priceSuggestionPriority as PriceSuggestionType[],
      assortmentId: dto.assortmentId,
      assortmentType: dto.assortmentType,
      bindingType: dto.bindingType,
      mgSupplierId: dto.mgSupplierId,
      levelProductGroup: dto.levelProductGroup,
      levelProductGroupName: dto.levelProductGroupName,
      mgItemNumber: dto.mgItemNumber,
      finfoNumber: dto.finfoNumber,
      mgSupplierName: dto.mgSupplierName,
      mgSupplierItemId: dto.mgSupplierItemId,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      validFrom: dto.validFrom ? new Date(dto.validFrom) : undefined,
      validTo: dto.validTo ? new Date(dto.validTo) : undefined,
      active: dto.active,
      current: dto.current,
      inherited: dto.inherited,
      source: dto.source,
      replacesConditions: dto.replacesConditions?.map(PriceSuggestionCondition.fromDto),
      newCondition: dto.newCondition && PriceSuggestionCondition.fromDto(dto.newCondition),
      operation: dto.operation as Operation,
      condition: dto.condition && Condition.fromDto(dto.condition),
    });

  getType = () => CalculusAreas.PRICE_SUGGESTION;
}
