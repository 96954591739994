import { Package } from './Package';
import { VatPackagePrice } from './VatPackagePrice';
import { CalculationDTO } from '../../dto';
import { MarkupCondition } from './MarkupCondition';
import { PriceError, PriceStatus } from '@retail/calculus/types';
import { DatedPrice } from './DatedPrice';
import { FreightCondition } from './FreightCondition';
import { PriceResultType } from './PriceCalculation';
import { PriceSuggestionConditionMinimal } from './PriceSuggestionMinimal';
import { PriceSuggestionType } from '@retail/price-suggestion/types';

export class Calculation {
  validFrom: Date;
  validTo?: Date;
  priceResultType?: PriceResultType;
  priceSuggestionType?: PriceSuggestionType;
  calculationPackage: Package;
  packageSalesPrices?: VatPackagePrice[];
  purchasePrice?: DatedPrice;
  costPrice?: DatedPrice;
  freight?: FreightCondition;
  markup?: MarkupCondition;
  priceSuggestion?: PriceSuggestionConditionMinimal;
  status: PriceStatus;
  errors: PriceError[];

  constructor(dto: CalculationDTO) {
    this.validFrom = new Date(dto.validFrom);
    this.validTo = dto.validTo ? new Date(dto.validTo) : undefined;
    this.priceResultType = dto.priceResultType;
    this.priceSuggestionType = dto.priceSuggestionType;
    this.calculationPackage = Package.fromDto(dto.calculationPackage);
    this.packageSalesPrices = dto.packageSalesPrices?.map(VatPackagePrice.fromDto) || [];
    this.purchasePrice = dto.purchasePrice ? DatedPrice.fromDto(dto.purchasePrice) : undefined;
    this.costPrice = dto.costPrice ? DatedPrice.fromDto(dto.costPrice) : undefined;
    this.freight = dto.freightCondition
      ? FreightCondition.fromDto(dto.freightCondition)
      : undefined;
    this.markup = dto.markupCondition ? MarkupCondition.fromDto(dto.markupCondition) : undefined;
    this.priceSuggestion = dto.priceSuggestionCondition
      ? PriceSuggestionConditionMinimal.fromDto(dto.priceSuggestionCondition)
      : undefined;
    this.status = dto.status;
    this.errors = dto.errors;
  }

  getSalesPriceForUnit = (unit: string): VatPackagePrice | undefined => {
    return this.packageSalesPrices?.find(
      (packagePrice) => packagePrice.supplierItemPackage.unit.unit === unit
    );
  };

  static fromDto(dto: CalculationDTO): Calculation {
    return new Calculation(dto);
  }
}
