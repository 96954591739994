import { PrioritizeSupplierDialog } from '@retail/my-assortment/components';
import { useAssortmentItemsActions } from '@retail/my-assortment/context';
import { ItemsDTO, SupplierWithPriority } from '@retail/my-assortment/types';
import { useCallback } from 'react';

interface Props {
  mgItemNumber: number;
  suppliers: SupplierWithPriority[];
  isPrioritizeDialogOpen: boolean;
  togglePrioritizeDialog: () => void;
}

export function PrioritizeSupplierDialogContainer({
  mgItemNumber,
  suppliers,
  isPrioritizeDialogOpen,
  togglePrioritizeDialog,
}: Props) {
  const { updatePriority, updateItemsIsLoading } = useAssortmentItemsActions();

  const handlePostPriorityChanges = useCallback(
    (suppliers: SupplierWithPriority[]) => {
      const supplierItems = suppliers.map((supplier) => {
        return {
          mgSupplierId: supplier.id,
          mgItemNumber,
          priority: supplier.priority,
        } as ItemsDTO;
      });
      updatePriority(supplierItems);
    },
    [mgItemNumber, updatePriority]
  );

  return (
    <PrioritizeSupplierDialog
      suppliers={suppliers}
      isOpen={isPrioritizeDialogOpen}
      onClose={togglePrioritizeDialog}
      handlePostPriorityChanges={handlePostPriorityChanges}
      isLoading={updateItemsIsLoading}
    />
  );
}
