import { useCallback, useMemo } from 'react';
import { Box, IconButton, Stack, styled, Typography } from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
} from '@mui/x-data-grid-premium';
import { dataGridBoldClass } from '@shared/styles';
import { AssortmentItem } from '@retail/my-assortment/types';
import { StackedTextCell } from '@retail/components';
import { RedCrossIcon } from '@shared/custom-icons';
import { TrashIcon } from '@sanity/icons';
import { useAppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';
import { getDataGridLocalization, MgPrisLocale } from '@retail/utils';

interface Props {
  mgSupplierId: GridRowId;
  items: AssortmentItem[];
  isLoading: boolean;
  language: string;
  onDeleteDraftItem: (ids: number[]) => void;
  onSelectItems: (mgSupplierId: GridRowId, itemIds: number[]) => void;
}

type GridColAssortmentItem = GridColDef<AssortmentItem>;

const baseColumnProps: Partial<GridColAssortmentItem> = {
  width: 150,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  headerAlign: 'center',
  align: 'center',
};

export const DraftAssortmentItemsTableContent = ({
  mgSupplierId,
  items,
  isLoading,
  language,
  onDeleteDraftItem,
  onSelectItems,
}: Props) => {
  const t = useAppTFunction();

  const columns: GridColAssortmentItem[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'supplier',
        width: 180,
        headerName: t('myAssortment.columns.supplier'),
        headerAlign: 'left',
        align: 'left',
        renderCell: ({ row }) => {
          const topLine = !row.priority ? (
            <Box display="flex" alignItems="center">
              <RedCrossIcon />
              {row.mgSupplierName}
            </Box>
          ) : (
            row.mgSupplierName
          );

          return (
            <Box display="flex" alignItems="center">
              <StackedTextCell
                topLine={topLine}
                lineThroughTopLine={!row.priority}
                lineThroughBottomLine={!row.priority}
                bottomLine={row.mgSupplierId}
              />
            </Box>
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'itemText',
        cellClassName: () => dataGridBoldClass,
        headerName: t('myAssortment.columns.itemText'),
        headerAlign: 'left',
        align: 'left',
        width: 300,
        renderCell: ({ row }) => (
          <StackedTextCell
            topLine={row.primaryText}
            bottomLine={row.secondaryText}
            lineThroughTopLine={!row.priority}
            lineThroughBottomLine={!row.priority}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'priorityValidFrom',
        headerName: t('myAssortment.columns.priorityValidFrom'),
        renderCell: ({ row }) => dayjs(row.priorityValidFrom).format('L'),
      },
      {
        ...baseColumnProps,
        field: 'mgItemFinfoNumber',
        headerName: t('myAssortment.columns.mgItemFinfoNumber'),
        headerAlign: 'left',
        align: 'left',
        renderCell: ({ row }) => (
          <StackedTextCell
            topLine={row.mgItemNumber}
            bottomLine={row.finfoNumber}
            lineThroughTopLine={!row.priority}
            lineThroughBottomLine={!row.priority}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'itemGroup',
        width: 170,
        headerName: t('myAssortment.columns.itemGroup'),
        headerAlign: 'left',
        align: 'left',
        renderCell: ({ row }) => (
          <StackedTextCell
            topLine={row.groupNumber}
            bottomLine={row.groupDescription}
            lineThroughTopLine={!row.priority}
            lineThroughBottomLine={!row.priority}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'currentCalculatedPurchasePrice',
        headerName: t('myAssortment.columns.purchasePrice'),
        renderCell: ({ row, value }) =>
          value ? (
            <DeletedTypography variant="subtitle2" isDeleted={!row.priority}>
              {value + ',-'}
            </DeletedTypography>
          ) : (
            '-'
          ),
      },
      {
        ...baseColumnProps,
        field: 'currentCalculatedCostPrice',
        headerName: t('myAssortment.columns.costPriceInBasePriceUnit'),
        renderCell: ({ row, value }) =>
          value ? (
            <DeletedTypography variant="subtitle2" isDeleted={!row.priority}>
              {value + ' ' + t('products.prices.currency.NOK.short')}
            </DeletedTypography>
          ) : (
            '-'
          ),
      },
      {
        ...baseColumnProps,
        field: 'currentCalculatedSalesPrice',
        headerName: t('myAssortment.columns.salesPrice'),
        renderCell: ({ row, value }) =>
          value ? (
            <DeletedTypography variant="subtitle2" isDeleted={!row.priority}>
              {value + ' ' + t('products.prices.currency.NOK.short')}
            </DeletedTypography>
          ) : (
            '-'
          ),
      },
      {
        ...baseColumnProps,
        field: 'actions',
        headerName: '',
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <IconButton disabled={isLoading} onClick={() => onDeleteDraftItem([row.id])}>
            <TrashIcon fontSize="25px" />
          </IconButton>
        ),
      },
    ],
    [isLoading, onDeleteDraftItem, t]
  );

  const onRowsSelectionHandler = useCallback(
    (mgSupplierId: GridRowId, selections: GridRowSelectionModel) => {
      onSelectItems(mgSupplierId, selections as number[]);
    },
    [onSelectItems]
  );

  return (
    <DataGridPremium
      getRowId={(row) => row.mgItemNumber}
      columns={columns}
      rows={items}
      rowHeight={70}
      disableRowSelectionOnClick
      disableColumnResize
      disableColumnReorder
      hideFooter
      checkboxSelection
      localeText={getDataGridLocalization(language as MgPrisLocale)}
      onRowSelectionModelChange={(rowSelectionModel) =>
        onRowsSelectionHandler(mgSupplierId, rowSelectionModel)
      }
      slots={{
        noRowsOverlay: () => (
          <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
            <Typography variant="h3" color="text.secondary">
              {t('myAssortment.noItems')}
            </Typography>
          </Stack>
        ),
      }}
      sx={{
        '.MuiDataGrid-row:hover': {
          cursor: 'auto',
        },
      }}
    />
  );
};

interface DeletedTypographyProps {
  isDeleted: boolean;
}

const DeletedTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isDeleted',
})<DeletedTypographyProps>(({ isDeleted }) => ({
  textDecoration: isDeleted ? 'line-through' : 'none',
}));
