import { Stack } from '@mui/material';
import { CalculusAreas, Conditions } from '@retail/calculus/types';
import { ConditionTypeChip } from './DraftDrawer';

interface Props {
  draftConditions: Conditions;
  rowSupplierId: number;
}

export function DraftConditionCountChips({ draftConditions, rowSupplierId }: Props) {
  const purchaseConditionCount = draftConditions?.purchaseConditions.find(
    (condition) => condition.mgSupplierId === rowSupplierId
  )?.purchaseConditions.length;

  const freightConditionCount = draftConditions?.freightConditions?.supplierFreightConditions.find(
    (condition) => condition.mgSupplierId === rowSupplierId
  )?.freightConditions.length;

  const markupConditionCount = draftConditions?.markupConditions?.supplierMarkupConditions.find(
    (condition) => condition.mgSupplierId === rowSupplierId
  )?.markupConditions.length;

  const priceSuggestionConditionCount =
    draftConditions?.priceSuggestionConditions?.supplierPriceSuggestionConditions.find(
      (condition) => condition.mgSupplierId === rowSupplierId
    )?.priceSuggestionConditions.length;

    const customerMarkupConditionCount =
      draftConditions?.customerMarkupConditions?.supplierMarkupConditions
        .filter((condition) => condition.mgSupplierId === rowSupplierId)
        ?.flatMap((cond) => cond.markupConditions).length;

    return (
      <Stack flexDirection="row" gap={1}>
        {!!purchaseConditionCount && (
          <ConditionTypeChip
            calculusArea={CalculusAreas.PURCHASE}
            customLabel={purchaseConditionCount + ''}
            width={36}
            rounded
          />
        )}
        {!!freightConditionCount && (
          <ConditionTypeChip
            calculusArea={CalculusAreas.FREIGHT}
            customLabel={freightConditionCount + ''}
            width={36}
            rounded
          />
        )}
        {!!markupConditionCount && (
          <ConditionTypeChip
            calculusArea={CalculusAreas.MARKUP}
            customLabel={markupConditionCount + ''}
            width={36}
            rounded
          />
        )}
        {!!priceSuggestionConditionCount && (
          <ConditionTypeChip
            calculusArea={CalculusAreas.PRICE_SUGGESTION}
            customLabel={priceSuggestionConditionCount + ''}
            width={36}
            rounded
          />
        )}
        {!!customerMarkupConditionCount && (
          <ConditionTypeChip
            calculusArea={CalculusAreas.CUSTOMER_MARKUP}
            customLabel={customerMarkupConditionCount + ''}
            width={36}
            rounded
          />
        )}
      </Stack>
    );
}
