import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { DeleteOrActivateConditionIds } from '@retail/calculus/types';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { useMutateMPEFetch } from '@retail/app/hooks';
import { mpeApi } from '@retail/mgpris/config';

export const useMutateActivateDraftedConditions = (queryOptions: QueryOptions = {}) => {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void, DeleteOrActivateConditionIds[]>({
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.activateConditions.getFullLinkPath(),
    }),
    method: 'POST',
    token,
    ...queryOptions,
  });
};

export const useDeleteCondition = (queryOptions: QueryOptions = {}) => {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void, DeleteOrActivateConditionIds[]>({
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.conditions.getFullLinkPath(),
    }),
    method: 'DELETE',
    token,
    ...queryOptions,
  });
};
