import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { HierarchyLevels, PriceSuggestionCondition } from '@retail/calculus/types';
import { usePriceSuggestionTFunction } from '@retail/price-suggestion/i18n';
import {
  FollowPriceSuggestion,
  PriceSuggestionType,
  PriceSuggestionTypeWithCount,
} from '@retail/price-suggestion/types';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useCallback, useState } from 'react';
import { PriceTypesRadionGroup } from './PriceTypeRadioGroup';

interface Props {
  open: boolean;
  onClose: () => void;
  conditionForEdit: PriceSuggestionCondition;
  priceSuggestionTypes: PriceSuggestionTypeWithCount[];
  changeCondition: (activateCondition: FollowPriceSuggestion) => void;
}

export function ChangeSelectedPriceTypeForCondition({
  open,
  onClose,
  conditionForEdit,
  priceSuggestionTypes,
  changeCondition,
}: Props) {
  const t = usePriceSuggestionTFunction();
  const [checkedPriceType, checkPriceType] = useState<undefined | PriceSuggestionType>(
    conditionForEdit.priceSuggestionPriority[0]
  );

  const submitPriceTypeChange = useCallback(
    (condition: PriceSuggestionCondition) => {
      changeCondition({
        groupNumber: condition.condition.productGroup?.groupNumber,
        mgItemNumber: condition.mgItemNumber,
        supplierLevel: condition.bindingType === HierarchyLevels.supplier,
        priceSuggestionType: checkedPriceType as PriceSuggestionType,
      });
      onClose();
    },
    [changeCondition, checkedPriceType, onClose]
  );
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        {t('priceSuggestion.changePriceType', { level: conditionForEdit.getLevelName() })}
      </DialogTitle>
      <DialogContent>
        <PriceTypesRadionGroup
          checkedPriceType={checkedPriceType}
          priceSuggestionTypes={priceSuggestionTypes}
          checkPriceType={checkPriceType}
        />
      </DialogContent>
      <DialogActions>
        <TextButton size="small" onClick={onClose}>
          {t('priceSuggestion.actions.cancel')}
        </TextButton>
        <ContainedButton
          onClick={() => submitPriceTypeChange(conditionForEdit)}
          disabled={checkedPriceType === conditionForEdit.priceSuggestionPriority[0]}
          size="small"
        >
          {t('priceSuggestion.actions.toApproval')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
