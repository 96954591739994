import { Checkbox, FormControlLabel, Typography } from '@mui/material';

interface Props {
  name: string;
  draftAssortmentId: number;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function SelectPriceListAssortmentCheckbox({
  name,
  draftAssortmentId,
  checked,
  onChange,
}: Props) {
  return (
    <FormControlLabel
      value={draftAssortmentId}
      control={<Checkbox checked={checked} onChange={onChange} />}
      label={<Typography>{name}</Typography>}
    />
  );
}
