import { PageLayout, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { useParams } from 'react-router-dom';
import { useFetchMgItem } from '@retail/products/data-access';
import { MgItem } from '@retail/products/types';
import { MgItemContainer, MgItemNotFound } from '@retail/products/containers';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { PdmMgItemContainer } from '@retail/mgpdm/containers';
import { useSelectedCompetitors } from '@retail/retailer/data-access';

export function MgItemPage() {
  const { itemNumber } = useParams<{ itemNumber: string }>();

  const { selectedBrandContext, selectedOrgUnitId, selectedDepartment, selectedPriceList } =
    useSelectedOrgUnit();
  const competitors = useSelectedCompetitors(selectedOrgUnitId);

  const baseAssortmentId = (selectedDepartment?.assortmentId ?? selectedPriceList?.assortmentId)!;

  const { data: mgItemDto } = useFetchMgItem(
    {
      assortmentId: baseAssortmentId,
      mgItemNumber: Number(itemNumber),
      competitorStoreIds: competitors.map((competitor) => competitor.id),
      nPrices: 2,
    },
    { suspense: true }
  );

  const mgItem = mgItemDto && MgItem.fromDto(mgItemDto);

  return (
    <PageLayout maxWidth="full" spacing={2}>
      {mgItem ? (
        <SuspenseWithSentryErrorBoundary>
          {selectedBrandContext ? (
            <PdmMgItemContainer mgItem={mgItem} />
          ) : (
            <MgItemContainer mgItem={mgItem} />
          )}
        </SuspenseWithSentryErrorBoundary>
      ) : (
        <MgItemNotFound mgItemNr={itemNumber} />
      )}
    </PageLayout>
  );
}
