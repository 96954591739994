import { ItemAgreementPriceDTO, PriceListItemDTO, PriceType, PriceUnit } from '../dto';
import { ItemAgreement } from './ItemAgreementPrices';

export interface PriceListPageResult {
  totalItems: number;
  items: ItemAgreement[];
  page: number;
  pageSize: number;
}

export interface PriceListItemProps {
  mgSupplierItemId: number;
  mgItemNumber: number;
  finfoNumber: number;
  mgSupplierId: number;
  primaryText: string;
  secondaryText?: string;

  agreementPrices: ItemAgreementPrice[];
}

export class PriceListItem {
  mgSupplierItemId: number;
  mgItemNumber: number;
  finfoNumber: number;
  mgSupplierId: number;
  primaryText: string;
  secondaryText?: string;

  agreementPrices: ItemAgreementPrice[];

  constructor({
    mgSupplierItemId,
    mgItemNumber,
    finfoNumber,
    mgSupplierId,
    primaryText,
    secondaryText,
    agreementPrices,
  }: PriceListItemProps) {
    this.mgSupplierItemId = mgSupplierItemId;
    this.mgItemNumber = mgItemNumber;
    this.finfoNumber = finfoNumber;
    this.mgSupplierId = mgSupplierId;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.agreementPrices = agreementPrices;
  }

  static fromDto = (dto: PriceListItemDTO): PriceListItem =>
    new PriceListItem({
      mgSupplierItemId: dto.mgSupplierItemId,
      mgItemNumber: dto.mgItemNumber,
      finfoNumber: dto.finfoNumber,
      mgSupplierId: dto.mgSupplierId,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      agreementPrices: dto.agreementPrices.map(ItemAgreementPrice.fromDto),
    });
}

export interface ItemAgreementPriceProps {
  agreementId: number;
  agreementText: string;
  price?: number;
  priceUnit?: PriceUnit;
  gtinPrice?: number;
  gtinPriceUnit?: string;
  priceType?: PriceType;
  validFrom?: string;
  validTo?: string;
}

export class ItemAgreementPrice {
  agreementId: number;
  agreementText: string;
  price?: number;
  priceUnit?: PriceUnit;
  gtinPrice?: number;
  gtinPriceUnit?: string;
  priceType?: PriceType;
  validFrom?: string;
  validTo?: string;

  constructor({
    agreementId,
    agreementText,
    price,
    priceUnit,
    gtinPrice,
    gtinPriceUnit,
    priceType,
    validFrom,
    validTo,
  }: ItemAgreementPriceProps) {
    this.agreementId = agreementId;
    this.agreementText = agreementText;
    this.price = price;
    this.priceUnit = priceUnit;
    this.gtinPrice = gtinPrice;
    this.gtinPriceUnit = gtinPriceUnit;
    this.priceType = priceType;
    this.validFrom = validFrom;
    this.validTo = validTo;
  }

  static fromDto = (dto: ItemAgreementPriceDTO): ItemAgreementPrice =>
    new ItemAgreementPrice({
      agreementId: dto.agreementId,
      agreementText: dto.agreementText,
      price: dto.price,
      priceUnit: dto.priceUnit,
      gtinPrice: dto.gtinPrice,
      gtinPriceUnit: dto.gtinPriceUnit,
      priceType: dto.priceType,
      validFrom: dto.validFrom,
      validTo: dto.validTo,
    });
}
