import { useToast } from '@retail/hooks';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAppTFunction } from '@retail/app/i18n';
import { getDate } from '@retail/utils';
import { useMutatePostConditions } from '@retail/calculus/data-access';
import {
  CreateAssortmentLevelCustomerMarkupCondition,
  CreateCustomerMarkupCondition,
  EnteredField,
} from '@retail/customer-markup/types';
import { CustomerMarkupCondition, HierarchyLevels } from '@retail/calculus/types';

interface Props {
  assortmentIds: number[];
}

export const useCustomerMarkupActions = ({ assortmentIds }: Props) => {
  const t = useAppTFunction();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: postCustomerMarkupConditions, isLoading: isLoadingPost } =
    useMutatePostConditions({ assortmentIds: assortmentIds });

  const handleInvalidateQueries = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['product-groups-hierarchy'] });
  }, [queryClient]);

  const createAssortmentLevelCustomerMarkupCondition = ({
    fromDate,
    enteredField,
    percent,
    factor,
  }: CreateAssortmentLevelCustomerMarkupCondition) => {
    postCustomerMarkupConditions({
      body: {
        customerMarkupConditions: {
          assortmentConditions: [
            {
              validFrom: getDate(fromDate),
              customerMarkup: {
                factor: enteredField === EnteredField.FACTOR ? factor : undefined,
                percent: enteredField === EnteredField.PERCENT ? percent : undefined,
              },
            },
          ],
          supplierConditions: [],
        },
      },
    })
      .then(() => {
        toast.success(t('calculus.actions.conditionCreateInDrawer'));
        handleInvalidateQueries();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('common.genericError'));
      });
  };
  const createCustomerMarkupCondition = ({
    mgSupplierId,
    level,
    fromDate,
    percent,
    factor,
    enteredField,
    groupNumber,
    groupCode,
    mgItemNumber,
  }: CreateCustomerMarkupCondition) => {
    const costumerMarkup = {
      factor: enteredField === EnteredField.FACTOR ? factor : undefined,
      percent: enteredField === EnteredField.PERCENT ? percent : undefined,
    };
    postCustomerMarkupConditions({
      body: {
        customerMarkupConditions: {
          assortmentConditions: [],
          supplierConditions: [
            {
              mgSupplierId,
              mgSupplierConditions:
                level === 'supplier'
                  ? [
                      {
                        validFrom: getDate(fromDate),
                        customerMarkup: costumerMarkup,
                      },
                    ]
                  : [],
              productGroupConditions:
                level === 'productGroup' && groupNumber
                  ? [
                      {
                        validFrom: getDate(fromDate),
                        groupNumber,
                        customerMarkup: costumerMarkup,
                      },
                    ]
                  : [],
              discountGroupConditions:
                level === 'discountGroup' && groupCode
                  ? [
                      {
                        validFrom: getDate(fromDate),
                        groupCode,
                        customerMarkup: costumerMarkup,
                      },
                    ]
                  : [],
              mgSupplierItemConditions:
                level === 'item' && mgItemNumber
                  ? [
                      {
                        validFrom: getDate(fromDate),
                        mgItemNumber: mgItemNumber,
                        customerMarkup: {
                          factor: enteredField === EnteredField.FACTOR ? Number(factor) : undefined,
                          percent:
                            enteredField === EnteredField.PERCENT ? Number(percent) : undefined,
                        },
                      },
                    ]
                  : [],
            },
          ],
        },
      },
    })
      .then(() => {
        toast.success(t('calculus.actions.conditionCreateInDrawer'));
        handleInvalidateQueries();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('common.genericError'));
      });
  };

  const deactivateCustomerMarkupCondition = ({
    bindingType,
    mgSupplierId,
    condition,
    mgItemNumber,
  }: CustomerMarkupCondition) => {
    const costumerMarkup = {
      factor: undefined,
      percent: undefined,
    };
    postCustomerMarkupConditions({
      body: {
        customerMarkupConditions: {
          assortmentConditions: [],
          supplierConditions: [
            {
              mgSupplierId,
              mgSupplierConditions:
                bindingType === HierarchyLevels.supplier
                  ? [
                      {
                        validFrom: getDate(),
                        customerMarkup: costumerMarkup,
                      },
                    ]
                  : [],
              productGroupConditions:
                (bindingType === HierarchyLevels.overGroup ||
                  bindingType === HierarchyLevels.mainGroup ||
                  bindingType === HierarchyLevels.underGroup) &&
                condition.productGroup
                  ? [
                      {
                        validFrom: getDate(),
                        groupNumber: condition.productGroup.groupNumber,
                        customerMarkup: costumerMarkup,
                      },
                    ]
                  : [],
              discountGroupConditions:
                bindingType === HierarchyLevels.discountGroup && condition.discountGroup
                  ? [
                      {
                        validFrom: getDate(),
                        groupCode: condition.discountGroup.code,
                        customerMarkup: costumerMarkup,
                      },
                    ]
                  : [],
              mgSupplierItemConditions:
                bindingType === HierarchyLevels.item && mgItemNumber
                  ? [
                      {
                        validFrom: getDate(),
                        mgItemNumber: mgItemNumber,
                        customerMarkup: costumerMarkup,
                      },
                    ]
                  : [],
            },
          ],
        },
      },
    })
      .then(() => {
        toast.success(t('calculus.actions.conditionCreateInDrawer'));
        handleInvalidateQueries();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('common.genericError'));
      });
  };

  return {
    createAssortmentLevelCustomerMarkupCondition,
    createCustomerMarkupCondition,
    deactivateCustomerMarkupCondition,
    isLoadingPost,
  };
};
