import { IconButton } from '@mui/material';
import { EditButtonIcon } from '@shared/custom-icons';

interface Props {
  disabled?: boolean;
}

export const EditIconButton = ({ disabled }: Props) => {
  return (
    <IconButton disabled={disabled}>
      <EditButtonIcon fontSize="small" />
    </IconButton>
  );
};
