import {
  PriceSuggestionCondition,
  FreightCondition,
  MarkupCondition,
  Operation,
  PurchaseCondition,
  CustomerMarkupCondition,
} from '@retail/calculus/types';
import { StackedTextCell } from '@retail/components';
import { useMarkupTFunction } from '@retail/markup/i18n';
import {
  isPurchaseCondition,
  isFreightCondition,
  isMarkupCondition,
  isPriceSuggestionCondition,
  isCustomerMarkupCondition,
} from '@retail/calculus/utils';
import { ConditionRow } from './DraftDrawerTableContent';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '@retail/products/utils';

interface Props {
  condition: ConditionRow;
}

export const ChangeType = ({ condition }: Props) => {
  const t = useMarkupTFunction();
  const { i18n } = useTranslation();

  const oldCondition = condition.getPreviousCondition();
  const newCondition = condition;

  const getPurchaseText = (condition: PurchaseCondition) =>
    `${condition.agreementText} (${condition.agreementId})`;

  const getFreightText = (condition: FreightCondition) =>
    condition.percentAddon
      ? `${formatPrice(condition.percentAddon, i18n.language)} %`
      : condition.fixedAddOn
      ? t('markup.pricePerUnit', { price: condition.fixedAddOn, unit: condition.fixedAddOnUnit })
      : condition.freightFactor;

  const getMarkupText = (condition: MarkupCondition) =>
    `${condition.salesFactor} (${condition.coverageDegree} ${t('markup.salesCoverage')})`;

  const getPriceSuggestionText = (condition: PriceSuggestionCondition) =>
    condition.priceSuggestionPriority?.[0];

  const getCustomerMarkupText = (condition: CustomerMarkupCondition) =>
    condition.percent
      ? `${formatPrice(condition.percent, i18n.language)} %`
      : condition.salesFactor;

  const prevValue = !oldCondition
    ? null
    : isPurchaseCondition(oldCondition)
    ? getPurchaseText(oldCondition)
    : isFreightCondition(oldCondition)
    ? getFreightText(oldCondition)
    : isMarkupCondition(oldCondition)
    ? getMarkupText(oldCondition)
    : isPriceSuggestionCondition(oldCondition)
    ? getPriceSuggestionText(oldCondition)
    : isCustomerMarkupCondition(oldCondition)
    ? getCustomerMarkupText(oldCondition)
    : '';

  const newValue =
    newCondition.operation === Operation.DELETE
      ? null
      : isPurchaseCondition(newCondition)
      ? getPurchaseText(newCondition)
      : isFreightCondition(newCondition)
      ? getFreightText(newCondition)
      : isMarkupCondition(newCondition)
      ? getMarkupText(newCondition)
      : isPriceSuggestionCondition(newCondition)
      ? getPriceSuggestionText(newCondition)
      : isCustomerMarkupCondition(newCondition)
      ? getCustomerMarkupText(newCondition)
      : '';

  return (
    <StackedTextCell
      topLine={newValue}
      bottomLine={prevValue}
      nullableBottomLine
      lineThroughBottomLine={!!prevValue || condition.operation === Operation.DELETE}
    />
  );
};
