import { useFetchProductGroupsForSupplier } from '@retail/calculus/data-access';
import { CalculusTabOptions, SupplierWithProductGroups } from '@retail/calculus/types';
import { productGroupSearchParamKey } from '@retail/products/utils';
import { useSelectedSearchParamState } from '@shared/hooks';
import { useMemo } from 'react';

interface Props {
  mgSupplierId?: number;
  assortmentId: number;
  inAssortment: boolean;
  filterItems?: boolean;
  filterConditions?: CalculusTabOptions;
  includeOwner?: boolean;
}

export const useSelectCalculusProductGroups = ({
  mgSupplierId,
  assortmentId,
  inAssortment,
  filterItems,
  filterConditions,
  includeOwner,
}: Props) => {
  const { data: supplierWithProductGroupsDto } = useFetchProductGroupsForSupplier(
    {
      assortmentId,
      baseAssortment: inAssortment,
      filterItems: filterItems,
      includeOwner: includeOwner,
      filterConditions: filterConditions ? [filterConditions] : undefined,
    },
    mgSupplierId,
    { suspense: true }
  );
  const supplierWithProductGroups = useMemo(
    () => SupplierWithProductGroups.fromDto(supplierWithProductGroupsDto!),
    [supplierWithProductGroupsDto]
  );

  const flattenedProductGroups = SupplierWithProductGroups.flatten(
    supplierWithProductGroups.productGroups
  );

  const [selectedProductGroup, selectProductGroup, selectProductGroupId] =
    useSelectedSearchParamState({
      options: flattenedProductGroups,
      getOptionId: ({ groupNumber }) => groupNumber,
      searchParamKey: productGroupSearchParamKey,
    });

  return {
    supplierWithProductGroups,
    selectedProductGroup,
    selectProductGroup,
    selectProductGroupId,
  };
};
