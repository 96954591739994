import { useFetchDiscountGroups } from '@retail/calculus/data-access';
import { DiscountGroup } from '@retail/calculus/types';
import {
  useFetchBk04ProductGroupsForSupplier,
  useFetchMgItem,
  useMutateMgSupplierItemsSearch,
} from '@retail/products/data-access';
import {
  MgSupplierItemSearchResult,
  MgSupplierItemSearchResultDTO,
  OverGroup,
  PaginationResultDTO,
} from '@retail/products/types';
import { useDebounce } from '@retail/hooks';
import { useEffect, useMemo } from 'react';
import { isNumeric } from '@shared/utils';

interface Props {
  supplierId: number;
  selectedProductGroup?: boolean;
  selectedDiscountGroup?: boolean;
  selectedMgItemNumber?: number;
  itemSearch?: string;
  onItemSearchSuccess?: (result: PaginationResultDTO<MgSupplierItemSearchResultDTO[]>) => void;
  onItemSearchError?: (error: any) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const useFetchSelectedLevelOptions = ({
  supplierId,
  selectedProductGroup,
  selectedDiscountGroup,
  selectedMgItemNumber,
  itemSearch = '',
  onItemSearchSuccess = noop,
  onItemSearchError = noop,
}: Props) => {
  const { data: productGroupsDto = [] } = useFetchBk04ProductGroupsForSupplier(
    { suspense: true, enabled: !!selectedProductGroup },
    { supplierId }
  );

  const productGroups = productGroupsDto.map(OverGroup.fromDto);
  const flattenedProductGroups = OverGroup.flatten(productGroups);

  const { data: discountGroupsDto = [] } = useFetchDiscountGroups(
    { supplierId },
    { suspense: true, enabled: !!selectedDiscountGroup }
  );
  const discountGroups = discountGroupsDto
    .map((discountGroup) => DiscountGroup.fromDto(discountGroup))
    .sort((a, b) => a.code.localeCompare(b.code));

  const {
    data: itemsDto,
    isLoading: isLoadingFetchItems,
    mutateAsync: searchMgSupplierItems,
    reset: resetSearch,
  } = useMutateMgSupplierItemsSearch();

  const debouncedSearch = useDebounce(itemSearch, 400);

  const { query, articleIds } = useMemo(() => {
    if (isNumeric(debouncedSearch)) {
      return { query: undefined, articleIds: [debouncedSearch] };
    }
    return { query: debouncedSearch, articleIds: undefined };
  }, [debouncedSearch]);

  useEffect(() => {
    if (!query && !articleIds) {
      resetSearch();
      return;
    }

    searchMgSupplierItems({
      urlVariables: {
        supplierIds: [supplierId],
        size: 80,
        page: 0,
        query,
        articleIds,
      },
    })
      .then(onItemSearchSuccess)
      .catch(onItemSearchError);
    // eslint-disable-next-line
  }, [query, articleIds]);

  const {
    data: mgItem,
    isLoading: isLoadingMgItem,
    error: errorLoadingMgItem,
  } = useFetchMgItem(
    {
      mgItemNumber: selectedMgItemNumber || 0,
    },
    { enabled: !!selectedMgItemNumber, suspense: false }
  );

  const mgSupplierItems = useMemo(
    () =>
      itemsDto?.result
        ? MgSupplierItemSearchResult.fromSearchResultDTOs(itemsDto.result, [])
        : undefined,
    [itemsDto?.result]
  );

  return {
    productGroups,
    flattenedProductGroups,
    discountGroups,
    mgSupplierItems,
    isLoadingFetchItems,
    isLoadingMgItem,
    errorLoadingMgItem,
    mgItemDto: mgItem,
  };
};
