import { useMutatePostConditions } from '@retail/calculus/data-access';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useToast } from '@retail/hooks';
import {
  CreateDiscountGroupLevelMarkupCondition,
  CreateMarkupCondition,
  CreateMgItemLevelMarkupCondition,
  CreateMgItemLevelSalesPriceCondition,
  CreateProductGroupLevelMarkupCondition,
  CreateSupplierLevelMarkupCondition,
  isCreateMgItemLevelSalesPriceCondition,
  SupplierMarkupConditionPostDTO,
} from '@retail/markup/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
  mapMarkupConditionToDto,
  mapSalesPriceConditionToDto,
  truncateSalesFactorMaxDecimals,
} from './conditionToDto';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';

export function useCreateMarkup() {
  const t = useCalculusTFunction();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { selectedOrgUnit } = useSelectedOrgUnit();
  const draftAssortmentId = selectedOrgUnit.draftAssortmentId!;

  const invalidateMarkups = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['product-groups-hierarchy'] });
  }, [queryClient]);

  const { mutateAsync: mutatePostMarkupChanges, isLoading: isLoadingPostChanges } =
    useMutatePostConditions({ assortmentIds: [draftAssortmentId] });

  const handleCreateConditionResponse = useCallback(
    (response: Promise<void>) =>
      response
        .then(() => {
          toast.success(t('calculus.actions.conditionCreateInDrawer'));
          invalidateMarkups();
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('calculus.error.generic'));
        }),
    [invalidateMarkups, t, toast]
  );

  const createAssortmentMarkupCondition = useCallback(
    ({ validFrom, templateName, salesFactor }: CreateMarkupCondition) =>
      handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: {
              assortmentConditions: [
                {
                  validFrom: validFrom.toISOString(),
                  markup: {
                    templateCreate: {
                      templateName,
                      salesFactor: truncateSalesFactorMaxDecimals(salesFactor),
                    },
                  },
                },
              ],
            },
          },
        })
      ),
    [handleCreateConditionResponse, mutatePostMarkupChanges]
  );

  const createSupplierLevelMarkupCondition = useCallback(
    ({ mgSupplierId, validFrom, templateName, salesFactor }: CreateSupplierLevelMarkupCondition) =>
      handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: {
              supplierConditions: [
                {
                  mgSupplierId,
                  mgSupplierConditions: [
                    {
                      validFrom: validFrom.toISOString(),
                      markup: {
                        templateCreate: {
                          templateName,
                          salesFactor: truncateSalesFactorMaxDecimals(salesFactor),
                        },
                      },
                    },
                  ],
                },
              ],
            },
          },
        })
      ),
    [handleCreateConditionResponse, mutatePostMarkupChanges]
  );

  const createProductGroupLevelMarkupCondition = useCallback(
    ({
      mgSupplierId,
      validFrom,
      groupNumber,
      templateName,
      salesFactor,
    }: CreateProductGroupLevelMarkupCondition) =>
      handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: {
              supplierConditions: [
                {
                  mgSupplierId,
                  productGroupConditions: [
                    {
                      validFrom: validFrom.toISOString(),
                      groupNumber,
                      markup: {
                        templateCreate: {
                          templateName,
                          salesFactor: truncateSalesFactorMaxDecimals(salesFactor),
                        },
                      },
                    },
                  ],
                },
              ],
            },
          },
        })
      ),
    [handleCreateConditionResponse, mutatePostMarkupChanges]
  );

  const createDiscountGroupLevelMarkupCondition = useCallback(
    ({
      mgSupplierId,
      validFrom,
      groupCode,
      templateName,
      salesFactor,
    }: CreateDiscountGroupLevelMarkupCondition) =>
      handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: {
              supplierConditions: [
                {
                  mgSupplierId,
                  discountGroupConditions: [
                    {
                      validFrom: validFrom.toISOString(),
                      groupCode,
                      markup: {
                        templateCreate: {
                          templateName,
                          salesFactor: truncateSalesFactorMaxDecimals(salesFactor),
                        },
                      },
                    },
                  ],
                },
              ],
            },
          },
        })
      ),
    [handleCreateConditionResponse, mutatePostMarkupChanges]
  );

  const createMgItemLevelMarkupCondition = useCallback(
    (condition: CreateMgItemLevelMarkupCondition) =>
      handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: {
              supplierConditions: [mapMarkupConditionToDto(condition, condition.mgSupplierId)],
            },
          },
        })
      ),
    [handleCreateConditionResponse, mutatePostMarkupChanges]
  );

  const createMgItemLevelSalesPriceCondition = useCallback(
    (condition: CreateMgItemLevelSalesPriceCondition) =>
      handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: {
              supplierConditions: [mapSalesPriceConditionToDto(condition, condition.mgSupplierId)],
            },
          },
        })
      ),
    [handleCreateConditionResponse, mutatePostMarkupChanges]
  );

  const createMultipleMgItemLevelConditions = useCallback(
    (conditions: (CreateMgItemLevelSalesPriceCondition | CreateMgItemLevelMarkupCondition)[]) => {
      const conditionsDto: SupplierMarkupConditionPostDTO[] = conditions.map((condition) => {
        if (isCreateMgItemLevelSalesPriceCondition(condition)) {
          return mapSalesPriceConditionToDto(condition, condition.mgSupplierId);
        } else {
          return mapMarkupConditionToDto(condition, condition.mgSupplierId);
        }
      });

      return handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: { supplierConditions: conditionsDto },
          },
        })
      );
    },
    [handleCreateConditionResponse, mutatePostMarkupChanges]
  );

  return {
    createAssortmentMarkupCondition,
    createSupplierLevelMarkupCondition,
    createProductGroupLevelMarkupCondition,
    createDiscountGroupLevelMarkupCondition,
    createMgItemLevelMarkupCondition,
    createMgItemLevelSalesPriceCondition,
    createMultipleMgItemLevelConditions,
    mutatePostMarkupChanges,
    invalidateMarkups,
    isLoadingPostChanges,
  };
}
