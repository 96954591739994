import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Competitor } from '@retail/retailer/types';
import { CompetitorPriceCell, NoPrice, RetailerColumnHeader } from '@retail/products/components';
import { CompetitorPricesResponse } from '@retail/products/types';

type GridColCompetitor = GridColDef<Competitor>;

interface Props {
  includeVat: boolean;
  competitors: Competitor[];
  competitorPrices?: CompetitorPricesResponse;
}

const baseColumnProps: Partial<GridColCompetitor> = {
  width: 130,
  headerName: '',
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

export const useCompetitorPricesColumns = ({
  includeVat,
  competitors,
  competitorPrices,
}: Props): GridColDef[] => {
  return useMemo(
    () =>
      competitors?.map((competitor) => ({
        ...baseColumnProps,
        field: competitor.key(),
        minWidth: 190,
        renderHeader: () => (
          <RetailerColumnHeader
            retailerName={competitor.retailer().name}
            storeName={competitor.name}
            retailerUrlName={competitor.urlName()}
            logoUrl={competitor.circularLogoUrl()}
          />
        ),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        renderCell: ({ row }) => {
          const currentMgItemPrices = competitorPrices?.[row.mgItemNumber];
          if (!currentMgItemPrices) return <NoPrice />;

          const currentCompetitorMgItem = currentMgItemPrices?.[competitor.id];
          if (!currentCompetitorMgItem || !currentCompetitorMgItem.length) return <NoPrice />;

          const currentPrice = currentCompetitorMgItem[0];
          const previousPrice = currentCompetitorMgItem?.[1];

          const currentDefaultPrice = currentPrice.getDefaultPrice();
          const previousDefaultPrice = previousPrice?.getDefaultPrice();

          return (
            <CompetitorPriceCell
              currentPrice={{
                price: currentDefaultPrice.price || 0,
                unit: currentDefaultPrice.unit || '',
                priceDate: currentPrice.lastObservedAt,
              }}
              prevPrice={
                previousPrice && {
                  price: previousDefaultPrice.price || 0,
                  unit: previousDefaultPrice.unit || '',
                  priceDate: previousPrice.lastObservedAt,
                }
              }
              eqMgItemNumberSrc={currentPrice.eqMgItemNumber}
              url={currentPrice.productUrl}
              excludeVat={!includeVat}
            />
          );
        },
      })),
    [competitorPrices, competitors, includeVat]
  );
};
