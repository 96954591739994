import { useAppTFunction } from '@retail/app/i18n';
import { useToast } from '@retail/hooks';
import { useUpdateAssortmentItems } from '@retail/my-assortment/data-access';
import { ItemsDTO } from '@retail/my-assortment/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDepartmentAssortments } from './useDepartmentAssortments';
import { useMemberAssortments } from './useMemberAssortments';

export const useAssortmentItemsActions = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const t = useAppTFunction();

  const { draftAssortmentId: departmentDraftAssortmentId, departmentId } =
    useDepartmentAssortments();

  const { draftAssortmentId: memberDraftAssortmentId, memberId } = useMemberAssortments();

  const { mutateAsync: mutateUpdateMemberItems, isLoading: updateMemberItemsIsLoading } =
    useUpdateAssortmentItems({
      assortmentId: memberDraftAssortmentId,
      memberId,
    });
  const { mutateAsync: mutateUpdateDepartmentItems, isLoading: updateDepartmentItemsIsLoading } =
    useUpdateAssortmentItems({
      assortmentId: departmentDraftAssortmentId,
      memberId: departmentId,
    });

  const addItemsToAssortment = useCallback(
    (items: ItemsDTO[]) =>
      mutateUpdateMemberItems({
        body: items,
      })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['assortmentItems'],
          });
        })
        .catch((error) => {
          console.error(error);
          throw error;
        }),
    [mutateUpdateMemberItems, queryClient]
  );

  const deleteItemsByDate = useCallback(
    (itemsForDeletion: ItemsDTO[]) => {
      mutateUpdateMemberItems({
        body: itemsForDeletion,
      })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['assortmentItems'],
          });
          toast.success(t('assortment.deleteItems.success'));
        })
        .catch((error) => {
          console.error(error);
          toast.error(t('common.genericError'));
        });
    },
    [mutateUpdateMemberItems, queryClient, t, toast]
  );

  const updatePriority = useCallback(
    (items: ItemsDTO[]) => {
      mutateUpdateDepartmentItems({
        body: items,
      })
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ['mg-item'] });
          queryClient.invalidateQueries({ queryKey: ['assortmentItems'] });
        })
        .catch((e) => console.error(e));
    },
    [mutateUpdateDepartmentItems, queryClient]
  );

  return {
    deleteItemsByDate,
    addItemsToAssortment,
    updatePriority,
    updateItemsIsLoading: updateDepartmentItemsIsLoading || updateMemberItemsIsLoading,
  };
};
