import { SupplierPurchaseConditionDTO } from '../../dtos';
import { CalculusAreas } from '../CalculusAreas';

import { PurchaseCondition } from './PurchaseCondition';

interface SupplierPurchaseConditionProps {
  mgSupplierId: number;
  purchaseConditions: PurchaseCondition[];
}

export class PurchaseConditions {
  mgSupplierId: number;
  purchaseConditions: PurchaseCondition[];

  constructor({ mgSupplierId, purchaseConditions }: SupplierPurchaseConditionProps) {
    this.mgSupplierId = mgSupplierId;
    this.purchaseConditions = purchaseConditions;
  }

  static fromDto = (dto: SupplierPurchaseConditionDTO): PurchaseConditions =>
    new PurchaseConditions({
      mgSupplierId: dto.mgSupplierId,
      purchaseConditions: dto.conditions.map(PurchaseCondition.fromDto),
    });

  getType = () => CalculusAreas.PURCHASE;
}
