import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { DataGridPagination, StackedTextCell } from '@retail/components';
import { useFetchAgreementPricesComparison } from '@retail/purchase-agreements/data-access';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { PriceListItem, PurchaseAgreement } from '@retail/purchase-agreements/types';
import { WarningIcon } from '@shared/custom-icons';
import { usePagination } from '@shared/hooks';
import { dataGridHorizontalPaddingSmallClass } from '@shared/styles';
import dayjs from 'dayjs';
import { useMemo } from 'react';

interface Props {
  assortmentId: number;
  selectedAgreements: PurchaseAgreement[];
  selectedProductGroup?: string;
  selectedMgItemNr?: number;
  query?: string;
  includeFuturePrices: boolean;
}

const baseColumnProps: Partial<GridColDef<PriceListItem>> = {
  width: 160,
  sortable: false,
  headerAlign: 'left',
  align: 'left',
};

export function PriceListTable({
  assortmentId,
  selectedAgreements,
  selectedProductGroup,
  selectedMgItemNr,
  query,
  includeFuturePrices,
}: Props) {
  const t = usePurchaseAgreementsTFunction();
  const { page, pageSize, setPage, setPageSize } = usePagination();
  const { data: agreementPriceComparisonPaginationDTO } = useFetchAgreementPricesComparison(
    {
      assortmentId,
      agreementIds: selectedAgreements.map((agreement) => agreement.id),
      groupNumbers: selectedProductGroup ? [selectedProductGroup] : [],
      mgItemNumbers: selectedMgItemNr ? [selectedMgItemNr] : [],
      current: !includeFuturePrices,
      query,
      page,
      size: pageSize,
    },
    { enabled: selectedAgreements.length > 0 }
  );
  const priceListItems = (agreementPriceComparisonPaginationDTO?.result ?? []).map(
    PriceListItem.fromDto
  );

  const totalItems = agreementPriceComparisonPaginationDTO?.totalCount ?? 0;

  const columns: GridColDef<PriceListItem>[] = useMemo(() => {
    const nameColDef: GridColDef<PriceListItem> = {
      ...baseColumnProps,
      field: 'primaryText',
      headerName: t('purchaseAgreements.agreement.columns.primaryText'),
      width: 260,
      renderCell: ({ row }) => {
        return <StackedTextCell topLine={row.primaryText} bottomLine={row.secondaryText} />;
      },
    };
    const idColDef: GridColDef<PriceListItem> = {
      ...baseColumnProps,
      field: 'finfo/mgItem',
      headerName: t('purchaseAgreements.agreement.columns.finfoAndMgItemId'),
      renderCell: ({ row }) => {
        return <StackedTextCell topLine={row.mgItemNumber} bottomLine={row.finfoNumber} />;
      },
    };

    const agreementPriceColDefs: GridColDef<PriceListItem>[] = selectedAgreements.map(
      (selectedAgreement) => ({
        field: selectedAgreement.id + '',
        ...baseColumnProps,
        renderHeader: () => (
          <StackedTextCell topLine={selectedAgreement.name} bottomLine={selectedAgreement.id} />
        ),
        renderCell: ({ row }) => {
          const agreementPrice = row.agreementPrices.find(
            (agreement) => agreement.agreementId === selectedAgreement.id
          );

          const validFrom =
            agreementPrice?.validFrom && dayjs(agreementPrice.validFrom).format('L');

          const validTo = agreementPrice?.validTo && dayjs(agreementPrice.validTo);
          //Agreements that has no end date is set to year 9999
          const toDate = validTo && validTo.year() !== 9999 ? validTo.format('L') : undefined;

          return agreementPrice?.price || agreementPrice?.gtinPrice ? (
            <Stack>
              <StackedTextCell
                topLine={t('purchaseAgreements.priceList.pricePerUnit', {
                  price: agreementPrice?.price ?? agreementPrice?.gtinPrice,
                  unit: agreementPrice?.priceUnit ?? agreementPrice.gtinPriceUnit,
                })}
                bottomLine={validFrom}
              />
              {toDate && (
                <Stack flexDirection="row" gap={1}>
                  <Tooltip title={t('purchaseAgreements.priceList.expires')}>
                    <div>
                      <WarningIcon color="warning" fontSize="small" />
                    </div>
                  </Tooltip>
                  {toDate}
                </Stack>
              )}
            </Stack>
          ) : (
            '-'
          );
        },
      })
    );
    return [nameColDef, idColDef, ...agreementPriceColDefs];
  }, [selectedAgreements, t]);

  return (
    <Box sx={{ width: '100%', overflow: 'hidden', pb: 2 }}>
      <Typography pl={3}>
        <b>{totalItems}</b>{' '}
        {totalItems === 1
          ? t('purchaseAgreements.priceList.count.singular')
          : t('purchaseAgreements.priceList.count.pluaral')}
      </Typography>
      <DataGridPremium
        className={dataGridHorizontalPaddingSmallClass}
        rowHeight={60}
        getRowId={(row) => row.mgItemNumber}
        columns={columns}
        rows={priceListItems}
        rowCount={totalItems}
        slots={{
          pagination: () => <DataGridPagination />,
          noRowsOverlay: () => (
            <Stack alignItems="center" justifyContent="center" height="100%">
              {selectedAgreements.length === 0 ? (
                <Typography variant="h4" color="text.secondary">
                  {t('purchaseAgreements.priceList.seePrices')}
                </Typography>
              ) : (
                <Typography variant="h4" color="text.secondary">
                  {t('purchaseAgreements.priceList.noMatches')}
                </Typography>
              )}
            </Stack>
          ),
        }}
        pagination={true}
        paginationMode="server"
        paginationModel={{
          page,
          pageSize,
        }}
        onPaginationModelChange={(model) => {
          if (model.page !== page) setPage(model.page);
          if (model.pageSize !== pageSize) setPageSize(model.pageSize);
        }}
      />
    </Box>
  );
}
