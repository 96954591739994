import { Stack, Typography, useTheme } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { CalculusAreas } from '@retail/calculus/types';
import { Chip } from '@shared/components';
import { useMemo } from 'react';

interface Props {
  calculusArea: CalculusAreas;
  width?: number;
  height?: number;
  rounded?: boolean;
  customLabel?: string;
  labelDetails?: string;
}

export const ConditionTypeChip = ({
  calculusArea,
  width = 144,
  height = 36,
  rounded,
  customLabel,
  labelDetails,
}: Props) => {
  const t = useAppTFunction();
  const { palette } = useTheme();

  const chipLabel = useMemo(() => {
    switch (calculusArea) {
      case CalculusAreas.PURCHASE:
        return t('app.routes.agreements');

      case CalculusAreas.FREIGHT:
        return t('app.routes.freight');

      case CalculusAreas.MARKUP:
        return t('app.routes.markup');

      case CalculusAreas.PRICE_SUGGESTION:
        return t('app.routes.priceSuggestion');

      case CalculusAreas.CUSTOMER_MARKUP:
        return t('app.routes.customerMarkup');

      default:
        return '-';
    }
  }, [calculusArea, t]);

  const chipColor = useMemo(() => {
    switch (calculusArea) {
      case CalculusAreas.PURCHASE:
        return palette.info[300];
      case CalculusAreas.FREIGHT:
        return '#C0B6F44D';
      case CalculusAreas.MARKUP:
        return palette.success[100];
      case CalculusAreas.PRICE_SUGGESTION:
        return palette.warning[50];
      case CalculusAreas.CUSTOMER_MARKUP:
        return '#f8e691';
      default:
        return palette.divider;
    }
  }, [calculusArea, palette]);

  return (
    <Chip
      rounded={rounded}
      sx={{ width: width, height: height }}
      backgroundColor={chipColor}
      label={
        <Stack alignItems="center" flexDirection="column">
          {customLabel ?? chipLabel}

          <Typography variant="body2">{labelDetails}</Typography>
        </Stack>
      }
    />
  );
};
