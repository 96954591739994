import { VatPrice } from './VatPrice';
import { Package } from './Package';
import { VatPackagePriceDTO } from '../../dto';

export class VatPackagePrice {
  price: VatPrice;
  customerPrice?: VatPrice;
  suggestedPrice?: VatPrice;
  markupPrice?: VatPrice;
  supplierItemPackage: Package;

  constructor(dto: VatPackagePriceDTO) {
    this.price = VatPrice.fromDto(dto.price);
    this.customerPrice = dto.customerPrice ? VatPrice.fromDto(dto.customerPrice) : undefined;
    this.suggestedPrice = dto.suggestedPrice ? VatPrice.fromDto(dto.suggestedPrice) : undefined;
    this.markupPrice = dto.markupPrice ? VatPrice.fromDto(dto.markupPrice) : undefined;
    this.supplierItemPackage = Package.fromDto(dto.supplierItemPackage);
  }

  static fromDto(dto: VatPackagePriceDTO): VatPackagePrice {
    return new VatPackagePrice(dto);
  }
}
