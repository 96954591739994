import { Stack, useTheme } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { HierarchyLevels, PackageType } from '@retail/calculus/types';
import { chipColor } from '@retail/calculus/utils';
import { Chip } from '@shared/components';
import { ArrowRightIcon } from '@shared/custom-icons';
import { useCallback } from 'react';

interface Props {
  bindingType?: HierarchyLevels;
  futureBindingType?: HierarchyLevels;
  active: boolean;
  packageType?: PackageType;
  isPriceSuggestion?: boolean;
  isFuturePriceSuggestion?: boolean;
  hasCustomerMarkup?: boolean;
}

const chipHeight = 28;
const chipWidth = 80;

export const LevelChipWithChange = ({
  bindingType,
  futureBindingType,
  active,
  packageType,
  isPriceSuggestion,
  isFuturePriceSuggestion,
  hasCustomerMarkup,
}: Props) => {
  const t = useAppTFunction();
  const { palette } = useTheme();

  const label = useCallback(
    (bindingType?: HierarchyLevels) => {
      switch (bindingType) {
        case HierarchyLevels.supplier:
          return t('markup.levels.supplierShort');
        case HierarchyLevels.overGroup:
          return t('markup.levels.overGroupShort');
        case HierarchyLevels.mainGroup:
          return t('markup.levels.mainGroupShort');
        case HierarchyLevels.productGroup:
          return t('markup.levels.productGroupShort');

        case HierarchyLevels.discountGroup:
          return t('markup.levels.discountGroupShort');

        case HierarchyLevels.item:
          switch (packageType) {
            case 'SECONDARY':
              return t('markup.levels.secondary');
            case 'TERTIARY':
              return t('markup.levels.tertiary');
            default:
              return t('markup.levels.itemShort');
          }
        default:
          return null;
      }
    },
    [t, packageType]
  );

  const currentLevelColor = isPriceSuggestion
    ? palette.warning[50]
    : chipColor({ palette, bindingType, packageType, active });
  const futureLevelColor = isFuturePriceSuggestion
    ? palette.warning[50]
    : chipColor({
        palette,
        bindingType: futureBindingType,
        packageType,
        active,
      });

  const currentLevel = isPriceSuggestion
    ? t('myAssortment.recommendedPrices.recommendedPricesShort')
    : hasCustomerMarkup
    ? `${label(bindingType)} + ${t('myAssortment.customerMarkupShort')}`
    : label(bindingType);
  const futureLevel = isFuturePriceSuggestion
    ? t('myAssortment.recommendedPrices.recommendedPricesShort')
    : label(futureBindingType);

  return futureBindingType ? (
    <Stack
      flexDirection="row"
      height={chipHeight}
      width={chipWidth}
      overflow="hidden"
      borderRadius={2}
    >
      <Stack width="50%" height={chipHeight} justifyContent="center" bgcolor={currentLevelColor}>
        {currentLevel}
      </Stack>
      <Stack
        height={chipHeight}
        width={6}
        justifyContent="center"
        sx={{
          background: `linear-gradient(to right, ${currentLevelColor} 50%, ${futureLevelColor} 50%-100%)`,
        }}
      >
        <ArrowRightIcon />
      </Stack>
      <Stack width="50%" height={chipHeight} justifyContent="center" bgcolor={futureLevelColor}>
        {futureLevel}
      </Stack>
    </Stack>
  ) : (
    <Chip
      sx={{ height: chipHeight, width: chipWidth }}
      backgroundColor={currentLevelColor}
      label={currentLevel}
    />
  );
};
