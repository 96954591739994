import { Box, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridColDef, GridOverlay } from '@mui/x-data-grid-premium';
import { MgItem } from '@retail/products/types';
import { Competitor } from '@retail/retailer/types';
import { useMemo } from 'react';
import { useProductsTFunction } from '@retail/products/i18n';
import { CompetitorPriceCell, NoPrice, OurPriceCell, RetailerColumnHeader } from '../ProductCell';

type GridColCompetitor = GridColDef<CompetitorPricesTableType>;

const baseColumnProps: Partial<GridColCompetitor> = {
  width: 170,
  sortable: false,
  align: 'left',
  headerAlign: 'left',
  headerName: '',
};

export interface CompetitorViewProps {
  mgItem: MgItem;
  competitors: Competitor[];
}

interface CompetitorPricesTableType {
  price: number;
  unit: string;
  priceDate?: string;
}

export function CompetitorViewForMgItem({ mgItem, competitors }: CompetitorViewProps) {
  const t = useProductsTFunction();

  const filteredCompetitors = useMemo(
    () =>
      competitors
        .filter((competitor) =>
          Object.keys(mgItem.competitorPrices ?? []).includes(String(competitor.id))
        )
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
    [competitors, mgItem.competitorPrices]
  );

  const rows = useMemo(() => {
    const prioritizedSupplierItem = mgItem.getPrioritizedSupplierItem();
    if (!prioritizedSupplierItem) return [];

    const { currentSalesPrices, basePriceUnit } = prioritizedSupplierItem;
    const priceForUnit = currentSalesPrices.getCurrentCostPriceForUnit(basePriceUnit);

    return [
      {
        price: priceForUnit?.price.price ?? 0,
        unit: basePriceUnit,
      },
    ];
  }, [mgItem]);

  const columns: GridColDef[] = useMemo(() => {
    const currentItemPriceColDef: GridColDef<CompetitorPricesTableType> = {
      ...baseColumnProps,
      field: 'currentPrice',
      type: 'singleSelect',
      headerName: mgItem.getPrimarySupplierItem().primaryText,
      renderCell: () => {
        const prioritizedSupplierItem = mgItem.getPrioritizedSupplierItem();

        if (!prioritizedSupplierItem) return <NoPrice />;

        const { currentSalesPrices, basePriceUnit } = prioritizedSupplierItem;

        const getCurrentSalesPrice = () => {
          const fPakUnitPrice = currentSalesPrices?.getBracketPackagePrice('F-PAK');
          const packageSalesPrice =
            fPakUnitPrice?.bracketMarkupPrice.getPriceForUnit(basePriceUnit);

          const price = packageSalesPrice?.price.priceWithoutVat;
          return price ?? undefined;
        };

        return (
          <OurPriceCell price={getCurrentSalesPrice()} unit={basePriceUnit} includeVat={false} />
        );
      },
    };

    const competitorColDefs: GridColDef<CompetitorPricesTableType>[] = filteredCompetitors.map(
      (competitor) => ({
        ...baseColumnProps,
        field: competitor.key(),
        minWidth: 190,
        type: 'singleSelect',
        renderHeader: () => (
          <Stack>
            <RetailerColumnHeader
              retailerName={competitor.retailer().name}
              storeName={competitor.name}
              retailerUrlName={competitor.urlName()}
              logoUrl={competitor.circularLogoUrl()}
            />
          </Stack>
        ),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        renderCell: () => {
          const currentPrice = mgItem.competitorPrices?.[competitor.id]?.[0];
          const previousPrice = mgItem.competitorPrices?.[competitor.id]?.[1];

          const currentPackagePrice = currentPrice?.packagePrices.find(
            (packagePrice) => packagePrice.primaryUnit
          );
          const previousPackagePrice = previousPrice?.packagePrices.find(
            (packagePrice) => packagePrice.primaryUnit
          );

          if (!currentPackagePrice) return <NoPrice />;

          return (
            <CompetitorPriceCell
              currentPrice={{
                price: currentPackagePrice.price ?? 0,
                unit: currentPackagePrice.unit ?? '',
                priceDate: currentPrice?.lastObservedAt ?? '',
              }}
              prevPrice={
                previousPackagePrice && {
                  price: previousPackagePrice.price ?? 0,
                  unit: previousPackagePrice.unit ?? '',
                  priceDate: previousPrice?.lastObservedAt ?? '',
                }
              }
              eqMgItemNumberSrc={currentPrice?.eqMgItemNumber}
              url={currentPrice?.productUrl}
              excludeVat={false}
            />
          );
        },
      })
    );

    return [currentItemPriceColDef, ...competitorColDefs];
  }, [filteredCompetitors, mgItem]);

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium<CompetitorPricesTableType>
          getRowId={(row) => row.price}
          slots={{
            noRowsOverlay: () => (
              <GridOverlay sx={{ backgroundColor: 'inherit' }}>
                <Stack direction="row" mt={6} gap={1} flexWrap="wrap">
                  <Typography variant="h3" color={(theme) => theme.palette.grey[200]}>
                    {t('products.prices.competitors.noCompetitorPrices')}
                  </Typography>
                </Stack>
              </GridOverlay>
            ),
          }}
          initialState={{
            pinnedColumns: { left: ['currentPrice'] },
          }}
          hideFooterRowCount
          rowHeight={75}
          columns={filteredCompetitors.length > 0 ? columns : []}
          rows={filteredCompetitors.length > 0 ? rows : []}
          disableRowSelectionOnClick
          disableColumnMenu
          sx={{
            '& .MuiDataGrid-columnHeaders': { marginLeft: 0 },
            '& .MuiDataGrid-row': { paddingLeft: 0 },
            '& .MuiDataGrid-topContainer': { paddingLeft: 0, paddingRight: 0 },
          }}
        />
      </Box>
    </Box>
  );
}
