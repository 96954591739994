import { FC, ReactNode, useCallback } from 'react';
import { ItemsDTO } from '@retail/my-assortment/types';
import { pdmRoutes } from '@retail/mgpris/config';
import { ItemPriority, MgSupplierItemSearchResult } from '@retail/products/types';
import { useAssortmentActions, useBrandAssortments } from '@retail/mgpdm/context';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';
import { AddItemsProps, MemberAssortmentChildrenProps } from '@retail/products/containers';

export interface BrandAssortmentChildrenProps {
  isLoading: boolean;
  mgItemLinkPath?: (mgItemNumber: string) => string;
  assortmentLinkPath?: (assortmentId: number) => string;
  addItemsToAssortment?: (items: MgSupplierItemSearchResult[]) => Promise<void>;
  assortmentId: number;
  monitoringAssortments: MonitoringAssortment[];
}

interface Props {
  children: (props: MemberAssortmentChildrenProps) => ReactNode;
}

export const BrandAssortmentFunctionsWrapper: FC<Props> = ({ children }) => {
  const { baseAssortmentId, monitoringAssortments } = useBrandAssortments();
  const { updateAssortmentItems, updateItemsIsLoading } = useAssortmentActions();

  const mgItemLinkPath = useCallback(
    (mgItemNumber: string) => pdmRoutes.items.item.getFullLinkPath({ itemNumber: mgItemNumber }),
    []
  );

  const assortmentLinkPath = useCallback(
    (assortmentId: number) =>
      pdmRoutes.marketInsight.assortments.assortment.getFullLinkPath({ assortmentId }),
    []
  );

  const addItemsToAssortment = useCallback(
    async ({ items, validFrom }: AddItemsProps) => {
      const itemDtos: ItemsDTO[] = items.map((item) => ({
        mgSupplierId: item.mgParticipantAccountId,
        mgItemNumber: item.mgItemNumber,
        priority: ItemPriority.ZERO,
        validFrom,
      }));
      await updateAssortmentItems(itemDtos);
    },
    [updateAssortmentItems]
  );

  return children({
    addItemsToAssortment,
    isLoading: updateItemsIsLoading,
    mgItemLinkPath,
    assortmentLinkPath,
    assortmentId: baseAssortmentId,
    monitoringAssortments,
  });
};
