import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { AddItemsTabs, ImportStateValidation } from '@retail/my-assortment/components';
import { useMutateAddItemsToDraft } from '@retail/my-assortment/data-access';
import { DraftTabs, ItemsDTO, ItemWithPriority } from '@retail/my-assortment/types';
import { isValidItem } from '@retail/my-assortment/utils';
import {
  MemberAssortmentFunctionsWrapper,
  MgSupplierItemSearchContainer,
} from '@retail/products/containers';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { CentralAssortmentOverview } from '../CentralAssortments/CentralAssortmentOverview';
import { ItemPriority, MgSupplierItemSearchResult } from '@retail/products/types';
import { useAppTFunction } from '@retail/app/i18n';
import { useToast } from '@retail/hooks';
import { useAssortmentImport } from '@retail/my-assortment/context';
import {
  InputDatePicker,
  SuspenseWithSentryErrorBoundary,
  withDialogSuspenseErrorBoundary,
} from '@retail/components';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { FromDateSchema, fromDateSchema } from './fromDateSchema';
import { UploadItemsContainer } from '@retail/products/components';

const today = new Date();
const csvData = 'MgItem-nummer;MgSupplier-id;Prioritized';

interface Props {
  memberId: string;
  draftAssortmentId: number;
  open: boolean;
  onClose: () => void;
  resetFilters: () => void;
}

function AddItemsToDraft({ memberId, draftAssortmentId, onClose, resetFilters, open }: Props) {
  const t = useAppTFunction();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [selectedTab, setSelectedTab] = useState<DraftTabs>(DraftTabs.ADD_ITEMS);
  const [checkedItems, setCheckedItems] = useState<MgSupplierItemSearchResult[]>([]);
  const [checkedAssortmentItems, setCheckedAssortmentItems] = useState<ItemWithPriority[]>([]);

  const { control, handleSubmit } = useForm<FromDateSchema>({
    resolver: yupResolver(fromDateSchema(t)),
    mode: 'onSubmit',
    defaultValues: {
      fromDate: today,
    },
  });
  const fromDateController = useController({ control, name: 'fromDate' });

  const { mutateAsync: postItems, isLoading: loadingPostItems } = useMutateAddItemsToDraft({
    memberId,
    assortmentId: draftAssortmentId,
  });

  const handleSubmitSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['assortmentItems'] });
    onClose();
  }, [onClose, queryClient]);

  const submitCheckedItems = useCallback(
    (selectedItems: MgSupplierItemSearchResult[], validFrom?: string) => {
      const items: ItemsDTO[] = selectedItems.map((item) => ({
        mgSupplierId: item.mgParticipantAccountId,
        mgItemNumber: item.mgItemNumber,
        priority: ItemPriority.ZERO,
        validFrom,
      }));
      postItems({ body: items })
        .then(() => {
          setCheckedItems([]);
          handleSubmitSuccess();
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('products.errors.generic'));
        });
    },
    [handleSubmitSuccess, postItems, t, toast]
  );

  const onSubmitCentralAssortmentItems = useCallback(
    (selectedItems: ItemWithPriority[], validFrom?: string) => {
      postItems({ body: selectedItems.map((item) => ({ ...item, validFrom })) })
        .then(() => {
          setCheckedItems([]);
          handleSubmitSuccess();
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('products.errors.generic'));
        });
    },
    [handleSubmitSuccess, postItems, t, toast]
  );

  const { importedItems, validationState, resetImportedAssortmentStates, loadingValidateItems } =
    useAssortmentImport({ assortmentId: draftAssortmentId });

  const onImportItems = useCallback(
    (validFrom?: string) => {
      const validItems = importedItems
        .filter((item) => isValidItem(item, validationState))
        .map((item) => ({
          ...item,
          validFrom,
        }));
      postItems({ body: validItems })
        .then(() => handleSubmitSuccess())
        .catch((err) => console.error(err));
    },
    [handleSubmitSuccess, importedItems, postItems, validationState]
  );

  useEffect(() => {
    if (selectedTab !== DraftTabs.IMPORT) resetImportedAssortmentStates();
    if (selectedTab !== DraftTabs.ADD_ITEMS) {
      setCheckedItems([]);
      resetFilters();
    }
    if (selectedTab !== DraftTabs.CENTRAL_ASSORTMENTS) setCheckedAssortmentItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const onSubmit = handleSubmit(({ fromDate }) => {
    const validFrom = dayjs(fromDate).isSame(today, 'day') ? undefined : fromDate.toISOString();
    if (selectedTab === DraftTabs.ADD_ITEMS) {
      submitCheckedItems(checkedItems, validFrom);
    } else if (selectedTab === DraftTabs.CENTRAL_ASSORTMENTS) {
      onSubmitCentralAssortmentItems(checkedAssortmentItems, validFrom);
    } else {
      onImportItems(validFrom);
    }
  });

  const importItemsDisabled =
    (selectedTab === DraftTabs.ADD_ITEMS && checkedItems.length === 0) ||
    (selectedTab === DraftTabs.IMPORT && importedItems.length === 0) ||
    (selectedTab === DraftTabs.CENTRAL_ASSORTMENTS && checkedAssortmentItems.length === 0);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{ padding: 4, '.MuiPaper-root': { gap: 0 } }}
    >
      <DialogTitle>
        <AddItemsTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexGrow: 1 }}>
        <Stack display="flex" flexGrow={1}>
          {selectedTab === DraftTabs.ADD_ITEMS && (
            <SuspenseWithSentryErrorBoundary>
              <MemberAssortmentFunctionsWrapper>
                {({ assortmentLinkPath, isLoading, monitoringAssortments }) => (
                  <MgSupplierItemSearchContainer
                    assortmentId={draftAssortmentId}
                    assortmentLinkPath={assortmentLinkPath}
                    isLoading={isLoading}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    monitoringAssortments={monitoringAssortments}
                    disableAddItemsToMonitoringAssortment
                  />
                )}
              </MemberAssortmentFunctionsWrapper>
            </SuspenseWithSentryErrorBoundary>
          )}

          {selectedTab === DraftTabs.IMPORT && (
            <Stack display="flex" flexGrow={1} justifyContent="center">
              <UploadItemsContainer
                isLarge
                loadingValidateItems={loadingValidateItems}
                resetImportedAssortmentStates={resetImportedAssortmentStates}
                csvData={csvData}
                renderValidation={<ImportStateValidation importedItems={importedItems} />}
              />
            </Stack>
          )}
          {selectedTab === DraftTabs.CENTRAL_ASSORTMENTS && (
            <SuspenseWithSentryErrorBoundary>
              <CentralAssortmentOverview
                checkedAssortmentItems={checkedAssortmentItems}
                setCheckedAssortmentItems={setCheckedAssortmentItems}
              />
            </SuspenseWithSentryErrorBoundary>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack alignItems="flex-end" spacing={2}>
          {!importItemsDisabled && (
            <InputDatePicker
              {...fromDateController.field}
              error={fromDateController.fieldState.error?.message}
              label={t('products.mgItem.addItemsToAssortment.fromDateType.label.plural')}
              minDate={today}
              margin="none"
              size="small"
              labelDirection="row"
            />
          )}
          <Stack direction="row" spacing={2}>
            <TextButton onClick={onClose}>{t('myAssortment.cancel')}</TextButton>
            <ContainedButton
              color="secondary"
              disabled={importItemsDisabled}
              loading={loadingValidateItems || loadingPostItems}
              onClick={onSubmit}
              size="small"
            >
              {selectedTab === DraftTabs.IMPORT
                ? t('myAssortment.actions.addApprovedItemsToDraft')
                : t('myAssortment.actions.addItemsToDraft')}
            </ContainedButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(AddItemsToDraft);
