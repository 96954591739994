import { useState } from 'react';
import { useSelectedCompetitors } from '@retail/retailer/data-access';
import { Stack, Typography } from '@mui/material';
import { useMonitoringAssortmentItems } from '@retail/monitoring-assortment/context';
import { SideFilters, ToggleFiltersButton, ToggleSwitch } from '@retail/components';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { MonitoringAssortmentTable } from '@retail/products/components';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';
import { useDisclosure } from '@shared/hooks';

interface Props {
  selectedAssortment: MonitoringAssortment;
  setItemToView: (item?: number) => void;
  toggleAddItemsModal: () => void;
}

export function MonitoringAssortmentContainer({
  selectedAssortment,
  setItemToView,
  toggleAddItemsModal,
}: Props) {
  const { selectedOrgUnitId } = useSelectedOrgUnit();
  const competitors = useSelectedCompetitors(selectedOrgUnitId);

  const {
    result: { assortmentItems, pageSize, page, totalItems },
    setPageSize,
    setPage,
    isFetching,
  } = useMonitoringAssortmentItems({
    assortmentId: selectedAssortment.id,
    competitors,
  });

  const t = useAssortmentTFunction();

  const [toggleVat, setToggleVat] = useState(false);
  const { isOpen: showFilters, onToggle: toggleFilters } = useDisclosure(true);

  return (
    <Stack mt={1} flexGrow={1} borderTop={({ palette }) => `1px solid ${palette.divider}`}>
      <Stack flexDirection="row" flexGrow={1}>
        <Stack flexGrow={1}>
          <Stack flexDirection="row" justifyContent="space-between" p={1}>
            <Stack flexDirection="row" gap={1} alignItems="center">
              <Typography variant="subtitle1">{selectedAssortment.size}</Typography>
              <Typography variant="body1">
                {selectedAssortment.size === 1
                  ? t('assortment.itemsInAssortmentSingular')
                  : t('assortment.itemsInAssortmentPlural')}
              </Typography>
            </Stack>
            {!showFilters && (
              <ToggleFiltersButton showFilters={showFilters} toggleFilters={toggleFilters} />
            )}
          </Stack>
          <MonitoringAssortmentTable
            updatePageSize={setPageSize}
            updatePage={setPage}
            totalItems={totalItems}
            pageSize={pageSize}
            page={page}
            items={assortmentItems}
            excludeVat={toggleVat}
            openAddItemsDialog={toggleAddItemsModal}
            competitors={competitors}
            setItemToView={setItemToView}
            loading={isFetching}
          />
        </Stack>
        {showFilters && (
          <SideFilters
            heading={t('assortment.filter')}
            showFilters={showFilters}
            sx={{ p: 1, pl: 2 }}
            closeFilters={toggleFilters}
          >
            <ToggleSwitch
              justifyContent="space-between"
              toggle={toggleVat}
              setToggle={setToggleVat}
              text={t('assortment.vat.excluded')}
            />
          </SideFilters>
        )}
      </Stack>
    </Stack>
  );
}
