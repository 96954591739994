import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import {
  BriefcaseIcon,
  CalculatorIcon,
  DownloadIconMenu,
  MarketIcon,
  ShoppingCartIcon,
  StorageUnitIcon,
} from '@shared/custom-icons';
import { ToggleOn } from '@mui/icons-material';
import { AppSidebar, Route } from './AppSidebar';
import { useIsSuperUser } from '@retail/auth/data-access';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';

export function MgpAppSidebar() {
  const t = useAppTFunction();
  const isSuperUser = useIsSuperUser();
  const { selectedPriceList } = useSelectedOrgUnit();

  const marketInsightRouteMatch = useMatch(`${mgPrisRoutes.marketInsight.fullRoutePath}/*`);
  const itemsRouteMatch = useMatch(`${mgPrisRoutes.items.fullRoutePath}/*`);
  const myAssortmentRouteMatch = useMatch(`${mgPrisRoutes.myAssortment.fullRoutePath}/*`);
  const suppliersRouteMatch = useMatch(`${mgPrisRoutes.suppliers.fullRoutePath}/*`);
  const pricesRouteMatch = useMatch(`${mgPrisRoutes.calculus.fullRoutePath}/*`);
  const exportRouteMatch = useMatch(`${mgPrisRoutes.export.fullRoutePath}/*`);
  const featureTogglesRouteMatch = useMatch(`${mgPrisRoutes.superuser.features.fullRoutePath}/*`);

  const navRoutes: Route[] = useMemo(() => {
    const priceListRoutes: Route[] = [
      {
        title: t('app.routes.myAssortment'),
        to: mgPrisRoutes.myAssortment.getFullLinkPath(),
        Icon: StorageUnitIcon,
        isActive: !!myAssortmentRouteMatch,
      },
      {
        title: t('app.routes.calculus'),
        Icon: CalculatorIcon,
        to: mgPrisRoutes.calculus.getFullLinkPath(),
        isActive: !!pricesRouteMatch,
      },
      {
        title: t('app.routes.products'),
        to: mgPrisRoutes.items.getFullLinkPath(),
        Icon: ShoppingCartIcon,
        isActive: !!itemsRouteMatch,
      },
    ];
    const routes: Route[] = [
      ...priceListRoutes,
      {
        title: t('app.routes.marketInsight'),
        to: mgPrisRoutes.marketInsight.getFullLinkPath(),
        Icon: MarketIcon,
        isActive: !!marketInsightRouteMatch,
      },
      {
        title: t('app.routes.suppliers'),
        to: mgPrisRoutes.suppliers.getFullLinkPath(),
        Icon: BriefcaseIcon,
        isActive: !!suppliersRouteMatch,
      },
      {
        title: t('app.routes.export'),
        to: mgPrisRoutes.export.getFullLinkPath(),
        Icon: DownloadIconMenu,
        isActive: !!exportRouteMatch,
      },
    ];
    if (isSuperUser) {
      routes.push({
        title: t('app.routes.features'),
        to: mgPrisRoutes.superuser.features.getFullLinkPath(),
        Icon: ToggleOn,
        isActive: !!featureTogglesRouteMatch,
      });
      priceListRoutes.push({
        title: t('app.routes.features'),
        to: mgPrisRoutes.superuser.features.getFullLinkPath(),
        Icon: ToggleOn,
        isActive: !!featureTogglesRouteMatch,
      });
    }

    return selectedPriceList ? priceListRoutes : routes;
  }, [
    t,
    myAssortmentRouteMatch,
    pricesRouteMatch,
    itemsRouteMatch,
    marketInsightRouteMatch,
    suppliersRouteMatch,
    exportRouteMatch,
    isSuperUser,
    selectedPriceList,
    featureTogglesRouteMatch,
  ]);

  return <AppSidebar navRoutes={navRoutes} />;
}
