import { useValidateImportedItems } from '@retail/monitoring-assortment/data-access';
import { ItemsCodeLists } from '@retail/monitoring-assortment/types';
import {
  assortmentSpreadsheetValidatedAtom,
  assortmentSpreadsheetValidationAtom,
  IdentifiableColumn,
  useImportedAssortmentState,
  useResetImportedAssortmentState,
} from '@retail/monitoring-assortment/utils';
import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

const columns = new Set<IdentifiableColumn>(['finfo', 'gtin', 'mgItem']);

export const useValidationOnImportedItems = () => {
  const importState = useImportedAssortmentState({ columnsToRetrieve: columns });

  const importedItems = importState.importedItems ?? [];

  const resetImportedAssortmentStates = useResetImportedAssortmentState();

  const [validationState, setValidationState] = useRecoilState(assortmentSpreadsheetValidationAtom);
  const [isValidated, setIsValidated] = useRecoilState(assortmentSpreadsheetValidatedAtom);

  const { mutateAsync: validateItemsAsync, isLoading: loadingValidateItems } =
    useValidateImportedItems();

  const onValidateItems = useCallback(async () => {
    validateItemsAsync({
      body: {
        finfoNumbers: importState.finfoNumbers,
        mgItemNumbers: importState.mgItemNumbers,
        gtinCodes: importState.gtinNumbers,
      },
    })
      .catch((err) => console.error(err))
      .then((response) => {
        const res = response as ItemsCodeLists;
        setValidationState(res);
      });
  }, [
    importState.finfoNumbers,
    importState.gtinNumbers,
    importState.mgItemNumbers,
    setValidationState,
    validateItemsAsync,
  ]);

  useEffect(() => {
    if (importState.status !== 'none' && importedItems.length > 0 && !isValidated) {
      onValidateItems();
      setIsValidated(true);
    }
  }, [importState.status, importedItems.length, isValidated, onValidateItems, setIsValidated]);

  return {
    importedItems,
    validationState,
    importState,
    resetImportedAssortmentStates,
    loadingValidateItems,
  };
};
