import { Box, FormControl, FormLabel } from '@mui/material';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { FormControlWrapper, FormLabelWrapper, HelperText } from '@shared/components';
import { startOfDay } from 'date-fns';
import { forwardRef } from 'react';

interface Props
  extends Pick<
    DatePickerProps<Date>,
    'onChange' | 'minDate' | 'maxDate' | 'value' | 'name' | 'sx' | 'slotProps'
  > {
  label?: string;
  required?: boolean;
  error?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  margin?: 'normal' | 'dense' | 'none';
  labelDirection?: 'row' | 'column';
  labelWidth?: number;
}

const InputDatePicker = forwardRef(
  (
    {
      name,
      label,
      required,
      error,
      onChange,
      value,
      minDate,
      maxDate,
      slotProps,
      fullWidth = true,
      size = 'medium',
      margin = 'normal',
      labelDirection = 'column',
      labelWidth,
      sx,
    }: Props,
    ref
  ) => {
    const today = startOfDay(new Date());

    const inputId = `${name}-id`;
    const formLabelId = `${name}-label`;
    const helperTextId = `${name}-helperText`;

    return (
      <FormControl
        margin={margin}
        error={!!error}
        fullWidth={fullWidth}
        required={required}
        component={FormControlWrapper}
        labelDirection={labelDirection}
        sx={labelDirection === 'row' ? { alignItems: 'center' } : undefined}
      >
        {label && (
          <FormLabelWrapper direction={labelDirection} width={labelWidth} size={size}>
            <FormLabel htmlFor={inputId} id={formLabelId}>
              {label}
            </FormLabel>
          </FormLabelWrapper>
        )}
        <Box flex="1 0 0">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              minDate={minDate}
              maxDate={maxDate}
              format="dd/MM/yyyy"
              onChange={onChange}
              value={value || null}
              disablePast={minDate === today}
              slotProps={{
                ...slotProps,
                textField: {
                  inputRef: ref,
                  size,
                  fullWidth,
                  ...(slotProps?.textField ?? {}),
                },
              }}
              sx={sx}
            />
          </LocalizationProvider>
          {error && (
            <HelperText id={helperTextId} status={'error'}>
              {error}
            </HelperText>
          )}
        </Box>
      </FormControl>
    );
  }
);

export default InputDatePicker;
