import { useCallback, useMemo, useState } from 'react';
import { Checkbox, Stack, Typography } from '@mui/material';
import {
  DataGridPremium,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRowId,
} from '@mui/x-data-grid-premium';
import { Conditions, CustomerMarkupsForPriceLists } from '@retail/calculus/types';
import { useAppTFunction } from '@retail/app/i18n';
import { DraftDrawerTableContent } from './DraftDrawerTableContent';
import { CustomDetailPanelToggle } from './CustomDetailPanelToggle';
import { dataGridNoHorizontalPaddingClass } from '@shared/styles';
import { MgSupplierMinimal } from '@retail/products/types';
import { DraftConditionCountChips } from '../DraftConditionCountChips';

interface Props {
  draftConditions: Conditions;
  customerMarkupsForPriceLists?: CustomerMarkupsForPriceLists[];
  selectedConditionSupplierIds: number[];
  isLoading: boolean;
  language: string;
  suppliersList: MgSupplierMinimal[];
  handleDeleteCondition: (id: number, priceListAssortmentId?: number) => void;
  onSelectConditionSupplierIds: (supplierIds: number[]) => void;
}

type GridRowCondition = GridColDef<MgSupplierMinimal>;

const baseColumnProps: Partial<GridRowCondition> = {
  width: 150,
  sortable: false,
  filterable: false,
  hideable: false,
  headerAlign: 'left',
  align: 'left',
};

export const DraftDrawerTable = ({
  draftConditions,
  customerMarkupsForPriceLists,
  selectedConditionSupplierIds,
  isLoading,
  language,
  suppliersList,
  handleDeleteCondition,
  onSelectConditionSupplierIds,
}: Props) => {
  const t = useAppTFunction();

  const handleSupplierCheckboxClicked = useCallback(
    (mgSupplierId: GridRowId) => {
      const updatedSupplierIds = selectedConditionSupplierIds.includes(mgSupplierId as number)
        ? selectedConditionSupplierIds.filter((supplierId) => supplierId !== mgSupplierId)
        : [...selectedConditionSupplierIds, mgSupplierId];

      onSelectConditionSupplierIds(updatedSupplierIds as number[]);
    },
    [onSelectConditionSupplierIds, selectedConditionSupplierIds]
  );

  const columns: GridRowCondition[] = useMemo(
    () => [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        field: 'checkbox',
        align: 'center',
        renderCell: ({ id }) => {
          return (
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                handleSupplierCheckboxClicked(id);
              }}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'supplier',
        width: 240,
        headerName: t('markup.supplier'),
        renderCell: ({ rowNode, field, row }) => {
          if (rowNode.type === 'group' && field === rowNode.groupingField) {
            return '';
          }
          return (
            <Typography variant="body2" fontWeight={600}>
              {row.name ?? row.mgSupplierId}
            </Typography>
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'conditionCount',
        flex: 1,
        headerName: t('markup.supplier'),
        renderCell: ({ row }) => (
          <DraftConditionCountChips
            draftConditions={draftConditions}
            rowSupplierId={row.mgSupplierId}
          />
        ),
      },
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        align: 'right',
        renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
      },
    ],
    [draftConditions, handleSupplierCheckboxClicked, t]
  );

  const getDetailPanelContent = useCallback(
    (mgSupplierId: GridRowId) => {
      const purchaseConditions =
        draftConditions?.purchaseConditions.find(
          (condition) => condition.mgSupplierId === mgSupplierId
        )?.purchaseConditions ?? [];

      const freightConditions =
        draftConditions?.freightConditions?.supplierFreightConditions.find(
          (condition) => condition.mgSupplierId === mgSupplierId
        )?.freightConditions ?? [];

      const markupConditions =
        draftConditions?.markupConditions?.supplierMarkupConditions.find(
          (condition) => condition.mgSupplierId === mgSupplierId
        )?.markupConditions ?? [];

      const priceSuggestionConditions =
        draftConditions?.priceSuggestionConditions?.supplierPriceSuggestionConditions.find(
          (condition) => condition.mgSupplierId === mgSupplierId
        )?.priceSuggestionConditions ?? [];

      const customerMarkupConditions =
        draftConditions?.customerMarkupConditions?.supplierMarkupConditions
          .filter((condition) => condition.mgSupplierId === mgSupplierId)
          ?.flatMap((cond) => cond.markupConditions) ?? [];

      return (
        <DraftDrawerTableContent
          condition={[
            ...purchaseConditions,
            ...freightConditions,
            ...markupConditions,
            ...priceSuggestionConditions,
            ...customerMarkupConditions,
          ]}
          customerMarkupsForPriceLists={customerMarkupsForPriceLists}
          isLoading={isLoading}
          language={language}
          handleDeleteCondition={handleDeleteCondition}
        />
      );
    },
    [
      customerMarkupsForPriceLists,
      draftConditions?.customerMarkupConditions?.supplierMarkupConditions,
      draftConditions?.freightConditions?.supplierFreightConditions,
      draftConditions?.markupConditions?.supplierMarkupConditions,
      draftConditions?.priceSuggestionConditions?.supplierPriceSuggestionConditions,
      draftConditions?.purchaseConditions,
      handleDeleteCondition,
      isLoading,
      language,
    ]
  );

  const [detailPanelsOpen, setDetailPanelOpen] = useState<GridRowId[]>(
    suppliersList.length === 1 ? [suppliersList[0].mgSupplierId] : []
  );

  const onRowClick = useCallback(
    (id: GridRowId) =>
      detailPanelsOpen.includes(id)
        ? setDetailPanelOpen(detailPanelsOpen.filter((panelId) => panelId !== id))
        : setDetailPanelOpen([...detailPanelsOpen, id]),
    [detailPanelsOpen]
  );

  return (
    <DataGridPremium
      className={dataGridNoHorizontalPaddingClass}
      detailPanelExpandedRowIds={detailPanelsOpen}
      onDetailPanelExpandedRowIdsChange={(id) => setDetailPanelOpen(id)}
      onRowClick={({ id }) => onRowClick(id)}
      getRowId={(row) => row.mgSupplierId}
      columns={columns}
      rows={suppliersList}
      rowHeight={50}
      columnHeaderHeight={0}
      getDetailPanelContent={({ id }) => getDetailPanelContent(id)}
      getDetailPanelHeight={() => 'auto'}
      hideFooter
      rowSelection
      slots={{
        noRowsOverlay: () => (
          <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
            <Typography variant="h3" color="text.secondary">
              {t('calculus.messages.noUpdates')}
            </Typography>
          </Stack>
        ),
      }}
      sx={{
        '.MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
      }}
    />
  );
};
