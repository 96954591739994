import { Tooltip } from '@mui/material';
import { CustomIconButton, EditIconButton } from '@retail/components';
import { EditButtonIcon } from '@shared/custom-icons';

interface Props {
  disabled?: boolean;
  title?: string;
  onEdit: () => void;
}

export function EditFieldButton({ disabled, title, onEdit }: Props) {
  return disabled ? (
    <Tooltip title={title} placement="top" arrow>
      <div>
        <EditIconButton disabled />
      </div>
    </Tooltip>
  ) : (
    <CustomIconButton onClick={onEdit} icon={<EditButtonIcon fontSize="small" />} />
  );
}
