import { Stack, useTheme } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { HierarchyLevels, PackageType } from '@retail/calculus/types';
import { chipColor } from '@retail/calculus/utils';
import { Chip } from '@shared/components';
import React, { useMemo } from 'react';

interface Props {
  bindingType?: HierarchyLevels;
  isAgreement?: boolean;
  labelDetails?: string | React.ReactNode;
  active: boolean;
  packageType?: PackageType;
  width?: number;
  height?: number;
  abbreviation?: boolean;
}

export const LevelChip = ({
  bindingType,
  isAgreement,
  labelDetails,
  active,
  packageType,
  width = 144,
  height = 36,
  abbreviation,
}: Props) => {
  const t = useAppTFunction();
  const { palette } = useTheme();

  const label = useMemo(() => {
    switch (bindingType) {
      case HierarchyLevels.supplier:
        return abbreviation ? t('markup.levels.supplierShort') : t('markup.levels.supplier');
      case HierarchyLevels.overGroup:
        return abbreviation ? t('markup.levels.overGroupShort') : t(`markup.levels.overGroup`);
      case HierarchyLevels.mainGroup:
        return abbreviation ? t('markup.levels.mainGroupShort') : t(`markup.levels.mainGroup`);
      case HierarchyLevels.productGroup:
        return abbreviation
          ? t('markup.levels.productGroupShort')
          : t(`markup.levels.productGroup`);
      case HierarchyLevels.discountGroup:
        return abbreviation
          ? t('markup.levels.discountGroupShort')
          : t(`markup.levels.discountGroup`);

      case HierarchyLevels.item:
        switch (packageType) {
          case 'SECONDARY':
            return t('markup.levels.secondary');
          case 'TERTIARY':
            return t('markup.levels.tertiary');
          default:
            return abbreviation ? t('markup.levels.itemShort') : t('markup.levels.item');
        }

      default:
        return isAgreement ? t('purchaseAgreements.availableAgreement') : '';
    }
  }, [bindingType, abbreviation, t, packageType, isAgreement]);

  const levelChipColor = chipColor({
    palette,
    bindingType,
    packageType,
    active,
  });

  return (
    <Chip
      sx={{ width: width, height: height }}
      backgroundColor={levelChipColor}
      label={
        <Stack alignItems="center">
          {label}
          {labelDetails}
        </Stack>
      }
    />
  );
};
