import { useProductsTFunction } from '@retail/products/i18n';
import { Menu, MenuButton } from '@retail/components';
import { Add } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { ContainedButton } from '@shared/components';

interface Props {
  onAddItemsToAssortment?: () => void;
  onAddItemsToMonitoringAssortment?: () => void;
  disabled: boolean;
  isLoading: boolean;
}

export const AddItemsMenu = ({
  onAddItemsToAssortment,
  onAddItemsToMonitoringAssortment,
  disabled,
  isLoading,
}: Props) => {
  const t = useProductsTFunction();

  if (!onAddItemsToAssortment && !onAddItemsToMonitoringAssortment) {
    return null;
  }

  if (!onAddItemsToAssortment) {
    return (
      <ContainedButton color="secondary" size="small" onClick={onAddItemsToMonitoringAssortment}>
        {t('products.mgItem.addToMonitor')}
      </ContainedButton>
    );
  }

  if (!onAddItemsToMonitoringAssortment) {
    return (
      <ContainedButton color="secondary" size="small" onClick={onAddItemsToAssortment}>
        {t('products.mgItem.addToMy')}
      </ContainedButton>
    );
  }

  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton
            disabled={disabled}
            // @ts-ignore
            loading={isLoading}
            size="small"
            color="secondary"
            startIcon={<Add />}
          >
            {t('products.mgItem.addSelected')}
          </MenuButton>
          {!!onAddItemsToAssortment && (
            <MenuItem
              onClick={() => {
                onClose();
                onAddItemsToAssortment();
              }}
            >
              {t('products.mgItem.addToMy')}
            </MenuItem>
          )}
          {!!onAddItemsToMonitoringAssortment && (
            <MenuItem
              onClick={() => {
                onClose();
                onAddItemsToMonitoringAssortment();
              }}
            >
              {t('products.mgItem.addToMonitor')}
            </MenuItem>
          )}
        </>
      )}
    </Menu>
  );
};
