import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { CalculusTabOptions } from '@retail/calculus/types';

interface Props {
  searchParamKey: string;
  resetContiguousSearchParamKey?: string;
  initialValue?: CalculusTabOptions;
}

export function useSelectCalculusTab({
  searchParamKey,
  resetContiguousSearchParamKey,
  initialValue,
}: Props): [CalculusTabOptions, (option: CalculusTabOptions) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTab = (searchParams.get(searchParamKey) as CalculusTabOptions) ?? initialValue;

  const selectTab = useCallback(
    (
      option: CalculusTabOptions,
      addContiguousSearchParamKey?: { searchParam: string; value: string }
    ) =>
      setSearchParams((prevParams) => {
        if (option) {
          prevParams.set(searchParamKey, option);
          addContiguousSearchParamKey &&
            prevParams.set(
              addContiguousSearchParamKey.searchParam,
              addContiguousSearchParamKey.value
            );
        } else {
          prevParams.delete(searchParamKey);
        }
        resetContiguousSearchParamKey && prevParams.delete(resetContiguousSearchParamKey);
        return prevParams;
      }),
    [resetContiguousSearchParamKey, searchParamKey, setSearchParams]
  );
  useEffect(() => {
    if (searchParams.get(searchParamKey) !== selectedTab) {
      selectTab(selectedTab);
    }
  }, [searchParamKey, searchParams, selectTab, selectedTab]);

  return [selectedTab, selectTab];
}
