import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import NewAssortmentFields from '../NewAssortmentFields';
import { importStepAtom } from '@retail/monitoring-assortment/utils';
import { NewAssortmentForm, newAssortmentSchema } from '../newAssortmentForm';
import { ItemCodes, ItemsCodeLists } from '@retail/monitoring-assortment/types';
import { ContainedButton, Dialog, OutlinedButton, Stepper } from '@shared/components';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';

import { UploadItemsContainer } from '@retail/products/components';
import { ValidateImportedItems } from './ValidateImportedItems';

export interface NewAssortment {
  title: string;
  itemCodes: ItemsCodeLists;
}

const csvData = 'MgItem-nummer;Finfo-nummer;Gtin-nummer';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (newAssortment: NewAssortment) => void;
  importedItems: ItemCodes[];
  resetImportedAssortmentStates: () => void;
  loadingValidateItems: boolean;
  validationState?: ItemsCodeLists;
  isLoading?: boolean;
}

export const ImportAssortmentDialog: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  importedItems,
  resetImportedAssortmentStates,
  loadingValidateItems,
  validationState,
  isLoading = false,
}) => {
  const [activeStep, setActiveStep] = useRecoilState(importStepAtom);
  const { handleSubmit, control } = useForm<NewAssortmentForm>({
    resolver: yupResolver(newAssortmentSchema),
    shouldUnregister: false,
  });
  const t = useAssortmentTFunction();

  const onSubmitForm = handleSubmit(({ title }) => {
    onSubmit({
      title,
      itemCodes: {
        finfoNumbers: importedItems.map(({ finfoNumber }) => finfoNumber) as number[],
        gtinCodes: importedItems.map(({ gtinCode }) => gtinCode) as number[],
        mgItemNumbers: importedItems.map(({ mgItemNumber }) => mgItemNumber) as number[],
      },
    });
    resetImportedAssortmentStates();
    setActiveStep(0);
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        resetImportedAssortmentStates();
        setActiveStep(0);
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" flexDirection="column" flex="1" alignItems="stretch" gap={3}>
          <Typography variant="h5">{t('assortment.admin.create.title')}</Typography>
          <Stepper
            activeStep={activeStep}
            steps={[
              t('assortment.admin.create.fromFile.import.title'),
              t('assortment.admin.create.fromFile.assortmentName'),
            ]}
          />
        </Box>
      </DialogTitle>
      {activeStep === 0 && (
        <>
          <DialogContent>
            <UploadItemsContainer
              isLarge
              loadingValidateItems={loadingValidateItems}
              csvData={csvData}
              resetImportedAssortmentStates={resetImportedAssortmentStates}
              renderValidation={
                <ValidateImportedItems
                  importedItems={importedItems}
                  invalidItemCodes={validationState}
                />
              }
            />
          </DialogContent>

          <DialogActions>
            <ContainedButton
              size="small"
              onClick={() => {
                setActiveStep(1);
              }}
              disabled={importedItems.length === 0}
            >
              {t('assortment.admin.create.next')}
            </ContainedButton>
          </DialogActions>
        </>
      )}
      {activeStep === 1 && (
        <>
          <DialogContent>
            <NewAssortmentFields control={control} />
          </DialogContent>
          <DialogActions>
            <OutlinedButton size="small" onClick={() => setActiveStep(0)} disabled={isLoading}>
              {t('assortment.admin.create.previous')}
            </OutlinedButton>
            <ContainedButton size="small" onClick={onSubmitForm} loading={isLoading}>
              {t('assortment.admin.create.importCTA')}
            </ContainedButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
