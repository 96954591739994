import { DatedBracketMarkupPrice } from './DatedBracketMarkupPrice';
import { FreightCondition } from './FreightCondition';
import { DatedPrice } from './DatedPrice';
import { CurrencyPrice } from './CurrencyPrice';
import { MgPackageClassCode } from '../MgItem';
import { PriceCalculationDTO } from '../../dto';
import { PackagePrice } from './PackagePrice';
import { PurchaseCondition } from './PurchaseCondition';
import { PriceSuggestionType } from '@retail/price-suggestion/types';

export enum PriceResultType {
  CALCULATION = 'CALCULATION',
  PRICE_SUGGESTION = 'PRICE_SUGGESTION',
  CUSTOMER = 'CUSTOMER',
}
export interface PriceCalculationProps {
  priceResultType?: PriceResultType;
  priceSuggestionType?: PriceSuggestionType;
  purchasePrice?: DatedPrice;
  purchaseCondition?: PurchaseCondition;
  freightCondition?: FreightCondition;
  costPrice?: DatedPrice;
  salesBracketPrices?: DatedBracketMarkupPrice[];
}

export class PriceCalculation {
  priceResultType?: PriceResultType;
  priceSuggestionType?: PriceSuggestionType;
  purchasePrice?: DatedPrice;
  purchaseCondition?: PurchaseCondition;
  freightCondition?: FreightCondition;
  costPrice?: DatedPrice;
  salesBracketPrices?: DatedBracketMarkupPrice[];

  constructor(props: PriceCalculationProps) {
    this.priceResultType = props.priceResultType;
    this.priceSuggestionType = props.priceSuggestionType;
    this.purchasePrice = props.purchasePrice;
    this.purchaseCondition = props.purchaseCondition;
    this.freightCondition = props.freightCondition;
    this.costPrice = props.costPrice;
    this.salesBracketPrices = props.salesBracketPrices;
  }

  getBracketPackagePrice = (
    bracketClassCode: MgPackageClassCode
  ): DatedBracketMarkupPrice | undefined =>
    this.salesBracketPrices?.find(
      ({ bracketMarkupPrice }) =>
        bracketMarkupPrice.calculationPackage.classCode === bracketClassCode
    );

  getCurrentCostPriceForUnit = (unit: string): PackagePrice | undefined => {
    return this.costPrice?.packagePrices?.find(
      ({ supplierItemPackage }) => supplierItemPackage.unit.unit === unit
    );
  };

  getCurrentCostPriceForClassCode = (classCode: MgPackageClassCode): CurrencyPrice | undefined => {
    return this.costPrice?.packagePrices?.find(
      ({ supplierItemPackage }) => supplierItemPackage.classCode === classCode
    )?.price;
  };

  getCurrentPurchasePriceForUnit = (unit: string): PackagePrice | undefined => {
    return this.purchasePrice?.packagePrices?.find(
      ({ supplierItemPackage }) => supplierItemPackage.unit.unit === unit
    );
  };

  static fromDto = (dto: PriceCalculationDTO | undefined): PriceCalculation => {
    return new PriceCalculation({
      priceResultType: dto?.priceResultType,
      priceSuggestionType: dto?.priceSuggestionType,
      purchasePrice: dto?.purchasePrice ? DatedPrice.fromDto(dto.purchasePrice) : undefined,
      purchaseCondition: dto?.purchaseCondition
        ? PurchaseCondition.fromDto(dto.purchaseCondition)
        : undefined,
      freightCondition: dto?.freightCondition
        ? FreightCondition.fromDto(dto.freightCondition)
        : undefined,
      costPrice: dto?.costPrice ? DatedPrice.fromDto(dto.costPrice) : undefined,
      salesBracketPrices: dto?.salesBracketPrices
        ? dto?.salesBracketPrices.map(DatedBracketMarkupPrice.fromDto)
        : undefined,
    });
  };
}
