import { FC, useCallback, useMemo, useState } from 'react';
import { DraftDrawerTable } from '@retail/calculus/components';
import { useCalculusConditionActions, useFetchCalculusConditions } from '@retail/calculus/context';
import { ContainedButton } from '@shared/components';
import { DraftActionsRowPortal } from '@retail/components';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useLanguageSelector } from '@retail/app/hooks';
import { Box } from '@mui/material';
import { useIsFeatureEnabled, useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { DeleteOrActivateConditionIds } from '@retail/calculus/types';

export const DraftContainer: FC = () => {
  const t = useCalculusTFunction();
  const { language } = useLanguageSelector();

  const { selectedDepartment, selectedPriceList } = useSelectedOrgUnit();
  const draftAssortmentId = (selectedDepartment?.draftAssortmentId ??
    selectedPriceList?.draftAssortmentId)!;

  const isPriceSuggestionEnabled = useIsFeatureEnabled('price-suggestion');
  const isCustomerMarkupEnabled = useIsFeatureEnabled('customer-markup');

  const [selectedConditionSupplierIds, setSelectedConditionSupplierIds] = useState<number[]>([]);
  const {
    removeConditionsIsLoading,
    handleDeleteConditions,
    activateDraft,
    activateConditionIsLoading,
  } = useCalculusConditionActions();

  const { draftConditions, customerMarkupsForPriceLists, suppliersList } =
    useFetchCalculusConditions();
  const handleDeleteCondition = useCallback(
    (conditionId: number, priceListAssortmentId?: number) => {
      if (priceListAssortmentId) {
        handleDeleteConditions([
          { assortmentId: priceListAssortmentId, conditionIds: [conditionId] },
        ]);
      } else {
        handleDeleteConditions([{ assortmentId: draftAssortmentId, conditionIds: [conditionId] }]);
      }
    },
    [draftAssortmentId, handleDeleteConditions]
  );

  const getConditionsForSelectedSuppliers = useCallback(() => {
    const supplierConditions: number[] = [];
    const priceListConditions: DeleteOrActivateConditionIds[] = [];

    selectedConditionSupplierIds.forEach((supplierId) => {
      const supplierPurchaseConditions = draftConditions?.purchaseConditions.find(
        (condition) => condition.mgSupplierId === supplierId
      );
      const supplierFreightConditions =
        draftConditions?.freightConditions?.supplierFreightConditions?.find(
          (condition) => condition.mgSupplierId === supplierId
        );
      const supplierMarkupConditions =
        draftConditions?.markupConditions?.supplierMarkupConditions.find(
          (condition) => condition.mgSupplierId === supplierId
        );

      const conditionIds = [
        ...(supplierPurchaseConditions?.purchaseConditions.map((condition) => condition.id) || []),
        ...(supplierFreightConditions?.freightConditions?.map((condition) => condition.id) || []),
        ...(supplierMarkupConditions?.markupConditions?.map((condition) => condition.id) || []),
      ];

      if (isPriceSuggestionEnabled) {
        const supplierPriceSuggestionConditions =
          draftConditions?.priceSuggestionConditions?.supplierPriceSuggestionConditions.find(
            (condition) => condition.mgSupplierId === supplierId
          );
        conditionIds.push(
          ...(supplierPriceSuggestionConditions?.priceSuggestionConditions?.map(
            (condition) => condition.id
          ) || [])
        );
      }

      if (isCustomerMarkupEnabled && !customerMarkupsForPriceLists) {
        const supplierCustomerMarkupConditions =
          draftConditions?.customerMarkupConditions?.supplierMarkupConditions
            .filter((condition) => selectedConditionSupplierIds.includes(condition.mgSupplierId))
            .flatMap((conditions) => conditions.markupConditions)
            .map(({ id }) => id) ?? [];
        conditionIds.push(...supplierCustomerMarkupConditions);
      }

      supplierConditions.push(...conditionIds);
    });

    if (isCustomerMarkupEnabled && customerMarkupsForPriceLists) {
      const supplierCustomerMarkupConditions =
        draftConditions?.customerMarkupConditions?.supplierMarkupConditions
          .filter((condition) => selectedConditionSupplierIds.includes(condition.mgSupplierId))
          .flatMap((conditions) => conditions.markupConditions)
          .map(({ id }) => id) ?? [];
      customerMarkupsForPriceLists.map((priceList) => {
        const conditionIdsForPriceList = supplierCustomerMarkupConditions.filter((id) =>
          priceList.conditionIds?.includes(id)
        );
        if (conditionIdsForPriceList.length > 0) {
          priceListConditions.push({
            assortmentId: priceList.priceListAssortmentId,
            conditionIds: conditionIdsForPriceList,
          });
        }
        return null;
      });
    }

    return [
      { assortmentId: draftAssortmentId, conditionIds: supplierConditions },
      ...priceListConditions,
    ].filter((condition) => condition.conditionIds.length !== 0);
  }, [
    customerMarkupsForPriceLists,
    draftAssortmentId,
    draftConditions?.customerMarkupConditions?.supplierMarkupConditions,
    draftConditions?.freightConditions?.supplierFreightConditions,
    draftConditions?.markupConditions?.supplierMarkupConditions,
    draftConditions?.priceSuggestionConditions?.supplierPriceSuggestionConditions,
    draftConditions?.purchaseConditions,
    isCustomerMarkupEnabled,
    isPriceSuggestionEnabled,
    selectedConditionSupplierIds,
  ]);

  const submitDeleteSupplierConditions = useCallback(() => {
    handleDeleteConditions(getConditionsForSelectedSuppliers());
  }, [getConditionsForSelectedSuppliers, handleDeleteConditions]);

  const submitDraft = useCallback(() => {
    activateDraft(getConditionsForSelectedSuppliers());
  }, [activateDraft, getConditionsForSelectedSuppliers]);

  const isLoading = useMemo(
    () => activateConditionIsLoading || removeConditionsIsLoading,
    [activateConditionIsLoading, removeConditionsIsLoading]
  );

  return (
    <Box height="100%">
      {draftConditions && (
        <DraftDrawerTable
          draftConditions={draftConditions}
          customerMarkupsForPriceLists={customerMarkupsForPriceLists}
          isLoading={isLoading}
          language={language}
          selectedConditionSupplierIds={selectedConditionSupplierIds}
          suppliersList={suppliersList}
          handleDeleteCondition={handleDeleteCondition}
          onSelectConditionSupplierIds={setSelectedConditionSupplierIds}
        />
      )}

      <DraftActionsRowPortal>
        <ContainedButton
          color="warning"
          size="small"
          disabled={!selectedConditionSupplierIds.length}
          onClick={submitDeleteSupplierConditions}
          loading={isLoading}
        >
          {selectedConditionSupplierIds.length === 1
            ? t('calculus.actions.disapproveChange', { count: selectedConditionSupplierIds.length })
            : t('calculus.actions.disapproveChanges', {
                count: selectedConditionSupplierIds.length,
              })}
        </ContainedButton>

        <ContainedButton
          onClick={submitDraft}
          size="small"
          disabled={!selectedConditionSupplierIds.length}
          loading={isLoading}
        >
          {selectedConditionSupplierIds.length === 1
            ? t('calculus.actions.approveChange', { count: selectedConditionSupplierIds.length })
            : t('calculus.actions.approveChanges', { count: selectedConditionSupplierIds.length })}
        </ContainedButton>
      </DraftActionsRowPortal>
    </Box>
  );
};
