import { SyncLock } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { AssortmentItemStatus } from '@retail/my-assortment/types';
import { CheckIcon, WarningIcon } from '@shared/custom-icons';
import { PriceError, PriceStatus } from '@retail/calculus/types';
import { ItemErrors } from './ItemErrors';

interface Props {
  status?: AssortmentItemStatus | PriceStatus;
  errors?: PriceError[];
  isCentered?: boolean;
}

export const ItemStatus = ({ status, errors, isCentered = true }: Props) => {
  const t = useMyAssortmentTFunction();

  switch (status) {
    case PriceStatus.ACTION_REQUIRED:
      return (
        <Tooltip title={<ItemErrors errors={errors ?? []} />}>
          <Stack alignItems={isCentered ? 'center' : undefined}>
            <WarningIcon color="warning" />
          </Stack>
        </Tooltip>
      );
    case AssortmentItemStatus.DRAFT:
      return (
        <Tooltip title={t('myAssortment.status.toApproval')}>
          <Stack alignItems={isCentered ? 'center' : undefined}>
            <CheckIcon color="#E8AE58" />
          </Stack>
        </Tooltip>
      );
    case PriceStatus.FOR_APPROVAL:
      return (
        <Tooltip title={t('myAssortment.status.toApproval')}>
          <Stack alignItems={isCentered ? 'center' : undefined} gap={0.5}>
            <CheckIcon color="#E8AE58" />
          </Stack>
        </Tooltip>
      );
    case AssortmentItemStatus.CURRENT:
    case PriceStatus.IN_ASSORTMENT:
      return (
        <Tooltip title={t('myAssortment.status.inAssortment')}>
          <Stack alignItems={isCentered ? 'center' : undefined} gap={0.5}>
            <CheckIcon />
          </Stack>
        </Tooltip>
      );
    case AssortmentItemStatus.PENDING:
    case PriceStatus.PENDING:
      return (
        <Tooltip title={t('myAssortment.status.toCalculation')}>
          <Stack alignItems={isCentered ? 'center' : undefined} gap={0.5}>
            <SyncLock />
          </Stack>
        </Tooltip>
      );
    default:
      return <Typography>{status}</Typography>;
  }
};
