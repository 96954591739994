import { useMutateMPEFetch } from '@retail/app/hooks';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { CustomerMarkupConditionsPostDTO } from '@retail/customer-markup/types';
import { FreightConditionsPostDTO } from '@retail/freight/types';
import { MarkupConditionsPostDTO } from '@retail/markup/types';
import { mpeApi } from '@retail/mgpris/config';
import { PriceSuggestionsPostDTO } from '@retail/price-suggestion/types';
import { PurchaseConditionsPostDTO } from '@retail/purchase-agreements/types';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';

interface CreateConditions {
  purchaseConditions?: PurchaseConditionsPostDTO[];
  freightConditions?: FreightConditionsPostDTO;
  markupConditions?: MarkupConditionsPostDTO;
  priceSuggestionConditions?: PriceSuggestionsPostDTO;
  customerMarkupConditions?: CustomerMarkupConditionsPostDTO;
}

interface PostConditionProps {
  assortmentIds: number[];
}

export function useMutatePostConditions(
  { assortmentIds }: PostConditionProps,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void, CreateConditions>({
    method: 'POST',
    url: () =>
      buildUrl({
        base: mpe.url,
        route: mpeApi.conditions.getFullLinkPath(),
        searchParams: { assortmentIds },
      }),
    token,
    ...queryOptions,
  });
}
