import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { MgSupplierItem } from '@retail/products/types';
import { ContainedButton, Dialog } from '@shared/components';
import { InputDatePicker, RadioInput } from '@retail/components';
import * as yup from 'yup';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';
import { ProductsTFunction } from '@retail/products/i18n';
import { assortmentItemFromDateSchema } from './assortmentItemFromDateSchema';

export type AddToMyAssortmentFunction = ({
  supplierId,
  fromDate,
}: {
  supplierId: number;
  fromDate?: Date;
}) => void;

interface Props {
  open: boolean;
  supplierItems: MgSupplierItem[];
  onClose: () => void;
  addToMyAssortment: AddToMyAssortmentFunction;
}

const supplierDateSchema = (t: ProductsTFunction) =>
  yup
    .object({
      supplierId: yup
        .number()
        .required(t('products.mgItem.addItemsToAssortment.supplier.validation.required'))
        .nullable(),
    })
    .concat(assortmentItemFromDateSchema(t));

type SupplierDateSchema = yup.InferType<ReturnType<typeof supplierDateSchema>>;

export const MonitoringItemSelectionDialog = ({
  open,
  supplierItems,
  onClose,
  addToMyAssortment,
}: Props) => {
  const t = useAppTFunction();
  const { control, handleSubmit, watch, register, formState } = useForm<SupplierDateSchema>({
    resolver: yupResolver(supplierDateSchema(t)),
    mode: 'onSubmit',
    defaultValues: {
      // @ts-ignore
      supplierId: null,
      fromDateType: 'today',
      fromDate: null,
    },
  });
  const fromDateController = useController({ control, name: 'fromDate' });
  const fromToday = watch('fromDateType') === 'today';

  const onSubmit = handleSubmit(({ supplierId, fromDate, fromDateType }) => {
    addToMyAssortment({
      supplierId,
      fromDate: fromDateType === 'later' && fromDate ? fromDate : undefined,
    });
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('products.mgItem.documentation.selectSupplier')}{' '}
        {t('products.mgItem.addItemsToAssortment.forSelectedItem')}
      </DialogTitle>

      <DialogContent
        sx={{
          paddingTop: (theme) => `${theme.spacing(0.5)} !important`,
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="monitoring-item-select-label" htmlFor="monitoring-item-select">
            {t('products.mgItem.addItemsToAssortment.supplier.label')}
          </InputLabel>
          <Select
            labelId="monitoring-item-select-label"
            id="monitoring-item-select"
            {...register('supplierId')}
            value={watch('supplierId')}
            label={t('products.mgItem.addItemsToAssortment.supplier.label')}
          >
            {supplierItems.map((item) => (
              <MenuItem key={item.id} value={item.mgSupplierId}>
                <Stack>
                  <Typography fontWeight={600}>{item.mgSupplierName}</Typography>
                  <Typography fontWeight={400}>{item.primaryText}</Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <RadioInput
          name="fromDateType"
          label={t('products.mgItem.addItemsToAssortment.fromDateType.label.singular')}
          options={[
            {
              label: t('products.mgItem.addItemsToAssortment.fromDateType.option.today'),
              value: 'today',
            },
            {
              label: t('products.mgItem.addItemsToAssortment.fromDateType.option.later'),
              value: 'later',
            },
          ]}
          control={control}
        />
        {!fromToday && (
          <InputDatePicker
            {...fromDateController.field}
            error={fromDateController.fieldState.error?.message}
            label={t('products.mgItem.addItemsToAssortment.fromDate.label')}
            minDate={dayjs().add(1, 'day').toDate()}
            margin="none"
          />
        )}
      </DialogContent>

      <DialogActions>
        <ContainedButton disabled={!formState.isValid} onClick={onSubmit}>
          {t('products.mgItem.addToMy')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};
