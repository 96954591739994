import { useCalculusConditionActions } from '@retail/calculus/context';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { DeleteDialog } from '@retail/components';

interface Props {
  markupIdToDelete: number;
  handleDelete: () => void;
}

export function DeleteDialogContainer({ markupIdToDelete, handleDelete }: Props) {
  const t = useCalculusTFunction();
  const { handleDeleteAssortmentConditions } = useCalculusConditionActions();
  return (
    <DeleteDialog
      isOpen
      dialogTitle={t('calculus.actions.deleteFromDraft')}
      message={t('calculus.actions.confirmRemoval')}
      onDelete={() => handleDeleteAssortmentConditions([markupIdToDelete])}
      onClose={handleDelete}
    />
  );
}
