import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { PriceSuggestionCondition } from '@retail/calculus/types';
import { usePriceSuggestionTFunction } from '@retail/price-suggestion/i18n';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  conditionToDeactivate: PriceSuggestionCondition;
  deactivateCondition: (condition: PriceSuggestionCondition) => void;
}

export function DeactivatePriceSuggestionConditionDialog({
  isOpen,
  onClose,
  conditionToDeactivate,
  deactivateCondition,
}: Props) {
  const t = usePriceSuggestionTFunction();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {t('priceSuggestion.deactivateTitle', { level: conditionToDeactivate.getLevelName() })}
      </DialogTitle>

      <DialogContent>
        <Typography variant="h6" fontWeight="medium">
          {t('priceSuggestion.deactivateDesc', {
            selectedLevel: conditionToDeactivate.getLevelName(),
          })}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', alignSelf: 'flex-end' }}>
        <TextButton size="small" onClick={onClose}>
          {t('priceSuggestion.actions.cancel')}
        </TextButton>
        <ContainedButton
          color="warning"
          size="small"
          sx={{ alignSelf: 'center' }}
          onClick={() => {
            deactivateCondition(conditionToDeactivate);
            onClose();
          }}
        >
          {t('priceSuggestion.actions.deactivate')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
