import { useMPEFetch, useMutateMPEFetch } from '@retail/app/hooks';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { mpeApi } from '@retail/mgpris/config';
import { buildUrl } from '@shared/utils';
import { QueryOptions } from '@shared/fetch-utils';
import { CompetitorPricesResponseDTO } from '@retail/my-assortment/types';

const NPrices = {
  ONLY_CURRENT: 1,
  CURRENT_AND_FUTURE_PRICES: 2,
} as const;

export type NPricesType = (typeof NPrices)[keyof typeof NPrices];

export interface FetchCompetitorPricesProps extends QueryOptions {
  storeIds: number[];
  nPrices?: NPricesType;
}

export function useFetchCompetitorPrices({
  storeIds,
  nPrices,
  ...queryOptions
}: FetchCompetitorPricesProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<CompetitorPricesResponseDTO, { mgItemNumbers: number[] }>({
    method: 'POST',
    token,
    url: buildUrl({
      base: mpe.url,
      route: mpeApi.competitorPrices.getFullLinkPath(),
      searchParams: {
        storeIds,
        nPrices,
      },
    }),
    ...queryOptions,
  });
}
