type Level = 'supplier' | 'productGroup' | 'discountGroup' | 'item' | '';

export enum EnteredField {
  FACTOR = 'FACTOR',
  PERCENT = 'PERCENT',
}

export interface CreateCustomerMarkupCondition {
  mgSupplierId: number;
  level: Level;
  fromDate: Date;
  percent?: number;
  factor?: number;
  enteredField: EnteredField;
  groupNumber?: string;
  groupCode?: string;
  mgItemNumber?: number;
}

export interface CreateAssortmentLevelCustomerMarkupCondition {
  fromDate: Date;
  percent?: number;
  factor?: number;
  enteredField: EnteredField;
}
