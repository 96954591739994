import { Close } from '@mui/icons-material';
import { IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { CopyToClipboard } from '@retail/components';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';

export interface MgItemInfoProps {
  primaryText: string;
  secondaryText?: string;
  mgItemNumber: number;
  closeProductCard: () => void;
  openProductCardModal: () => void;
}

export const MgItemInfo = ({
  primaryText,
  secondaryText,
  mgItemNumber,
  closeProductCard,
  openProductCardModal,
}: MgItemInfoProps) => {
  const t = useMyAssortmentTFunction();
  return (
    <Stack p={2} alignItems="flex-start" flexDirection="row" justifyContent="space-between">
      <Stack flexGrow={1} alignItems="flex-start">
        <Tooltip title={t('myAssortment.actions.seeProductInfo')}>
          <Link
            sx={{ color: ({ palette }) => palette.common.black }}
            component="button"
            onClick={openProductCardModal}
            variant="subtitle1"
          >
            {primaryText}
          </Link>
        </Tooltip>
        {secondaryText && <Typography variant="body2">{secondaryText}</Typography>}

        <CopyToClipboard value={mgItemNumber}>
          <Stack direction="row" gap={1}>
            <Typography variant="body2">{t('myAssortment.productCard.mgItemNr')}:</Typography>
            <Typography variant="subtitle2" fontWeight="bold">
              {mgItemNumber}
            </Typography>
          </Stack>
        </CopyToClipboard>
      </Stack>

      <IconButton sx={{ p: 0 }} onClick={closeProductCard}>
        <Close />
      </IconButton>
    </Stack>
  );
};
