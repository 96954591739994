import { MouseEventHandler, ReactNode, useCallback } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { ContainedButton } from '@shared/components';
import { useRecoilState } from 'recoil';

import { BodyLoader, SpreadsheetInput } from '@retail/components';
import { useToast } from '@retail/hooks';
import { useProductsTFunction } from '@retail/products/i18n';
import { downloadTemplateCsv, itemsFileAtom, useSpreadsheetState } from '@retail/products/utils';
import { SpreadSheetRow } from '@retail/products/types';

const handleDownloadTemplate = (e: React.MouseEvent<HTMLAnchorElement>, csvData: string) => {
  e.nativeEvent.stopImmediatePropagation();
  e.stopPropagation();
  e.preventDefault();
  try {
    downloadTemplateCsv(csvData, 'itemsTemplate.csv');
  } catch (error) {
    console.error('Error downloading the template:', error);
  }
};

interface Props {
  isLarge?: boolean;
  loadingValidateItems: boolean;
  csvData: string;
  resetImportedAssortmentStates: () => void;
  renderValidation: ReactNode;
}

export const UploadItemsContainer = ({
  isLarge,
  loadingValidateItems,
  csvData,
  resetImportedAssortmentStates,
  renderValidation,
}: Props) => {
  const t = useProductsTFunction();
  const toast = useToast();

  const [spreadsheet, setSpreadsheet] = useSpreadsheetState();
  const [acceptedFile, setAcceptedFile] = useRecoilState(itemsFileAtom);

  const onUpload = useCallback(
    (rows: SpreadSheetRow[], file: File) => {
      resetImportedAssortmentStates();
      const [headerRow, ...dataRows] = rows;
      setAcceptedFile(file);
      setSpreadsheet({ headerRow, dataRows });
    },
    [resetImportedAssortmentStates, setAcceptedFile, setSpreadsheet]
  );

  const onRemoveFile: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();
      resetImportedAssortmentStates();
    },
    [resetImportedAssortmentStates]
  );

  const onRejectFile = useCallback(
    () => toast.error(t('products.uploadItems.error.fileType')),
    [t, toast]
  );

  return (
    <Stack gap={2} alignItems="center">
      <SpreadsheetInput
        onUpload={onUpload}
        onRejectFiles={onRejectFile}
        showUploadIcon
        sx={isLarge ? { width: 500, height: 350 } : undefined}
      >
        <Typography pt={1}>{t('products.uploadItems.title')}</Typography>
        <Typography pb={2} variant="body2">
          {acceptedFile ? acceptedFile.name : t('products.uploadItems.acceptedFormats')}
        </Typography>

        <Stack flexDirection="row" gap={2}>
          <ContainedButton size="small">
            {spreadsheet ? t('products.uploadItems.change') : t('products.uploadItems.upload')}
          </ContainedButton>

          {spreadsheet && (
            <ContainedButton color="warning" onClick={onRemoveFile} size="small">
              {t('products.uploadItems.removeFile')}
            </ContainedButton>
          )}
        </Stack>
        <Link
          mt={1}
          onClick={(event) => handleDownloadTemplate(event, csvData)}
          variant="body2"
          whiteSpace="nowrap"
          sx={{ cursor: 'pointer' }}
        >
          {t('products.uploadItems.downloadTemplate')}
        </Link>
      </SpreadsheetInput>

      {spreadsheet && (loadingValidateItems ? <BodyLoader /> : renderValidation)}
    </Stack>
  );
};
