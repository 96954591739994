import { Percent } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { ConditionDatesForm } from '@retail/calculus/components';
import { TextInputField } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import { CreateCustomerMarkupSchema } from './customerMarkupSchema';
import { calculateFromPercentage, calculateToPercentage } from '@retail/utils';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { ChangeEventHandler, useCallback } from 'react';
import { EnteredField } from '@retail/customer-markup/types';

export function CustomerMarkupConditionValueForm() {
  const t = useCalculusTFunction();

  const { control } = useFormContext<CreateCustomerMarkupSchema>();
  const factorController = useController({ name: 'factor', control });
  const percentController = useController({ name: 'percent', control });
  const enteredFieldController = useController({ name: 'enteredField', control });

  const onPercentChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const value = e.target.value.replace(',', '.');
      if (Number(value)) {
        factorController.field.onChange(calculateFromPercentage(Number(value)));
      }
      percentController.field.onChange(value);
      enteredFieldController.field.onChange(EnteredField.FACTOR);
    },
    [percentController.field, enteredFieldController.field, factorController.field]
  );

  const onFactorChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const value = e.target.value.replace(',', '.');
      if (Number(value)) {
        percentController.field.onChange(calculateToPercentage(Number(value)));
      }
      factorController.field.onChange(value);
      enteredFieldController.field.onChange(EnteredField.PERCENT);
    },
    [enteredFieldController.field, factorController.field, percentController.field]
  );

  return (
    <Stack gap={2} pt={2}>
      <Stack flexDirection="row" gap={1}>
        <TextInputField
          {...percentController.field}
          error={percentController.fieldState.error?.message}
          margin="none"
          required
          label={t('calculus.customerMarkup.percent')}
          size="small"
          fullWidth
          placeholder={t('calculus.customerMarkup.enterPercent')}
          endAdornment={<Percent />}
          onChange={onPercentChange}
        />

        <TextInputField
          {...factorController.field}
          error={factorController.fieldState.error?.message}
          margin="none"
          required
          label={t('calculus.customerMarkup.factor')}
          size="small"
          fullWidth
          placeholder={t('calculus.customerMarkup.enterFactor')}
          onChange={onFactorChange}
        />
      </Stack>
      <ConditionDatesForm labelAlignment="row" excludeToDate todayAsDefaultFromValue />
    </Stack>
  );
}
