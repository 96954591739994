import { Stack, Tooltip, Typography } from '@mui/material';
import { FieldValueView } from '../FieldValueView';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '@retail/products/utils';
import { SalesPriceInput } from './SalesPriceInput';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { EditFieldButton } from '../EditFieldButton';
import { SalesPriceEditState } from '../store';
import { InfoOutlined } from '@mui/icons-material';

interface Props {
  currentSalesPrice?: number;
  packageUnit: string;
  newSalesPrice?: {
    value: number;
    isApproximate: boolean;
  };
  isEditing: boolean;
  salesPriceEditState: SalesPriceEditState;
  openEditMode: () => void;
  cancelEditMode: () => void;
  includeVat: boolean;
  onChangeSalesPrice: (salesPrice: number) => void;
}

export function CalculatedSalesPriceView({
  currentSalesPrice,
  packageUnit,
  newSalesPrice,
  isEditing,
  salesPriceEditState,
  openEditMode,
  cancelEditMode,
  includeVat,
  onChangeSalesPrice,
}: Props) {
  const {
    i18n: { language },
  } = useTranslation();
  const t = useMyAssortmentTFunction();

  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
      <Stack flexDirection="row" gap={1}>
        <Typography fontWeight={600}>
          {t('myAssortment.ordinarySalesPricePer', { unit: packageUnit })}
        </Typography>
        <Stack flexDirection="row" alignItems="center" gap={0.5}>
          <Typography>
            {includeVat
              ? t('myAssortment.actions.includeVat')
              : t('myAssortment.actions.excludeVat')}
          </Typography>

          <Tooltip title={t('myAssortment.calculatedSalesPrice')}>
            <InfoOutlined fontSize="small" />
          </Tooltip>
        </Stack>
      </Stack>
      {isEditing ? (
        <SalesPriceInput
          defaultValue={newSalesPrice?.value || currentSalesPrice || 0}
          onSubmit={onChangeSalesPrice}
          onCancel={cancelEditMode}
        />
      ) : (
        <Stack direction="row" alignItems="center">
          <FieldValueView
            oldValue={`${currentSalesPrice ? formatPrice(currentSalesPrice, language) : '-'} ${t(
              'myAssortment.productCard.currency.sek'
            )}`}
            newValue={`${
              newSalesPrice?.value ? formatPrice(newSalesPrice.value, language) : '-'
            } ${t('myAssortment.productCard.currency.sek')}`}
            hasNewValue={!!newSalesPrice && newSalesPrice.value !== currentSalesPrice}
            approximatelyEqual={newSalesPrice?.isApproximate}
          />
          <EditFieldButton
            onEdit={openEditMode}
            disabled={salesPriceEditState !== 'editable'}
            title={
              salesPriceEditState !== 'editable'
                ? t(`myAssortment.productCard.salesPrice.error.${salesPriceEditState}`)
                : undefined
            }
          />
        </Stack>
      )}
    </Stack>
  );
}
