import { ToggleFiltersButton } from '@retail/components';
import {
  AssortmentItemsCalculusViewTable,
  SearchFilterChips,
} from '@retail/my-assortment/components';
import { useAssortmentItems, useIncludeVatMyAssortment } from '@retail/my-assortment/context';
import { useDisclosure, usePagination, useSelectedSearchParamState } from '@shared/hooks';
import { useCallback, useState } from 'react';
import { ChangeSelectedItemAgreementDialog } from '@retail/purchase-agreements/containers';
import { AssortmentItem } from '@retail/my-assortment/types';
import { Divider, Stack, Typography } from '@mui/material';
import { ItemPricesFilters } from './ItemPricesFilters';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { useLanguageSelector } from '@retail/app/hooks';
import { ProductCardSuspenseContainer } from '../ProductCard/ProductCardSuspenseContainer';
import { useSelectCalculusProductGroups } from '@retail/calculus/context';
import { ProductGroup } from '@retail/products/types';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';

interface Props {
  draftAssortmentId: number;
  baseAssortmentId: number;
  mgSupplierId: number;
  priceListLevel: boolean;
}

export function AssortmentItemsCalculusView({
  draftAssortmentId,
  baseAssortmentId,
  mgSupplierId,
  priceListLevel,
}: Props) {
  const t = useMyAssortmentTFunction();
  const { language } = useLanguageSelector();
  const { selectedPriceList } = useSelectedOrgUnit();

  const pagination = usePagination();
  const [inAssortment, setInAssortment] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { includeVat } = useIncludeVatMyAssortment();

  const { supplierWithProductGroups, selectedProductGroup, selectProductGroupId } =
    useSelectCalculusProductGroups({
      mgSupplierId,
      assortmentId: draftAssortmentId,
      filterItems: true,
      inAssortment,
    });

  const { isOpen: showFilters, onOpen: openFilters, onClose: closeFilters } = useDisclosure(true);

  const { assortmentItems, totalItems } = useAssortmentItems({
    assortmentId: draftAssortmentId,
    selectedSupplier: mgSupplierId,
    selectedProductGroup: selectedProductGroup?.groupNumber,
    inAssortment: inAssortment ? true : undefined,
    searchQuery,
    pagination,
  });

  const [editItemAgreement, setEditItemAgreement] = useState<AssortmentItem | undefined>(undefined);

  const [selectedItem, setSelectedItem, setSelectedItemId] = useSelectedSearchParamState({
    options: assortmentItems,
    searchParamKey: 'item',
    getOptionId: ({ mgSupplierItemId }) => mgSupplierItemId,
  });

  const viewCalculusCard = useCallback(
    (itemId: number) => {
      if (selectedItem?.mgSupplierItemId === itemId) {
        setSelectedItemId(null);
        openFilters();
      } else {
        setSelectedItemId(itemId);
        closeFilters();
      }
    },
    [closeFilters, openFilters, selectedItem?.mgSupplierItemId, setSelectedItemId]
  );

  return (
    <Stack flexGrow={1} flexDirection="row" overflow="auto">
      <Stack flexGrow={1}>
        <Stack
          flexDirection="row"
          alignItems="center"
          height={32}
          justifyContent="space-between"
          m={2}
          ml={0}
        >
          <SearchFilterChips
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            selectedProductGroup={selectedProductGroup as ProductGroup | null}
            selectProductGroupId={selectProductGroupId}
          />
          <Stack flexDirection="row" gap={1} alignItems="center">
            <Typography>
              {t('myAssortment.nrOfItems', {
                items: totalItems > pagination.pageSize ? pagination.pageSize : totalItems,
                totalItems,
              })}
            </Typography>
            <Divider orientation="vertical" />
            {!showFilters && (
              <ToggleFiltersButton showFilters={showFilters} toggleFilters={openFilters} />
            )}
          </Stack>
        </Stack>

        <Stack
          flexDirection="row"
          flexGrow={1}
          borderTop={({ palette }) => `1px solid ${palette.divider}`}
        >
          <AssortmentItemsCalculusViewTable
            {...pagination}
            items={assortmentItems}
            totalItems={totalItems}
            includeVat={includeVat}
            language={language}
            viewItemCalculusCard={viewCalculusCard}
            setEditItemAgreement={setEditItemAgreement}
            disableEdit={!!selectedPriceList}
            priceListLevel={priceListLevel}
          />
          {selectedItem && (
            <ProductCardSuspenseContainer
              item={{
                primaryText: selectedItem.primaryText,
                secondaryText: selectedItem.secondaryText,
                mgItemNumber: selectedItem.mgItemNumber,
                mgSupplierId: selectedItem.mgSupplierId,
              }}
              closeProductCard={() => {
                setSelectedItem(null);
                openFilters();
              }}
            />
          )}
        </Stack>
      </Stack>
      {showFilters && (
        <ItemPricesFilters
          supplierWithProductGroups={supplierWithProductGroups}
          selectedProductGroup={selectedProductGroup}
          selectProductGroupId={selectProductGroupId}
          inAssortment={inAssortment}
          searchQuery={searchQuery}
          showFilters={showFilters}
          setInAssortment={setInAssortment}
          setSearchQuery={setSearchQuery}
          closeFilters={closeFilters}
        />
      )}

      {editItemAgreement && (
        <ChangeSelectedItemAgreementDialog
          open
          onClose={() => setEditItemAgreement(undefined)}
          assortmentItem={editItemAgreement}
          assortmentId={baseAssortmentId}
          mgSupplierId={mgSupplierId}
        />
      )}
    </Stack>
  );
}
