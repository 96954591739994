import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import dayjs from 'dayjs';
import {
  AddButton,
  CustomColumnMenu,
  NoTableRowsOverlay,
  StackedTextCell,
} from '@retail/components';
import { Box, Link, Stack, Typography } from '@mui/material';
import { MGIcon } from '@shared/custom-icons';
import { AssortmentType, HierarchyLevels, PurchaseCondition } from '@retail/calculus/types';
import { PurchaseConditionsTableActions } from './PurchaseConditionsTableActions';
import { useLevelSearchParams } from '@retail/calculus/hooks';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { getTogglableDataGridColumns } from '@shared/utils';
import { LevelChip } from '@retail/calculus/components';

interface Props {
  conditions: PurchaseCondition[];
  language: string;
  handleActivate: (agreement: PurchaseCondition) => void;
  handleDeactivate: (agreement: PurchaseCondition) => void;
  handleEdit: (agreement: PurchaseCondition) => void;
  handleDelete: (conditionId: number) => void;
  setConditionForPriceList: (condition?: PurchaseCondition) => void;
  openCreateDialog: () => void;
}

const baseColumnProps: Partial<GridColDef> = {
  width: 140,
  sortable: false,
  headerAlign: 'left',
  align: 'left',
};

export function ConditionsTable({
  conditions,
  language,
  handleActivate,
  handleDeactivate,
  handleEdit,
  handleDelete,
  setConditionForPriceList,
  openCreateDialog,
}: Props) {
  const t = usePurchaseAgreementsTFunction();

  const hasActiveSupplierAgreement = useMemo(
    () =>
      conditions.some((condition) => condition.condition?.bindingType === HierarchyLevels.supplier),
    [conditions]
  );
  const { selectedLevel } = useLevelSearchParams();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'level',
        headerName: t('purchaseAgreements.agreement.columns.type'),
        width: 190,
        renderCell: ({ row }) => {
          return (
            <LevelChip
              bindingType={
                row.condition?.bindingType ?? (row.current ? HierarchyLevels.supplier : undefined)
              }
              isAgreement={!row.id}
              height={row.standardAgreement ? 45 : undefined}
              labelDetails={
                row.standardAgreement && (
                  <Typography variant="body3" justifyContent="center">
                    <Stack alignItems="center" flexDirection="row" gap={0.5}>
                      <MGIcon fontSize="small" />
                      {t('purchaseAgreements.agreement.columns.standardAgreement')}
                    </Stack>
                  </Typography>
                )
              }
              active={
                row.assortmentType === AssortmentType.BASE ||
                (row.standardAgreement && !hasActiveSupplierAgreement)
              }
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'name',
        headerName: t('purchaseAgreements.agreement.columns.levelOrItemName'),
        width: 260,
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.getLevelName()} bottomLine={row.getLevelId()} />
        ),
      },
      {
        ...baseColumnProps,
        field: 'agreement',
        headerName: t('purchaseAgreements.agreement.columns.agreement'),
        width: 180,
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.agreementText} bottomLine={row.agreementId} />
        ),
      },
      {
        ...baseColumnProps,
        field: 'activeFromTo',
        headerName: t('purchaseAgreements.agreement.columns.activeFromTo'),
        renderCell: ({ row }) => {
          const activeFrom = row.validFrom ? dayjs(row.validFrom).format('ll') : '-';
          const activeTo = row.validTo && dayjs(row.validTo).format('ll');
          return <StackedTextCell topLine={activeFrom} bottomLine={activeTo} />;
        },
      },
      {
        ...baseColumnProps,
        field: 'totalItems',
        headerName: t('purchaseAgreements.agreement.columns.priceList'),
        renderCell: ({ row }) => (
          <Link component="button" onClick={() => setConditionForPriceList(row)} fontWeight={600}>
            {t('purchaseAgreements.priceList.lookUpPrices')}
          </Link>
        ),
      },
      {
        ...baseColumnProps,
        field: 'channel',
        headerName: t('purchaseAgreements.agreement.columns.channel'),
      },
      {
        ...baseColumnProps,
        field: 'source',
        headerName: t('purchaseAgreements.agreement.columns.source'),
      },
      {
        ...baseColumnProps,
        field: 'agreementUpdatedAt',
        headerName: t('purchaseAgreements.agreement.columns.lastUpdated'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : '-'),
      },
      {
        ...baseColumnProps,
        field: 'actions',
        headerName: '',
        width: 90,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <PurchaseConditionsTableActions
            handleActivate={
              (!row.id && (!row.standardAgreement || selectedLevel !== HierarchyLevels.supplier)) ||
              (row.standardAgreement && !row.current)
                ? () => handleActivate(row)
                : undefined
            }
            handleDeactivate={
              row.id && row.assortmentType !== AssortmentType.DRAFT
                ? () => handleDeactivate(row)
                : undefined
            }
            handleEdit={
              row.id || (row.standardAgreement && row.current) ? () => handleEdit(row) : undefined
            }
            handleDelete={
              row.assortmentType === AssortmentType.DRAFT ? () => handleDelete(row.id) : undefined
            }
          />
        ),
      },
    ],
    [
      t,
      hasActiveSupplierAgreement,
      selectedLevel,
      setConditionForPriceList,
      handleActivate,
      handleDeactivate,
      handleEdit,
      handleDelete,
    ]
  );

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium
          columns={columns}
          rows={conditions}
          getRowId={(row) => `${row.agreementId}${row.id}`}
          rowHeight={70}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnReorder
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  DATA_GRID_ID.CALCULUS_PURCHASE_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: { right: ['actions'] },
          }}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);
            saveHiddenDataGridColumnsToLocalStorage(
              DATA_GRID_ID.CALCULUS_PURCHASE_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          slots={{
            noRowsOverlay: () => (
              <NoTableRowsOverlay
                noRowsText={t('purchaseAgreements.agreement.noAgreements')}
                addAction={
                  <AddButton onClick={openCreateDialog}>
                    {t('purchaseAgreements.agreement.newAgreement')}
                  </AddButton>
                }
              />
            ),
            columnMenu: CustomColumnMenu,
          }}
          slotProps={{
            ...columnsManagementSlotProps,
          }}
          hideFooter
        />
      </Box>
    </Box>
  );
}
