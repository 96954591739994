import { Stack, Typography } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  currentSalesPrice?: number;
  currentSalesCoverage?: number;
  packageUnit: string;
  includeVat: boolean;
}

export function SalesPriceView({
  currentSalesPrice,
  currentSalesCoverage,
  packageUnit,
  includeVat,
}: Props) {
  const t = useMyAssortmentTFunction();
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <Stack pr={4} py={1} gap={0.5}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="row" gap={1} alignItems="center">
          <Typography fontWeight={600}>
            {t('myAssortment.salesPricePer', { unit: packageUnit })}
          </Typography>
          <Typography>
            {includeVat
              ? t('myAssortment.actions.includeVat')
              : t('myAssortment.actions.excludeVat')}
          </Typography>
        </Stack>
        <Typography variant="body2">
          {`${currentSalesPrice ? formatPrice(currentSalesPrice, language) : '-'} ${t(
            'myAssortment.productCard.currency.sek'
          )}`}
        </Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={600}>{t('myAssortment.salesCoverage')}</Typography>
        <Typography variant="body2">
          {currentSalesCoverage ? `${currentSalesCoverage.toLocaleString(language)}%` : '-'}
        </Typography>
      </Stack>
    </Stack>
  );
}
