import { useMemo } from 'react';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useMarkupTFunction } from '@retail/markup/i18n';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { dataGridBoldClass } from '@shared/styles';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { AssortmentType, MarkupCondition } from '@retail/calculus/types';
import {
  AddButton,
  CustomColumnMenu,
  NoTableRowsOverlay,
  StackedTextCell,
} from '@retail/components';
import { CalculusAreaTableActions, LevelChip } from '@retail/calculus/components';
import { getTogglableDataGridColumns } from '@shared/utils';
import { formatPrice } from '@retail/products/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  groupNumber?: string;
  language: string;
  markups: MarkupCondition[];
  disableActions: boolean;
  handleDeactivate: (markup: MarkupCondition) => void;
  handleEdit: (markup: MarkupCondition) => void;
  handleDelete: (markupId: number) => void;
  openCreateDialog: () => void;
}

const baseColumnProps: Partial<GridColDef<MarkupCondition>> = {
  width: 140,
  sortable: false,
  headerAlign: 'left',
  align: 'left',
};

export function MarkupsTable({
  markups,
  language,
  disableActions,
  handleDeactivate,
  handleEdit,
  handleDelete,
  openCreateDialog,
}: Props) {
  const t = useMarkupTFunction();
  const { i18n } = useTranslation();

  const columns: GridColDef<MarkupCondition>[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        cellClassName: () => dataGridBoldClass,
        field: 'level',
        headerName: t('markup.level'),
        width: 190,
        renderCell: ({ row }) => {
          return (
            <LevelChip
              bindingType={row.condition.bindingType}
              active={row.assortmentType === AssortmentType.BASE}
              packageType={
                ['SECONDARY', 'TERTIARY'].includes(row.markupPackageType + '')
                  ? (row.markupPackageType as 'SECONDARY' | 'TERTIARY')
                  : undefined
              }
            />
          );
        },
      },
      {
        ...baseColumnProps,
        cellClassName: () => dataGridBoldClass,
        field: 'name',
        headerName: t('markup.levelOrItemName'),
        width: 260,
        renderCell: ({ row }) => {
          const name = row.getLevelName();
          const id = row.getLevelId();

          return <StackedTextCell topLine={name} bottomLine={id} />;
        },
      },
      {
        ...baseColumnProps,
        field: 'markupName',
        headerName: t('markup.markupName'),
        width: 220,
        renderCell: ({ row }) => row.markupTemplateName,
      },
      {
        ...baseColumnProps,
        field: 'salesFactor',
        headerName: t('markup.salesFactor'),
        renderCell: ({ row }) => row.salesFactor,
      },
      {
        ...baseColumnProps,
        field: 'coverageDegree',
        width: 100,
        headerName: t('markup.salesCoverage'),
        renderCell: ({ row }) =>
          row.coverageDegree ? `${formatPrice(Number(row.coverageDegree), i18n.language)} %` : '-',
      },
      {
        ...baseColumnProps,
        field: 'seeItemPrices',
        headerName: t('markup.salesPrice'),
        renderCell: ({ row }) => '-',
      },
      {
        ...baseColumnProps,
        field: 'activeFromTo',
        width: 140,
        headerName: t('markup.activeFromTo'),
        renderCell: ({ row }) => {
          const activeFrom = dayjs(row.validFrom).format('ll');
          const activeTo = row.validTo && dayjs(row.validTo).format('ll');
          return <StackedTextCell topLine={activeFrom} bottomLine={activeTo} centerAlign />;
        },
      },
      {
        ...baseColumnProps,
        field: 'source',
        width: 100,
        headerName: t('markup.source'),
        renderCell: ({ row }) => row.source ?? '-',
      },

      {
        ...baseColumnProps,
        field: 'actions',
        headerName: '',
        width: 90,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <CalculusAreaTableActions
            handleDeactivate={
              row.assortmentType !== AssortmentType.DRAFT && !disableActions
                ? () => handleDeactivate(row)
                : undefined
            }
            handleEdit={!disableActions ? () => handleEdit(row) : undefined}
            handleDelete={
              row.assortmentType === AssortmentType.DRAFT && !disableActions
                ? () => handleDelete(row.id)
                : undefined
            }
          />
        ),
      },
    ],
    [t, disableActions, handleDeactivate, handleEdit, handleDelete]
  );

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium
          getRowId={(row) => `${row.id}${row.markupTemplateId}`}
          columns={columns}
          rows={markups}
          rowHeight={70}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  DATA_GRID_ID.CALCULUS_MARKUP_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: { right: ['actions'] },
          }}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);
            saveHiddenDataGridColumnsToLocalStorage(
              DATA_GRID_ID.CALCULUS_MARKUP_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnReorder
          hideFooter
          slots={{
            noRowsOverlay: () => (
              <NoTableRowsOverlay
                noRowsText={t('markup.noMarkups')}
                addAction={
                  <AddButton onClick={openCreateDialog} disabled={disableActions}>
                    {t('markup.create.createMarkup')}
                  </AddButton>
                }
              />
            ),
            columnMenu: CustomColumnMenu,
          }}
          slotProps={{
            ...columnsManagementSlotProps,
          }}
        />
      </Box>
    </Box>
  );
}
