import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useFetchMemberAssortments } from '@retail/my-assortment/data-access';
import { AssortmentTypeDTO } from '@retail/my-assortment/types';
import { useMemo } from 'react';

export const useMemberAssortments = () => {
  const { selectedDepartment, selectedPriceList } = useSelectedOrgUnit();
  const memberId = selectedPriceList?.memberId ?? selectedDepartment!.memberId;

  const { data: assortments = [] } = useFetchMemberAssortments({ memberId });

  const baseAssortment = useMemo(
    () => assortments.find((assortment) => assortment.type === AssortmentTypeDTO.BASE)!,
    [assortments]
  );
  const draftAssortment = useMemo(
    () => assortments.find((assortment) => assortment.type === AssortmentTypeDTO.DRAFT)!,
    [assortments]
  );

  return {
    memberId,
    draftAssortment,
    baseAssortment,
    draftAssortmentId: draftAssortment.id,
    baseAssortmentId: baseAssortment.id,
  };
};
