import { MutableRefObject, useMemo } from 'react';
import { AssortmentItem, AssortmentTab } from '@retail/my-assortment/types';
import { StackedTextCell } from '@retail/components';
import dayjs from 'dayjs';
import { formatPrice } from '@retail/products/utils';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-premium';
import { PricePerUnit } from '@retail/products/components';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useSalesPriceColumn } from './useSalesPriceColumn';
import { MarkupChange, MarkupMetaSchema } from '../../ProductCard';

export interface ShowColumnProps {
  selectedTab: AssortmentTab;
  showBracketPriceColumns: boolean;
  isPriceSuggestionEnabled: boolean;
}

export interface RowPriceProps {
  includeVat: boolean;
  enableSortable?: boolean;
  apiRef?: MutableRefObject<GridApiPremium>;
  createCondition?: (
    { fromDate, markupName }: MarkupMetaSchema,
    {
      salesPrice,
      salesPriceIncVat,
      mgItemNumber,
      mgSupplierId,
      id,
    }: Partial<MarkupChange> & Pick<AssortmentItem, 'id' | 'mgItemNumber' | 'mgSupplierId'>,
    unit: string
  ) => void;
  createMarkupDialog?: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
  };
  isLoadingSalesPriceCondition?: boolean;
}

const baseColumnProps: Partial<GridColDef> = {
  width: 130,
  headerName: '',
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

export const useCurrentItemPriceColumns = ({
  includeVat,
  enableSortable,
  apiRef,
  createMarkupDialog,
  isLoadingSalesPriceCondition,
  createCondition,
}: RowPriceProps) => {
  const { t } = useMyAssortmentTranslation();
  const { i18n } = useTranslation();

  const salesPriceColumn = useSalesPriceColumn({
    includeVat,
    enableSortable,
    apiRef,
    createMarkupDialog,
    isLoadingSalesPriceCondition,
    createCondition,
  });

  return useMemo(() => {
    const purchasePriceColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      field: 'purchasePrice',
      headerName: t('myAssortment.columns.purchasePrice'),
      width: 180,
      sortable: enableSortable,
      renderCell: ({ row }) => {
        const primaryPackageUnit = row.primaryPackageUnit;
        const currentPurchasePrice = row.currentSalesPrices?.purchasePrice;
        const priceDate = currentPurchasePrice
          ? dayjs(currentPurchasePrice.validFrom).format('ll')
          : null;
        const purchasePriceForUnit = row.getCurrentPurchasePriceForUnit(primaryPackageUnit);
        const purchasePrice = purchasePriceForUnit
          ? formatPrice(purchasePriceForUnit.price.price, i18n.language)
          : '-';
        const unitDescription = purchasePriceForUnit?.supplierItemPackage?.unit?.description;

        return (
          <StackedTextCell
            nullableBottomLine
            topLine={
              <PricePerUnit
                price={purchasePrice}
                unit={primaryPackageUnit}
                description={unitDescription}
              />
            }
            bottomLine={priceDate}
          />
        );
      },
      valueGetter: (_, row) =>
        row.getCurrentPurchasePriceForUnit(row.primaryPackageUnit)?.price.price,
    };
    const costPriceInSalesUnitColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      field: 'costPriceInSalesUnit',
      width: 160,
      headerName: t('myAssortment.columns.costPriceInSalesUnit'),
      sortable: enableSortable,
      renderCell: ({ row }) => {
        const primaryPackageUnit = row.primaryPackageUnit;

        const validFrom = row.currentSalesPrices?.costPrice?.validFrom;
        const priceDate = validFrom ? dayjs(validFrom).format('ll') : undefined;

        const currentCostPrice = row.getCurrentCostPriceForUnit(primaryPackageUnit);

        const costPrice = currentCostPrice
          ? formatPrice(currentCostPrice.price.price, i18n.language)
          : '-';

        const unitDescription = currentCostPrice?.supplierItemPackage.unit.description;

        return (
          <StackedTextCell
            nullableBottomLine
            topLine={
              <PricePerUnit
                price={costPrice}
                unit={primaryPackageUnit}
                description={unitDescription}
              />
            }
            bottomLine={priceDate}
          />
        );
      },
      valueGetter: (_, row) => row.getCurrentCostPriceForUnit(row.primaryPackageUnit)?.price.price,
    };
    const costPriceColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      field: 'costPrice',
      width: 160,

      headerName: t('myAssortment.columns.costPriceInBasePriceUnit'),
      renderCell: ({ row }) => {
        const basePriceUnit = row.basePriceUnit;

        const validFrom = row.currentSalesPrices?.costPrice?.validFrom;
        const priceDate = validFrom ? dayjs(validFrom).format('ll') : undefined;

        const currentCostPrice = row.getCurrentCostPriceForUnit(basePriceUnit);

        const costPrice = currentCostPrice
          ? formatPrice(currentCostPrice.price.price, i18n.language)
          : '-';
        const unitDescription = currentCostPrice?.supplierItemPackage.unit.description;

        return (
          <StackedTextCell
            nullableBottomLine
            topLine={
              <PricePerUnit price={costPrice} unit={basePriceUnit} description={unitDescription} />
            }
            bottomLine={priceDate}
          />
        );
      },
    };
    return {
      purchasePriceColumn,
      costPriceInSalesUnitColumn,
      costPriceColumn,
      salesPriceColumn,
    };
  }, [t, enableSortable, salesPriceColumn, i18n.language]);
};
