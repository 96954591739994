import { useMemo } from 'react';
import { MgSupplierItemConditionsPageResult } from '@retail/calculus/types';
import { usePagination } from '@shared/hooks';
import { CalculusAreas, SupplierItemConditions } from '@retail/calculus/types';
import { useFetchAffectedItems } from '@retail/calculus/data-access';

interface Props {
  assortmentId: number;
  mgSupplierId: number;
  productGroup?: string;
  discountGroupId?: number;
  agreementId?: number;
  baseAssortment?: boolean;
  conditionType: CalculusAreas;
  initPageSize?: number;
}

export const useAffectedItems = ({
  assortmentId,
  mgSupplierId,
  productGroup,
  discountGroupId,
  agreementId,
  baseAssortment,
  conditionType,
  initPageSize,
}: Props) => {
  const { pageSize, page, setPageSize, setPage } = usePagination({
    initPageSize: initPageSize,
  });

  const { data: supplierItemConditionsPageDto, isLoading } = useFetchAffectedItems({
    assortmentId,
    mgSupplierId,
    groupNumber: productGroup,
    discountGroupId,
    agreementId,
    conditionType,
    baseAssortment,
    page,
    size: pageSize,
    suspense: true,
  });

  const items = useMemo(
    () =>
      supplierItemConditionsPageDto
        ? supplierItemConditionsPageDto.result.map(SupplierItemConditions.fromDto)
        : [],
    [supplierItemConditionsPageDto]
  );

  return {
    isLoading,
    result: {
      totalItems: supplierItemConditionsPageDto?.totalCount,
      items,
      page,
      pageSize,
    } as MgSupplierItemConditionsPageResult,
    setPage,
    setPageSize,
  };
};
