export enum OrgUnitRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  NONE = 'NONE',
}

export interface Department {
  dataownerId: string;
  locationId: string;
  memberId: string;
  departmentId: string;
  latitude?: number;
  longitude?: number;
  name: string;
  role: OrgUnitRole;
  orgUnitId: number;
  assortmentId: number;
  draftAssortmentId: number;
}

export interface Member {
  dataownerId: string;
  memberId: string;
  name: string;
  role: OrgUnitRole;
  orgUnitId: number;
  assortmentId: number;
  draftAssortmentId: number;
}

export interface PriceList {
  name: string;
  assortmentId: number;
  draftAssortmentId: number;
  dataownerId: string;
  locationId: string;
  orgUnitId: number;
  memberId: string;
  departmentId: string;
}
