import { PageLayout, SuspenseWithSentryErrorBoundary, TabContent } from '@retail/components';
import { Tabs } from '@shared/components';
import { mgPrisRoutes, pdmRoutes } from '@retail/mgpris/config';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { MonitoringAssortment } from '@retail/monitoring-assortment/types';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';

const MarketInsightTabs = ['marketinsight', 'competitors'] as const;
type MarketInsightTab = (typeof MarketInsightTabs)[number];

interface Props {
  monitoringAssortments: MonitoringAssortment[];
  marketInsightRoute: typeof mgPrisRoutes.marketInsight | typeof pdmRoutes.marketInsight;
}

export function MarketInsightPage({ monitoringAssortments, marketInsightRoute }: Props) {
  const navigate = useNavigate();
  const t = useAssortmentTFunction();

  const competitorsRouteMatch = useMatch(`${marketInsightRoute.competitors.fullRoutePath}`);
  const marketInsightRouteMatch = useMatch(`${marketInsightRoute.fullRoutePath}`);

  const currentTab: MarketInsightTab = useMemo(() => {
    if (competitorsRouteMatch) return 'competitors';
    return 'marketinsight';
  }, [competitorsRouteMatch]);

  useEffect(() => {
    if (marketInsightRouteMatch && monitoringAssortments[0]) {
      navigate(
        marketInsightRoute.assortments.assortment.getFullLinkPath({
          assortmentId: monitoringAssortments[0].id,
        })
      );
    }
  }, [
    marketInsightRoute.assortments.assortment,
    marketInsightRouteMatch,
    monitoringAssortments,
    navigate,
  ]);

  useEffect(() => {
    if (marketInsightRouteMatch && !monitoringAssortments.length) {
      navigate(marketInsightRoute.assortments.getFullLinkPath());
    }
  }, [marketInsightRoute.assortments, marketInsightRouteMatch, monitoringAssortments, navigate]);

  return (
    <PageLayout maxWidth="full" fixedHeight>
      <Tabs<MarketInsightTab>
        prefixId="market-insight"
        tabs={[
          {
            label: t('assortment.marketInsight'),
            value: 'marketinsight',
            to: marketInsightRoute.getFullLinkPath(),
          },
          {
            label: t('assortment.competitors.heading'),
            value: 'competitors',
            to: marketInsightRoute.competitors.getFullLinkPath(),
          },
        ]}
        currentTab={currentTab}
      />
      <TabContent sx={{ overflow: 'hidden', pb: 1 }}>
        <SuspenseWithSentryErrorBoundary>
          <Outlet context={{ monitoringAssortments }} />
        </SuspenseWithSentryErrorBoundary>
      </TabContent>
    </PageLayout>
  );
}
