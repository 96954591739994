import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { ValidateImportedItems } from '@retail/monitoring-assortment/components';
import {
  useMonitoringAssortmentActions,
  useValidationOnImportedItems,
} from '@retail/monitoring-assortment/context';
import { AddItemsTabs } from '@retail/my-assortment/components';
import { CentralAssortmentOverview } from '@retail/my-assortment/containers';
import { useAssortmentFilters } from '@retail/my-assortment/context';
import { DraftTabs, ItemWithPriority } from '@retail/my-assortment/types';
import { UploadItemsContainer } from '@retail/products/components';
import { MgSupplierItemSearchContainer } from '@retail/products/containers';
import { useProductsTFunction } from '@retail/products/i18n';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  assortmentId: number;
}

const csvData = 'MgItem-nummer;Finfo-nummer;Gtin-nummer';

export function AddItemsToMonitoringAssortment({ open, onClose, assortmentId }: Props) {
  const t = useProductsTFunction();
  const [selectedTab, setSelectedTab] = useState<DraftTabs>(DraftTabs.ADD_ITEMS);
  const [checkedItems, setCheckedItems] = useState<MgSupplierItemSearchResult[]>([]);
  const [checkedAssortmentItems, setCheckedAssortmentItems] = useState<ItemWithPriority[]>([]);

  const { handleAddItemsToMgAssortment } = useMonitoringAssortmentActions();
  const { importedItems, validationState, resetImportedAssortmentStates, loadingValidateItems } =
    useValidationOnImportedItems();

  //TODO: Change when filers for monitoring assortment is ready
  const { resetFilters } = useAssortmentFilters({ assortmentId });

  const itemsToAdd = useMemo(
    () =>
      selectedTab === DraftTabs.ADD_ITEMS
        ? checkedItems.map((item) => item.mgItemNumber)
        : selectedTab === DraftTabs.CENTRAL_ASSORTMENTS
        ? checkedAssortmentItems.map((item) => item.mgItemNumber)
        : DraftTabs.IMPORT
        ? (importedItems.map(({ mgItemNumber }) => mgItemNumber) as number[])
        : [],
    [checkedAssortmentItems, checkedItems, importedItems, selectedTab]
  );

  const addItemsToAssorment = useCallback(() => {
    handleAddItemsToMgAssortment({
      mgItemNumbers: itemsToAdd,
      finfoNumbers:
        selectedTab === DraftTabs.IMPORT
          ? (importedItems.map(({ finfoNumber }) => finfoNumber) as number[])
          : [],
      assortmentId,
    }).then(() => {
      onClose();
      resetFilters();
      resetImportedAssortmentStates();
    });
  }, [
    assortmentId,
    handleAddItemsToMgAssortment,
    importedItems,
    itemsToAdd,
    onClose,
    resetFilters,
    resetImportedAssortmentStates,
    selectedTab,
  ]);

  useEffect(() => {
    if (selectedTab !== DraftTabs.IMPORT) resetImportedAssortmentStates();
    if (selectedTab !== DraftTabs.ADD_ITEMS) {
      setCheckedItems([]);
      resetFilters();
    }
    if (selectedTab !== DraftTabs.CENTRAL_ASSORTMENTS) setCheckedAssortmentItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        resetFilters();
      }}
      fullScreen
      sx={{ padding: 4, '.MuiPaper-root': { gap: 0 } }}
    >
      <DialogTitle>
        <AddItemsTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexGrow: 1 }}>
        {selectedTab === DraftTabs.ADD_ITEMS && (
          <SuspenseWithSentryErrorBoundary>
            <MgSupplierItemSearchContainer
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              isLoading={false}
              assortmentId={assortmentId}
              monitoringAssortments={[]}
              disableAddItemsToMonitoringAssortment
            />
          </SuspenseWithSentryErrorBoundary>
        )}
        {selectedTab === DraftTabs.IMPORT && (
          <Stack display="flex" flexGrow={1} justifyContent="center">
            <UploadItemsContainer
              isLarge
              loadingValidateItems={loadingValidateItems}
              csvData={csvData}
              resetImportedAssortmentStates={resetImportedAssortmentStates}
              renderValidation={
                <ValidateImportedItems
                  importedItems={importedItems}
                  invalidItemCodes={validationState}
                />
              }
            />
          </Stack>
        )}
        {selectedTab === DraftTabs.CENTRAL_ASSORTMENTS && (
          <SuspenseWithSentryErrorBoundary>
            <CentralAssortmentOverview
              checkedAssortmentItems={checkedAssortmentItems}
              setCheckedAssortmentItems={setCheckedAssortmentItems}
            />
          </SuspenseWithSentryErrorBoundary>
        )}
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={2}>
          <TextButton onClick={onClose}>{t('products.cancel')}</TextButton>
          <ContainedButton color="secondary" size="small" onClick={addItemsToAssorment}>
            {t('products.mgItem.add')}
          </ContainedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
