import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { CalculusInfoRow } from './CalculusInfoRow';
import { InfinityIcon } from '@shared/custom-icons';
import { useTranslation } from 'react-i18next';
import { FreightCondition } from '@retail/products/types';
import { LevelChip } from '@retail/calculus/components';

interface Props {
  freightCondition?: FreightCondition;
}

export function FreightAccordion({ freightCondition }: Props) {
  const { i18n } = useTranslation();
  const t = useAppTFunction();

  const freightLabel = useMemo(() => {
    if (freightCondition?.percentAddon) {
      return `${freightCondition.percentAddon.toLocaleString(i18n.language, {
        maximumFractionDigits: 2,
      })}%`;
    }
    if (freightCondition?.freightFactor) {
      return freightCondition.freightFactor;
    }
    if (freightCondition?.fixedAddOn) {
      return t('freight.columns.pricePerUnit', {
        price: freightCondition.fixedAddOn,
        unit: freightCondition.fixedAddOnUnit?.unit,
      });
    }
    return 0;
  }, [
    freightCondition?.fixedAddOn,
    freightCondition?.fixedAddOnUnit,
    freightCondition?.freightFactor,
    freightCondition?.percentAddon,
    i18n.language,
    t,
  ]);

  return (
    <Accordion>
      <AccordionSummary>
        <CalculusInfoRow label={t('myAssortment.freightFactor')} chipLabel={freightLabel} />
      </AccordionSummary>
      <AccordionDetails>
        <Paper sx={{ py: 0, px: 2 }}>
          {freightCondition ? (
            <Grid container gap={2} flexDirection="column">
              <Stack gap={6} flexDirection="row" fontSize={14}>
                <Stack flexDirection="row" gap={2} alignItems="center">
                  <Grid item fontWeight={600}>
                    {t('freight.columns.freightValue')}
                  </Grid>
                  <Grid item>{freightLabel}</Grid>
                </Stack>
                <Stack flexDirection="row" gap={2} alignItems="center">
                  <Grid item fontWeight={600}>
                    {t('freight.columns.level')}
                  </Grid>
                  <Grid item>
                    {
                      <LevelChip
                        abbreviation
                        width={50}
                        height={26}
                        active
                        bindingType={freightCondition.condition.bindingType}
                      />
                    }
                  </Grid>
                </Stack>
              </Stack>
              <Stack gap={6} flexDirection="row" fontSize={14}>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('freight.columns.fromDate')}
                  </Grid>
                  <Grid item>{dayjs(freightCondition.condition.validFrom).format('ll')}</Grid>
                </Stack>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('freight.columns.toDate')}
                  </Grid>
                  <Grid item>
                    {freightCondition.condition.validTo ? (
                      dayjs(freightCondition.condition.validTo).format('ll')
                    ) : (
                      <InfinityIcon />
                    )}
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
          ) : (
            <Typography>{t('myAssortment.productCard.noActiveFreightCondition')}</Typography>
          )}
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
}
