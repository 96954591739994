import {
  CreateMgItemLevelMarkupCondition,
  CreateMgItemLevelSalesPriceCondition,
  SupplierMarkupConditionPostDTO,
} from '@retail/markup/types';

const MAX_SALES_FACTOR_DECIMALS = 6;

export const truncateSalesFactorMaxDecimals = (salesFactor: number) =>
  Number(Number(salesFactor).toFixed(MAX_SALES_FACTOR_DECIMALS));

export const mapSalesPriceConditionToDto = (
  {
    salesPrice,
    mgItemNumber,
    validFrom,
    templateName,
    incVat,
    unit,
    gtin,
  }: CreateMgItemLevelSalesPriceCondition,
  mgSupplierId: number
): SupplierMarkupConditionPostDTO => ({
  mgSupplierId,
  mgSupplierItemConditions: [
    {
      validFrom: validFrom.toISOString(),
      mgItemNumber,
      salesPriceMarkup: { salesPrice, templateName, incMva: incVat, unit },
      gtinCode: gtin,
    },
  ],
});

export const mapMarkupConditionToDto = (
  { mgItemNumber, salesFactor, templateName, validFrom, gtin }: CreateMgItemLevelMarkupCondition,
  mgSupplierId: number
): SupplierMarkupConditionPostDTO => ({
  mgSupplierId,
  mgSupplierItemConditions: [
    {
      validFrom: validFrom.toISOString(),
      mgItemNumber,
      gtinCode: gtin,
      markup: {
        templateCreate: {
          templateName,
          salesFactor: truncateSalesFactorMaxDecimals(salesFactor),
        },
      },
    },
  ],
});
