import { useCalculusTFunction } from '@retail/calculus/i18n';
import {
  CreateCustomerMarkupSchema,
  CustomerMarkupConditionValueForm,
} from '@retail/customer-markup/components';
import { ContainedButton, Dialog } from '@shared/components';
import { useFormContext } from 'react-hook-form';
import { useCustomerMarkupActions } from '@retail/customer-markup/context';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useCallback, useState } from 'react';
import { PriceListAssortmentSelect } from './PriceListAssortmentSelect';

interface Props {
  assortmentId: number[];
  open: boolean;
  includeAssortmentSelect: boolean;
  onClose: () => void;
}

function CreateAssortmentLevelCustomerMarkupConditionDialog({
  assortmentId,
  open,
  includeAssortmentSelect,
  onClose,
}: Props) {
  const t = useCalculusTFunction();
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useFormContext<CreateCustomerMarkupSchema>();

  const [selectedAssortmentIds, selectAssortmentId] = useState<number[]>(
    includeAssortmentSelect ? [] : assortmentId
  );

  const { createAssortmentLevelCustomerMarkupCondition } = useCustomerMarkupActions({
    assortmentIds: selectedAssortmentIds,
  });

  const onActivate = useCallback(
    ({ enteredField, fromDate, factor, percent }: CreateCustomerMarkupSchema) => {
      createAssortmentLevelCustomerMarkupCondition({
        fromDate,
        enteredField,
        factor: Number(factor),
        percent: Number(percent),
      });

      reset();
      onClose();
    },
    [createAssortmentLevelCustomerMarkupCondition, reset, onClose]
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ pb: 1 }}>{t('calculus.createCustomerMarkup')}</DialogTitle>

      <DialogContent>
        {includeAssortmentSelect && (
          <PriceListAssortmentSelect
            selectedAssortmentIds={selectedAssortmentIds}
            selectAssortmentId={selectAssortmentId}
          />
        )}
        <CustomerMarkupConditionValueForm />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', mt: 1 }}>
        <ContainedButton
          disabled={Object.keys(errors).length > 0}
          onClick={handleSubmit(onActivate)}
        >
          {t('calculus.activate')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(CreateAssortmentLevelCustomerMarkupConditionDialog);
