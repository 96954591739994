import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { AssortmentTab } from '@retail/my-assortment/types';
import { UnderlinedTabs } from '@shared/components';
import { useMemo } from 'react';

interface Props {
  selectedTab: AssortmentTab;
  setSelectedTab: (assortmentTab: AssortmentTab) => void;
}

export const AssortmentTabs = ({ selectedTab, setSelectedTab }: Props) => {
  const t = useMyAssortmentTFunction();
  const selectTab = (_: any, newTab: AssortmentTab) => setSelectedTab(newTab);

  const tabs = useMemo(
    () => [
      { label: t('myAssortment.tabs.myAssortment'), value: AssortmentTab.MY_ASSORTMENT },
      { label: t('myAssortment.tabs.priceChanges'), value: AssortmentTab.PRICE_CHANGES },
      { label: t('myAssortment.tabs.competitorPrices'), value: AssortmentTab.COMPETITOR_PRICES },
    ],
    [t]
  );

  return (
    <UnderlinedTabs id="assortment" tabs={tabs} currentTab={selectedTab} onChange={selectTab} />
  );
};
