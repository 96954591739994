import { useSelectedOrgUnit, useUserBrands } from '@retail/app/stores/selected-context';
import { AssortmentItems } from './AssortmentItems';
import { usePagination } from '@shared/hooks';
import { useAssortmentItems } from '@retail/my-assortment/context';
import {
  AssortmentTab,
  FilterOptions,
  FilterSelection,
  SetFilterOptions,
} from '@retail/my-assortment/types';
import { useSelectedCompetitors } from '@retail/retailer/data-access';

interface Props {
  assortmentId: number;
  searchFilters: FilterOptions;
  setSearchFilters: SetFilterOptions;
  filterOptions: FilterSelection;
  selectedTab: AssortmentTab;
  showFilters: boolean;
  toggleFilters: () => void;
  toggleAddItemsDialog: () => void;
}

export function AssortmentItemsContainer({
  assortmentId,
  searchFilters,
  setSearchFilters,
  filterOptions,
  selectedTab,
  showFilters,
  toggleFilters,
  toggleAddItemsDialog,
}: Props) {
  const { selectedBrand } = useUserBrands();
  const pagination = usePagination();

  const { selectedOrgUnitId } = useSelectedOrgUnit();
  const competitors = useSelectedCompetitors(selectedOrgUnitId);

  const { assortmentItems, totalItems } = useAssortmentItems({
    assortmentId,
    selectedSupplier: searchFilters.selectedSupplier?.mgSupplierId,
    selectedProductGroup: searchFilters.selectedProductGroup?.groupNumber,
    selectedDiscountGroup: searchFilters.selectedDiscountGroup?.id,
    searchQuery: searchFilters.searchQuery,
    inAssortment: true,
    withAgreementPrices: searchFilters.itemsWithAgreementPrice,
    calculationPackages: selectedBrand.wholesaler
      ? ['PRIMARY', 'SECONDARY', 'TERTIARY']
      : ['PRIMARY'],
    pagination,
    nFuturePrices: 1,
  });

  return (
    <AssortmentItems
      {...searchFilters}
      {...setSearchFilters}
      {...filterOptions}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      filterOptions={filterOptions}
      assortmentItems={assortmentItems}
      totalItems={totalItems}
      competitors={competitors}
      pagination={pagination}
      selectedTab={selectedTab}
      showFilters={showFilters}
      toggleFilters={toggleFilters}
      toggleAddItemsDialog={toggleAddItemsDialog}
    />
  );
}
