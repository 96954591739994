import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/material';
import {
  DataGridPremium,
  GridRowSelectionModel,
  useGridApiRef,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
import {
  dataGridCellNoFocusOutlineClass,
  dataGridColorRowClass,
  dataGridHorizontalPaddingSmallClass,
  dataGridRowHoverClass,
  dataGridSelectedRowClass,
  dataGridWhiteRowClass,
} from '@shared/styles';
import { AssortmentItem, AssortmentTab } from '@retail/my-assortment/types';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { AddItemsButton } from '../AddItemsButton';
import { CustomColumnMenu, DataGridPagination, NoTableRowsOverlay } from '@retail/components';
import clsx from 'clsx';
import { GridRowClassNameParams } from '@mui/x-data-grid/models/params';
import { useAssortmentColumns } from './useAssortmentColumns';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { getTogglableDataGridColumns } from '@shared/utils';
import { useCompetitorPricesColumns } from './useCompetitorPricesColumns';
import { Competitor } from '@retail/retailer/types';
import { CompetitorPricesResponse } from '@retail/products/types';
import { RowPriceProps } from './AssortmentColumns';
import { MarkupChange, MarkupMetaSchema } from '../ProductCard';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

interface CompetitorProps {
  competitors: Competitor[];
  handleFetchCompetitorPrices?: () => Promise<CompetitorPricesResponse>;
}

interface Props {
  items: AssortmentItem[];
  selectedItem: AssortmentItem | null;
  page: number;
  pageSize: number;
  totalItems: number;
  includeVat: boolean;
  selectedTab: AssortmentTab;
  itemsChecked: number[];
  language: string;
  selectedSupplier?: number;
  getSupplierCalculusLink?: (supplierId: number) => string;
  toggleAddItemsDialog: () => void;
  viewItemProductCard: (itemId: number) => void;
  setPage: (newPage: number) => void;
  setPageSize: (newPageSize: number) => void;
  setItemsChecked: Dispatch<SetStateAction<number[]>>;
  deleteItem: (itemId: number) => void;
  selectSupplierById: (supplierId: number | null) => void;
  selectProductGroupByNr: (productGroupNr: string | null) => void;
  selectDiscountGroupById: (discountGroupId: number | null) => void;
  createCondition?: (
    apiRef: MutableRefObject<GridApiPremium>,
    { fromDate, markupName }: MarkupMetaSchema,
    {
      salesPrice,
      salesPriceIncVat,
      id,
      mgItemNumber,
      mgSupplierId,
    }: Partial<MarkupChange> & Pick<AssortmentItem, 'id' | 'mgItemNumber' | 'mgSupplierId'>,
    unit: string
  ) => void;
  disableItemRemoval?: boolean;
  showBracketPriceColumns?: boolean;
  isPriceSuggestionEnabled: boolean;
  isPriceListLevel?: boolean;
}

export const AssortmentItemsTable = ({
  items,
  selectedItem,
  page,
  pageSize,
  totalItems,
  includeVat,
  selectedTab,
  itemsChecked,
  language,
  selectedSupplier,
  competitors,
  createMarkupDialog,
  handleFetchCompetitorPrices,
  getSupplierCalculusLink,
  toggleAddItemsDialog,
  viewItemProductCard,
  setPage,
  setPageSize,
  setItemsChecked,
  deleteItem,
  selectSupplierById,
  selectProductGroupByNr,
  selectDiscountGroupById,
  createCondition,
  disableItemRemoval,
  showBracketPriceColumns = false,
  isPriceSuggestionEnabled,
  isPriceListLevel,
  isLoadingSalesPriceCondition,
}: Props & CompetitorProps & Omit<RowPriceProps, 'createCondition'>) => {
  const { t } = useMyAssortmentTranslation();
  const apiRef = useGridApiRef();

  const lastMgItemNumber = useRef<number | undefined>(items[0]?.mgItemNumber);
  const rowColor = useRef<string>(dataGridColorRowClass);

  const [competitorPrices, setCompetitorPrices] = useState<CompetitorPricesResponse | undefined>(
    undefined
  );

  const onRowsSelectionHandler = (selections: GridRowSelectionModel) => {
    setItemsChecked(selections as number[]);
  };

  const selectRowId = useCallback(
    (rowId: number) => viewItemProductCard(rowId),
    [viewItemProductCard]
  );

  const handleCreateCondition = (
    { fromDate, markupName }: MarkupMetaSchema,
    {
      id,
      salesPrice,
      mgItemNumber,
      mgSupplierId,
    }: Partial<MarkupChange> & Pick<AssortmentItem, 'id' | 'mgItemNumber' | 'mgSupplierId'>,
    unit: string
  ) => {
    createCondition &&
      createCondition(
        apiRef,
        { fromDate, markupName, toDate: null },
        {
          id,
          salesPrice,
          mgSupplierId,
          mgItemNumber,
        },
        unit
      );
  };

  const columnConfigs = useAssortmentColumns({
    apiRef,
    includeVat,
    enableSortable: !!selectedSupplier && totalItems < pageSize,
    disableItemRemoval,
    createMarkupDialog,
    isLoadingSalesPriceCondition,
    createCondition: handleCreateCondition,
    deleteItem,
    getSupplierCalculusLink,
    selectSupplier: selectSupplierById,
    selectProductGroup: selectProductGroupByNr,
    selectDiscountGroup: selectDiscountGroupById,
    selectRowId: isPriceListLevel ? undefined : selectRowId,
  });

  const columns = useMemo(
    () =>
      columnConfigs
        .filter(({ show }) =>
          show({ selectedTab, showBracketPriceColumns, isPriceListLevel, isPriceSuggestionEnabled })
        )
        .map(({ getColumn }) => getColumn()),
    [
      columnConfigs,
      isPriceListLevel,
      isPriceSuggestionEnabled,
      selectedTab,
      showBracketPriceColumns,
    ]
  );

  const competitorPricesColumns = useCompetitorPricesColumns({
    includeVat,
    competitors,
    competitorPrices,
  });

  const getRowClassName = useCallback(
    ({ row }: GridRowClassNameParams<AssortmentItem>) => {
      if (row.mgItemNumber !== lastMgItemNumber.current) {
        rowColor.current =
          rowColor.current === dataGridWhiteRowClass
            ? dataGridColorRowClass
            : dataGridWhiteRowClass;
        lastMgItemNumber.current = row.mgItemNumber;
      }

      return clsx(rowColor.current, dataGridRowHoverClass, {
        [dataGridSelectedRowClass]: selectedItem?.id === row.id,
      });
    },
    [selectedItem?.id]
  );

  useEffect(() => {
    if (selectedTab === AssortmentTab.COMPETITOR_PRICES && handleFetchCompetitorPrices) {
      handleFetchCompetitorPrices().then((response) => setCompetitorPrices(response));
    }
  }, [handleFetchCompetitorPrices, selectedTab]);

  const columnFields = useMemo(
    () =>
      columns
        .map((column) => column.field)
        .filter(
          (column) =>
            column !== 'actions' &&
            column !== 'futureSalesPrice' &&
            column !== 'purchasePrice' &&
            column !== 'supplier'
        ),
    [columns]
  );

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box key={selectedSupplier} sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium<AssortmentItem>
          key={selectedTab}
          apiRef={apiRef}
          columns={[
            ...columns,
            ...(selectedTab === AssortmentTab.COMPETITOR_PRICES ? competitorPricesColumns : []),
          ]}
          rows={items}
          getRowId={(row) => row.id}
          getRowClassName={getRowClassName}
          getCellClassName={() => clsx(dataGridCellNoFocusOutlineClass)}
          className={dataGridHorizontalPaddingSmallClass}
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          rowHeight={65}
          checkboxSelection={!disableItemRemoval}
          disableRowSelectionOnClick
          disableColumnReorder
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  selectedTab === AssortmentTab.MY_ASSORTMENT
                    ? DATA_GRID_ID.ASSORTMENT_ITEMS_TABLE
                    : DATA_GRID_ID.ASSORTMENT_PRICE_CHANGES_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: {
              right: ['actions'],
              left:
                selectedTab === AssortmentTab.COMPETITOR_PRICES
                  ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...columnFields]
                  : undefined,
            },
          }}
          rowCount={totalItems}
          pagination={true}
          paginationMode="server"
          paginationModel={{ page, pageSize }}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);

            saveHiddenDataGridColumnsToLocalStorage(
              selectedTab === AssortmentTab.MY_ASSORTMENT
                ? DATA_GRID_ID.ASSORTMENT_ITEMS_TABLE
                : selectedTab === AssortmentTab.PRICE_CHANGES
                ? DATA_GRID_ID.ASSORTMENT_PRICE_CHANGES_TABLE
                : DATA_GRID_ID.ASSORTMENT_COMPETITOR_PRICES,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          onPaginationModelChange={(model) => {
            if (model.page !== page) setPage(model.page);
            if (model.pageSize !== pageSize) setPageSize(model.pageSize);
          }}
          rowSelectionModel={itemsChecked}
          onRowSelectionModelChange={onRowsSelectionHandler}
          slots={{
            columnMenu: CustomColumnMenu,
            pagination: () => <DataGridPagination />,
            noRowsOverlay: () => (
              <NoTableRowsOverlay
                noRowsText={t('myAssortment.noItems')}
                addAction={<AddItemsButton onClick={toggleAddItemsDialog} />}
              />
            ),
          }}
          slotProps={{ ...columnsManagementSlotProps }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              marginLeft:
                selectedTab === AssortmentTab.COMPETITOR_PRICES ? `0 !important` : undefined,
            },
            '& .MuiDataGrid-row': {
              paddingLeft:
                selectedTab === AssortmentTab.COMPETITOR_PRICES ? `0 !important` : undefined,
              marginLeft: (theme) =>
                selectedTab === AssortmentTab.COMPETITOR_PRICES ? theme.spacing(2) : undefined,
            },
            '& .MuiDataGrid-topContainer': {
              paddingLeft:
                selectedTab === AssortmentTab.COMPETITOR_PRICES ? `0 !important` : undefined,
              marginLeft: (theme) =>
                selectedTab === AssortmentTab.COMPETITOR_PRICES ? theme.spacing(2) : undefined,
              paddingRight:
                selectedTab === AssortmentTab.COMPETITOR_PRICES ? `0 !important` : undefined,
              marginRight: (theme) =>
                selectedTab === AssortmentTab.COMPETITOR_PRICES ? theme.spacing(2) : undefined,
            },
          }}
        />
      </Box>
    </Box>
  );
};
