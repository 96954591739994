import { Supplier } from '@retail/suppliers/types';
import {
  Autocomplete,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  InputBase,
  Popper,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { ChevronLeft } from '@mui/icons-material';
import { useCalculusTFunction } from '@retail/calculus/i18n';

interface Props {
  suppliers: Supplier[];
  selectedSupplier?: Supplier;
  selectedOrgUnitName?: string;
  onSelectSupplier: (value: number) => void;
  onSelectAssortmentLevel: () => void;
}

export function AssortmentOrSupplierSelect({
  suppliers,
  selectedSupplier,
  selectedOrgUnitName,
  onSelectSupplier,
  onSelectAssortmentLevel,
}: Props) {
  const t = useCalculusTFunction();
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSelectSupplier = (supplierId?: number) => {
    if (supplierId) {
      onSelectSupplier(supplierId);
    } else {
      onSelectAssortmentLevel();
    }

    setAnchorEl(null);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        component="button"
        onClick={handleClick}
        sx={{
          borderTopLeftRadius: ({ shape }) => shape.borderRadius * 2,
          borderTopRightRadius: ({ shape }) => shape.borderRadius * 2,
        }}
        bgcolor={`${palette.grey['600']} !important`}
        border={`2px solid ${palette.grey['600']}`}
        alignItems="center"
        pr={2}
        justifyContent="space-evenly"
        color={palette.common.white}
        width={498}
        height={45}
        display="flex"
        flexDirection="row"
      >
        <Stack width={478}>
          <Typography variant="body1">{selectedSupplier?.name ?? selectedOrgUnitName}</Typography>
        </Stack>
        <ChevronLeft
          sx={{
            transform: open ? 'rotate(90deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s ease-in-out',
          }}
        />
      </Box>
      <StyledPopper open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            {selectedOrgUnitName && (
              <>
                <Stack
                  m={2}
                  mb={1}
                  onClick={() => handleSelectSupplier(undefined)}
                  component={Button}
                  width="fill-available"
                  alignItems="flex-start"
                  borderRadius={3}
                  zIndex={1}
                  border={`1px solid ${palette.common.white}`}
                  sx={{
                    '&:hover': {
                      backgroundColor: palette.primary.A100,
                      border: `1px solid ${palette.primary.A100}`,
                    },
                  }}
                >
                  <Typography color={palette.common.black} variant="body2">
                    {selectedOrgUnitName}
                  </Typography>
                </Stack>
                <Divider sx={{ my: 1 }} />
              </>
            )}

            <Autocomplete
              open
              forcePopupIcon={false}
              size="small"
              disablePortal={false}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.mgSupplierId === value.mgSupplierId}
              onChange={(_, value) =>
                value?.mgSupplierId && handleSelectSupplier(value.mgSupplierId)
              }
              options={suppliers}
              getOptionLabel={({ name }) => name}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={t('calculus.searchForSuppliers')}
                />
              )}
              PopperComponent={PopperComponent}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: theme.shadows[1],
  zIndex: theme.zIndex.modal,
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius * 2,
  width: 500,
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: 0,
  width: '100%',
  border: 0,
  '& input': {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(() => ({
  [`& .MuiPaper-root`]: {
    border: 0,
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}
