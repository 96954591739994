import { GridColDef } from '@mui/x-data-grid-premium';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { useMemo } from 'react';
import { CopyToClipboard, StackedTextCell, TopLineTypography } from '@retail/components';
import { FilterCellAction } from '../FilterCellAction';
import { supplierSearchParamKey } from '@retail/products/utils';
import { AssortmentItem } from '@retail/my-assortment/types';

interface Props {
  enableSortable?: boolean;
  selectSupplier?: (supplierId: number) => void;
  selectProductGroup?: (productGroupNumber: string) => void;
  selectDiscountGroup?: (
    discountGroupId: number,
    addContiguousSearchParamKey?: { searchParam: string; value: string }
  ) => void;
}

const baseColumnProps: Partial<GridColDef> = {
  width: 130,
  headerName: '',
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

export const useItemInfoColumns = ({
  enableSortable,
  selectSupplier,
  selectProductGroup,
  selectDiscountGroup,
}: Props) => {
  const { t } = useMyAssortmentTranslation();

  return useMemo(() => {
    const itemIdsColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      field: 'mgItemFinfoNumber',
      headerName: t('myAssortment.columns.mgItemFinfoNumber'),

      renderCell: ({ row }) => (
        <StackedTextCell
          topLine={
            <CopyToClipboard value={row.mgItemNumber} hasIconButtonPadding={false}>
              {row.mgItemNumber}
            </CopyToClipboard>
          }
          bottomLine={row.finfoNumber}
        />
      ),
    };
    const itemNameColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      field: 'itemText',
      headerName: t('myAssortment.columns.itemText'),

      width: 250,
      renderCell: ({ row }) => (
        <StackedTextCell
          nullableBottomLine
          topLine={row.primaryText}
          bottomLine={row.secondaryText}
        />
      ),
    };
    const supplierColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      width: 200,
      field: 'supplier',

      headerName: t('myAssortment.columns.supplier'),
      renderCell: ({ row }) => (
        <StackedTextCell
          topLine={
            <TopLineTypography variant="subtitle2" isInline>
              {row.mgSupplierName}
              {selectSupplier && (
                <FilterCellAction
                  onFilterClick={() => selectSupplier(row.mgSupplierId)}
                  filterTitle={t('myAssortment.actions.selectSupplierAsQuickFilter')}
                />
              )}
            </TopLineTypography>
          }
          bottomLine={row.mgSupplierId}
        />
      ),
    };
    const itemGroupColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      width: 200,
      field: 'itemGroup',
      headerName: t('myAssortment.columns.itemGroup'),
      sortable: enableSortable,
      renderCell: ({ row }) => (
        <StackedTextCell
          topLine={
            <TopLineTypography variant="subtitle2" isInline>
              {row.groupNumber}

              {row.groupNumber && selectProductGroup && (
                <FilterCellAction
                  onFilterClick={() => selectProductGroup(row.groupNumber)}
                  filterTitle={t('myAssortment.actions.selectProductGroupAsQuickFilter')}
                />
              )}
            </TopLineTypography>
          }
          bottomLine={row.groupDescription}
        />
      ),
      valueGetter: (_, row) => row.groupNumber,
    };
    const discountGroupColumn: GridColDef<AssortmentItem> = {
      ...baseColumnProps,
      width: 200,
      field: 'discountGroup',
      headerName: t('myAssortment.columns.discountGroup'),
      sortable: enableSortable,
      renderCell: ({ row }) => (
        <StackedTextCell
          topLine={
            <TopLineTypography variant="subtitle2" isInline>
              {row.discountGroup?.name}
              {row.discountGroup?.code && selectDiscountGroup && (
                <FilterCellAction
                  onFilterClick={() => {
                    selectDiscountGroup(row.discountGroup?.id ?? null, {
                      searchParam: supplierSearchParamKey,
                      value: `${row.mgSupplierId}`,
                    });
                  }}
                  filterTitle={t('myAssortment.actions.selectDiscountGroupAsQuickFilter')}
                />
              )}
            </TopLineTypography>
          }
          bottomLine={row.discountGroup?.code}
        />
      ),
      valueGetter: (_, row) => row.discountGroup?.code,
    };
    return { itemIdsColumn, itemNameColumn, supplierColumn, itemGroupColumn, discountGroupColumn };
  }, [selectDiscountGroup, selectProductGroup, selectSupplier, enableSortable, t]);
};
