import {
  BodyLoader,
  draftActionsRowPortalId,
  SuspenseWithSentryErrorBoundary,
} from '@retail/components';
import { useDisclosure } from '@shared/hooks';
import { OutlinedButton, UnderlinedTabs } from '@shared/components';
import { DrawerIcon } from '@shared/custom-icons';
import { Box, Paper, Stack, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useAssortmentDraftDrawer } from '@retail/my-assortment/context';
import { GlobalBottomDrawer } from './GlobalBottomDrawer';
import { DraftDrawerTabs, useCurrentDraftDrawerTab } from './globalDrawerState';
import { useAppTFunction } from '@retail/app/i18n';
import { useSearchParams } from 'react-router-dom';
import { DraftContainer } from '@retail/calculus/containers';
import { CalculusDraftTabOptions } from '@retail/calculus/types';
import { DraftAssortmentItemsContainer } from '@retail/my-assortment/containers';
import { useFetchCalculusConditions } from '@retail/calculus/context';

const calcTabToDraftTabMap: Record<CalculusDraftTabOptions, DraftDrawerTabs> = {
  ITEMS: DraftDrawerTabs.ASSORTMENT,
  CONDITIONS: DraftDrawerTabs.CONDITIONS,
};

// Summary drawer uses member draft, table uses department draft
export function DraftDrawer() {
  const t = useAppTFunction();
  const { transitions } = useTheme();

  const [showContent, setShowContent] = useState(false);

  const [searchParams] = useSearchParams();
  const calculusTab = searchParams.get('calculusTab');

  const [selectedTab, setSelectedTab] = useCurrentDraftDrawerTab();
  const { isOpen, onToggle } = useDisclosure(false);

  const {
    totalItems,
    draftAssortmentItems,
    timeoutLoading: assortmentTimeoutLoading,
    activateIsLoading: activateAssortmentIsLoading,
  } = useAssortmentDraftDrawer(isOpen);

  const { conditionsLength, draftConditions, draftConditionsIsLoading } =
    useFetchCalculusConditions(isOpen);

    useEffect(() => {
      conditionsLength === 0 && draftAssortmentItems?.length === 0 && onToggle();
    }, [conditionsLength, draftAssortmentItems?.length, onToggle]);


  const showDraftDrawer =
    (draftAssortmentItems && draftAssortmentItems.length > 0) || !!conditionsLength;

  const tabs = useMemo(
    () => [
      {
        label: t('calculus.tabs.conditions', { count: conditionsLength }),
        value: DraftDrawerTabs.CONDITIONS,
        isHighlighted: !!draftConditions,
      },
      {
        label: t('calculus.tabs.myAssortment', { count: totalItems }),
        value: DraftDrawerTabs.ASSORTMENT,
        isHighlighted: Boolean(totalItems && totalItems > 0),
      },
    ],
    [conditionsLength, draftConditions, t, totalItems]
  );

  useEffect(() => {
    if (
      !!calculusTab &&
      calculusTab !== selectedTab &&
      Object.keys(CalculusDraftTabOptions).includes(calculusTab)
    ) {
      setSelectedTab(calcTabToDraftTabMap[calculusTab as CalculusDraftTabOptions]);
    } else {
      setSelectedTab(DraftDrawerTabs.CONDITIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculusTab]);

  useEffect(() => {
    let timeoutId: number | undefined;
    if (isOpen) {
      timeoutId = window.setTimeout(
        () => setShowContent(true),
        transitions.duration.leavingScreen + 200
      );
    } else {
      setShowContent(false);
    }
    return () => {
      timeoutId && window.clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    showDraftDrawer && (
      <GlobalBottomDrawer
        isOpen={
          isOpen ||
          draftConditionsIsLoading ||
          assortmentTimeoutLoading ||
          activateAssortmentIsLoading
        }
        collapsedHeight={34}
      >
        <OutlinedButton
          sx={{ height: 28, alignSelf: 'center' }}
          size="small"
          onClick={onToggle}
          startIcon={
            <DrawerIcon
              orientation={isOpen ? 'down' : 'up'}
              sx={{ width: '16px', height: '16px' }}
            />
          }
        >
          {t('calculus.hasCalculusUpdates')}
        </OutlinedButton>

        {isOpen && (
          <Stack flexGrow={1} overflow="hidden">
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                mx: 2,
                overflow: 'auto',
              }}
            >
              <Box mx={3} my={2}>
                <UnderlinedTabs
                  id="draftDrawerTabs"
                  tabs={tabs}
                  currentTab={selectedTab}
                  onChange={(_, value) => setSelectedTab(value)}
                />
              </Box>

              {draftConditionsIsLoading ||
              assortmentTimeoutLoading ||
              activateAssortmentIsLoading ||
              !showContent ? (
                <BodyLoader />
              ) : (
                <Stack flexGrow={1} bgcolor={({ palette }) => palette.grey[50]}>
                  {selectedTab === DraftDrawerTabs.ASSORTMENT && (
                    <SuspenseWithSentryErrorBoundary>
                      <DraftAssortmentItemsContainer />
                    </SuspenseWithSentryErrorBoundary>
                  )}

                  {selectedTab === DraftDrawerTabs.CONDITIONS && (
                    <SuspenseWithSentryErrorBoundary>
                      <DraftContainer />
                    </SuspenseWithSentryErrorBoundary>
                  )}
                </Stack>
              )}
            </Paper>

            <Stack
              m={2}
              spacing={2}
              justifyContent="flex-end"
              id={draftActionsRowPortalId}
              direction="row"
            />
          </Stack>
        )}
      </GlobalBottomDrawer>
    )
  );
}
