import { FeatureContext, FeatureFlag, MgPrisFeature } from '@retail/mgpris/config-types';
import React, { ReactNode, useMemo } from 'react';
import { isFeatureEnabled } from '@retail/utils';

interface Props {
  features: FeatureFlag[];
  name: MgPrisFeature;
  context: FeatureContext;
  renderEnabled: () => ReactNode;
  renderDisabled?: () => ReactNode;
}

const DefaultDisabledComponent = () => null;

export const Feature: React.FC<Props> = ({
  features,
  name,
  context,
  renderEnabled,
  renderDisabled = DefaultDisabledComponent,
}) => {
  const isEnabled = useMemo(() => {
    return isFeatureEnabled({ features, name, context });
  }, [context, features, name]);

  if (isEnabled) {
    return renderEnabled();
  }
  return renderDisabled();
};
