import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { ExpandCircleDown } from '@mui/icons-material';
import { AssortmentPriorityProps, ItemsDTO } from '@retail/my-assortment/types';
import { useRecoilValue } from 'recoil';
import { ErrorChip } from './ErrorChip';
import { assortmentSpreadsheetValidationAtom, isValidItem } from '@retail/my-assortment/utils';
import { useMemo } from 'react';

interface Props {
  importedItems: ItemsDTO[];
}

export const ImportStateValidation = ({ importedItems }: Props) => {
  const { t } = useMyAssortmentTranslation();

  const validationState = useRecoilValue(assortmentSpreadsheetValidationAtom);

  const nrOfValidRows = useMemo(() => {
    const allInvalid = importedItems.filter((item) => isValidItem(item, validationState));
    return allInvalid.length;
  }, [importedItems, validationState]);

  return (
    <Stack gap={1}>
      {!!nrOfValidRows && (
        <Alert
          severity="success"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography>
            {t('myAssortment.uploadAssortment.itemsRead', {
              nr: nrOfValidRows,
            })}
          </Typography>
        </Alert>
      )}

      {nrOfValidRows < importedItems.length && (
        <Alert
          severity="error"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Stack gap={1}>
            <Typography>
              {t('myAssortment.uploadAssortment.invalidItems', {
                nr: importedItems.length - nrOfValidRows,
              })}
            </Typography>
            <Typography>{t('myAssortment.uploadAssortment.error.file')}</Typography>
            <Typography>{t('myAssortment.uploadAssortment.error.columns')}</Typography>

            {!!validationState?.invalidDates?.length && (
              <StyledAccordion>
                <AccordionSummary expandIcon={<ExpandCircleDown />} id="date">
                  {t('myAssortment.uploadAssortment.error.invalidDates', {
                    count: validationState.invalidDates.length,
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack>
                    <Typography variant="caption" sx={{ mb: 1 }}>
                      {t('myAssortment.uploadAssortment.error.invalidDatesInfo')}{' '}
                      {t('myAssortment.uploadAssortment.error.invalidGeneralInfo')}
                    </Typography>

                    <Box>
                      {validationState?.invalidDates.map(
                        (error: AssortmentPriorityProps, index) => (
                          <ErrorChip
                            key={`${error.validFrom}-${error.mgSupplierId}-${error.mgItemNumber}-${index}`}
                            error={error}
                          />
                        )
                      )}
                    </Box>
                  </Stack>
                </AccordionDetails>
              </StyledAccordion>
            )}

            {validationState?.invalidItemNumbers &&
              validationState.invalidItemNumbers.length > 0 && (
                <StyledAccordion>
                  <AccordionSummary expandIcon={<ExpandCircleDown />} id="itemNumber">
                    {t('myAssortment.uploadAssortment.error.invalidItemNumbers', {
                      count: validationState.invalidItemNumbers.length,
                    })}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack>
                      <Typography variant="caption" sx={{ mb: 1 }}>
                        {t('myAssortment.uploadAssortment.error.invalidItemNumbersInfo')}{' '}
                        {t('myAssortment.uploadAssortment.error.invalidGeneralInfo')}
                      </Typography>

                      <Box>
                        {validationState?.invalidItemNumbers.map(
                          (error: AssortmentPriorityProps, index) => (
                            <ErrorChip
                              key={`${error.mgItemNumber}-${error.mgSupplierId}-${error.validFrom}-${index}`}
                              error={error}
                            />
                          )
                        )}
                      </Box>
                    </Stack>
                  </AccordionDetails>
                </StyledAccordion>
              )}

            {validationState?.invalidPriorities && validationState.invalidPriorities.length > 0 && (
              <StyledAccordion>
                <AccordionSummary expandIcon={<ExpandCircleDown />} id="priorities">
                  {t('myAssortment.uploadAssortment.error.invalidPriorities', {
                    count: validationState.invalidPriorities.length,
                  })}
                </AccordionSummary>
                <AccordionDetails>
                  <Stack>
                    <Typography variant="caption" sx={{ mb: 1 }}>
                      {t('myAssortment.uploadAssortment.error.invalidPrioritiesInfo')}{' '}
                      {t('myAssortment.uploadAssortment.error.invalidGeneralInfo')}
                    </Typography>

                    <Box>
                      {validationState?.invalidPriorities.map(
                        (error: AssortmentPriorityProps, index) => (
                          <ErrorChip
                            key={`${error.mgSupplierId}-${error.mgItemNumber}-${index}-${error.validFrom}`}
                            error={error}
                          />
                        )
                      )}
                    </Box>
                  </Stack>
                </AccordionDetails>
              </StyledAccordion>
            )}

            {validationState?.invalidSupplierIds &&
              validationState?.invalidSupplierIds.length > 0 && (
                <StyledAccordion>
                  <AccordionSummary expandIcon={<ExpandCircleDown />} id="supplierIds">
                    {t('myAssortment.uploadAssortment.error.invalidSupplierIds', {
                      count: validationState.invalidSupplierIds.length,
                    })}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack>
                      <Typography variant="caption" sx={{ mb: 1 }}>
                        {t('myAssortment.uploadAssortment.error.invalidSupplierIdsInfo')}{' '}
                        {t('myAssortment.uploadAssortment.error.invalidGeneralInfo')}
                      </Typography>

                      <Box>
                        {validationState?.invalidSupplierIds.map(
                          (error: AssortmentPriorityProps, index) => (
                            <ErrorChip
                              key={`${error.mgSupplierId}-${error.mgItemNumber}-${error.validFrom}-${index}`}
                              error={error}
                            />
                          )
                        )}
                      </Box>
                    </Stack>
                  </AccordionDetails>
                </StyledAccordion>
              )}
          </Stack>
        </Alert>
      )}
    </Stack>
  );
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'inherit',
  border: `1px solid ${theme.palette.error.main}`,
}));
