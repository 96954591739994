import { Box } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useAppTFunction } from '@retail/app/i18n';
import { LevelChip } from '@retail/calculus/components';
import { AssortmentType, HierarchyLevels, PriceSuggestionCondition } from '@retail/calculus/types';
import {
  AddButton,
  CustomColumnMenu,
  NoTableRowsOverlay,
  StackedTextCell,
} from '@retail/components';
import { getPriceSuggestionType } from '@retail/products/utils';
import {
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { InfinityIcon } from '@shared/custom-icons';
import { getTogglableDataGridColumns } from '@shared/utils';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { PriceSuggestionActions } from './PriceSuggestionActions';

interface Props {
  priceSuggestionConditions: PriceSuggestionCondition[];
  language: string;
  noPriceSuggestionTypes: boolean;
  editCondition: (condition: PriceSuggestionCondition) => void;
  deactivateCondition: (condition: PriceSuggestionCondition) => void;
  deleteCondition: (conditionId?: number) => void;
  openCreateDialog: () => void;
}

const baseColumnProps: Partial<GridColDef> = {
  width: 140,
  sortable: false,
  headerAlign: 'left',
  align: 'left',
};

export function PriceSuggestionConditionsTable({
  priceSuggestionConditions,
  language,
  noPriceSuggestionTypes,
  editCondition,
  deactivateCondition,
  deleteCondition,
  openCreateDialog,
}: Props) {
  const t = useAppTFunction();
  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'level',
        headerName: t('purchaseAgreements.agreement.columns.type'),
        width: 190,
        renderCell: ({ row }) => {
          return (
            <LevelChip
              bindingType={row.bindingType ?? (row.current ? HierarchyLevels.supplier : undefined)}
              active={row.assortmentType === AssortmentType.BASE}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'name',
        headerName: t('priceSuggestion.levelOrItemName'),
        width: 260,
        renderCell: ({ row }) => {
          const name = row.getLevelName();
          const id = row.getLevelId();
          return <StackedTextCell topLine={name} bottomLine={id} />;
        },
      },
      {
        ...baseColumnProps,
        field: 'priceSuggestionPriority',
        headerName: t('priceSuggestion.priceType'),
        renderCell: ({ row }) => getPriceSuggestionType(t, row.priceSuggestionPriority?.[0]),
      },
      {
        ...baseColumnProps,
        field: 'validFrom',
        headerName: t('purchaseAgreements.agreement.columns.activeFrom'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : '-'),
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        headerName: t('purchaseAgreements.agreement.columns.activeTo'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : <InfinityIcon />),
      },
      {
        ...baseColumnProps,
        field: 'actions',
        width: 90,
        headerName: '',
        renderCell: ({ row }) => (
          <PriceSuggestionActions
            editCondition={() => editCondition(row)}
            deactivateCondition={
              row.assortmentType !== AssortmentType.DRAFT
                ? () => deactivateCondition(row)
                : undefined
            }
            deleteCondition={
              row.assortmentType === AssortmentType.DRAFT
                ? () => deleteCondition(row.id)
                : undefined
            }
          />
        ),
      },
    ],
    [deactivateCondition, deleteCondition, editCondition, t]
  );

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium
          columns={columns}
          rows={priceSuggestionConditions}
          getRowId={(row) => row.id}
          rowHeight={70}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnReorder
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  DATA_GRID_ID.CALCULUS_PURCHASE_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: { right: ['actions'] },
          }}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);
            saveHiddenDataGridColumnsToLocalStorage(
              DATA_GRID_ID.CALCULUS_PURCHASE_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          slots={{
            noRowsOverlay: () => (
              <NoTableRowsOverlay
                noRowsText={t('priceSuggestion.noActivePriceSuggestion')}
                addAction={
                  <AddButton onClick={openCreateDialog} disabled={noPriceSuggestionTypes}>
                    {t('priceSuggestion.followPriceSuggestion')}
                  </AddButton>
                }
              />
            ),
            columnMenu: CustomColumnMenu,
          }}
          slotProps={{
            ...columnsManagementSlotProps,
          }}
          hideFooter
        />
      </Box>
    </Box>
  );
}
