import React, { PropsWithChildren } from 'react';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { mgPrisRoutes, pdmRoutes } from '@retail/mgpris/config';

export const PdmUserRouteGuardOutlet: React.FC<PropsWithChildren> = () => {
  const { selectedBrandContext } = useSelectedOrgUnit();
  const location = useLocation();

  if (!selectedBrandContext) return <Navigate to={mgPrisRoutes.root.getFullLinkPath()} replace />;

  if (location.pathname === pdmRoutes.root.fullRoutePath)
    return <Navigate to={pdmRoutes.brandAssortment.getFullLinkPath()} replace />;

  return <Outlet />;
};
