import { useProductsTFunction } from '@retail/products/i18n';
import { MgItem, MgItemTabs } from '@retail/products/types';
import { useState } from 'react';
import { Paper, UnderlinedTabs } from '@shared/components';
import {
  Documentation,
  EnvironmentDocumentation,
  ProductInformation,
  SupplierItems,
} from '@retail/products/components';
import { JSONContainer } from './JSONContainer';
import { Stack } from '@mui/material';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { MgItemCompetitorPrices } from './MgItemCompetitorPrices';

interface Props {
  mgItem: MgItem;
}

export function MgItemTabContainer({ mgItem }: Props) {
  const t = useProductsTFunction();

  const [currentTab, setCurrentTab] = useState<MgItemTabs>(MgItemTabs.PRODUCT_INFORMATION);

  const selectTab = (_: any, newTab: MgItemTabs) => setCurrentTab(newTab);

  const tabs = [
    { label: t('products.mgItem.details.title'), value: MgItemTabs.PRODUCT_INFORMATION },
    { label: t('products.mgItem.supplierItem.packages'), value: MgItemTabs.PACKAGING },
    {
      label: t('products.prices.competitors.title'),
      value: MgItemTabs.COMPETITOR_PRICES,
    },
    { label: t('products.mgItem.documentation.title'), value: MgItemTabs.DOCUMENTATION },
    {
      label: t('products.mgItem.environmentDocumentation.title'),
      value: MgItemTabs.ENVIRONMENTAL_DOCUMENTATION,
    },

    {
      label: t('products.mgItem.json.title'),
      value: MgItemTabs.JSON,
    },
  ];

  const getTabContent = () => {
    if (!mgItem.getPrimarySupplierItem()) {
      return <Paper paddingY="dense">{t('products.mgItem.json.noSuppliersInfo')}</Paper>;
    }

    switch (currentTab) {
      case MgItemTabs.PRODUCT_INFORMATION:
        return <ProductInformation mgItem={mgItem} />;
      case MgItemTabs.PACKAGING:
        return <SupplierItems mgItem={mgItem} />;
      case MgItemTabs.COMPETITOR_PRICES:
        return <MgItemCompetitorPrices mgItem={mgItem} />;
      case MgItemTabs.DOCUMENTATION:
        return <Documentation mgItem={mgItem} />;
      case MgItemTabs.ENVIRONMENTAL_DOCUMENTATION:
        return <EnvironmentDocumentation mgItem={mgItem} />;
      case MgItemTabs.JSON:
        return (
          <SuspenseWithSentryErrorBoundary>
            <JSONContainer mgItem={mgItem} />
          </SuspenseWithSentryErrorBoundary>
        );
      default:
        return null;
    }
  };

  return (
    <Stack gap={4} width="100%">
      <UnderlinedTabs
        id="item"
        tabs={tabs}
        currentTab={currentTab}
        variant="scrollable"
        onChange={selectTab}
      />
      {getTabContent()}
    </Stack>
  );
}
