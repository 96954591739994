import React, { PropsWithChildren } from 'react';
import { Box, Portal } from '@mui/material';
import Draggable from 'react-draggable';
import type { DraggableProps } from 'react-draggable'

export const GlobalDraggable: React.FC<PropsWithChildren<Partial<DraggableProps>>> = ({
  children,
  bounds = 'parent',
  ...draggableProps
}) => {
  return (
    <Portal container={document.getElementById('root')}>
      <Draggable bounds={bounds} {...draggableProps}>
        <Box position="fixed" zIndex={({ zIndex }) => zIndex.modal - 1}>
          {children}
        </Box>
      </Draggable>
    </Portal>
  );
};
