import { create } from 'zustand/react';
import { BracketPriceState, Markup } from './BracketPriceState';
import {
  calculateMarkupFromCoverage,
  calculateMarkupFromDiscount,
  calculateMarkupFromSalesPrice,
} from './packageCalculations';

const initState: Pick<BracketPriceState, 'selectedPackageType' | 'newMarkups' | 'editingFields'> = {
  selectedPackageType: 'PRIMARY',
  newMarkups: undefined,
  editingFields: {
    'F-PAK': undefined,
    'D-PAK': undefined,
    'T-PAK': undefined,
  },
};

export const useBracketPriceStore = create<BracketPriceState>((setState, getState) => ({
  ...initState,

  changeSelectedPackage: (selectedPackageType) => setState({ selectedPackageType }),

  getSalesPriceEditState: (priceCalculation) => {
    const currentCostPriceInFpakUnit = priceCalculation.getCurrentCostPriceForClassCode('F-PAK');

    return currentCostPriceInFpakUnit ? 'editable' : 'noCostPrice';
  },

  setNewSalesPrice: (newSalesPrice, packageClassCode, priceCalculation, includeVat, packages) => {
    const currentState = getState();

    switch (packageClassCode) {
      case 'F-PAK': {
        const fPakPackageMarkup: Markup = {
          ...calculateMarkupFromSalesPrice(
            newSalesPrice,
            packageClassCode,
            priceCalculation,
            includeVat,
            packages
          ),
          editedField: 'price',
        };
        const dPakPackageMarkup =
          packages.find((p) => p.classCode === 'D-PAK') &&
          calculateMarkupFromSalesPrice(
            newSalesPrice,
            'D-PAK',
            priceCalculation,
            includeVat,
            packages,
            fPakPackageMarkup.salesPrice
          );
        const tPakPackageMarkup =
          packages.find((p) => p.classCode === 'T-PAK') &&
          calculateMarkupFromSalesPrice(
            newSalesPrice,
            'T-PAK',
            priceCalculation,
            includeVat,
            packages,
            fPakPackageMarkup.salesPrice
          );
        setState({
          newMarkups: {
            'F-PAK': fPakPackageMarkup,
            'D-PAK': dPakPackageMarkup,
            'T-PAK': tPakPackageMarkup,
          },
          editingFields: {
            ...currentState.editingFields,
            'F-PAK': undefined,
          },
        });
        return;
      }
      case 'D-PAK': {
        const newFPakPrice = currentState.newMarkups?.['F-PAK']?.salesPrice;
        const dPakPackageMarkup: Markup = {
          ...calculateMarkupFromSalesPrice(
            newSalesPrice,
            packageClassCode,
            priceCalculation,
            includeVat,
            packages,
            newFPakPrice
          ),
          editedField: 'price',
        };
        const tPakPackageMarkup =
          packages.find((p) => p.classCode === 'T-PAK') &&
          calculateMarkupFromSalesPrice(
            newSalesPrice,
            'T-PAK',
            priceCalculation,
            includeVat,
            packages,
            newFPakPrice
          );
        setState({
          newMarkups: {
            'F-PAK': currentState.newMarkups?.['F-PAK'],
            'D-PAK': dPakPackageMarkup,
            'T-PAK': tPakPackageMarkup,
          },
          editingFields: {
            ...currentState.editingFields,
            'D-PAK': undefined,
          },
        });
        return;
      }
      case 'T-PAK': {
        const newFPakPrice = currentState.newMarkups?.['F-PAK']?.salesPrice;
        const tPakPackageMarkup: Markup = {
          ...calculateMarkupFromSalesPrice(
            newSalesPrice,
            packageClassCode,
            priceCalculation,
            includeVat,
            packages,
            newFPakPrice
          ),
          editedField: 'price',
        };
        setState({
          newMarkups: {
            ...currentState.newMarkups,
            'T-PAK': tPakPackageMarkup,
          },
          editingFields: {
            ...currentState.editingFields,
            'T-PAK': undefined,
          },
        });
        return;
      }
    }
  },

  setNewSalesCoverage: (newSalesCoverage, packageClassCode, priceCalculation, packages) => {
    const currentState = getState();
    switch (packageClassCode) {
      case 'F-PAK': {
        const fPakPackageMarkup: Markup = {
          ...calculateMarkupFromCoverage(
            newSalesCoverage,
            packageClassCode,
            priceCalculation,
            packages
          ),
          editedField: 'coverage',
        };
        const dPakPackageMarkup =
          packages.find((p) => p.classCode === 'D-PAK') &&
          calculateMarkupFromCoverage(
            newSalesCoverage,
            'D-PAK',
            priceCalculation,
            packages,
            fPakPackageMarkup.salesPrice
          );
        const tPakPackageMarkup =
          packages.find((p) => p.classCode === 'T-PAK') &&
          calculateMarkupFromCoverage(
            newSalesCoverage,
            'T-PAK',
            priceCalculation,
            packages,
            fPakPackageMarkup.salesPrice
          );
        setState({
          newMarkups: {
            'F-PAK': fPakPackageMarkup,
            'D-PAK': dPakPackageMarkup,
            'T-PAK': tPakPackageMarkup,
          },
          editingFields: {
            ...currentState.editingFields,
            'F-PAK': undefined,
          },
        });
        return;
      }
      case 'D-PAK': {
        const newFPakPrice = currentState.newMarkups?.['F-PAK']?.salesPrice;
        const dPakPackageMarkup: Markup = {
          ...calculateMarkupFromCoverage(
            newSalesCoverage,
            packageClassCode,
            priceCalculation,
            packages,
            newFPakPrice
          ),
          editedField: 'coverage',
        };
        const tPakPackageMarkup =
          packages.find((p) => p.classCode === 'T-PAK') &&
          calculateMarkupFromCoverage(
            newSalesCoverage,
            'T-PAK',
            priceCalculation,
            packages,
            newFPakPrice
          );
        setState({
          newMarkups: {
            'F-PAK': currentState.newMarkups?.['F-PAK'],
            'D-PAK': dPakPackageMarkup,
            'T-PAK': tPakPackageMarkup,
          },
          editingFields: {
            ...currentState.editingFields,
            'D-PAK': undefined,
          },
        });
        return;
      }
      case 'T-PAK': {
        const newFPakPrice = currentState.newMarkups?.['F-PAK']?.salesPrice;
        const tPakPackageMarkup: Markup = {
          ...calculateMarkupFromCoverage(
            newSalesCoverage,
            packageClassCode,
            priceCalculation,
            packages,
            newFPakPrice
          ),
          editedField: 'coverage',
        };
        setState({
          newMarkups: {
            ...currentState.newMarkups,
            'T-PAK': tPakPackageMarkup,
          },
          editingFields: {
            ...currentState.editingFields,
            'T-PAK': undefined,
          },
        });
        return;
      }
    }
  },

  setNewDiscount: (newDiscount, packageClassCode, priceCalculation, packages) => {
    const currentState = getState();
    switch (packageClassCode) {
      case 'F-PAK': {
        throw new Error('Cannot set discount for F-PAK');
      }
      case 'D-PAK': {
        const newFPakPrice = currentState.newMarkups?.['F-PAK']?.salesPrice;
        const dPakPackageMarkup: Markup = {
          ...calculateMarkupFromDiscount(
            newDiscount,
            packageClassCode,
            priceCalculation,
            packages,
            newFPakPrice
          ),
          editedField: 'discount',
        };
        const tPakPackageMarkup =
          packages.find((p) => p.classCode === 'T-PAK') &&
          calculateMarkupFromDiscount(newDiscount, 'T-PAK', priceCalculation, packages);
        setState({
          newMarkups: {
            'F-PAK': currentState.newMarkups?.['F-PAK'],
            'D-PAK': dPakPackageMarkup,
            'T-PAK': tPakPackageMarkup,
          },
          editingFields: {
            ...currentState.editingFields,
            'D-PAK': undefined,
          },
        });
        return;
      }
      case 'T-PAK': {
        const newFPakPrice = currentState.newMarkups?.['F-PAK']?.salesPrice;
        const tPakPackageMarkup: Markup = {
          ...calculateMarkupFromDiscount(
            newDiscount,
            packageClassCode,
            priceCalculation,
            packages,
            newFPakPrice
          ),
          editedField: 'discount',
        };
        setState({
          newMarkups: {
            ...currentState.newMarkups,
            'T-PAK': tPakPackageMarkup,
          },
          editingFields: {
            ...currentState.editingFields,
            'T-PAK': undefined,
          },
        });
        return;
      }
    }
  },

  setEditingField: (field, packageClassCode) =>
    setState({
      editingFields: {
        ...getState().editingFields,
        [packageClassCode]: field,
      },
    }),

  resetMarkup: () =>
    setState({
      newMarkups: undefined,
      editingFields: {
        'F-PAK': undefined,
        'D-PAK': undefined,
        'T-PAK': undefined,
      },
    }),

  resetState: () => setState(initState),
}));
