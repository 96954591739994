import { Stack } from '@mui/material';
import { CalculusInfoRow } from './CalculusInfoRow';
import { usePriceSuggestionTFunction } from '@retail/price-suggestion/i18n';
import { PriceSuggestionType } from '@retail/price-suggestion/types';
import { getPriceSuggestionType } from '@retail/products/utils';

interface Props {
  selectedPriceSuggestion?: PriceSuggestionType;
  noAvailablePriceSuggestions: boolean;
  openPriceSuggestionChangeModal: () => void;
}

export function PriceSuggestion({
  selectedPriceSuggestion,
  noAvailablePriceSuggestions,
  openPriceSuggestionChangeModal,
}: Props) {
  const t = usePriceSuggestionTFunction();
  return (
    <Stack flexDirection="row" alignItems="center" py={1.5} px={2}>
      <CalculusInfoRow
        label={t('priceSuggestion.recommendedPrice')}
        chipLabel={selectedPriceSuggestion && getPriceSuggestionType(t, selectedPriceSuggestion)}
        onEdit={openPriceSuggestionChangeModal}
        editDisabled={noAvailablePriceSuggestions}
      />

      <Stack width={({ spacing }) => spacing(3)} />
    </Stack>
  );
}
