import { useMemo } from 'react';
import { BracketPackagePrice } from './BracketPriceTable';
import { useBracketPriceStore } from './store';
import { MgSupplierItemPackage, PriceCalculation } from '@retail/products/types';

export const useBracketPackagePrices = (
  priceCalculation: PriceCalculation,
  packages: MgSupplierItemPackage[],
  includeVat: boolean
) => {
  const {
    newMarkups,
    setNewSalesPrice,
    setNewDiscount,
    setNewSalesCoverage,
    editingFields,
    setEditingField,
    getSalesPriceEditState,
  } = useBracketPriceStore((state) => state);

  return useMemo(() => {
    const prices: BracketPackagePrice[] = [];
    const fPakPrices = priceCalculation.getBracketPackagePrice('F-PAK');
    const dPakPrices = priceCalculation.getBracketPackagePrice('D-PAK');
    const tPakPrices = priceCalculation.getBracketPackagePrice('T-PAK');
    const editState = getSalesPriceEditState(priceCalculation);

    if (fPakPrices) {
      const fPakPriceInFpak = fPakPrices.bracketMarkupPrice.getPriceForClassCode('F-PAK');
      const newFPakMarkup = newMarkups?.['F-PAK'];

      prices.push({
        packageClassCode: 'F-PAK',
        id: 'fpak',
        salesPrice: {
          currentValue: includeVat
            ? fPakPriceInFpak?.price.priceWithVat
            : fPakPriceInFpak?.price.priceWithoutVat,
          onChange: (salesPrice) =>
            setNewSalesPrice(salesPrice, 'F-PAK', priceCalculation, includeVat, packages),
          newValue:
            newFPakMarkup?.salesPrice && newFPakMarkup.salesPriceIncVat
              ? {
                  value: includeVat ? newFPakMarkup.salesPriceIncVat : newFPakMarkup.salesPrice,
                  isApproximate: newMarkups?.['F-PAK']?.editedField !== 'price',
                }
              : undefined,
          editState,
        },
        salesPriceCoverage: {
          currentValue: fPakPrices.bracketMarkupPrice.markup?.markupPriceCoverage,
          newValue: newFPakMarkup?.salesCoverage
            ? {
                value: newFPakMarkup.salesCoverage,
                isApproximate: newMarkups?.['F-PAK']?.editedField !== 'coverage',
              }
            : undefined,
          onChange: (salesCoverage) =>
            setNewSalesCoverage(salesCoverage, 'F-PAK', priceCalculation, packages),
        },
        setEditingField: (field) => setEditingField(field, 'F-PAK'),
        editingField: editingFields?.['F-PAK'],
        unit: fPakPrices.bracketMarkupPrice.calculationPackage.unit.unit,
        consistsOfCount: fPakPrices.bracketMarkupPrice.calculationPackage.calculatedCount,
      });
    }

    if (dPakPrices) {
      const dPakPriceInFpak = dPakPrices.bracketMarkupPrice.getPriceForClassCode('F-PAK');
      const fPakPriceInFpak = fPakPrices?.bracketMarkupPrice.getPriceForClassCode('F-PAK');
      const currentDpakSalesPriceInFpak = includeVat
        ? dPakPriceInFpak?.price.priceWithVat
        : dPakPriceInFpak?.price.priceWithoutVat;
      const currentFpakSalesPriceInFpak = includeVat
        ? fPakPriceInFpak?.price.priceWithVat
        : fPakPriceInFpak?.price.priceWithoutVat;
      const currentDiscount =
        currentFpakSalesPriceInFpak && currentDpakSalesPriceInFpak
          ? ((currentFpakSalesPriceInFpak - currentDpakSalesPriceInFpak) /
              currentFpakSalesPriceInFpak) *
            100
          : 0;
      const newDPakMarkup = newMarkups?.['D-PAK'];

      prices.push({
        packageClassCode: 'D-PAK',
        id: 'dpak',
        salesPrice: {
          currentValue: currentDpakSalesPriceInFpak,
          newValue:
            newDPakMarkup?.salesPrice && newDPakMarkup.salesPriceIncVat
              ? {
                  value: includeVat ? newDPakMarkup.salesPriceIncVat : newDPakMarkup.salesPrice,
                  isApproximate: newMarkups?.['D-PAK']?.editedField !== 'price',
                }
              : undefined,
          onChange: (salesPrice) =>
            setNewSalesPrice(salesPrice, 'D-PAK', priceCalculation, includeVat, packages),
          editState,
        },
        discount: {
          currentValue: currentDiscount,
          newValue: newDPakMarkup?.discount
            ? {
                value: newDPakMarkup.discount,
                isApproximate: newMarkups?.['D-PAK']?.editedField !== 'discount',
              }
            : undefined,
          onChange: (discount) => setNewDiscount(discount, 'D-PAK', priceCalculation, packages),
          editState,
        },
        salesPriceCoverage: {
          currentValue: dPakPrices.bracketMarkupPrice.markup?.markupPriceCoverage,
          newValue: newDPakMarkup?.salesCoverage
            ? {
                value: newDPakMarkup.salesCoverage,
                isApproximate: newMarkups?.['D-PAK']?.editedField !== 'coverage',
              }
            : undefined,
          onChange: (salesCoverage) =>
            setNewSalesCoverage(salesCoverage, 'D-PAK', priceCalculation, packages),
        },
        setEditingField: (field) => setEditingField(field, 'D-PAK'),
        editingField: editingFields?.['D-PAK'],
        unit: dPakPrices.bracketMarkupPrice.calculationPackage.unit.unit,
        consistsOfCount: dPakPrices.bracketMarkupPrice.calculationPackage.calculatedCount,
      });
    }

    if (tPakPrices) {
      const tPakPriceInFpak = tPakPrices.bracketMarkupPrice.getPriceForClassCode('F-PAK');
      const fPakPriceInFpak = fPakPrices?.bracketMarkupPrice.getPriceForClassCode('F-PAK');
      const currentTpakSalesPriceInFpak = includeVat
        ? tPakPriceInFpak?.price.priceWithVat
        : tPakPriceInFpak?.price.priceWithoutVat;
      const currentFpakSalesPriceInFpak = includeVat
        ? fPakPriceInFpak?.price.priceWithVat
        : fPakPriceInFpak?.price.priceWithoutVat;
      const currentDiscount =
        currentFpakSalesPriceInFpak && currentTpakSalesPriceInFpak
          ? ((currentFpakSalesPriceInFpak - currentTpakSalesPriceInFpak) /
              currentFpakSalesPriceInFpak) *
            100
          : 0;
      const newTPakMarkup = newMarkups?.['T-PAK'];

      prices.push({
        packageClassCode: 'T-PAK',
        id: 'tpak',
        salesPrice: {
          currentValue: currentTpakSalesPriceInFpak,
          newValue:
            newTPakMarkup?.salesPrice && newTPakMarkup.salesPriceIncVat
              ? {
                  value: includeVat ? newTPakMarkup.salesPriceIncVat : newTPakMarkup.salesPrice,
                  isApproximate: newMarkups?.['T-PAK']?.editedField !== 'price',
                }
              : undefined,
          onChange: (salesPrice) =>
            setNewSalesPrice(salesPrice, 'T-PAK', priceCalculation, includeVat, packages),
          editState,
        },
        discount: {
          currentValue: currentDiscount,
          newValue: newTPakMarkup?.discount
            ? {
                value: newTPakMarkup.discount,
                isApproximate: newMarkups?.['T-PAK']?.editedField !== 'discount',
              }
            : undefined,
          onChange: (discount) => setNewDiscount(discount, 'T-PAK', priceCalculation, packages),
          editState,
        },
        salesPriceCoverage: {
          currentValue: tPakPrices.bracketMarkupPrice.markup?.markupPriceCoverage,
          newValue: newTPakMarkup?.salesCoverage
            ? {
                value: newTPakMarkup.salesCoverage,
                isApproximate: newMarkups?.['T-PAK']?.editedField !== 'coverage',
              }
            : undefined,
          onChange: (salesCoverage) =>
            setNewSalesCoverage(salesCoverage, 'T-PAK', priceCalculation, packages),
        },
        setEditingField: (field) => setEditingField(field, 'T-PAK'),
        editingField: editingFields?.['T-PAK'],
        unit: tPakPrices.bracketMarkupPrice.calculationPackage.unit.unit,
        consistsOfCount: tPakPrices.bracketMarkupPrice.calculationPackage.calculatedCount,
      });
    }

    return prices;
  }, [
    editingFields,
    getSalesPriceEditState,
    includeVat,
    newMarkups,
    packages,
    priceCalculation,
    setEditingField,
    setNewDiscount,
    setNewSalesCoverage,
    setNewSalesPrice,
  ]);
};
