import { Stack } from '@mui/material';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { AddButton, CalculusActionPortal } from '@retail/components';
import {
  createCustomerMarkupSchema,
  CustomerMarkupTable,
} from '@retail/customer-markup/components';
import { useLanguageSelector } from '@retail/app/hooks';
import { Supplier } from '@retail/suppliers/types';
import {
  CalculusAreas,
  Conditions,
  ConditionState,
  CustomerMarkupCondition,
  CustomerMarkupsForPriceLists,
  SupplierCustomerMarkupCondition,
} from '@retail/calculus/types';
import { UniversalFormProvider } from '@shared/components';
import { useCallback, useMemo, useState } from 'react';
import { useFetchConditions } from '@retail/calculus/data-access';
import { useMyPriceLists, useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useLevelSearchParams } from '@retail/calculus/hooks';
import CreateCustomerMarkupConditionDialog from './CreateCustomerMarkup/CreateCustomerMarkupConditionDialog';
import CreateAssortmentLevelCustomerMarkupConditionDialog from './CreateCustomerMarkup/CreateAssortmentLevelCustomerMarkupConditionDialog';
import { DeactivateCustomerMarkupDialogContainer } from './DeactivateCustomerMarkupDialogContainer';
import { EditCustomerMarkupDialog } from './EditCustomerMarkupDialog';

interface Props {
  selectedSupplier?: Supplier;
  typesToShow: ConditionState;
  isCreateOpen: boolean;
  toggleCreateDialog: () => void;
}

export function CustomerMarkupContainer({
  selectedSupplier,
  typesToShow,
  isCreateOpen,
  toggleCreateDialog,
}: Props) {
  const t = useCalculusTFunction();
  const { language } = useLanguageSelector();

  const customerMarkupSchema = useMemo(() => createCustomerMarkupSchema(t), [t]);
  const { selectedDepartment, selectedPriceList } = useSelectedOrgUnit();

  const priceLists = useMyPriceLists();
  const baseAssortmentId = (selectedDepartment?.assortmentId ?? selectedPriceList?.assortmentId)!;
  const draftAssortmentId = (selectedDepartment?.draftAssortmentId ??
    selectedPriceList?.draftAssortmentId)!;

  const { selectedProductGroupNumber, selectedMgItemId } = useLevelSearchParams();

  const baseAssortmentIds = priceLists
    ? priceLists.map((priceList) => priceList.assortmentId)
    : [baseAssortmentId];

  const draftAssortmentIds = priceLists
    ? priceLists.map((priceList) => priceList.draftAssortmentId)
    : [draftAssortmentId];

  const { data: conditionDtos = [] } = useFetchConditions({
    assortmentIds: typesToShow === ConditionState.ACTIVE ? baseAssortmentIds : draftAssortmentIds,
    conditionTypes: [CalculusAreas.CUSTOMER_MARKUP],
    conditionState: typesToShow === ConditionState.DRAFT ? ConditionState.ALL : typesToShow,
    mgSupplierIds: selectedSupplier ? [selectedSupplier.mgSupplierId] : [],
    groupNumbers: selectedProductGroupNumber ? [selectedProductGroupNumber] : [],
    mgItemNumbers: selectedMgItemId ? [selectedMgItemId] : [],
    includeOwnerConditions: typesToShow !== ConditionState.DRAFT,
  });

  const conditions = conditionDtos.map(Conditions.fromDto);

  const assortmentCustomerMarkupConditions = conditions
    .flatMap((condition) => condition.customerMarkupConditions?.assortmentMarkupConditions)
    .filter((customerMarkup) => !!customerMarkup) as CustomerMarkupCondition[];
  const supplierCustomerMarkupConditions = conditions
    .flatMap((condition) => condition.customerMarkupConditions?.supplierMarkupConditions)
    .filter((customerMarkup) => !!customerMarkup) as SupplierCustomerMarkupCondition[];

  const customerMarkupConditions = useMemo(
    () =>
      (selectedSupplier
        ? supplierCustomerMarkupConditions.flatMap(
            (supplierConditions) => supplierConditions.markupConditions
          )
        : assortmentCustomerMarkupConditions) ?? [].filter((customerMarkup) => !!customerMarkup),
    [assortmentCustomerMarkupConditions, selectedSupplier, supplierCustomerMarkupConditions]
  );
  const existingCustomerMarkupConditions = customerMarkupConditions.filter(
    (markup) => markup.operation !== 'DELETE'
  );

  const customerMarkupsForPriceLists: CustomerMarkupsForPriceLists[] | undefined = useMemo(() => {
    if (!priceLists) return undefined;
    const draftConditionsWithCustomerMarkup = conditions?.filter(
      (priceList) => !!priceList.customerMarkupConditions?.supplierMarkupConditions.length
    );

    return draftConditionsWithCustomerMarkup?.flatMap((conditions) => {
      const priceList = priceLists?.find(
        (priceList) => priceList.assortmentId === conditions.assortmentId
      );
      return {
        priceListAssortmentId: conditions.assortmentId,
        priceListDraftAssortmentId: priceList?.draftAssortmentId,
        priceListName: priceList?.name,
        conditionIds: conditions.customerMarkupConditions?.supplierMarkupConditions
          .flatMap((condition) => condition.markupConditions)
          .map(({ id }) => id),
      };
    });
  }, [conditions, priceLists]);

  const levelOrPriceListDraftAssortmentId = useCallback(
    (conditionId: number) =>
      customerMarkupsForPriceLists?.find((priceList) =>
        priceList.conditionIds?.includes(conditionId)
      )?.priceListDraftAssortmentId ?? draftAssortmentId,
    [customerMarkupsForPriceLists, draftAssortmentId]
  );

  const [customerMarkupForDeactivation, setCustomerMarkupForDeactivation] = useState<
    CustomerMarkupCondition | undefined
  >();
  const [customerMarkupForEdit, setCustomerMarkupForEdit] = useState<
    CustomerMarkupCondition | undefined
  >();

  return (
    <Stack flexGrow={1} borderTop={({ palette }) => `1px solid ${palette.divider}`}>
      <CalculusActionPortal>
        <AddButton onClick={toggleCreateDialog}>{t('calculus.createCustomerMarkup')}</AddButton>
      </CalculusActionPortal>

      <CustomerMarkupTable
        multipleChains={!!selectedDepartment}
        language={language}
        openCreateDialog={toggleCreateDialog}
        conditions={existingCustomerMarkupConditions}
        customerMarkupsForPriceLists={customerMarkupsForPriceLists}
        handleDeactivate={setCustomerMarkupForDeactivation}
        handleEdit={setCustomerMarkupForEdit}
      />

      {isCreateOpen && (
        <UniversalFormProvider
          schema={customerMarkupSchema}
          defaultValues={customerMarkupSchema.getDefault()}
        >
          {selectedSupplier ? (
            <CreateCustomerMarkupConditionDialog
              open
              assortmentId={[draftAssortmentId]}
              supplier={selectedSupplier}
              includeAssortmentSelect={!selectedPriceList}
              onClose={toggleCreateDialog}
            />
          ) : (
            !!selectedPriceList && (
              <CreateAssortmentLevelCustomerMarkupConditionDialog
                open
                assortmentId={[draftAssortmentId]}
                includeAssortmentSelect={!selectedPriceList}
                onClose={toggleCreateDialog}
              />
            )
          )}
        </UniversalFormProvider>
      )}
      {customerMarkupForDeactivation && (
        <DeactivateCustomerMarkupDialogContainer
          customerMarkup={customerMarkupForDeactivation}
          assortmentId={levelOrPriceListDraftAssortmentId(customerMarkupForDeactivation.id)}
          onClose={() => setCustomerMarkupForDeactivation(undefined)}
        />
      )}
      {customerMarkupForEdit && (
        <EditCustomerMarkupDialog
          open
          customerMarkupToEdit={customerMarkupForEdit}
          assortmentId={levelOrPriceListDraftAssortmentId(customerMarkupForEdit.id)}
          onClose={() => setCustomerMarkupForEdit(undefined)}
        />
      )}
    </Stack>
  );
}
