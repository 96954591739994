import { DeleteItemsDialog } from '@retail/my-assortment/components';
import { useAssortmentItemsActions } from '@retail/my-assortment/context';
import { AssortmentItem, ItemsDTO } from '@retail/my-assortment/types';
import { useCallback } from 'react';

interface Props {
  assortmentItems: AssortmentItem[];
  itemsForDeletion: number[];
  onClose: () => void;
  resetSelectedItems: () => void;
}

export function DeleteItemsDialogContainer({
  assortmentItems,
  itemsForDeletion,
  onClose,
  resetSelectedItems,
}: Props) {
  const { deleteItemsByDate, updateItemsIsLoading } = useAssortmentItemsActions();

  const confirmDeleteCheckedItemsFromDate = useCallback(
    (date: string) => {
      if (itemsForDeletion.length) {
        const itemsWithUpdatedNullPriority: ItemsDTO[] = itemsForDeletion.map((checkedItemId) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const assortmentItem = assortmentItems.find(
            (assortmentItem) => checkedItemId === assortmentItem.id
          )!;
          return {
            mgSupplierId: assortmentItem.mgSupplierId,
            mgItemNumber: assortmentItem.mgItemNumber,
            priority: null,
            validFrom: date,
          };
        });
        deleteItemsByDate(itemsWithUpdatedNullPriority);
        resetSelectedItems();
      }
    },
    [assortmentItems, deleteItemsByDate, itemsForDeletion, resetSelectedItems]
  );

  return (
    <DeleteItemsDialog
      isOpen={true}
      numberOfItems={itemsForDeletion.length}
      isDeleting={updateItemsIsLoading}
      onClose={onClose}
      onDelete={confirmDeleteCheckedItemsFromDate}
    />
  );
}
