import { useAppTFunction } from '@retail/app/i18n';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import {
  useDeleteCondition,
  useMutateActivateDraftedConditions,
} from '@retail/calculus/data-access';
import { DeleteOrActivateConditionIds } from '@retail/calculus/types';
import { useToast } from '@retail/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useCalculusConditionActions = () => {
  const t = useAppTFunction();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { selectedDepartment, selectedPriceList } = useSelectedOrgUnit();
  const draftAssortmentId = (selectedDepartment?.draftAssortmentId ??
    selectedPriceList?.draftAssortmentId)!;

  const handleInvalidateQueries = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['mg-item'] });
    queryClient.invalidateQueries({ queryKey: ['product-groups-hierarchy'] });
  }, [queryClient]);

  // Remove conditions from draft drawer
  const { mutateAsync: removeConditions, isLoading: removeConditionsIsLoading } =
    useDeleteCondition();

  const handleDeleteConditions = (ids: DeleteOrActivateConditionIds[]) => {
    removeConditions({ body: ids })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
        queryClient.invalidateQueries({ queryKey: ['product-groups-hierarchy'] });

        if (ids.length === 1) toast.success(t('calculus.messages.conditionDeleted'));
        else toast.success(t('calculus.messages.conditionsDeleted'));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('markup.error.generic'));
      });
  };

  const handleDeleteAssortmentConditions = (ids: number[]) => {
    removeConditions({ body: [{ assortmentId: draftAssortmentId, conditionIds: ids }] })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
        queryClient.invalidateQueries({ queryKey: ['product-groups-hierarchy'] });

        if (ids.length === 1) toast.success(t('calculus.messages.conditionDeleted'));
        else toast.success(t('calculus.messages.conditionsDeleted'));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('markup.error.generic'));
      });
  };

  // Activate conditions in draft drawer
  const { mutateAsync: activateDraftCondition, isLoading: activateConditionIsLoading } =
    useMutateActivateDraftedConditions();

  const activateDraft = useCallback(
    (conditionIds: DeleteOrActivateConditionIds[]) => {
      activateDraftCondition({
        body: conditionIds,
      })
        .then(() => {
          toast.success(t('calculus.messages.success'));
          handleInvalidateQueries();
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('markup.error.generic'));
        });
    },
    [activateDraftCondition, handleInvalidateQueries, t, toast]
  );

  return {
    activateConditionIsLoading,
    removeConditionsIsLoading,
    handleDeleteAssortmentConditions,
    handleDeleteConditions,
    activateDraft,
  };
};
