import { CustomerMarkupConditionDTO } from '../../dtos';
import { CalculusAreas } from '../CalculusAreas';
import { Operation } from '../Conditions';
import { ConditionBase, ConditionBaseProps } from '../ConditionBase';
import { Condition } from '../Condition';

interface CustomerMarkupConditionProps extends ConditionBaseProps<CustomerMarkupCondition> {
  salesFactor?: number;
  percent?: number;
}

export class CustomerMarkupCondition extends ConditionBase<CustomerMarkupCondition> {
  salesFactor?: number;
  percent?: number;

  constructor({ salesFactor, percent, ...superProps }: CustomerMarkupConditionProps) {
    super(superProps);

    this.salesFactor = salesFactor;
    this.percent = percent;
  }

  static fromDto = (dto: CustomerMarkupConditionDTO): CustomerMarkupCondition =>
    new CustomerMarkupCondition({
      id: dto.id,
      assortmentId: dto.assortmentId,
      assortmentType: dto.assortmentType,
      bindingType: dto.bindingType,
      mgSupplierId: dto.mgSupplierId,
      levelProductGroup: dto.levelProductGroup,
      levelProductGroupName: dto.levelProductGroupName,
      mgItemNumber: dto.mgItemNumber,
      finfoNumber: dto.finfoNumber,
      mgSupplierName: dto.mgSupplierName,
      mgSupplierItemId: dto.mgSupplierItemId,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      validFrom: dto.validFrom ? new Date(dto.validFrom) : undefined,
      validTo: dto.validTo ? new Date(dto.validTo) : undefined,
      active: dto.active,
      current: dto.current,
      inherited: dto.inherited,
      source: dto.source,
      salesFactor: dto.salesFactor,
      percent: dto.percent,
      operation: dto.operation as Operation,
      condition: Condition.fromDto(dto.condition),
    });

  getType = () => CalculusAreas.CUSTOMER_MARKUP;
}
