import { Stack } from '@mui/material';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { CompetitorViewForMgItem } from '@retail/products/components';
import { MgItem } from '@retail/products/types';
import { useSelectedCompetitors } from '@retail/retailer/data-access';

interface Props {
  mgItem: MgItem;
}

export const MgItemCompetitorPrices = ({ mgItem }: Props) => {
  const { selectedOrgUnitId } = useSelectedOrgUnit();

  const competitors = useSelectedCompetitors(selectedOrgUnitId);

  return (
    <Stack flexGrow={1}>
      <SuspenseWithSentryErrorBoundary>
        <CompetitorViewForMgItem mgItem={mgItem} competitors={competitors} />
      </SuspenseWithSentryErrorBoundary>
    </Stack>
  );
};
