import { SupplierMarkupConditionsDTO } from '../../dtos';
import { MarkupCondition } from './MarkupCondition';

interface SupplierMarkupConditionProps {
  mgSupplierId: number;
  markupConditions: MarkupCondition[];
}

export class SupplierMarkupCondition {
  mgSupplierId: number;
  markupConditions: MarkupCondition[];

  constructor({ mgSupplierId, markupConditions }: SupplierMarkupConditionProps) {
    this.mgSupplierId = mgSupplierId;
    this.markupConditions = markupConditions;
  }

  static fromDto = (dto: SupplierMarkupConditionsDTO): SupplierMarkupCondition =>
    new SupplierMarkupCondition({
      mgSupplierId: dto.mgSupplierId,
      markupConditions: dto.conditions.map(MarkupCondition.fromDto),
    });
}
