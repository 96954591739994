import { useMutateCreateOrUpdateConditions } from '@retail/calculus/data-access';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { FollowPriceSuggestion } from '@retail/price-suggestion/types';
import { useToast } from '@retail/hooks';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { getDate } from '@retail/utils';
import { useQueryClient } from '@tanstack/react-query';
import { HierarchyLevels, PriceSuggestionCondition } from '@retail/calculus/types';

interface Props {
  mgSupplierId: number;
}

export function usePriceSuggestionActions({ mgSupplierId }: Props) {
  const t = useCalculusTFunction();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { draftAssortmentId, departmentId } = useDepartmentAssortments();

  const invalidateAgreements = () => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['product-groups-hierarchy'] });
  };

  const { mutateAsync: mutatePostPurchaseChanges, isLoading: isLoadingPostChanges } =
    useMutateCreateOrUpdateConditions({ assortmentId: draftAssortmentId, departmentId });

  const activateOrChangeCondition = (activateCondition: FollowPriceSuggestion) => {
    mutatePostPurchaseChanges({
      body: {
        priceSuggestionConditions: [
          {
            mgSupplierId,
            mgSupplierConditions: activateCondition.supplierLevel
              ? [
                  {
                    validFrom: getDate(activateCondition.fromDate),
                    priceSuggestion: {
                      priority: [activateCondition.priceSuggestionType],
                    },
                  },
                ]
              : undefined,
            productGroupConditions: activateCondition.groupNumber
              ? [
                  {
                    groupNumber: activateCondition.groupNumber,
                    validFrom: getDate(activateCondition.fromDate),
                    priceSuggestion: {
                      priority: [activateCondition.priceSuggestionType],
                    },
                  },
                ]
              : undefined,
            discountGroupConditions: activateCondition.discountGroupCode
              ? [
                  {
                    groupCode: activateCondition.discountGroupCode,
                    validFrom: getDate(activateCondition.fromDate),
                    priceSuggestion: {
                      priority: [activateCondition.priceSuggestionType],
                    },
                  },
                ]
              : undefined,
            mgSupplierItemConditions: activateCondition.mgItemNumber
              ? [
                  {
                    mgItemNumber: activateCondition.mgItemNumber,
                    validFrom: getDate(activateCondition.fromDate),
                    priceSuggestion: {
                      priority: [activateCondition.priceSuggestionType],
                    },
                  },
                ]
              : undefined,
          },
        ],
      },
    })
      .then(() => {
        toast.success(t('calculus.actions.conditionCreateInDrawer'));
        invalidateAgreements();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('calculus.error.generic'));
      });
  };

  const deactivateCondition = (condition: PriceSuggestionCondition) => {
    mutatePostPurchaseChanges({
      body: {
        priceSuggestionConditions: [
          {
            mgSupplierId,
            mgSupplierConditions:
              condition.bindingType === HierarchyLevels.supplier
                ? [
                    {
                      validFrom: getDate(),
                      priceSuggestion: {
                        priority: [],
                      },
                    },
                  ]
                : undefined,
            productGroupConditions: condition.condition.productGroup
              ? [
                  {
                    groupNumber: condition.condition.productGroup.groupNumber,
                    validFrom: getDate(),
                    priceSuggestion: {
                      priority: [],
                    },
                  },
                ]
              : undefined,
            discountGroupConditions: condition.condition.discountGroup
              ? [
                  {
                    groupCode: condition.condition.discountGroup.code,
                    validFrom: getDate(),
                    priceSuggestion: {
                      priority: [],
                    },
                  },
                ]
              : undefined,
            mgSupplierItemConditions: condition.mgItemNumber
              ? [
                  {
                    mgItemNumber: condition.mgItemNumber,
                    validFrom: getDate(),
                    priceSuggestion: {
                      priority: [],
                    },
                  },
                ]
              : undefined,
          },
        ],
      },
    })
      .then(() => {
        toast.success(t('calculus.actions.conditionCreateInDrawer'));
        invalidateAgreements();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('calculus.error.generic'));
      });
  };

  return {
    activateOrChangeCondition,
    deactivateCondition,
    isLoadingPostChanges,
  };
}
