import { DialogContent, DialogTitle, Stack } from '@mui/material';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import {
  SuspenseWithSentryErrorBoundary,
  withDialogSuspenseErrorBoundary,
} from '@retail/components';
import { useSelectProductGroups } from '@retail/products/context';
import { ProductGroup } from '@retail/products/types';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { Dialog } from '@shared/components';
import { useCallback, useState } from 'react';
import { PriceListTable } from './PriceListTable';
import { PriceListFilters } from '@retail/purchase-agreements/components';
import { useDisclosure } from '@shared/hooks';

interface Props {
  open: boolean;
  onClose: () => void;
  assortmentId: number;
  agreements: PurchaseAgreement[];
  defaultSelectedAgreements?: PurchaseAgreement[];
  levelProductGroupNr?: string;
  levelMgItemNr?: number;
}

function PriceComparisonDialogContainer({
  open,
  onClose,
  assortmentId,
  agreements,
  defaultSelectedAgreements,
  levelProductGroupNr,
  levelMgItemNr,
}: Props) {
  const t = useCalculusTFunction();

  const [selectedPurchaseAgreements, selectPurchaseAgreements] = useState<PurchaseAgreement[]>(
    defaultSelectedAgreements ?? []
  );
  const { isOpen: includeFuturePrices, onToggle: toggleIncludeFuturePrices } = useDisclosure(false);

  const handleSelectPurchaseAgreement = useCallback(
    (selectedAgreement: PurchaseAgreement) => {
      if (selectedPurchaseAgreements.find((agreement) => agreement.id === selectedAgreement.id)) {
        selectPurchaseAgreements(
          selectedPurchaseAgreements.filter((agreement) => agreement.id !== selectedAgreement.id)
        );
      } else {
        selectPurchaseAgreements([...selectedPurchaseAgreements, selectedAgreement]);
      }
    },
    [selectedPurchaseAgreements]
  );

  const { productGroupHierarchy, productGroups } = useSelectProductGroups();
  const [selectedProductGroup, selectProductGroup] = useState<ProductGroup | undefined>(undefined);
  const handleSelectProductGroup = useCallback(
    (groupNumber: string | null) => {
      if (groupNumber === null) {
        selectProductGroup(undefined);
        return;
      }
      const selectedProductGroup = productGroups.find(
        (productGroup) => productGroup.groupNumber === groupNumber
      );
      selectProductGroup(selectedProductGroup);
    },
    [productGroups]
  );

  const [query, setQuery] = useState<string>('');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      sx={{ '& .MuiPaper-root': { minHeight: '90%', maxHeight: '90%', px: 0 } }}
    >
      <DialogTitle sx={{ paddingLeft: 3 }}>{t('calculus.actions.comparePriceLists')}</DialogTitle>
      <DialogContent sx={{ display: 'flex' }}>
        <Stack flexDirection="row" flexGrow={1} justifyContent="space-between" overflow="hidden">
          <SuspenseWithSentryErrorBoundary>
            <PriceListTable
              assortmentId={assortmentId}
              selectedAgreements={selectedPurchaseAgreements}
              selectedProductGroup={selectedProductGroup?.groupNumber ?? levelProductGroupNr}
              selectedMgItemNr={levelMgItemNr}
              query={query}
              includeFuturePrices={includeFuturePrices}
            />
          </SuspenseWithSentryErrorBoundary>

          <PriceListFilters
            agreements={agreements}
            productGroupHierarchy={productGroupHierarchy}
            selectedPurchaseAgreements={selectedPurchaseAgreements}
            selectedProductGroup={selectedProductGroup}
            includeFuturePrices={includeFuturePrices}
            selectPurchaseAgreement={handleSelectPurchaseAgreement}
            selectProductGroup={handleSelectProductGroup}
            setQuery={setQuery}
            toggleIncludeFuturePrices={toggleIncludeFuturePrices}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(PriceComparisonDialogContainer);
