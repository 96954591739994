import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { TextButton } from '@retail/components';
import { ContainedButton, Dialog } from '@shared/components';
import { useFormContext } from 'react-hook-form';
import { ExportSchema } from './exportSchema';
import { ExportDialogForm } from './ExportDialogForm';
import { useState } from 'react';

export const ExportFileTypes = {
  DosOem: 'dosOem',
  Csv: 'csv',
} as const;

export type ExportFileType = (typeof ExportFileTypes)[keyof typeof ExportFileTypes];

interface Props {
  isLoading: boolean;
  onSubmit: (data: ExportSchema, csv: boolean) => void;
  onUpdateDateTimePicker: (fromDate: Date | null) => Promise<void>;
  onClose: () => void;
}

export const ExportDialog = ({ isLoading, onSubmit, onClose, onUpdateDateTimePicker }: Props) => {
  const t = useAppTFunction();

  const [fileType, setFileType] = useState<ExportFileType>(ExportFileTypes.DosOem);

  const { handleSubmit } = useFormContext<ExportSchema>();

  const handleClick = (data: ExportSchema) => onSubmit(data, fileType === ExportFileTypes.Csv);

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <form>
        <DialogTitle pb={0}>{t('export.exportFile')}</DialogTitle>

        <DialogContent>
          <ExportDialogForm
            fileType={fileType}
            setFileType={setFileType}
            onUpdateDateTimePicker={onUpdateDateTimePicker}
          />
        </DialogContent>

        <DialogActions sx={{ mt: 3 }}>
          <TextButton onClick={onClose} disabled={isLoading}>
            {t('common.cancel')}
          </TextButton>

          <ContainedButton
            type="submit"
            color="secondary"
            loading={isLoading}
            onClick={handleSubmit(handleClick)}
          >
            {t('export.download')}
          </ContainedButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
