import { ProductGroupFilter, SideFilters, ToggleSwitch } from '@retail/components';
import { SelectDiscountGroup, SelectSupplier } from '@retail/my-assortment/components';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { FilterOptions, FilterSelection, SetFilterOptions } from '@retail/my-assortment/types';
import { MgSupplierItemSearchField } from '@retail/products/components';
import { OverGroup, ProductGroup } from '@retail/products/types';
import { useDisclosure } from '@shared/hooks';

interface Props {
  showFilters: boolean;
  closeFilters: () => void;
}

export const MyAssortmentFilters = ({
  selectedSupplier,
  selectedProductGroup,
  selectedDiscountGroup,
  itemsWithAgreementPrice,
  suppliers,
  productGroups,
  discountGroups,
  searchQuery,
  showFilters,
  closeFilters,
  selectSupplierById,
  selectProductGroupByNr,
  selectDiscountGroupById,
  setSearchQuery,
  setOnlyItemsWithAgreementPrice,
}: Props & FilterOptions & SetFilterOptions & FilterSelection) => {
  const t = useMyAssortmentTFunction();

  const { isOpen: showProductGroups, onToggle: toggleShowProductGroups } = useDisclosure(true);
  const baseProductGroups = productGroups.map(OverGroup.fromDto);

  return (
    <SideFilters
      heading={t('myAssortment.filters.assortmentFilters')}
      showFilters={showFilters}
      closeFilters={closeFilters}
      sx={{ px: 3 }}
    >
      <ToggleSwitch
        text={t('myAssortment.itemsWithPurchasePrice')}
        toggle={itemsWithAgreementPrice}
        setToggle={setOnlyItemsWithAgreementPrice}
        justifyContent="space-between"
      />
      <SelectSupplier
        suppliers={suppliers}
        selectedSupplier={selectedSupplier?.mgSupplierId ?? null}
        selectSupplier={selectSupplierById}
      />
      {selectedSupplier && (
        <SelectDiscountGroup
          discountGroups={discountGroups}
          selectedDiscountGroup={selectedDiscountGroup?.id ?? null}
          selectDiscountGroup={selectDiscountGroupById}
        />
      )}
      <MgSupplierItemSearchField
        searchTerm={searchQuery}
        isDisabled={false}
        size="small"
        onSubmitSearch={setSearchQuery}
      />

      <ProductGroupFilter
        productGroups={baseProductGroups}
        selectedProductGroup={selectedProductGroup as ProductGroup}
        showProductGroups={showProductGroups}
        toggleShowProductGroups={toggleShowProductGroups}
        selectProductGroup={selectProductGroupByNr}
        getCount={(productGroup: ProductGroup) => productGroup.numberOfItems}
      />
    </SideFilters>
  );
};
