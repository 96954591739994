import { useMyOrgUnits, useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useMemo } from 'react';
import { OrgUnitHeading, OrgUnitSelect } from '@retail/components';

export const AvailableOrgUnitsContainer: React.FC = () => {
  const { departments, members, brand, priceLists } = useMyOrgUnits();
  const {
    selectedMember,
    selectMember,
    selectedDepartment,
    selectDepartment,
    selectedPriceList,
    selectPriceList,
    selectedBrandContext,
    selectBrand,
  } = useSelectedOrgUnit();

  const hasMultipleOrgUnits = useMemo(() => {
    return (priceLists?.length ?? 0) + departments.length + members.length + (brand ? 1 : 0) > 1;
  }, []);

  if (hasMultipleOrgUnits) {
    return (
      <OrgUnitSelect
        brand={brand}
        members={members}
        departments={departments}
        priceLists={priceLists}
        selectedDepartment={selectedDepartment}
        selectedMember={selectedMember}
        selectedPriceList={selectedPriceList}
        selectedBrand={selectedBrandContext}
        selectBrand={selectBrand}
        selectMember={selectMember}
        selectDepartment={selectDepartment}
        selectPriceList={selectPriceList}
      />
    );
  }

  if (selectedBrandContext) {
    return <OrgUnitHeading name={selectedBrandContext.name} />;
  }
  if (selectedMember) {
    return <OrgUnitHeading name={selectedMember.name} />;
  }
  if (selectedDepartment) {
    return <OrgUnitHeading name={selectedDepartment.name} />;
  }

  return <OrgUnitHeading name="" />;
};
