import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { CustomerMarkupCondition, HierarchyLevels } from '@retail/calculus/types';
import {
  CustomerMarkupConditionValueForm,
  editCustomerMarkupSchema,
  EditCustomerMarkupSchema,
} from '@retail/customer-markup/components';
import { useCustomerMarkupActions } from '@retail/customer-markup/context';
import { EnteredField } from '@retail/customer-markup/types';
import { calculateFromPercentage, calculateToPercentage } from '@retail/utils';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface Props {
  open: boolean;
  customerMarkupToEdit: CustomerMarkupCondition;
  assortmentId: number;
  onClose: () => void;
}

export function EditCustomerMarkupDialog({
  open,
  customerMarkupToEdit,
  assortmentId,
  onClose,
}: Props) {
  const t = useCalculusTFunction();
  const { createCustomerMarkupCondition } = useCustomerMarkupActions({
    assortmentIds: [assortmentId],
  });

  const formMethods = useForm<EditCustomerMarkupSchema>({
    defaultValues: {
      factor: customerMarkupToEdit.salesFactor
        ? customerMarkupToEdit.salesFactor
        : customerMarkupToEdit.percent
        ? calculateFromPercentage(customerMarkupToEdit.percent)
        : undefined,
      percent: customerMarkupToEdit.percent
        ? customerMarkupToEdit.percent
        : customerMarkupToEdit.salesFactor
        ? calculateToPercentage(customerMarkupToEdit.salesFactor)
        : undefined,
      enteredField: customerMarkupToEdit.salesFactor ? EnteredField.FACTOR : EnteredField.PERCENT,
      fromDate: new Date(),
    },
    resolver: yupResolver(editCustomerMarkupSchema(t)),
  });

  const onActivate = useCallback(
    ({ fromDate, factor, percent, enteredField }: EditCustomerMarkupSchema) => {
      createCustomerMarkupCondition({
        mgSupplierId: customerMarkupToEdit.mgSupplierId,
        level:
          customerMarkupToEdit.bindingType === HierarchyLevels.supplier
            ? 'supplier'
            : customerMarkupToEdit.bindingType === HierarchyLevels.discountGroup
            ? 'discountGroup'
            : customerMarkupToEdit.bindingType === HierarchyLevels.item
            ? 'item'
            : 'productGroup',
        fromDate,
        percent: percent ? Number(percent) : undefined,
        factor: factor ? Number(factor) : undefined,
        enteredField,
        groupCode: customerMarkupToEdit.condition.discountGroup?.code,
        groupNumber: customerMarkupToEdit.condition.productGroup?.groupNumber,
        mgItemNumber: customerMarkupToEdit.mgItemNumber,
      });

      onClose();
    },
    [customerMarkupToEdit, createCustomerMarkupCondition, onClose]
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {t('calculus.customerMarkup.edit', { name: customerMarkupToEdit?.getDisplayText() })}
      </DialogTitle>

      <DialogContent>
        <FormProvider {...formMethods}>
          <CustomerMarkupConditionValueForm />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <TextButton onClick={onClose} size="small">
          {t('calculus.cancel')}
        </TextButton>
        <ContainedButton
          disabled={Object.keys(formMethods?.formState?.errors).length > 0}
          onClick={formMethods.handleSubmit(onActivate)}
          size="small"
        >
          {t('calculus.actions.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
