import { UploadedMarkup } from './markupImportState';
import { SupplierMarkupConditionPostDTO } from '@retail/markup/types';
import dayjs from 'dayjs';

interface Props {
  validMarkups: UploadedMarkup[];
}

export const getImportedMarkupConditions = ({ validMarkups }: Props) => {
  const requestMarkupConditionsMap: Map<number, SupplierMarkupConditionPostDTO> = new Map();

  validMarkups.forEach(
    ({
      mgSupplierId,
      mgItemNumber,
      groupNumber,
      groupCode,
      validFrom,
      salesFactor,
      salesPrice,
    }) => {
      if (!requestMarkupConditionsMap.has(mgSupplierId)) {
        requestMarkupConditionsMap.set(mgSupplierId, {
          mgSupplierId,
          mgSupplierConditions: [],
          productGroupConditions: [],
          discountGroupConditions: [],
          mgSupplierItemConditions: [],
        });
      }

      const supplierConditions = requestMarkupConditionsMap.get(mgSupplierId)!;
      const markup = {
        templateCreate: {
          salesFactor,
          templateName: String(mgSupplierId),
        },
      };

      const updatedValidFrom = validFrom
        ? dayjs(validFrom)?.format('YYYY-MM-DD')
        : dayjs(new Date()).format('YYYY-MM-DD');

      if (mgItemNumber) {
        supplierConditions.mgSupplierItemConditions?.push({
          mgItemNumber: mgItemNumber ?? 0,
          validFrom: updatedValidFrom,
          markup: !salesPrice ? markup : undefined,
          salesPriceMarkup: salesPrice
            ? {
                templateName: String(mgSupplierId),
                salesPrice,
                incMva: true,
              }
            : undefined,
        });
      } else if (groupCode) {
        supplierConditions.discountGroupConditions?.push({
          validFrom: updatedValidFrom,
          groupCode,
          markup,
        });
      } else if (groupNumber) {
        supplierConditions.productGroupConditions?.push({
          validFrom: updatedValidFrom,
          groupNumber,
          markup,
        });
      } else {
        supplierConditions.mgSupplierConditions?.push({
          validFrom: updatedValidFrom,
          markup,
        });
      }
    }
  );

  // Filter out empty arrays
  return Array.from(requestMarkupConditionsMap.values()).map((condition) => {
    return Object.fromEntries(
      Object.entries(condition).filter(([_, value]) => !Array.isArray(value) || value.length > 0)
    ) as SupplierMarkupConditionPostDTO;
  });
};
