import { FormLabel, Stack } from '@mui/material';
import { useMyOrgUnits, useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { SelectPriceListAssortmentCheckbox } from '@retail/customer-markup/components';
import { useCallback } from 'react';

interface Props {
  selectedAssortmentIds: number[];
  selectAssortmentId: (id: number[]) => void;
}

export function PriceListAssortmentSelect({ selectedAssortmentIds, selectAssortmentId }: Props) {
  const t = useCalculusTFunction();
  const { selectedDepartment } = useSelectedOrgUnit();
  const { priceLists } = useMyOrgUnits();
  const departmentPriceLists = priceLists.filter(
    (priceList) => priceList.departmentId === selectedDepartment?.departmentId
  );
  const onCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const assortmentId = Number(event.target.value);

      if (selectedAssortmentIds.includes(assortmentId)) {
        selectAssortmentId(selectedAssortmentIds.filter((id) => id !== assortmentId));
      } else {
        selectAssortmentId([...selectedAssortmentIds, assortmentId]);
      }
    },
    [selectAssortmentId, selectedAssortmentIds]
  );
  return (
    <Stack pt={2} gap={1}>
      <FormLabel required>{t('calculus.customerMarkup.selectedCustomers')}: </FormLabel>
      {departmentPriceLists.map((priceList) => {
        return (
          <Stack key={priceList.draftAssortmentId} pl={2}>
            <SelectPriceListAssortmentCheckbox
              key={priceList.draftAssortmentId}
              {...priceList}
              checked={selectedAssortmentIds.includes(priceList.draftAssortmentId)}
              onChange={onCheck}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}
