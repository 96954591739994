import { CalculusAreas } from './CalculusAreas';
import { Condition } from './Condition';
import { AssortmentType, Operation } from './Conditions';
import { HierarchyLevels } from './HierarchyLevels';

export interface DiscountGroupMinimal {
  id: number;
  code: string;
  name?: string;
  mgSupplierId: number;
}
export interface ConditionBaseProps<T extends ConditionBase<T>> {
  id: number;
  assortmentId?: number;
  assortmentType: AssortmentType;
  mgSupplierId: number;
  levelProductGroup?: string;
  levelProductGroupName?: string;
  mgItemNumber?: number;
  finfoNumber?: number;
  mgSupplierName?: string;
  mgSupplierItemId?: number;
  primaryText?: string;
  secondaryText?: string;
  validFrom?: Date;
  validTo?: Date;
  active?: boolean;
  current?: boolean;
  inherited?: boolean;
  source?: string;
  bindingType?: HierarchyLevels;
  futureConditions?: T[];
  subConditions?: T[];
  replacesConditions?: T[];
  newCondition?: T;
  operation?: Operation;
  condition: Condition;
}

export abstract class ConditionBase<T extends ConditionBase<T>> {
  id: number;
  assortmentId?: number;
  assortmentType: AssortmentType;
  mgSupplierId: number;
  levelProductGroup?: string;
  levelProductGroupName?: string;
  mgItemNumber?: number;
  finfoNumber?: number;
  mgSupplierName?: string;
  mgSupplierItemId?: number;
  primaryText?: string;
  secondaryText?: string;
  validFrom?: Date;
  validTo?: Date;
  active?: boolean;
  current?: boolean;
  inherited?: boolean;
  source?: string;
  bindingType?: HierarchyLevels;
  futureConditions?: T[];
  subConditions?: T[];
  replacesConditions?: T[];
  newCondition?: T;
  operation?: Operation;
  condition: Condition;

  constructor({
    id,
    assortmentType,
    mgSupplierId,
    validFrom,
    assortmentId,
    levelProductGroup,
    levelProductGroupName,
    mgItemNumber,
    finfoNumber,
    mgSupplierName,
    mgSupplierItemId,
    primaryText,
    secondaryText,
    validTo,
    active,
    current,
    inherited,
    source,
    bindingType,
    futureConditions,
    subConditions,
    replacesConditions,
    newCondition,
    operation,
    condition,
  }: ConditionBaseProps<T>) {
    this.id = id;
    this.assortmentId = assortmentId;
    this.assortmentType = assortmentType;
    this.mgSupplierId = mgSupplierId;
    this.levelProductGroup = levelProductGroup;
    this.levelProductGroupName = levelProductGroupName;
    this.mgItemNumber = mgItemNumber;
    this.finfoNumber = finfoNumber;
    this.mgSupplierName = mgSupplierName;
    this.mgSupplierItemId = mgSupplierItemId;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.active = active;
    this.current = current;
    this.inherited = inherited;
    this.source = source;
    this.bindingType = bindingType;
    this.futureConditions = futureConditions;
    this.subConditions = subConditions;
    this.replacesConditions = replacesConditions;
    this.newCondition = newCondition;
    this.operation = operation;
    this.condition = condition;
  }

  getPreviousCondition = () => this.replacesConditions?.[this.replacesConditions?.length - 1];

  getDisplayText = () => {
    const topLine =
      this.primaryText ??
      this.condition?.productGroup?.name ??
      this.condition?.discountGroup?.name ??
      this.mgSupplierName;

    const bottomLine =
      this.secondaryText ??
      this.condition?.productGroup?.groupNumber ??
      this.condition?.discountGroup?.code ??
      this.mgSupplierId;

    return topLine ? `${topLine} ${bottomLine ? `(${bottomLine})` : ''}` : '-';
  };

  getLevelName = () => {
    return (
      this.primaryText ??
      this.condition?.productGroup?.name ??
      this.condition?.discountGroup?.name ??
      this.mgSupplierName
    );
  };

  getLevelId = () => {
    return (
      this.secondaryText ??
      this.condition?.productGroup?.groupNumber ??
      this.condition?.discountGroup?.code ??
      this.mgSupplierId
    );
  };

  abstract getType: () => CalculusAreas;
}
