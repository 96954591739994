import { ConditionDTO } from '../dtos';
import { HierarchyLevels } from './HierarchyLevels';
import { GroupType } from './ProductGroupsStructure';

export interface MgSupplierMinimal {
  mgSupplierId: number;
  name: string;
}

export interface ProductGroupMinimal {
  type: GroupType;
  groupNumber: string;
  name: string;
}

export interface DiscountGroupMinimal {
  id: number;
  code: string;
  name?: string;
  mgSupplierId: number;
}

interface ConditionProps {
  id: number;
  validFrom?: Date;
  validTo?: Date;
  bindingType?: HierarchyLevels;
  mgSupplier?: MgSupplierMinimal;
  mgSupplierItemId?: number;
  productGroup?: ProductGroupMinimal;
  discountGroup?: DiscountGroupMinimal;
  inherited: boolean;
}

export class Condition {
  id: number;
  validFrom?: Date;
  validTo?: Date;
  bindingType?: HierarchyLevels;
  mgSupplier?: MgSupplierMinimal;
  mgSupplierItemId?: number;
  productGroup?: ProductGroupMinimal;
  discountGroup?: DiscountGroupMinimal;
  inherited: boolean;

  constructor({
    id,
    validFrom,
    validTo,
    bindingType,
    mgSupplier,
    mgSupplierItemId,
    productGroup,
    discountGroup,
    inherited,
  }: ConditionProps) {
    this.id = id;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.bindingType = bindingType;
    this.mgSupplier = mgSupplier;
    this.mgSupplierItemId = mgSupplierItemId;
    this.productGroup = productGroup;
    this.discountGroup = discountGroup;
    this.inherited = inherited;
  }

  static fromDto = (dto: ConditionDTO): Condition =>
    new Condition({
      id: dto.id,
      validFrom: dto.validFrom ? new Date(dto.validFrom) : undefined,
      validTo: dto.validTo ? new Date(dto.validTo) : undefined,
      bindingType: dto.bindingType,
      mgSupplier: dto.mgSupplier,
      mgSupplierItemId: dto.mgSupplierItemId,
      productGroup: dto.productGroup,
      discountGroup: dto.discountGroup,
      inherited: dto.inherited,
    });
}
