import { useProductsTFunction } from '@retail/products/i18n';
import { PanelProps, ProductInformationAccordion } from './ProductInformationAccordion';
import { ProductInformationAccordionRow } from './ProductInformationAccordionRow';
import { PANELS, ProductInformationAccordionsProps } from './ProductInformationAccordions';
import dayjs from 'dayjs';
import { formatPrice } from '@retail/products/utils';
import i18next from 'i18next';
import { useTheme } from '@mui/material';

export const InfoPanel = ({
  expanded,
  selectedSupplierItem,
  handleChange,
}: PanelProps & ProductInformationAccordionsProps) => {
  const t = useProductsTFunction();
  const { palette } = useTheme();
  return (
    <ProductInformationAccordion
      panelType={PANELS.INFO}
      backgroundColor={palette.grey[50]}
      expanded={expanded}
      handleChange={handleChange}
    >
      <ProductInformationAccordionRow title={t('products.mgItem.details.primaryText')}>
        {selectedSupplierItem.primaryText}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.secondaryText')}>
        {selectedSupplierItem.secondaryText}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.productGroup')}>
        {selectedSupplierItem.bk04Varegruppe?.id}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.registrationDate')}>
        {selectedSupplierItem.registrationDate
          ? dayjs(selectedSupplierItem.registrationDate).format('ll')
          : ''}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.discontinuationCode')}>
        {selectedSupplierItem.discontinuationCode}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.discontinuationDate')}>
        {selectedSupplierItem.discontinuationDate
          ? dayjs(selectedSupplierItem.discontinuationDate).format('ll')
          : ''}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.articleType')}>
        {selectedSupplierItem.articleType}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.vatCode')}>
        {selectedSupplierItem.vatCode}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.basePrice')}>
        {selectedSupplierItem.basePrice
          ? `${formatPrice(selectedSupplierItem.basePrice, i18next.language)}`
          : ''}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.basePriceDate')}>
        {selectedSupplierItem.basePriceDate
          ? dayjs(selectedSupplierItem.basePriceDate).format('ll')
          : ''}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.basePriceUnit')}>
        {selectedSupplierItem.packages.find((pkge) => pkge.basePriceUnit)?.vilmaUnit.description}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.supplierProductName')}>
        {selectedSupplierItem.supplierProductName}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.nobbNumber')}>
        {selectedSupplierItem.nobbNumber}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.finfoNumber')}>
        {selectedSupplierItem.finfoNumber}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.replacedBySupplierItemId')}>
        {selectedSupplierItem.replacedBySupplierItemId}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.prevMgItemNumber')}>
        {selectedSupplierItem.prevMgItemNumber}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.discountGroup')}>
        {selectedSupplierItem.discountGroup?.name}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.discountGroupDescription')}>
        {selectedSupplierItem.discountGroup?.code}
      </ProductInformationAccordionRow>
    </ProductInformationAccordion>
  );
};
